import { makeStyles } from "@material-ui/core/styles";

export const clientBannerStyles = makeStyles((theme) => ({
  VerificationBanner: {
    paddingBottom: "40px",
    position: "relative",
  },
  MarkAsComplete: {
    position: "absolute",
    right: "50px",
    top: "5px",
  },
  alertMessagePrefix: {
    fontWeight: "bold",
    marginRight: "16px",
  },
  alertMessage: {
    [theme.breakpoints.down("md")]: {
      width: "65%",
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "75%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
}));
