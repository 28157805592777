enum APP_CONSTANT {
  ERROR_NON_OPM_USER = "not present in OPM database",
  DELOITTE = "Deloitte",
  LOCAL_HOST = "localhost",
  CORTEX_11 = "Cortex11",
  DATA_EXTRACTION = "DataExtraction",
  FROM_LEGACY = "fromLegacy",
  ANALYTICS = "analytics",
  CONTENT_LIBRARY = "content-library",
  NOTIFICATION_HUB = "notificationHub",
}

export default APP_CONSTANT;

export const MENU_OPTIONS = [
  "home",
  "data-kitchen",
  "outputs",
  "management",
  "learningLibrary",
  "specialist-tools",
  "select-analytics",
  "sampling",
  "trial-balance",
  "review-and-confirm-analytics",
  "sample",
  "run-analytic",
];

export enum NETWORK_STATUS {
  ONLINE = "online",
  OFFline = "offline",
}

export enum APPINSIGHTS_TITLES {
  APP_NAME = "Cortex UI",
  LANDING = "Home",
  ENGAGEMENT_LINKING_OMNIA = "Engagement Linking (Omnia)",
  ENGAGEMENT_LINKING = "Engagement Linking",
  TRIAL_BALANCE = "Trial Balance",
  DATA_TRANSFORMATIONS = "Data Transformations",
  ADVANCED_SCRIPTING = "Advanced Scripting",
  SPECIALIST_TOOLS = "Specialist Tools",
  SELECT_ANALYTICS = "Select Analytics",
  AUDIT_SAMPLING = "Audit Sampling",
  JE_INSIGHTS = "Journal Entry Testing & Insights - Cortex JE Insights",
  JE_EVALUATION = "Journal Entry Testing & Insights - Evaluation",
  JE_JET = "Journal Entry Testing & Insights",
  REVIEW_CONFIRM_ANALYTICS = "Select Analytics - Review & Confirm",
  ENG_MANAGEMENT = "Engagement Management",
  ENG_HOME = "Analytics Center",
  DATAKITCHEN_UPLOADNEWDATA = "Data Kitchen - General Info",
  DATAKITCHEN_UPLOADENTITYGROUP = "Data Kitchen - Upload Entity Groups",
  DATAKITCHEN_UPLOADENTITY = "Data Kitchen - Upload Entities",
  DATAKITCHEN_GENERATEDATAREQUEST = "Data Kitchen - Request Data",
  DATAKITCHEN_DATASETTINGS = "Data Kitchen - Data Settings",
  DATAKITCHEN_CLIENTDATAMAPPING = "Data Kitchen - View Data Table",
  DATAKITCHEN_ACCOUNTMAPPING = "Data Kitchen - Account Mapping",
  DATA_KITCHEN = "Data Kitchen",
  CONTENT_LIBRARY = "Content Library",
  CLIENT_TEAM = "Manage Client Team",
  CREATE_ACCESS_REQUEST = "Create Access Request",
  REQUEST_HISTORY = "Active Support Requests",
  DEPLOYMENT_HISTORY = "Deployment History",
  CREATE_NEW_ANALTICS = "Create New Analytic",
  ANALYTICS_LIBRARY_EDITOR = "Analytics Library Editor",
  ACCESS_DENIED = "Access Denied",
  LOGIN = "Login",
  DEPLOYMENT_VIEW = "Deployment View",
  VIEW_SUPPORT_REQUEST = "View Support Request",
  VIEW_MANAGE_ANALYTICS = "View & Manage Analytic",
  DATA_INTEGRITY_CHECK = "Add Data Integrity Checks",
  ENGAGEMENT_ANALYTICS_OUTPUT = "Engagement Analytic - Output",
  ENGAGEMENT_ANALYTICS_VISUALIZATION = "Engagement Analytic - Visualization Output",
  JOURNAL_ENTRY_TESTING_INSIGHTS = "Journal Entry Testing & Insights -Test",
  ENGAGEMENT_ANALYTICS = "Engagement Analytic",
  ENGAGEMENT_SAMPLE = "Engagement Sample",
  ENGAGEMENT_LINKING_MANUAL = "Engagement Linking - Manual Linking",
  ENGAGEMENT_LINKING_CREATION_INPROGRESS = "Engagement Linking - Engagement Creation In Progress",
  ENGAGEMENT_LINKING_REQUEST_ACCESS = "Engagement Linking - Request Access",
}
