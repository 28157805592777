import { useState } from "react";

export function useOneTrustInit(
  oneTrustUrl: string | undefined,
  oneTrustDomainScriptId: string | undefined
): {
  isOneTrustReady: boolean;
  isOneTrustDisabled: boolean;
} {
  const [isOneTrustReady, setIsOneTrustReady] = useState<boolean>(false);

  if (oneTrustUrl && oneTrustDomainScriptId && !isOneTrustReady) {
    const oneTrustScriptEl: HTMLElement | null = document.getElementById("oneTrustScript");

    if (!oneTrustScriptEl) {
      // Add one trust  script in the head tag
      const oneTrust: HTMLScriptElement = document.createElement("script");
      oneTrust.id = "oneTrustScript";
      oneTrust.src = oneTrustUrl;
      oneTrust.type = "text/javascript";
      oneTrust.dataset.domainScript = oneTrustDomainScriptId;
      oneTrust.className = "optanon-category-1";
      oneTrust.crossOrigin = "anonymous";

      const head = document.head;
      head.insertBefore(oneTrust, head.firstChild);
    }
    setIsOneTrustReady(true);
  }

  return {
    isOneTrustReady,
    isOneTrustDisabled:
      !oneTrustUrl ||
      oneTrustUrl === "" ||
      !oneTrustDomainScriptId ||
      oneTrustDomainScriptId === "",
  };
}
