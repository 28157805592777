import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "@cortex-libs/i18n";
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonTypes,
  Colors,
  ColumnProps,
  Container,
  ContextMenuOptions,
  Icon,
  Progress,
  ProgressTypes,
  SearchBox,
  Table,
  Text,
  TextVariants,
  Toast,
  ToastVariant,
  UnauthorizedNotification,
} from "@cortex-libs/prism";
import {
  useClient,
  useExtractionUserList,
  UserRole,
  User,
  useCurrentUser,
  EditedExternalClientUserDetails,
} from "@cortex-libs/dmf";
import { manageClientTeamEnum } from "../../../@constants/constants";
import { UserEmail, UserName, RoleUser, contextMenuColumn } from "./ManageClientTeamColumn";
import { IconNames } from "@cortex-libs/assets";
import { TeamMemberModal, EditInfoModal } from "@cortex-modules/engagement";
import { DeleteClientTeamMemberModal } from "./deleteClientTeamMember/DeleteClientTeamMemberModal";
import { ClientVerificationBanner } from "./ClientVerificationBanner/ClientVerificationBanner";

export const ManageClientTeam = (): JSX.Element => {
  const NUMBER_OF_ROWS = 10;

  const { t } = useTranslation();

  const dataInstance = "ClientTeamMember_Users";

  const { id: clientId } = useParams<{ id: string }>();
  const { loggedInUser } = useCurrentUser();
  const {
    client,
    isLoading: isClientLoading,
    showReviewRequired,
    markUserReviewAsComplete,
    clientHttpError,
  } = useClient(clientId);
  const {
    extractionUserList,
    isLoading: isUserLoading,
    addClientTeamMembers,
    isClientTeamMembersAdding,
    lastExcecutedAction,
    refreshUserList,
    deleteClientUser,
    editExternalClientUserName,
  } = useExtractionUserList(true, true, clientId || "");

  const [deleteInprogress, setDeleteInprogress] = useState<boolean>(false);
  const [isTeamMemberModalOpen, setIsTeamMemberModalOpen] = useState<boolean>(false);
  const [searchUsersResult, setSearchUsersResult] = useState<User[]>([]);
  const [currentInProgressPage, setCurrentInProgressPage] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  // -------------------Delete Engagement Users Section Start ----------------
  const [userToBeDeleted, setuserToBeDeleted] = useState<User>({} as User);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const closeDeleteModalHandler = () => setIsDeleteModalOpen(false);

  const onDeleteClientTeamMember = () => {
    deleteClientUser(clientId, userToBeDeleted?.email);
    setIsDeleteModalOpen(false);
  };
  // -------------------Delete Engagement Users Section End -----------------
  let clientTeamMemberUserList: User[] = [];
  // Add new Client Users
  const [newlyAddedMemberEmails, setNewlyAddedMemberEmails] = useState<Array<string>>([]);
  const [newlyAddedMember, setNewlyAddedMember] = useState<Array<string>>([]);
  const [newlyAddedAtTopList, setNewlyAddedAtTopList] = useState<User[]>([]);
  const [verifyToast, setVerifyToast] = useState<React.ReactNode>(<></>);
  const [isDco, setIsDco] = useState<boolean>(false);
  const [canSeeClientMembers, setCanSeeClientMembers] = useState<boolean>(false);

  const getNewlyAddedOptionEmailsHander = (newlyAddedEmails: Array<string>) =>
    setNewlyAddedMemberEmails(newlyAddedEmails);

  const getNewlyAddedMemberHandler = (newlyAddedEmails: Array<string>) =>
    setNewlyAddedMember(newlyAddedEmails);

  useEffect(() => {
    if (newlyAddedMemberEmails?.length) {
      refreshUserList();
    }
  }, [newlyAddedMemberEmails]);

  useEffect(() => {
    Array.from(extractionUserList?.values());
    const clientTeamMemberUserList: User[] = Array.from(extractionUserList?.values());
    const currentUser: User = clientTeamMemberUserList?.find(
      (user) => user?.email === loggedInUser?.email?.toLowerCase()
    );

    if (!isUserLoading) {
      if (currentUser?.email) {
        setCanSeeClientMembers(true);
      } else {
        setCanSeeClientMembers(false);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const roles: any[] = currentUser?.roles;
    if (roles?.find((ro) => ro.name === "Deloitte Client Owner")) {
      setIsDco(true);
    } else {
      setIsDco(false);
    }
  }, [extractionUserList, loggedInUser]);

  useEffect(() => {
    if ((!isUserLoading && newlyAddedMemberEmails?.length) || !newlyAddedMemberEmails?.length) {
      clientTeamMemberUserList = Array.from(extractionUserList?.values());
      clientTeamMemberUserList.sort((arg1: User, arg2: User) =>
        arg1?.firstName?.toLowerCase() > arg2?.firstName?.toLowerCase() ? 1 : -1
      );

      const filteredList: User[] = [
        ...clientTeamMemberUserList?.filter(
          (user) =>
            newlyAddedMember?.includes(user?.email?.toLowerCase()) ||
            Boolean(user?.additionInProgress)
        ),
        ...clientTeamMemberUserList?.filter(
          (user) =>
            !newlyAddedMember?.includes(user?.email?.toLowerCase()) && !user?.additionInProgress
        ),
      ];
      setNewlyAddedAtTopList(filteredList);
      setSearchUsersResult(filteredList);
      if (
        lastExcecutedAction?.toLowerCase() ===
        manageClientTeamEnum.LastExcecutedAction.toLowerCase()
      ) {
        setCurrentInProgressPage(0);
      }
    }
  }, [extractionUserList]);

  const searchUser = (searchTerm: string) => {
    clientTeamMemberUserList = Array.from(extractionUserList?.values());
    if (searchTerm !== "") {
      setSearchUsersResult(
        clientTeamMemberUserList?.filter((user) => {
          return (
            user.lastName?.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
            user.firstName?.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
            user.email?.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
            `${user.firstName?.toLowerCase()} ${user.lastName?.toLowerCase()}`.indexOf(
              searchTerm.toLowerCase()
            ) !== -1 ||
            `${user.lastName?.toLowerCase()} ${user.firstName?.toLowerCase()}`.indexOf(
              searchTerm.toLowerCase()
            ) !== -1
          );
        })
      );
      setCurrentInProgressPage(0);
    } else {
      setSearchUsersResult(newlyAddedAtTopList);
      setCurrentInProgressPage(selectedPage);
    }
  };

  const newTeamMemberHandler = () => setIsTeamMemberModalOpen(!isTeamMemberModalOpen);

  const getJobTitle = (row: Record<string, unknown>) => {
    setDeleteInprogress(false);
    if (row?.additionInProgress) {
      return t("translations:management.adding-user");
    } else if (row?.deletionInProgress) {
      setDeleteInprogress(true);
      return t("translations:management.deleting-user");
    } else if (row?.type === manageClientTeamEnum.DELOITTE) {
      return !row?.jobTitle ? "" : row?.jobTitle;
    } else {
      return manageClientTeamEnum.EXTERNAL;
    }
  };

  // Edit External Client Name functionality Start

  const [selectedClientUser, setSelectedClientUser] = useState<User>({} as User);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const handleEditModalSubmit = (editedClientUserDetails: EditedExternalClientUserDetails) => {
    setIsEditModalOpen(false);
    editExternalClientUserName(clientId, editedClientUserDetails);
  };

  const handleEditModalClose = () => setIsEditModalOpen(false);

  //Edit External Client Name functionality End

  // ContextMenu

  const handleContextMenuClickAway = () => {
    if (!isEditModalOpen && !isDeleteModalOpen) {
      setSelectedClientUser({} as User);
    }
  };
  const handlerContextMenu = (client: User) => {
    setSelectedClientUser(client);
  };
  const handleContextItemClick = (currentItem: ContextMenuOptions | undefined) => {
    const removeButton = document.querySelectorAll<HTMLElement>(
      "[data-instance='menu-item-remove']"
    )[0];

    const editInfoButton = document.querySelectorAll<HTMLElement>(
      "[data-instance='menu-item-edit-info']"
    )[0];
    if (currentItem?.item?.toUpperCase() === t(manageClientTeamEnum.EDIT_INFO).toUpperCase()) {
      setIsEditModalOpen(true);
      if (removeButton !== undefined || removeButton !== null) {
        removeButton.style.display = "none";
      }
      if (editInfoButton !== undefined || editInfoButton !== null) {
        editInfoButton.style.display = "none";
      }
    }
    if (currentItem?.item?.toUpperCase() === t(manageClientTeamEnum.REMOVE).toUpperCase()) {
      setuserToBeDeleted(selectedClientUser);
      setIsDeleteModalOpen(true);
      if (removeButton !== undefined && removeButton !== null) {
        removeButton.style.display = "none";
      }
      if (editInfoButton !== undefined && editInfoButton !== null) {
        editInfoButton.style.display = "none";
      }
    }
  };

  const ClientTeamMemberTablesColumns: ColumnProps[] = [
    {
      field: manageClientTeamEnum.CLIENT_NAME,
      title: t("translations:landing.manage-client.team.table.name")?.toUpperCase(),
      render: (row: Record<string, unknown>): React.ReactNode =>
        UserName(
          `${String(row?.firstName)[0]?.toUpperCase()}${String(row?.lastName)[0]?.toUpperCase()}`,
          String(row?.firstName),
          String(row?.lastName),
          String(getJobTitle(row)),
          row
        ),
    },
    {
      field: manageClientTeamEnum.EMAIL,
      title: t("translations:landing.manage-client.team.table.email")?.toUpperCase(),
      render: (row: Record<string, unknown>): React.ReactNode => UserEmail(String(row?.email)),
    },
    {
      field: manageClientTeamEnum.ROLE,
      title: t("translations:landing.manage-client.team.table.role")?.toUpperCase(),
      render: (row: Record<string, unknown>): React.ReactNode => RoleUser(row?.roles as UserRole[]),
    },
    {
      field: manageClientTeamEnum.MORE_OPTION,
      title: "",
      render: (row: Record<string, unknown>): React.ReactNode => (
        <div style={{ position: "relative" }}>
          {contextMenuColumn(
            String(row?.email),
            t,
            handleContextMenuClickAway,
            handlerContextMenu,
            handleContextItemClick,
            selectedClientUser?.email,
            loggedInUser?.email?.toLowerCase() === String(row?.email)?.toLowerCase(),
            String(getJobTitle(row)) === manageClientTeamEnum.EXTERNAL,
            row,
            deleteInprogress
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!isEditModalOpen || !isDeleteModalOpen) {
      handleContextMenuClickAway();
    }
  }, [isEditModalOpen, isDeleteModalOpen]);

  const closeToast = () => {
    setVerifyToast(<></>);
  };

  const handleMarkAsComplete = () => {
    setVerifyToast(
      <Toast
        title={t("translations:client.verification.success-header")}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        description={t("translations:client.verification.success-message")}
        onClose={closeToast}
        variant={ToastVariant.SUCCESS}
        iconName={IconNames.StatusTick}
        open={true}
        autoHideDuration={10000}
      />
    );
  };

  const markAsComplete = () => {
    markUserReviewAsComplete(client?.id, handleMarkAsComplete);
  };

  return (
    <>
      {isClientLoading && searchUsersResult?.length === 0 ? (
        <Box display="flex" height="100vh" alignItems="center" justifyContent="center">
          <Progress type={ProgressTypes.CIRCULAR} status={"info"} />
        </Box>
      ) : (
        <>
          {client?.name && canSeeClientMembers ? (
            <Box pt={6} pb={16}>
              <Container maxWidth="xl">
                {verifyToast}
                {isDco && (
                  <ClientVerificationBanner
                    isOpen={showReviewRequired}
                    markAsComplete={markAsComplete}
                  />
                )}
                <>
                  <Breadcrumbs
                    crumbs={[
                      {
                        label: t("translations:landing.manage-client.title"),
                        to: "/landing",
                      },
                      {
                        label: client?.name,
                        to: "",
                        disable: true,
                      },
                      {
                        label: t("translations:landing.manage-client.team.title"),
                        to: "",
                      },
                    ]}
                    itemsAfterCollapse={2}
                    itemsBeforeCollapse={2}
                    maxItems={3}
                    target="_blank"
                    key={"clientTeamBreadcrumbs"}
                  />
                  <Box py={5} data-instance="lalal">
                    <Text type={TextVariants.HEADING_1}>
                      {t("translations:landing.manage-client.team.title")}
                    </Text>
                    <Box py={2}>
                      <Text>{t("translations:landing.manage-client.team.description")}</Text>
                    </Box>
                  </Box>
                  <hr></hr>

                  <Box pt={5}>
                    <Box display="flex" justifyContent="space-between">
                      <Box width="100%" data-instance={dataInstance}>
                        <Box display="flex" justifyContent="space-between">
                          <SearchBox
                            placeholder={t("translations:landing.manage-client.team.search")}
                            withOutDropdown={true}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            options={searchUsersResult as any}
                            onInputChange={searchUser}
                            labelKey={t("translations:landing.manage-client.team.search")}
                            width={"320px"}
                          />

                          <Button
                            buttonType={ButtonTypes.TEXT}
                            startIcon={<Icon name={IconNames.Plus} outline></Icon>}
                            onClick={newTeamMemberHandler}
                            className="Add_ClientTeamMember_Button_on_management_HomePage"
                          >
                            <Text type={TextVariants.BODY_COPY_SEMI_BOLD} color={Colors.BLUE_4}>
                              {t("translations:landing.manage-client.team.add")?.toUpperCase()}
                            </Text>
                          </Button>
                        </Box>
                        <Box mt={3}>
                          <Table
                            enablePagination
                            removeSideSpacing
                            rowsPerPage={NUMBER_OF_ROWS}
                            currentPage={currentInProgressPage}
                            onPageChange={(pageNumber: number) => {
                              setCurrentInProgressPage(pageNumber);
                              setSelectedPage(pageNumber);
                            }}
                            rowCount={searchUsersResult?.length}
                            rows={JSON.parse(JSON.stringify(searchUsersResult))}
                            columns={ClientTeamMemberTablesColumns}
                            tableClassName="Client_Team_Member_Table"
                          ></Table>
                        </Box>
                      </Box>
                      <TeamMemberModal
                        isOpen={isTeamMemberModalOpen}
                        onClose={newTeamMemberHandler}
                        getNewlyAddedOptions={getNewlyAddedOptionEmailsHander}
                        addClientTeamMemberFlag={true}
                        addClientTeamMembers={addClientTeamMembers}
                        isClientTeamMembersAdding={isClientTeamMembersAdding}
                        clientId={clientId}
                        getNewlyAddedMemberHandler={getNewlyAddedMemberHandler}
                      />
                    </Box>
                  </Box>
                </>
                <EditInfoModal
                  currentUser={selectedClientUser}
                  isOpen={isEditModalOpen}
                  handleClose={handleEditModalClose}
                  handleSubmit={handleEditModalSubmit}
                />
              </Container>
              <DeleteClientTeamMemberModal
                modalOpen={isDeleteModalOpen}
                handleClose={closeDeleteModalHandler}
                onClickDeleteButton={onDeleteClientTeamMember}
                userInfo={selectedClientUser}
              />
            </Box>
          ) : clientHttpError ? (
            <>
              <UnauthorizedNotification message={t("common:permissions.access-denied-message")} />
            </>
          ) : null}
        </>
      )}
    </>
  );
};
