import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  userNotificationToasterStyles: {
    "& [class*='MuiPaper-root']": {
      maxWidth: "340px !important",
      overflowWrap: "anywhere",
      maxHeight: "90vh",
      overflowY: "auto",
    },
  },
  userNotificationToasterWrapper: {
    "& [class*='MuiSnackbar-root']": { zIndex: "1300 !important" },
  },
});
