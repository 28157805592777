import { Box, AlertDialog, ButtonTypes, AlertDialogTypes } from "@cortex-libs/prism";
import { useTranslation } from "@cortex-libs/i18n";

import { DeleteAlertProps } from "./@constants/DeleteAlertProps";

export const DeleteAlert = (props: DeleteAlertProps): JSX.Element => {
  const { t } = useTranslation();
  const { onClickDeleteButton, setIsDeleteModalOpen, modalMessage, modalTitle, modalSize } = props;

  const handleOnClickDeleteButton = () => {
    onClickDeleteButton();
    setIsDeleteModalOpen();
  };

  const handleClose = () => {
    setIsDeleteModalOpen();
  };

  return (
    <Box px={2} data-testid="reset-modal-je">
      <AlertDialog
        {...props}
        maxWidth={modalSize || "xs"}
        buttons={[
          {
            label: t("common:cancel"),
            buttonType: ButtonTypes.SECONDARY,
            handler: handleClose,
          },
          {
            label: t("common:delete"),
            buttonType: ButtonTypes.PRIMARY,
            handler: handleOnClickDeleteButton,
          },
        ]}
        title={modalTitle}
        message={modalMessage}
        handleClose={handleClose}
        type={AlertDialogTypes.DELETE}
      />
    </Box>
  );
};
