import { IconNames } from "@cortex-libs/assets";
import { TFunction } from "@cortex-libs/i18n";
import { DayMonth } from "@cortex-libs/dmf";
import { ContextMenu, ContextMenuOptions, Icon } from "@cortex-libs/prism";
import { Text, Colors } from "@cortex-libs/prism";
import { manageClientEnum } from "../../@constants/constants";
import { toUpper } from "lodash";

export const getClientName = (clientName: string): JSX.Element => (
  <>
    <Text color={Colors.GRAY_7}>{clientName}</Text>
  </>
);

export const getCountry = (country: string): JSX.Element => (
  <>
    <Text color={Colors.GRAY_7}>{country}</Text>
  </>
);

const convertShortDateToFormatedString = (date: DayMonth, format: string): string => {
  let result = toUpper(format);
  result = result.replace("MM", date.month.toString()?.padStart(2, "0"));
  result = result.replace("DD", date.day.toString()?.padStart(2, "0"));
  return result;
};

export const getFiscalYear = (date: DayMonth, formatPassed: string): JSX.Element => {
  const shortDateFormat = formatPassed;
  return (
    <>
      <Text color={Colors.GRAY_7}>{convertShortDateToFormatedString(date, shortDateFormat)}</Text>
    </>
  );
};

export const contextMenuColumn = (
  clientId: string,
  t: TFunction<"translation">,
  handleContextMenuClickAway: () => void,
  handlerContextMenu: (clientId: string) => void,
  handleContextItemClick: (
    currentItem: ContextMenuOptions | undefined,
    clientId: string,
    clientName: string,
    usesSecureAgent: boolean
  ) => void,
  openContextMenu: string,
  clientName: string,
  usesSecureAgent: boolean
): JSX.Element => {
  return (
    <>
      <ContextMenu
        isOpen={openContextMenu === clientId}
        height="70vh"
        iconSize="medium"
        color={Colors.BLACK_86}
        handleClickAway={() => handleContextMenuClickAway()}
        handleMenuList={() => handlerContextMenu(clientId)}
        onClickItem={(currentItem) =>
          handleContextItemClick(currentItem, clientId, clientName, usesSecureAgent)
        }
        options={[
          {
            item: t(manageClientEnum.SET_UP_AUTO_DATA_EXTRACTION)?.toUpperCase(),
            icon: <Icon name={IconNames.ExternalLink} fontSize="medium" color={Colors.BLACK} />,
          },
          {
            item: t(manageClientEnum.MANAGE_CLIENT_TEAM)?.toUpperCase(),
          },
        ]}
        width="26ch"
      />
    </>
  );
};
