import { useTranslation } from "@cortex-libs/i18n";
import { Modal, Box, TextVariants, Text, Button } from "@cortex-libs/prism";

import { TOUModalProps } from "./@constants/TOUModalProps";
import { useStyles } from "./@constants/Styles";

export const TOUModal = (props: TOUModalProps): JSX.Element => {
  const { t } = useTranslation();
  const translationKey = "translations:tou-modal";
  const classes = useStyles();

  const { onAccept, isOpen, onClose, document, title } = props;

  return (
    <Modal open={isOpen} onCloseButtonClick={onClose}>
      <Box className={classes.touModal}>
        <Box px={5}>
          <Text type={TextVariants.SUB_HEADING_1}>
            {title?.length ? t(title) : t(`${translationKey}.heading-default`)}
          </Text>
          <Box className={classes.touModalDeScription}>
            <Text type={TextVariants.BODY_COPY_REGULAR}>{t(`${translationKey}.description`)}</Text>
            <Button size="medium" type="submit" onClick={onAccept}>
              {t(`${translationKey}.button-text`)}
            </Button>
          </Box>
        </Box>
        <object data={document} type="application/pdf" width="100%" height="79%" />
      </Box>
    </Modal>
  );
};
