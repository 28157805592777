import { ToastVariant } from "@cortex-libs/prism";
import { IconNames } from "@cortex-libs/assets";

export enum UserNotificationMethods {
  // In future, Add new method-names here to push notifications in global-toast
  DELETE_ENGAGEMENT = "delete-engagement",
  LEGALHOLD_SUBMIT = "legalhold-submit",
  LEGALHOLD_LIFT = "legalhold-lift",
  ROLLFORWARD_ENGAGEMENT = "rollforward-engagement",
  WRAPUP_ENGAGEMENT = "wrapup-engagement",
  ENGAGEMENT_CERTIFICATION = "engagement-certification",
  CREATE_EXTERNAL_ENGAGEMENT = "create-external-engagement",
  CREATE_ASYNC_ENGAGEMENT = "create-async-engagement",
  TRANSACTIONANALYSISJE_DWW_TEMPLATE_GENERATION = "TransactionAnalysisJeDWWTemplateGeneration",
  NEW_FRAMEWORK = "method-name-of-notification",
  OUTPUT_FILE_SHARING_STATUS = "output-file-sharing-status",
  CREATE_SUPPORT_TICKET = "create-support-ticket",
  ENCRYPTED_FILE_UPLOAD = "EncryptionFileUpload",
  ENGAGEMENT_RENAME = "rename-engagement",
  DOWNLOAD_INPUT_ASYNC = "downloadInputAsync",
}

export enum GroupNotificationMethods {
  // In future, Add new method-names here for group level notifications
  ENGAGEMENT_CERTIFICATION = "engagement-certification",
  CLIENT_CERTIFICATION = "client-certification",
  WRAPUP_ENGAGEMENT = "wrapup-engagement",
  DATAKITCHEN_DOWNLOAD_ZIP = "DataKitchen_DownloadAsZip",
  DATAKITCHEN_ACCOUNT_MAPPING_IMPORT_TB = "DataKitchen_AccountMapping_ImportTBData",
  WORKBOOK_DOWNLOAD_ZIP = "NoteBook-DownloadAsZip",
  DATA_KITCHEN_IMPORT_ACCOUNTMAPPING = "DataKitchen_AccountMapping_Import",
  ANALYTICENTERPAGEANALYTICLISTREFRESH = "AnalyticCenterPageAnalyticListRefresh",
  DATAKITCHEN_ACCOUNTMAPPING_INDIVIDUALSAVE = "DataKitchen_AccountMapping_IndividualSave",
  DATAKITCHEN_ACCOUNTMAPPING_BULK_SAVE = "DataKitchen_AccountMapping_BulkSave",
  ENGAGEMENT_SAMPLING = "engagement-sampling",
  ENGAGEMENT_SAMPLING_ANALYSIS = "engagement-sampling-analysis",
  ENGAGEMENT_SAMPLING_SUMMARY = "engagement-sampling-summary",
  ENGAGEMENT_SAMPLING_STRATIFICATION = "engagement-sampling-stratification",
  ENGAGEMENT_SAMPLING_EXTRACTION = "engagement-sampling-extraction",
  UPDATE_DATA_INBOX = "UpdateDataInbox",
  UPDATE_INPUT = "UPDATEINPUT",
  FILE_UPLOAD_STATUS_TB_PAGE = "DataKitchen_FileUpload_Status",
  DATA_INTEGRITY_CHECKS_RESULT_STATUS = "data-integrity-check-spark",
  DATA_INTEGRITY_CHECKS_RESULT_REPORT = "data-integrity-check-report",
  DATA_INTEGRITY_CHECK_STATUS_TB_PAGE = "DataKitchen_CombinedDic_Status",
  COMBINED_DATASET_STATUS_TB_PAGE = "DataKitchen_CombinedDataset_Status",
  DATAKITCHEN_ACCOUNT_MAPPING_CUSTOM_EDIT_SAVE = "DataKitchen_AccountMapping_Custom_Edit_Save",
  FILE_PREVIEW = "FilePreview",
  FILE_UPLOAD = "FileUpload",
  DIC_SPARK_JOB = "DicSparkJob",
  DIC_REPORT_JOB = "DicReportJob",
  CDM_DATA_TABLE_PREVIEW = "CdmDataTablePreview",
  UPLOAD_TO_CDM = "UploadToCdm",
  UPLOAD_TO_RAW = "UploadToRaw",
  CSV_PQT_GENERATION = "csv-to-pqt-generation",
  FDR_DOWNLOAD = "FDR_DOWNLOAD",
}

export enum UserNotificationStatus {
  SUCCESS = "success",
  FAILED = "failure",
  INPROGRESS = "inProgress",
  PARTIALLYCOMPLETE = "partiallycompleted",
  MANUALENGAGEMENTCREATIONPARTIALCOMPLETE = "partiallycompleted",
}

export enum UserNotificationConstants {
  CONTACT_SUPPORT_LINK = "https://deloitteglobal.service-now.com/sp?id=kb_article_view&sysparm_article=KB0055217",
  LINK_DETECTOR = "{{__",
  LINK_DETECTOR_PREFIX = "{{__",
  LINK_DETECTOR_SUFIX = "__}}",
  BUTTON = "button",
  APPSETTING = "appsettings",
  DEFAULT_AUTO_HIDE_DURATION = 5000,
  ACTIONS = "actions",
  DEFAULT_CONTRACT_VERSION = "1.0",
  DEFAULT_SCOPE = "User",
  FEATURE_FLAG = "FeatureFlag",
}

export type ToasterElements = {
  variant: ToastVariant;
  iconName: IconNames;
  title: string;
  description: React.ReactNode | string;
  footer: React.ReactNode;
  disableAutoHide: boolean;
};

export enum ToasterActions {
  OPEN_CREATE_TICKET_MODAL = "openCreateSupportTicketModal",
}
