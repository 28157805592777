import { GeoList } from "@cortex-libs/dmf";
import APP_CONSTANT from "../app/@constant/app";

export const isUserInDefaultGeo = (geoList: Array<GeoList>, defaultGeoCode: string): boolean => {
  const currentURL = window.location.origin;
  if (!currentURL.includes(APP_CONSTANT.LOCAL_HOST)) {
    if (geoList && defaultGeoCode) {
      const foundURL = geoList?.find((geo: GeoList) => geo?.geocode === defaultGeoCode)
        ?.url as string;
      if (foundURL && new URL(foundURL)?.origin === currentURL) {
        return true;
      }
      if (currentURL.includes(`.${defaultGeoCode.toLowerCase()}.`)) {
        return true;
      }
    }
    return false;
  }
  return true;
};

export const getEnviornmentGeoCode = (geoList: Array<GeoList>): string | null => {
  if (geoList?.length) {
    return (
      geoList?.find(
        (geo: GeoList) => geo?.url && new URL(geo?.url)?.origin === window.location.origin
      )?.geocode || null
    );
  }
  return null;
};
