import { useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, useHistory, Redirect } from "react-router-dom";
import {
  GeoPickerOptions,
  Progress,
  ProgressTypes,
  HeaderRoutes,
  Box,
  Notification,
  AlertVariants,
} from "@cortex-libs/prism";
import { ANALYTICS_PATH, AnalyticsRouter } from "@cortex-modules/analytics";
import { CONTENT_LIBRARY_PATH, ContentLibraryRouter } from "@cortex-modules/content-library";
import { EngagementRouter } from "@cortex-modules/engagement";
import {
  LOCAL_STORAGE_KEYS,
  useCurrentUser,
  useUserGlobalPermissions,
  Permission,
  Action,
  useConfiguration,
  useSignalR,
  ContentAuthorRole,
  RolesMasterRole,
  useGeoList,
  ENGAGEMENT_PATH,
  ExternalUserType,
  useFetchTOUDocument,
  useLanguage,
  useAdobeTracking,
} from "@cortex-libs/dmf";

import { OneTrustInitializer } from "../components/OneTrustInitializer/OneTrustInitializer";
import { Landing, Login } from "../screens";
import APP_ROUTES from "./routes";
import "./app.css";
import APP_CONSTANT, { MENU_OPTIONS, NETWORK_STATUS, APPINSIGHTS_TITLES } from "./@constant/app";
import { TOUModal } from "../components/TOUModal/TOUModal";
import { Documents } from "../screens/Login/@constants/LoginFooter";
import { MaintenanceBanner } from "../components/MaintenanceBanner/MaintenanceBanner";
import { MainHeader } from "../components/main-header";
import { useI18nInit } from "./useI18nInit";
import { AccessDenied } from "../screens/AccessDenied/AccessDenied";
import { UserNotificationToaster } from "../components/UserNotificationToaster/UserNotificationToaster";
import { ToasterActions } from "../components/UserNotificationToaster/@constants/constant";
import { CreateSupportTicketModal } from "../components/CreateSupportTicketModal/CreateSupportTicketModal";
import { ManageClientTeam } from "../screens/Landing/components/manageClient/manageClientTeam/ManageClientTeam";
import { DisplayIdleModal } from "../components/DisplayIdleModal/DisplayIdleModal";
import { HubConnection } from "@microsoft/signalr";
import {
  Auth,
  isAuthenticated,
  handleAuthError,
  GetProgressStatus,
  InteractionStatus,
} from "../utils/authHelper";

import { ServiceInitializer } from "../components/ServiceInitializer/ServiceInitializer";
import env from "../utils/env.js";
import { sessionTimeoutEnum } from "@cortex-apps/main/src/screens/Landing/@constants/constants";
import CookiesNotice from "../CookiesNote/Cookie-Notice";
import { useAppInsights } from "./appinsights";
const Main = () => {
  const {
    loggedInUser,
    localError,
    unacceptedDocuments,
    acceptTermsOfUse,
    isLoading,
    addTokenToBlacklist,
  } = useCurrentUser();
  const { downloadedDocument, openDocInNewTab } = useFetchTOUDocument();
  const { canExecuteAction } = useUserGlobalPermissions([Permission.ENGAGEMENTS], Action.View);
  const { baseConfiguration, setSettings } = useConfiguration();
  const { appInsights } = useAppInsights();
  const {
    signalRMessage,
    subscribeToSignalR,
    userNotifications,
    userNotificationCloseHandler,
    joinGroup,
    removeFromGroup,
    unsubscribeToSignalR,
    signalRConnections,
  } = useSignalR();
  const [userUnAuthorized, setUserUnAuthorized] = useState<boolean>(false);
  const [noAccess, setnoAccess] = useState<boolean>(false);
  const { geoList } = useGeoList();
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [showNotificationMessage, setShowNotificationMessage] = useState<boolean>(false);
  useAdobeTracking({
    loggedInUser: loggedInUser,
    geoLocation: baseConfiguration?.geoLocation,
  });

  const currentPage = useHistory().location.pathname;
  const { isLanguageChange } = useLanguage();
  const userPreferedLang = isLanguageChange || loggedInUser?.preferredLanguage;
  const { isI18nInitialized } = useI18nInit(userPreferedLang, baseConfiguration?.apiUrl);
  const [previousJoinedGroup, setPreviousJoinedGroup] = useState<string>("");
  const [previousJoinedEngGroup, setPreviousJoinedEngGroup] = useState<string>("");
  const [signalRSubscription, setSignalRSubscription] = useState<boolean>(false);
  const [idleModalDisplay, setIdleModalDisplay] = useState<boolean>(false);

  useEffect(() => {
    if (!baseConfiguration) return;

    if (!currentPage.includes("/engagement/") && !currentPage.includes("/client/")) {
      subscribeToSignalR(`${baseConfiguration.apiUrl}/${APP_CONSTANT.NOTIFICATION_HUB}`);
      setSignalRSubscription(true);
    }
  }, [baseConfiguration]);

  const handleError = (error: unknown) => {
    // eslint-disable-next-line no-console
    console.error(error);
  };

  const getGroupName = (pageUrl: string) => {
    const pageData = pageUrl?.split("/");
    if (pageData.length > 3) {
      return pageData[1] + "-" + pageData[2] + "-" + pageData[3];
    } else {
      return "";
    }
  };

  const getEngagementGroupName = (pageUrl: string) => {
    const pageData = pageUrl?.split("/");
    if (pageData.length > 3) {
      return pageData[1] + "-" + pageData[2];
    } else {
      return "";
    }
  };

  const addToSignalRGroup = (groupName: string) => {
    if (!baseConfiguration) return;
    const engagementGroupName = getEngagementGroupName(currentPage);
    if (previousJoinedGroup && previousJoinedGroup !== groupName) {
      removeFromGroup(
        `${baseConfiguration.apiUrl}/${APP_CONSTANT.NOTIFICATION_HUB}`,
        previousJoinedGroup
      );
      setPreviousJoinedGroup("");
    }
    if (previousJoinedEngGroup && previousJoinedEngGroup !== engagementGroupName) {
      removeFromGroup(
        `${baseConfiguration.apiUrl}/${APP_CONSTANT.NOTIFICATION_HUB}`,
        previousJoinedEngGroup
      );
      setPreviousJoinedEngGroup("");
    }
    if (currentPage.includes("/engagement/") || currentPage.includes("/client/")) {
      if (
        !signalRSubscription &&
        (!signalRConnections ||
          !signalRConnections.includes(
            `${baseConfiguration?.apiUrl}/${APP_CONSTANT.NOTIFICATION_HUB}`
          ))
      ) {
        subscribeToSignalR(
          baseConfiguration?.apiUrl
            ? `${baseConfiguration?.apiUrl}/${APP_CONSTANT.NOTIFICATION_HUB}`
            : ``,
          (currentConnection: HubConnection) => {
            try {
              setSignalRSubscription(true);
              currentConnection?.invoke("AddToGroup", groupName);
              setPreviousJoinedEngGroup(engagementGroupName);
              currentConnection?.invoke("AddToGroup", engagementGroupName);
            } catch (error) {
              handleError(error);
            }
          }
        );
      } else {
        joinGroup(`${baseConfiguration.apiUrl}/${APP_CONSTANT.NOTIFICATION_HUB}`, groupName);
        if (engagementGroupName && previousJoinedEngGroup !== engagementGroupName) {
          joinGroup(
            `${baseConfiguration.apiUrl}/${APP_CONSTANT.NOTIFICATION_HUB}`,
            engagementGroupName
          );
          setPreviousJoinedEngGroup(engagementGroupName);
        }
      }
      setPreviousJoinedGroup(groupName);
    }
  };

  // Engagement Signalr
  useEffect(() => {
    if (!baseConfiguration) return;
    const groupName = getGroupName(currentPage);
    addToSignalRGroup(groupName);
  }, [currentPage, baseConfiguration]);

  useEffect(() => {
    if (signalRMessage?.length > 0) {
      setNotificationMessage(signalRMessage);
      setShowNotificationMessage(true);
      setTimeout(() => {
        setShowNotificationMessage(false);
      }, 5000);
    }
  }, [signalRMessage]);

  //UserNotificationToaster Section
  const [isSupportTicketModalOpen, setIsSupportTicketModalOpen] = useState<boolean>(false);
  const [supportTicketModalDetails, setSupportTicketModalDetails] = useState<
    Record<string, string>
  >({});

  const handleToasterActions = (actionName: string, notificationData?: Record<string, string>) => {
    if (actionName === ToasterActions.OPEN_CREATE_TICKET_MODAL) {
      setSupportTicketModalDetails(
        {
          ...notificationData,
          userId: loggedInUser?.email || "",
        } || {}
      );
      setIsSupportTicketModalOpen(true);
    }
  };

  const handleCloseSupportTicketModal = () => setIsSupportTicketModalOpen(false);

  const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];
  let timer: NodeJS.Timeout;
  let Idletimer: NodeJS.Timeout;
  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // this resets idle the timer if it exists.
  const resetIdleTimer = () => {
    if (Idletimer) clearTimeout(Idletimer);
  };
  // logs out user by clearing out auth token in localStorage and redirecting url to /login page.
  const logoutAction = () => {
    localStorage.clear();
    sessionStorage.clear();
    Auth.logout();
    window.location.href = window.location.origin + "/login";
  };

  // logs out user by clearing out auth token in localStorage and redirecting url to /login page.
  const stayloggedIn = () => {
    setIdleModalDisplay(false);
  };

  const userIsActive = () => {
    const lastActivity = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_ACTIVITY_TIME);
    if (lastActivity) {
      const storedTime = new Date(lastActivity).getTime();
      const currentTime = new Date().getTime();
      const timeDifferenceMilliseconds = currentTime - storedTime;
      if (timeDifferenceMilliseconds < sessionTimeoutEnum.SESSION_IDLE_TIMEOUT) return true;
      else return false;
    }

    return false;
  };
  const userIdleWarning = () => {
    const lastActivity = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_ACTIVITY_TIME);
    if (lastActivity) {
      const storedTime = new Date(lastActivity).getTime();
      const currentTime = new Date().getTime();
      const timeDifferenceMilliseconds = currentTime - storedTime;
      if (timeDifferenceMilliseconds < sessionTimeoutEnum.SESSION_IDLE_WARNING) return true;
      else return false;
    }

    return false;
  };

  // this function sets the timer that logs out the user after 30 min
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // check if user is active or not , if so , reset the timer else logout
      if (userIsActive()) handleLogoutTimer();
      else logoutAction();
    }, sessionTimeoutEnum.SESSION_IDLE_TIMEOUT);
  };
  // this function sets the timer that logs out the user after 30 min
  const handleIdleTimer = () => {
    Idletimer = setTimeout(() => {
      // clears any pending timer.
      resetIdleTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetIdleTimer);
      });
      // check if user is active or not , if so , reset the timer else logout

      if (userIdleWarning()) handleIdleTimer();
      else {
        if (!idleModalDisplay) {
          setIdleModalDisplay((prevIdleModalDisplay) => {
            if (!prevIdleModalDisplay) {
              return true;
            }
            return prevIdleModalDisplay;
          });
        }
      }
    }, sessionTimeoutEnum.SESSION_IDLE_WARNING);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 30 min of inactivity resets.
  // However, if none of the event is triggered within configurable time, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.DISPLAYIDLEOPEN, "FALSE");
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        if (localStorage.getItem(LOCAL_STORAGE_KEYS.DISPLAYIDLEOPEN) == "FALSE") {
          const currentTime = new Date();
          localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_ACTIVITY_TIME, currentTime.toJSON());
          resetTimer();
          handleLogoutTimer();
          resetIdleTimer();
          handleIdleTimer();
        }
      });
    });
  }, []);

  useEffect(() => {
    if (loggedInUser?.lastRefreshed && !loggedInUser?.email) {
      setnoAccess(true);
    }

    if (!baseConfiguration) {
      setSettings();
    }

    if (baseConfiguration && loggedInUser) {
      if (loggedInUser?.type === ExternalUserType?.name && !canExecuteAction) {
        window.location.href = `${baseConfiguration?.auditUrl}/clientview/setup`;
      } else {
        loggedInUser?.appRoles?.forEach((role) => {
          if (!canExecuteAction) {
            switch (role) {
              case ContentAuthorRole.name: {
                if (
                  currentPage?.split("/")[1] !== APP_CONSTANT.ANALYTICS &&
                  currentPage?.split("/")[1] !== APP_CONSTANT.CONTENT_LIBRARY
                )
                  window.location.href = `${baseConfiguration?.auditUrl}/content-library/source-systems`;
                break;
              }
              case RolesMasterRole.name: {
                window.location.href = `${baseConfiguration?.auditUrl}/admin/roles`;
                break;
              }
              default:
                break;
            }
          } else {
            switch (role) {
              case RolesMasterRole.name: {
                window.location.href = `${baseConfiguration?.auditUrl}/admin/roles`;
                break;
              }
              default:
                break;
            }
          }
        });
      }
    }
    // Handling SignalR connection lifetime based on network status
    if (!baseConfiguration) return;
    window.addEventListener(NETWORK_STATUS.OFFline, () => {
      if (previousJoinedGroup) {
        removeFromGroup(
          `${baseConfiguration.apiUrl}/${APP_CONSTANT.NOTIFICATION_HUB}`,
          previousJoinedGroup
        );
        setPreviousJoinedGroup("");
      }
      unsubscribeToSignalR(`${baseConfiguration?.apiUrl}/${APP_CONSTANT.NOTIFICATION_HUB}`);
      setSignalRSubscription(false);
    });
    window.addEventListener(NETWORK_STATUS.ONLINE, () => {
      if (currentPage.includes("/engagement/")) {
        const groupName = getGroupName(currentPage);
        addToSignalRGroup(groupName);
      } else {
        subscribeToSignalR(`${baseConfiguration.apiUrl}/${APP_CONSTANT.NOTIFICATION_HUB}`);
      }
    });
  }, [baseConfiguration, loggedInUser, currentPage]);

  // Local storage event handler -- to reload on cache clear
  const handleLocalStorage = useCallback(() => {
    if (!localStorage.length) {
      window.location.reload();
    }
  }, [localStorage]);

  useEffect(() => {
    // on mount
    window.addEventListener("storage", handleLocalStorage);

    // on unmount
    return () => {
      window.removeEventListener("storage", handleLocalStorage);
    };
  }, []);

  // Terms of Use Modal
  const { CORTEX_11, DELOITTE } = APP_CONSTANT;
  const [displayedDocument, setDisplayedDocument] = useState<string>("");
  const [titleOfTOU, setTitleOfTOU] = useState<string>("");
  useEffect(() => {
    if (unacceptedDocuments?.length) {
      const TOUTranslationKey = "translations:tou-modal";
      if (isLoading) return;
      const isNotAcceptedYet = unacceptedDocuments?.some((tou) =>
        [
          CORTEX_11,
          // Add names of unaccepted documents here in future
        ].includes(tou as APP_CONSTANT)
      );
      const conditionForOpen =
        isNotAcceptedYet && loggedInUser?.type && loggedInUser?.type !== DELOITTE;
      if (conditionForOpen) {
        openDocInNewTab(baseConfiguration?.termsOfUsePath || "", Documents.TOU, true);
        setTitleOfTOU(`${TOUTranslationKey}.heading-client-tou`);
        if (downloadedDocument) {
          setDisplayedDocument(String(downloadedDocument));
        }
      } else setDisplayedDocument("");
    }
  }, [unacceptedDocuments, isLoading, loggedInUser, downloadedDocument]);

  useEffect(() => {
    /*
      Checking if user is unAuthorized
      For Non OPM user : if error is coming "user is not present in OPM database"
    */
    setUserUnAuthorized(false);
    if (localError && localError.length > 0) {
      setUserUnAuthorized(true);
    }
  }, [localError]);

  const onTOUAccept = () => acceptTermsOfUse(CORTEX_11);
  const continueUserLogout = useCallback(() => {
    Auth.logout();
  }, []);

  const onTOUDeny = useCallback(() => {
    addTokenToBlacklist(continueUserLogout);
  }, []);

  // Redirect to default-geo-url of user
  useEffect(() => {
    if (!(window.location.href.indexOf(APP_CONSTANT.LOCAL_HOST) > -1)) {
      const paramValue = new URLSearchParams(window.location.search)?.get(
        HeaderRoutes.SELECTED_GEO_PARAM
      );

      if (paramValue?.length) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.IS_USER_DEFAULT_GEO, "true");
        localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_GEO, paramValue);
      }

      const newURL = paramValue?.length
        ? window.location.href?.split("?")[0]
        : window.location.href;
      window.history.pushState({}, "", newURL);

      if (geoList?.length && loggedInUser) {
        const isUserDefaultGeo = localStorage.getItem(LOCAL_STORAGE_KEYS.IS_USER_DEFAULT_GEO);
        const shouldRedirectToDefault = isUserDefaultGeo === "false";
        // We'll grab the Geo primarily from the user. If it doesn't have a geoCode, we will grab it from the configuration file.
        let currentGeo = loggedInUser.geoCode || baseConfiguration?.geoLocation || env?.GeoLocation;
        currentGeo = currentGeo?.toUpperCase()?.trim();

        let defaultUrl = geoList?.filter(
          (option: GeoPickerOptions) => option?.geocode === currentGeo
        )[0]?.url;
        defaultUrl = !defaultUrl ? geoList[0]?.url : defaultUrl;
        const defaultUrlOrigin = defaultUrl ? new URL(defaultUrl)?.origin : null;

        if (shouldRedirectToDefault) {
          window.location.href = defaultUrl;
        }

        if (isUserDefaultGeo === null) {
          if (defaultUrlOrigin && window.location.origin === defaultUrlOrigin)
            localStorage.setItem(LOCAL_STORAGE_KEYS.IS_USER_DEFAULT_GEO, "true");
          else {
            localStorage.setItem(LOCAL_STORAGE_KEYS.IS_USER_DEFAULT_GEO, "false");
            if (defaultUrl) window.location.href = defaultUrl;
          }
        }
      }
    }
  }, [loggedInUser?.geoCode, window.location, geoList]);

  //Maintenance Banner
  const [displayMaintenaceBanner, setDisplayMaintenaceBanner] = useState<boolean>(false);

  const getPageTitleData = () => {
    let pageTitle;
    const engLinkingregex = /\/engagement\/linking\/manual\/[\w-]+/;
    const runAnalyticsregex = /\/run-analytic\/[\w-]+/;
    switch (true) {
      case currentPage.endsWith("/landing"):
        pageTitle = APPINSIGHTS_TITLES.LANDING;
        break;
      case currentPage.endsWith("/omnia"):
        pageTitle = APPINSIGHTS_TITLES.ENGAGEMENT_LINKING_OMNIA;
        break;
      case currentPage.endsWith("/external"):
        pageTitle = APPINSIGHTS_TITLES.ENGAGEMENT_LINKING;
        break;
      case currentPage.endsWith("/trial-balance"):
        pageTitle = APPINSIGHTS_TITLES.TRIAL_BALANCE;
        break;
      case currentPage.endsWith("/data-transformations-template"):
        pageTitle = APPINSIGHTS_TITLES.DATA_TRANSFORMATIONS;
        break;
      case currentPage.endsWith("/databricks-notebook"):
        pageTitle = APPINSIGHTS_TITLES.ADVANCED_SCRIPTING;
        break;
      case currentPage.endsWith("/specialist-tools"):
        pageTitle = APPINSIGHTS_TITLES.SPECIALIST_TOOLS;
        break;
      case currentPage.endsWith("/select-analytics"):
        pageTitle = APPINSIGHTS_TITLES.SELECT_ANALYTICS;
        break;
      case currentPage.endsWith("/sampling"):
        pageTitle = APPINSIGHTS_TITLES.AUDIT_SAMPLING;
        break;
      case currentPage.endsWith("/insights"):
        pageTitle = APPINSIGHTS_TITLES.JE_INSIGHTS;
        break;
      case currentPage.endsWith("/evaluation"):
        pageTitle = APPINSIGHTS_TITLES.JE_EVALUATION;
        break;
      case currentPage.endsWith("/jet"):
        pageTitle = APPINSIGHTS_TITLES.JE_JET;
        break;
      case currentPage.endsWith("/review-and-confirm-analytics"):
        pageTitle = APPINSIGHTS_TITLES.REVIEW_CONFIRM_ANALYTICS;
        break;
      case currentPage.endsWith("/management"):
        pageTitle = APPINSIGHTS_TITLES.ENG_MANAGEMENT;
        break;
      case currentPage.endsWith("/home"):
        pageTitle = APPINSIGHTS_TITLES.ENG_HOME;
        break;
      case currentPage.endsWith("/uploadnewdata"):
        pageTitle = APPINSIGHTS_TITLES.DATAKITCHEN_UPLOADNEWDATA;
        break;
      case currentPage.endsWith("/uploadentitygroupdata"):
        pageTitle = APPINSIGHTS_TITLES.DATAKITCHEN_UPLOADENTITYGROUP;
        break;
      case currentPage.endsWith("/uploadentitiesdata"):
        pageTitle = APPINSIGHTS_TITLES.DATAKITCHEN_UPLOADENTITY;
        break;
      case currentPage.endsWith("/generate-datarequest"):
        pageTitle = APPINSIGHTS_TITLES.DATAKITCHEN_GENERATEDATAREQUEST;
        break;
      case currentPage.endsWith("/datasettings"):
        pageTitle = APPINSIGHTS_TITLES.DATAKITCHEN_DATASETTINGS;
        break;
      case currentPage.endsWith("/clientdatamapping"):
        pageTitle = APPINSIGHTS_TITLES.DATAKITCHEN_CLIENTDATAMAPPING;
        break;
      case currentPage.endsWith("/accountmapping"):
        pageTitle = APPINSIGHTS_TITLES.DATAKITCHEN_ACCOUNTMAPPING;
        break;
      case currentPage.endsWith("/data-kitchen"):
        pageTitle = APPINSIGHTS_TITLES.DATA_KITCHEN;
        break;
      case currentPage.endsWith("/content-library"):
        pageTitle = APPINSIGHTS_TITLES.CONTENT_LIBRARY;
        break;
      case currentPage.endsWith("/team"):
        pageTitle = APPINSIGHTS_TITLES.CLIENT_TEAM;
        break;
      case currentPage.endsWith("/supportrequest"):
        pageTitle = APPINSIGHTS_TITLES.CREATE_ACCESS_REQUEST;
        break;
      case currentPage.endsWith("/requesthistory"):
        pageTitle = APPINSIGHTS_TITLES.REQUEST_HISTORY;
        break;
      case currentPage.endsWith("/deploymenthistory"):
        pageTitle = APPINSIGHTS_TITLES.DEPLOYMENT_HISTORY;
        break;
      case currentPage.endsWith("/add-analytic"):
        pageTitle = APPINSIGHTS_TITLES.CREATE_NEW_ANALTICS;
        break;
      case currentPage.endsWith("/analytics"):
        pageTitle = APPINSIGHTS_TITLES.ANALYTICS_LIBRARY_EDITOR;
        break;
      case currentPage.endsWith("/access-denied"):
        pageTitle = APPINSIGHTS_TITLES.ACCESS_DENIED;
        break;
      case currentPage.endsWith("/login"):
        pageTitle = APPINSIGHTS_TITLES.LOGIN;
        break;
      case currentPage.includes("/deploymentview/"):
        pageTitle = APPINSIGHTS_TITLES.DEPLOYMENT_VIEW;
        break;
      case currentPage.includes("/supportrequest/"):
        pageTitle = APPINSIGHTS_TITLES.VIEW_SUPPORT_REQUEST;
        break;
      case currentPage.includes("/view-analytic/"):
        pageTitle = APPINSIGHTS_TITLES.VIEW_MANAGE_ANALYTICS;
        break;
      case currentPage.includes("/data-integrity-check/"):
        pageTitle = APPINSIGHTS_TITLES.DATA_INTEGRITY_CHECK;
        break;
      case currentPage.includes("/new-engagement/"):
        pageTitle = APPINSIGHTS_TITLES.ENGAGEMENT_LINKING_CREATION_INPROGRESS;
        break;
      case currentPage.includes("/request-access/"):
        pageTitle = APPINSIGHTS_TITLES.ENGAGEMENT_LINKING_REQUEST_ACCESS;
        break;
      case currentPage.includes("/sample/"):
        pageTitle = APPINSIGHTS_TITLES.ENGAGEMENT_SAMPLE;
        break;
      case currentPage.includes("/jet/test/"):
        pageTitle = APPINSIGHTS_TITLES.JOURNAL_ENTRY_TESTING_INSIGHTS;
        break;
      case currentPage.includes("/output/"):
        pageTitle = APPINSIGHTS_TITLES.ENGAGEMENT_ANALYTICS_VISUALIZATION;
        break;
      case currentPage.includes("/data/"):
        pageTitle = APPINSIGHTS_TITLES.ENGAGEMENT_ANALYTICS_OUTPUT;
        break;
      case engLinkingregex.test(currentPage):
        pageTitle = APPINSIGHTS_TITLES.ENGAGEMENT_LINKING_MANUAL;
        break;
      case runAnalyticsregex.test(currentPage):
        pageTitle = APPINSIGHTS_TITLES.ENGAGEMENT_ANALYTICS;
        break;
      default:
        pageTitle = APPINSIGHTS_TITLES.APP_NAME;
    }
    if (pageTitle !== APPINSIGHTS_TITLES.APP_NAME) {
      pageTitle = APPINSIGHTS_TITLES.APP_NAME + " | " + pageTitle;
    }
    return pageTitle;
  };

  useEffect(() => {
    setDisplayMaintenaceBanner(
      !currentPage.split("/")?.some((route) => MENU_OPTIONS.includes(route))
    );
    if (appInsights) {
      const pageName = getPageTitleData();
      appInsights?.trackPageView({ name: pageName });
    }
  }, [currentPage]);

  const getAppData = () => {
    if (
      loggedInUser?.lastRefreshed &&
      (userUnAuthorized ||
        noAccess ||
        (loggedInUser?.type !== APP_CONSTANT.DELOITTE && !canExecuteAction))
    ) {
      return (
        <>
          <MainHeader />
          <Redirect to={APP_ROUTES.ACESS_DENIED} />
          <Route path={APP_ROUTES.ACESS_DENIED}>
            <AccessDenied />
          </Route>
        </>
      );
    } else {
      return (
        <>
          {!loggedInUser ||
          (loggedInUser != null && !loggedInUser.lastRefreshed) ||
          !isI18nInitialized ? (
            <Box display="flex" mt={10} width="100%" justifyContent="center">
              <Progress status="info" type={ProgressTypes.CIRCULAR} />
            </Box>
          ) : (
            <>
              {idleModalDisplay && (
                <DisplayIdleModal
                  logoutAction={logoutAction}
                  isOpen={true}
                  stayloggedIn={stayloggedIn}
                />
              )}
              <TOUModal
                isOpen={!!displayedDocument?.length}
                onAccept={onTOUAccept}
                onClose={onTOUDeny}
                document={displayedDocument}
                title={titleOfTOU}
              />
              <MainHeader />
              <MaintenanceBanner
                style={{ marginTop: "40px", padding: "0px 76px" }}
                isOpen={displayMaintenaceBanner}
              />
              {showNotificationMessage ? (
                <Notification
                  isNotificationBanner
                  severity={AlertVariants.INFO}
                  message={
                    <>
                      <span style={{ marginRight: "15px" }}>{notificationMessage}</span>
                    </>
                  }
                  isOpen={showNotificationMessage}
                  showClose={false}
                />
              ) : (
                <> </>
              )}
              <CreateSupportTicketModal
                open={isSupportTicketModalOpen}
                onClose={handleCloseSupportTicketModal}
                modalDetails={supportTicketModalDetails}
              />
              <UserNotificationToaster
                notifications={userNotifications}
                onClose={userNotificationCloseHandler}
                handleActions={handleToasterActions}
              />
              <Route exact path="/">
                <Landing />
              </Route>
              <Route exact path={APP_ROUTES.LANDING}>
                <Landing />
              </Route>
              <Route path={ANALYTICS_PATH}>
                <AnalyticsRouter />
              </Route>
              <Route path={CONTENT_LIBRARY_PATH}>
                <ContentLibraryRouter />
              </Route>
              <Route exact path={APP_ROUTES.MANAGE_CLIENT_TEAM}>
                <ManageClientTeam />
              </Route>
              <Route path={ENGAGEMENT_PATH}>
                <EngagementRouter />
              </Route>
            </>
          )}
        </>
      );
    }
  };

  return getAppData();
};

const ProtectedApp = () => {
  const isAuth = isAuthenticated();
  const progressStatus = GetProgressStatus();
  const [loginError, setloginError] = useState<string>("");

  /**
   * Added event callback to capture failed login event
   */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Auth.getAuthInstance().addEventCallback((message: any) => {
    if (message.eventType === "msal:loginFailure") {
      setloginError(message?.error?.errorCode);
    }
  });

  useEffect(() => {
    if (progressStatus === InteractionStatus.None && !isAuth) {
      Auth.login();
    }
  }, [progressStatus, isAuth]);

  if (isAuth && Auth.getActiveAccount()) {
    return (
      <ServiceInitializer>
        <Main />
      </ServiceInitializer>
    );
  } else if (loginError !== "") {
    window.localStorage.clear();
    window.sessionStorage.clear();
    handleAuthError(loginError);
    return (
      <Box display="flex" mt={10} width="100%" justifyContent="center">
        Cannot authenticate user account...
      </Box>
    );
  } else {
    return (
      <Box display="flex" mt={10} width="100%" justifyContent="center">
        <Progress status="info" type={ProgressTypes.CIRCULAR} />
      </Box>
    );
  }
};

function App(): JSX.Element {
  return (
    <OneTrustInitializer>
      <Router>
        <Switch>
          <Route path="/CookiesNotice" component={CookiesNotice} />
          <Route exact path={APP_ROUTES.LOGIN} component={Login} />
          <ProtectedApp />
        </Switch>
      </Router>
    </OneTrustInitializer>
  );
}

export default App;
