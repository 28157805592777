export enum Documents {
  TOU = "Client Terms of Use.pdf",
  NOTICES = "Frontend Notices.pdf",
  COOKIE_NOTICE = "Cookie Notice.pdf",
}

export enum DocumentsPath {
  TOU = "/dev/static/CORTEX TOU.pdf",
  PRIVACY_STATEMENT = "https://www2.deloitte.com/us/en/legal/privacy.html",
  NOTICES = "/dev/static/FRONT-END NOTICES.pdf",
  DATA_EXTRACTION = "/dev/static/Data Extraction Terms of Use.pdf",
  COOKIE_NOTICES = "/dev/static/Cookie Notice.pdf",
}

export enum DocumentsNewWindowTitle {
  TOU = "Terms of Use",
  PRIVACY_STATEMENT = "Privacy",
  NOTICES = "Notices",
  DATA_EXTRACTION = "Data Extraction Terms of Use",
}
