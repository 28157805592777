import { useEffect, useState } from "react";
import { getI18n, i18nInit, I18nConfig } from "@cortex-libs/i18n";
import { Auth } from "../utils/authHelper";

export const useI18nInit = (
  lang?: string | undefined,
  apiUrl?: string
): {
  isI18nInitialized: boolean;
} => {
  const [isInitialized, setIsInitialized] = useState(false);

  const init = async (apiUrl: string): Promise<void> => {
    if (isInitialized) return;
    const token = await Auth.getToken();
    const config: I18nConfig = { apiUrl: apiUrl, token: token };
    await i18nInit(config);
    setIsInitialized(true);
  };

  useEffect(() => {
    if (apiUrl) init(apiUrl);
  }, [apiUrl]);

  useEffect(() => {
    const i18n = getI18n();
    if (isInitialized && i18n?.isInitialized && lang && i18n?.language !== lang) {
      i18n?.changeLanguage(lang ?? "en-US");
    }
  }, [lang, isInitialized]);

  return { isI18nInitialized: isInitialized };
};
