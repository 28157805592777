import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toUpper } from "lodash";
import { UnlinkModal, WrapUpWarningModal } from "@cortex-modules/management/";
import {
  Box,
  Calender,
  Card,
  CheckmarksSelect,
  Chip,
  ChipOptions,
  Colors,
  ContextMenu,
  ContextMenuOptions,
  FiscalYear,
  Grid,
  Input,
  InputLabel,
  InputVariants,
  Modal,
  Progress,
  ProgressTypes,
  SearchBox,
  Text,
  TextVariants,
  Button,
  ButtonTypes,
  Icon,
  Tooltip,
  ViewMore,
} from "@cortex-libs/prism";
import { IconNames } from "@cortex-libs/assets";
import { useTranslation } from "@cortex-libs/i18n";
import {
  Action,
  DayMonth,
  MyEngagements,
  Permission,
  UpdateEngagementInfo,
  useClient,
  useCortexContentIndustries,
  useEngagement,
  useFormat,
  useMyEngagementList,
  useUserGlobalPermissions,
  useSecurity,
  useGeoList,
  useCurrentUser,
  Engagement,
  AppAdminRole,
  LegalHoldStatus,
  engLegalholdStatus,
  engWrappedupStatus,
  ENGAGEMENT_ROUTES,
  useConfiguration,
  ExternalUserType,
  useCreateEngagement,
  engRollForwardStatus,
  WrapupStatus,
  ENGAGEMENT_STATUS,
  useExternalIntegration,
  UnlinkingEngagementDetails,
  UnlinkEngagementResponse,
  useLanguage,
} from "@cortex-libs/dmf";
import {
  contextMenu,
  EngagementDeleteStatus,
  engagementStatus,
  EngagementConstants,
} from "../@constants/constants";
import { getPrimaryList } from "../@constants/utils";
import { isDate } from "lodash";
import { useStyles } from "../@constants/Styles";
import { DeleteAlert } from "../../../components/DeleteAlert/DeleteAlert";
import { isUserInDefaultGeo } from "../../../utils/default-geo-utils";
import {
  RollForwardEngagementModal,
  CreateEngagementModal,
  CreateEngagementAsyncModal,
  convertToUTCDate,
} from "@cortex-modules/engagement";
import { RollForwardStatus } from "@cortex-libs/dmf";

// eslint-disable-next-line @typescript-eslint/ban-types
export type EngagementsProp = {};

export const Engagements = (): JSX.Element => {
  const OFFSET = 24;
  const interpolation = {
    prefix: "${",
    suffix: "}",
  };
  const { id: engagementId } = useParams<{ id: string }>();
  const {
    engagementsTilesStyles,
    engTileWrapper,
    contextmenustyles,
    deleteFailedIndicatorStyles,
    searchWrapper,
    loadMoreButton,
    searchProgressWrapper,
  } = useStyles();
  const { baseConfiguration } = useConfiguration();
  const { t } = useTranslation();
  const { loggedInUser } = useCurrentUser();
  const [isCreateEngagementModalOpen, setIsCreateEngagementModalOpen] = useState(false);
  const [isCreateEngagementAsyncModalOpen, setIsCreateEngagementAsyncModalOpen] = useState(false);
  const isAppAdmin = loggedInUser?.appRoles?.some((role) => role === AppAdminRole.name);
  const {
    myEngagementList,
    isLoading,
    deleteEngagement,
    engagementDeletionInprogress,
    setDeletionInprogressEngagements,
    engagementRollForwordInprogress,
    resetMatConvertStatus,
    handleAsyncEngCreationError,
    updateMyEngagementList,
    totalEngagementCount,
    loadMoreEngagement,
    myEngListIsLoading,
    searchEngagement,
    engagementSearchList,
    engSearchIsLoading,
    resetEngagementSearchList,
    showLoadMoreButton,
    resetShowLoadMoreButton,
    refreshEngagement,
    disableViewMore,
  } = useMyEngagementList(isAppAdmin as boolean);
  const { updateEngagementInfo, newlyAddEngagement } = useEngagement(engagementId);
  const { canUserExecuteAction } = useSecurity();
  const [searchResult, setSearchResult] = useState<MyEngagements[]>(myEngagementList);
  const { canExecuteAction: canAddEngagement } = useUserGlobalPermissions(
    [Permission.ENGAGEMENTS],
    Action.Add
  );
  // Hide add engagement button if user is not on default geo
  const { geoList } = useGeoList();
  const [menuItem, setMenuItem] = useState<number | 0 | undefined>();
  const [searchKeyWords, setSearchKeyWords] = useState<string>();

  const history = useHistory();
  const [offset, setOffset] = useState<number>(OFFSET);

  const [showViewMore, setShowViewMore] = useState<boolean>(true);
  const [loadMoreActive, setLoadMoreActive] = useState<boolean>(false);

  const handleNewEngagementButtonClick = () => {
    const asyncMode = !!JSON.parse(
      String(
        baseConfiguration?.isEngagementCreationModeAsync ??
          EngagementConstants.DEFAULT_ENGAGEMENT_CREATION_VALUE
      ).toLowerCase()
    );
    if (asyncMode) setIsCreateEngagementAsyncModalOpen(true);
    else setIsCreateEngagementModalOpen(true);
  };

  const handleEngagementClick = (e: React.MouseEvent<HTMLElement>, eng: Engagement) => {
    e.preventDefault();
    resetMatConvertStatus();
    if (loggedInUser?.type === ExternalUserType?.name) {
      window.location.href = `${baseConfiguration?.auditUrl}/engagement/${eng?.id}/data-sources`;
    } else if (isAppAdmin) {
      history.push(ENGAGEMENT_ROUTES.ENGAGEMENT_MANAGEMENT_DETAIL.replace(":id", eng?.id));
    } else {
      history.push(ENGAGEMENT_ROUTES.ENGAGEMENT_HOME_DETAIL.replace(":id", eng?.id));
    }
    refreshEngagement();
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>, index: number) => {
    e.preventDefault();
    setMenuItem(index);
  };

  const handleClose = () => {
    setMenuItem(undefined);
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }
    setSearchResult(myEngagementList);
  }, [myEngagementList]);

  const sortEngList = () => {
    const filteredList = [
      ...myEngagementList?.filter(
        (eng) =>
          eng?.createEngagementStatus === ENGAGEMENT_STATUS.IN_PROGRESS ||
          eng?.rollForwardStatus === RollForwardStatus.PROGRESS
      ),
      ...myEngagementList?.filter(
        (eng) =>
          eng?.createEngagementStatus !== ENGAGEMENT_STATUS.FAILED &&
          eng?.createEngagementStatus !== ENGAGEMENT_STATUS.IN_PROGRESS &&
          eng?.rollForwardStatus !== RollForwardStatus.PROGRESS
      ),
    ];
    updateMyEngagementList(filteredList);
  };

  useEffect(() => {
    sortEngList();
  }, []);

  const searchEngagements = (searchTerm: string) => {
    const engList = [...myEngagementList];
    if (isAppAdmin && searchTerm !== "") {
      setSearchKeyWords(searchTerm);
      const result = engList?.filter((item) => {
        return (
          item.name?.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
          item.clientName?.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
        );
      });
      setSearchResult(result);
    } else if (searchTerm !== "") {
      setSearchKeyWords(searchTerm);
      const result = engList?.filter((item) => {
        return (
          item.name?.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
          item.clientName?.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
        );
      });
      setShowViewMore(false);
      setSearchResult(result);
      setShowViewMore(false);
    } else {
      sortEngList();
      setShowViewMore(true);
      setSearchKeyWords("");
      setShowViewMore(true);
      setLoadMoreActive(false);
      resetEngagementSearchList();
      resetShowLoadMoreButton();
    }
  };

  const handleLoadMoreCall = (offset: number) => {
    if (isAppAdmin && searchKeyWords !== "" && !disableViewMore) {
      loadMoreEngagement(offset, searchKeyWords);
    } else {
      loadMoreEngagement(offset);
    }
  };

  useEffect(() => {
    if (isAppAdmin && loadMoreActive) {
      if (!showLoadMoreButton && !disableViewMore) {
        setShowViewMore(true);
      } else {
        setShowViewMore(false);
      }
    } else {
      if (searchKeyWords !== undefined && searchKeyWords !== "") {
        setShowViewMore(false);
      }
    }
  }, [showLoadMoreButton, disableViewMore, searchKeyWords]);

  useEffect(() => {
    if (!engagementSearchList.length) return;
    setSearchResult(engagementSearchList);
  }, [engagementSearchList]);

  const handleLoadMoreButtonClick = () => {
    searchEngagement(0, searchKeyWords);
    setLoadMoreActive(true);
  };

  let engList: MyEngagements[] = searchResult;
  const primayList = getPrimaryList(engList);
  const secList = engList?.filter((eng) => !primayList.includes(eng));
  engList = secList.concat(primayList);
  const addIcon = <Icon name={IconNames.Plus} outline></Icon>;
  const dateFormat = useFormat()?.date;
  const shortDateFormat = useFormat()?.shortDate;

  const convertShortDateToFormatedString = (date: DayMonth, format: string): string => {
    let result = toUpper(format);
    result = result.replace("MM", date.month.toString()?.padStart(2, "0"));
    result = result.replace("DD", date.day.toString()?.padStart(2, "0"));
    return result;
  };

  //-------------------- Edit Engagement Section Start --------------------
  const [isEditEngagementModalOpen, setIsEditEngagementModalOpen] = useState<boolean>(false);

  const [clientId, setClientId] = useState("");
  const { client: clientInEditEngagement, isLoading: isClientInViewLoading } = useClient(clientId);
  const [engagementInEdit, setEngagementInEdit] = useState<MyEngagements | null>(null);
  const [newEngagementName, setNewEngagementName] = useState<string>("");
  const [newEngagementFiscalEndDate, setNewEngagementFiscalEndDate] = useState<Date | null>(null);
  const [newDeliverableReleaseDate, setNewDeliverableReleaseDate] = useState<Date | null>(null);

  const [isEditEngagementFormReady, setIsEditEngagementFormReady] = useState<boolean>(false);

  useEffect(() => {
    if (isEditEngagementFormReady) {
      setIsEditEngagementModalOpen(true);
    }
  }, [isEditEngagementFormReady]);

  useEffect(() => {
    if (engagementInEdit === null) return;
    if (newEngagementName === "") return;
    if (newEngagementFiscalEndDate === null) return;
    if (newDeliverableReleaseDate === null) return;
    if (isClientInViewLoading === true && clientInEditEngagement === undefined) return;
    setIsEditEngagementFormReady(true);
  }, [
    engagementInEdit,
    newEngagementName,
    newEngagementFiscalEndDate,
    newDeliverableReleaseDate,
    clientInEditEngagement,
  ]);

  const clearEditEngagementFormData = () => {
    setEngagementInEdit(null);
    setNewEngagementName("");
    setNewEngagementFiscalEndDate(null);
    setNewDeliverableReleaseDate(null);
    setIsEditEngagementFormReady(false);
  };

  useEffect(() => {
    if (isEditEngagementModalOpen === false) {
      clearEditEngagementFormData();
    }
  }, [isEditEngagementModalOpen]);

  const isAnyEngagementInfoChanged = () => {
    if (newEngagementName !== engagementInEdit?.name) return true;

    const originalFiscalEndDate = new Date(engagementInEdit?.fiscalYearEnd || Date.now());
    if (
      newEngagementFiscalEndDate?.getFullYear() !== originalFiscalEndDate?.getFullYear() ||
      newEngagementFiscalEndDate?.getDate() !== originalFiscalEndDate?.getDate() ||
      newEngagementFiscalEndDate?.getMonth() !== originalFiscalEndDate?.getMonth()
    )
      return true;

    const originalReleaseDate = new Date(engagementInEdit?.reportIssuanceDate || Date.now());
    if (
      newDeliverableReleaseDate?.getFullYear() !== originalReleaseDate?.getFullYear() ||
      newDeliverableReleaseDate?.getDate() !== originalReleaseDate?.getDate() ||
      newDeliverableReleaseDate?.getMonth() !== originalReleaseDate?.getMonth()
    )
      return true;

    return false;
  };

  const handleNewEngagementNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewEngagementName(e.target.value);
  };

  const handleEditEngagementModalClose = () => {
    setIsEditEngagementModalOpen(false);
    setClientId("");
  };

  const handleEditEngagementSaveChanges = () => {
    const newInfo: UpdateEngagementInfo = {
      name: newEngagementName,
      fiscalYearEnd: convertToUTCDate(newEngagementFiscalEndDate || new Date(Date.now())),
      reportIssuanceDate: convertToUTCDate(newDeliverableReleaseDate || new Date(Date.now())),
    };

    updateEngagementInfo(engagementInEdit?.id || "", newInfo);
    setIsEditEngagementModalOpen(false);
  };

  const handleEditEngagementStart = (engagementIndex: number) => {
    const engagementToEdit = engList[engagementIndex];
    setClientId(engList[engagementIndex]?.clientId || "");

    setNewEngagementName(engagementToEdit?.name || "");

    const fiscalYearEnd = new Date(engagementToEdit?.fiscalYearEnd || Date.now());
    setNewEngagementFiscalEndDate(fiscalYearEnd);

    const reportIssuanceDate = new Date(engagementToEdit?.reportIssuanceDate || Date.now());
    setNewDeliverableReleaseDate(reportIssuanceDate);

    setEngagementInEdit(engagementToEdit);
  };

  function isValidDate(obj: Date) {
    return isDate(obj) && obj.toString() !== "Invalid Date";
  }

  const areUpdateEngagementInputsValid = () => {
    if (
      newEngagementName.length === 0 ||
      newEngagementFiscalEndDate === null ||
      !isValidDate(new Date(newEngagementFiscalEndDate as Date)) ||
      newDeliverableReleaseDate === null ||
      !isValidDate(new Date(newDeliverableReleaseDate as Date))
    )
      return false;

    return true;
  };

  const canEditEngagementBeSaved = () => {
    if (isAnyEngagementInfoChanged() && areUpdateEngagementInputsValid()) {
      return true;
    }
    return false;
  };

  const editEngagementModalContent = (
    <>
      <Box bgcolor={Colors.WHITE} width={550} p={3}>
        <Text type={TextVariants.SUB_HEADING_1}>
          {t("translations:engagement.edit.modal.title")}
        </Text>
        <Grid container>
          <Box display="flex">
            <Box ml={0} m={2} my={2} width="517px">
              <InputLabel>
                <Text color={Colors.BLUE_4} type={TextVariants.FIELD_LABEL}>
                  {t("translations:engagement.edit.modal.form.engagement-name-label")}
                </Text>
              </InputLabel>
              <Box pt={0.5}>
                <Input
                  textFieldVariant={InputVariants.OUTLINED}
                  type={TextVariants.BODY_COPY_SMALL}
                  value={newEngagementName}
                  onChange={handleNewEngagementNameChanged}
                  error={newEngagementName.length === 0}
                  helperText={newEngagementName.length === 0 ? "Error - Invalid name" : ""}
                  disabled={
                    engagementInEdit?.wrapupStatus === WrapupStatus.COMPLETE ||
                    engagementInEdit?.matId !== null ||
                    engagementInEdit?.matNumber !== null
                  }
                />
              </Box>
            </Box>
          </Box>

          <Box display="flex">
            <Box ml={0} m={2} my={2} width="285px">
              <Box>
                <InputLabel disabled>
                  <Text color={Colors.BLUE_4} type={TextVariants.FIELD_LABEL}>
                    {t("translations:engagement.edit.modal.form.audit-year-label")}
                  </Text>
                </InputLabel>
                <Box mt={-2.1}>
                  <Calender
                    format=""
                    onChange={(date: Date | null) => setNewEngagementFiscalEndDate(date)}
                    selectedDate={newEngagementFiscalEndDate}
                    placeHolder={toUpper(dateFormat)}
                    invalidDateErrMsg={t("translations:analytics.quarter-dates.date-validation")}
                    disabled={
                      engagementInEdit?.wrapupStatus === WrapupStatus.COMPLETE ||
                      engagementInEdit?.matId !== null ||
                      engagementInEdit?.matNumber !== null
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box ml={0} m={2} my={2} width="222px">
              <Box>
                <InputLabel disabled>
                  <Text color={Colors.BLUE_4} type={TextVariants.FIELD_LABEL}>
                    {t(
                      "translations:engagement.edit.modal.form.estimable-deliverable-release-date-label"
                    )}
                  </Text>
                </InputLabel>
                <Box mt={-2.1}>
                  <Calender
                    format=""
                    onChange={(date: Date | null) => setNewDeliverableReleaseDate(date)}
                    selectedDate={newDeliverableReleaseDate}
                    placeHolder={toUpper(dateFormat)}
                    invalidDateErrMsg={t("translations:analytics.quarter-dates.date-validation")}
                    disabled={engagementInEdit?.wrapupStatus === WrapupStatus.COMPLETE}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Box pr={2} textAlign="right">
          <Button
            disabled={!canEditEngagementBeSaved()}
            buttonType={ButtonTypes.PRIMARY}
            onClick={handleEditEngagementSaveChanges}
          >
            {t("translations:engagement.edit.modal.actionButtonLabel")}
          </Button>
        </Box>
      </Box>
    </>
  );
  //-------------------- Edit Engagement Section End --------------------

  //-------------------- View Client Section Start --------------------
  const { industries } = useCortexContentIndustries();

  const [clientInViewId, setClientInViewId] = useState("");
  const { client: clientInView, isLoading: isClientLoading } = useClient(clientInViewId);
  const { isLanguageChange } = useLanguage();
  const [isViewClientModalOpen, setIsViewClientModalOpen] = useState<boolean>(false);
  const [newClientName, setNewClientName] = useState<string>("");
  const [newSelectedIndusties, setNewSelectedIndustries] = useState<string[] | null>(null);
  const [newClientFiscalYearEnd, setNewClientFiscalYearEnd] = useState<string>("");
  const [isViewClientFormReady, setIsViewClientFormReady] = useState<boolean>(false);

  useEffect(() => {
    if (isClientLoading === true || clientInView === undefined) return;

    setNewClientName(clientInView.name);
    setNewSelectedIndustries(clientInView.industries);
    const shortDateString = convertShortDateToFormatedString(
      clientInView.fiscalYearEnd,
      shortDateFormat
    );
    setNewClientFiscalYearEnd(shortDateString);
  }, [clientInView, isLanguageChange]);

  useEffect(() => {
    if (isViewClientFormReady) {
      setIsViewClientModalOpen(true);
    }
  }, [isViewClientFormReady]);

  useEffect(() => {
    if (newClientName === "") return;
    if (newSelectedIndusties === null) return;
    if (newClientFiscalYearEnd === "") return;
    setIsViewClientFormReady(true);
  }, [newClientName, newSelectedIndusties, newClientFiscalYearEnd]);

  const clearViewClientFormData = () => {
    setClientInViewId("");
    setNewClientName("");
    setNewSelectedIndustries(null);
    setNewClientFiscalYearEnd("");
    setIsViewClientFormReady(false);
  };

  useEffect(() => {
    if (isViewClientModalOpen === false) {
      clearViewClientFormData();
    }
  }, [isViewClientModalOpen]);

  const handleViewClientStart = (engagementIndex: number) => {
    const engagementToEdit = engList[engagementIndex];
    setClientInViewId(engList[engagementIndex]?.clientId || "");

    setEngagementInEdit(engagementToEdit);
  };

  const handleViewClientModalClose = () => {
    setIsViewClientModalOpen(false);
  };

  const viewClientModalContent = (
    <>
      <Box bgcolor={Colors.WHITE} width={700} p={3}>
        <Text type={TextVariants.SUB_HEADING_1}>{t("translations:client.view.modal.title")}</Text>
        <Grid container>
          <Box pt={3}>
            <Box display="flex">
              <Box mr={2} mb={3} width="264px">
                <Text color={Colors.BLUE_4} type={TextVariants.FIELD_LABEL}>
                  {t("translations:client.view.modal.form.client-name-label")}
                </Text>
                <Input
                  textFieldVariant={InputVariants.OUTLINED}
                  type={TextVariants.BODY_COPY_SMALL}
                  value={newClientName}
                  disabled
                />
              </Box>
              <Box ml={6} mr={2} width="264px">
                <InputLabel disabled>
                  <Text color={Colors.BLUE_4} type={TextVariants.FIELD_LABEL}>
                    {t("translations:client.view.modal.form.country-label")}
                  </Text>
                </InputLabel>
                <Input
                  disabled
                  textFieldVariant={InputVariants.OUTLINED}
                  type={TextVariants.BODY_COPY_SMALL}
                  value={
                    Array.isArray(clientInView?.countries)
                      ? clientInView?.countries?.join(", ")
                      : clientInView?.countries
                  }
                  id="country-dropdown"
                />
              </Box>
            </Box>
            <Box display="flex">
              <Box ml={0} m={2} my={2} width="264px">
                <Box>
                  <InputLabel>
                    <Text color={Colors.BLUE_4} type={TextVariants.FIELD_LABEL}>
                      {t("translations:client.view.modal.form.industry-label")}
                    </Text>
                  </InputLabel>
                </Box>
                <Box>
                  <CheckmarksSelect
                    options={[...industries.values()]}
                    placeholder={t("translations:client.edit.modal.form.industry-placeholder")}
                    selectedOptions={newSelectedIndusties || []}
                    disabled
                  />
                </Box>
              </Box>
              <Box ml={6} mr={2} my={2} width="264px">
                <Box>
                  <Text color={Colors.BLUE_4} type={TextVariants.FIELD_LABEL}>
                    {t("translations:client.view.modal.form.fiscal-year-end-label")}
                  </Text>
                </Box>
                <Box mt={-0.5}>
                  <FiscalYear
                    isFiscalYearEndDisabled
                    value={newClientFiscalYearEnd}
                    handleDateChanged={() => {
                      // NOTE Added empty array function as handleDateChanged is mandatory
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Box pr={2} textAlign="right">
          <Button buttonType={ButtonTypes.PRIMARY} onClick={handleViewClientModalClose}>
            {t("translations:client.view.modal.actionButtonLabel")}
          </Button>
        </Box>
      </Box>
    </>
  );
  //-------------------- View Client Section End --------------------

  //--------------------Roll Forward Section Start-------------------

  const [isRollForwardOpen, setIsRollForwardModalOpen] = useState<boolean>(false);
  const [isAsyncRollforwardMode, setIsAsyncRollforwardMode] = useState<boolean>(false);
  const [rollForwardClientName, setRollForwardClientName] = useState<string>("");
  const [rollForwardEngName, setRollForwardEngName] = useState<string>("");
  const [completeToast] = useState<React.ReactNode>(<></>);
  const { rollForwardEngagement, asyncRollForwardEngagement } = useCreateEngagement();
  const [rollForwardEngId, setRollForwardEngId] = useState<string>("");

  const closeRollForwardModal = () => {
    setIsRollForwardModalOpen(false);
    setIsAsyncRollforwardMode(false);
    setRollForwardEngName("");
  };

  const handlePollingStart = (info: Engagement, error?: boolean) => {
    if (!error || info) {
      closeRollForwardModal();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-debugger, @typescript-eslint/semi
  const handleRollForward = (_data: Record<string, unknown>) => {
    const payload = { sourceEngagementId: rollForwardEngId, ..._data };
    rollForwardEngagement(payload, handlePollingStart, loggedInUser?.email?.toLowerCase());
    closeRollForwardModal();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-debugger, @typescript-eslint/semi
  const handleAsyncRollforward = (_data: Record<string, unknown>) => {
    const payload = { sourceEngagementId: rollForwardEngId, ..._data };
    asyncRollForwardEngagement(payload);
    closeRollForwardModal();
  };
  //--------------------Roll Forward Section End---------------------

  // -------------------Delete Engagement Section Start ----------------
  const [engagementToBeDeleted, setEngagementToBeDeleted] = useState<Engagement>({} as Engagement);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const closeDeleteModalHandler = () => {
    setIsDeleteModalOpen(false);
  };
  const onDeleteConfirm = () => {
    deleteEngagement(
      engagementToBeDeleted?.id,
      t("translations:landing.engagement.delete.failed-message", {
        engagementName: engagementToBeDeleted?.name,
      })
    );
    setDeletionInprogressEngagements([...engagementDeletionInprogress, engagementToBeDeleted?.id]);
  };

  // Detects if Engagement is Failed to Delete
  const isEngDeletionFailed = (eng: MyEngagements): boolean =>
    eng?.deleteEngagementStatus === EngagementDeleteStatus.FAILED;

  // -------------------Delete Engagement Section End -----------------

  //-----------------Active Omnia Engagement Unlink Start-----------------

  const {
    getExternalEngagementsStatus,
    externalEngagementStatusResponse,
    unlinkedEngagementStatus,
    externalEngagementLoaded,
    unlinkExternalEngagement,
  } = useExternalIntegration();

  const [isActiveOmniaEngModalOpen, setIsActiveOmniaEngModalOpen] = useState<boolean>(false);
  const [isUnlinkModelOpen, setIsUnlinkModelOpen] = useState(false);
  const [unlinkingDetails, setUnlinkingDetails] = useState<UnlinkingEngagementDetails>({
    id: "",
    name: "",
  });
  const [unlinkBannerState, setUnlinkBannerState] = useState<UnlinkEngagementResponse>(
    UnlinkEngagementResponse.NONE
  );
  useEffect(() => {
    if (
      externalEngagementLoaded &&
      unlinkedEngagementStatus !== UnlinkEngagementResponse.INPROGRESS
    ) {
      setIsUnlinkModelOpen(false);

      if (
        externalEngagementStatusResponse?.size ||
        unlinkedEngagementStatus === UnlinkEngagementResponse.ERROR
      ) {
        setIsDeleteModalOpen(false);
        setIsActiveOmniaEngModalOpen(true);
      } else {
        setIsActiveOmniaEngModalOpen(false);
        setIsDeleteModalOpen(true);
      }
    }
  }, [externalEngagementStatusResponse, externalEngagementLoaded, unlinkedEngagementStatus]);

  const handleActiveOmniaEngModalClose = () => {
    setIsActiveOmniaEngModalOpen(false);
  };

  const handleUnlinkButtonClick = (externalEngagementDetails: Record<string, unknown>) => {
    setIsActiveOmniaEngModalOpen(false);
    setIsUnlinkModelOpen(true);

    setUnlinkingDetails({
      id: externalEngagementDetails?.id as string,
      name: externalEngagementDetails?.name as string,
    });
  };

  useEffect(() => {
    setUnlinkBannerState(unlinkedEngagementStatus);
  }, [unlinkedEngagementStatus]);

  useEffect(() => {
    setUnlinkBannerState(unlinkedEngagementStatus);
  }, [unlinkedEngagementStatus]);

  const handleActiveOmniaEngClick = async (engagementId: string) => {
    if (engagementId) {
      setUnlinkBannerState(UnlinkEngagementResponse.NONE);
      await getExternalEngagementsStatus(engagementId);
    }
  };
  const handleUnlink = () => {
    if (engagementToBeDeleted?.id)
      unlinkExternalEngagement(engagementToBeDeleted?.id, unlinkingDetails?.id);
  };

  //------------------------Active Omnia Engagement Unlink End------------

  const handleMenuItemClick = (currentItem?: ContextMenuOptions, index?: number) => {
    if (index === undefined || currentItem === undefined) {
      return;
    }

    switch (currentItem.item) {
      case t(contextMenu.EDIT_ENGAGEMENT).toUpperCase():
        handleEditEngagementStart(menuItem || 0);
        break;
      case t(contextMenu.VIEW_CLIENT_INFO).toUpperCase():
        handleViewClientStart(menuItem || 0);
        break;
      case t(contextMenu.ROLL_FORWARD_INFO).toUpperCase():
        setIsRollForwardModalOpen(true);
        setIsAsyncRollforwardMode(true);
        setRollForwardClientName(engList[menuItem || 0]?.clientName || "");
        setRollForwardEngName(engList[menuItem || 0]?.name || "");
        setClientId(engList[menuItem || 0]?.clientId || "");
        setRollForwardEngId(engList[menuItem || 0]?.id || "");
        break;
      case t(contextMenu.DELETE).toUpperCase():
        setEngagementToBeDeleted(engList[menuItem || 0]);
        handleActiveOmniaEngClick(engList[menuItem || 0]?.id || "");
        break;
    }

    handleClose();
  };

  const createContextMenuOptions = (
    canUpdateEngagement: boolean,
    isDeleteFailed: boolean,
    canViewClientInfo: boolean,
    canDeleteEngagement: boolean,
    isLegalHoldApproved: boolean,
    canViewRollForward: boolean
  ) => {
    const contextMenuTranslationKey = "translations:landing.engagement.tool-tip";
    const options = [];
    if (canUpdateEngagement && !isDeleteFailed) {
      options.push({
        item: t(contextMenu.EDIT_ENGAGEMENT).toUpperCase(),
      });
    }
    if (canViewClientInfo) {
      options.push({
        item: t(contextMenu.VIEW_CLIENT_INFO).toUpperCase(),
      });
    }
    if (canViewRollForward && !isDeleteFailed) {
      options.push({
        item: t(contextMenu.ROLL_FORWARD_INFO).toUpperCase(),
      });
    }
    options.push({
      item: t(contextMenu.DELETE).toUpperCase(),
      disabled: isLegalHoldApproved || !canDeleteEngagement,
      toolTipHeader: isLegalHoldApproved ? t(`${contextMenuTranslationKey}.header`) : "",
      toolTipText: isLegalHoldApproved ? t(`${contextMenuTranslationKey}.legal-hold`) : "",
    });
    return options;
  };

  const displayContextMenu = (engagement: MyEngagements, index: number): JSX.Element | null => {
    const canUpdateEngagement = canUserExecuteAction(
      Action.Update,
      [Permission.ENGAGEMENTS],
      engagement?.permissions || {}
    );
    const canDeleteEngagement = canUserExecuteAction(
      Action.Delete,
      [Permission.ENGAGEMENTS],
      engagement?.permissions || {}
    );
    const canViewClientInfo = canUserExecuteAction(
      Action.View,
      [Permission.CLIENT],
      engagement?.permissions || {}
    );
    const canViewRollForward =
      engWrappedupStatus(engagement) &&
      engagement?.permissions?.engagement_RollForward_PageAccess.view
        ? canUserExecuteAction(Action.View, [Permission.ENGAGEMENTS], engagement?.permissions || {})
        : false;
    const isLegalHoldApproved = engagement?.legalHoldStatus === LegalHoldStatus?.APPROVED;
    const isDisabled =
      (engagementDeletionInprogress?.includes(engagement?.id) &&
        !isEngDeletionFailed(engagement)) ||
      engagementRollForwordInprogress?.includes(engagement?.id) ||
      engagement?.rollForwardStatus === RollForwardStatus.PROGRESS;

    return (canUpdateEngagement ||
      canDeleteEngagement ||
      canViewClientInfo ||
      canViewRollForward) &&
      !engagementDeletionInprogress.includes(engagement.id) &&
      !(engagement?.deleteEngagementStatus === ENGAGEMENT_STATUS.ENGAGEMENT_DELETE_STATUS) &&
      !(engagement?.rollForwardStatus === RollForwardStatus.PROGRESS) ? (
      <ContextMenu
        options={createContextMenuOptions(
          canUpdateEngagement,
          isEngDeletionFailed(engagement),
          canViewClientInfo,
          canDeleteEngagement,
          isLegalHoldApproved,
          canViewRollForward
        )}
        isOpen={menuItem === index}
        handleMenuList={(e: React.MouseEvent<HTMLElement>) => handleClick(e, index)}
        handleClickAway={handleClose}
        onClickItem={handleMenuItemClick}
        width="16ch"
        disabled={isDisabled}
        zIndex={5}
      />
    ) : null;
  };

  const handleCompleteNewEngagement = () => {
    setIsCreateEngagementModalOpen(false);
  };

  const handleCompleteNewEngagementAsync = () => {
    setIsCreateEngagementAsyncModalOpen(false);
  };

  const handleEngagementCreationError = () => {
    if (newlyAddEngagement?.length > 0) {
      handleAsyncEngCreationError();
    }
    handleCompleteNewEngagement();
    setIsCreateEngagementModalOpen(false);
  };

  const getTileActionMessage = (eng: MyEngagements): string => {
    if (
      engagementDeletionInprogress?.includes(eng?.id) ||
      eng?.deleteEngagementStatus === ENGAGEMENT_STATUS.ENGAGEMENT_DELETE_STATUS
    ) {
      return t(`translations:landing.engagement.delete.deletion-inProgress`);
    } else if (
      eng?.rollForwardStatus === RollForwardStatus.PROGRESS ||
      engagementRollForwordInprogress?.includes(eng?.id)
    ) {
      return t(`translations:engagement.new.engagements-table.rolledStatus.rolling`);
    }
    return "";
  };

  // Returns true if Engagement Deletion is Failed and User doesn't have permission to Delete again.
  const isEngTileHidden = (eng: MyEngagements) => {
    return (
      !canUserExecuteAction(Action.Delete, [Permission.ENGAGEMENTS], eng?.permissions || {}) &&
      isEngDeletionFailed(eng)
    );
  };

  const setSearchMessage = (
    <>
      <Box>
        <Grid container item justifyContent="space-between">
          <Box data-instance="landing_searchBox">
            {!engagementSearchList.length && showLoadMoreButton && offset < totalEngagementCount && (
              <Text type={TextVariants.BODY_COPY_SMALL} color={Colors.BLACK}>
                {engList.length ? (
                  <>
                    {t("translations:landing.engagement.search.search-result", {
                      engagementCount:
                        offset > totalEngagementCount ? totalEngagementCount : offset,
                      totalCount: totalEngagementCount,
                      interpolation,
                    })}
                  </>
                ) : (
                  <>
                    {t("translations:landing.engagement.search.no-search-result", {
                      engagementCount:
                        offset > totalEngagementCount ? totalEngagementCount : offset,
                      totalCount: totalEngagementCount,
                      interpolation,
                    })}{" "}
                  </>
                )}
                {showLoadMoreButton && (
                  <Button
                    buttonType={ButtonTypes.TEXT}
                    className={searchWrapper}
                    onClick={handleLoadMoreButtonClick}
                  >
                    {t("translations:landing.engagement.search.load-more-button")}
                  </Button>
                )}
              </Text>
            )}
            {(engagementSearchList.length > 0 ||
              !showLoadMoreButton ||
              offset > totalEngagementCount) && (
              <Text type={TextVariants.BODY_COPY_SMALL} color={Colors.BLACK}>
                {t("translations:landing.engagement.search.final-search-result")}
              </Text>
            )}
          </Box>
        </Grid>
      </Box>
    </>
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    return () => resetEngagementSearchList();
  }, []);

  return (
    <Box>
      <CreateEngagementModal
        isCreateEngagementModalOpen={isCreateEngagementModalOpen}
        onCloseButtonClick={() => setIsCreateEngagementModalOpen(false)}
        completeNewEngagement={handleCompleteNewEngagement}
        engagementCreationError={handleEngagementCreationError}
      />
      <CreateEngagementAsyncModal
        isCreateEngagementModalOpen={isCreateEngagementAsyncModalOpen}
        onCloseButtonClick={() => setIsCreateEngagementAsyncModalOpen(false)}
        completeNewEngagement={handleCompleteNewEngagementAsync}
        engagementCreationError={handleEngagementCreationError}
      />
      <Box>
        <Grid container item justifyContent="space-between">
          <Box data-instance="landing_searchBox" display="flex">
            <SearchBox
              placeholder={t("translations:landing.engagement.search.search-title")}
              withOutDropdown={true}
              options={myEngagementList}
              onInputChange={searchEngagements}
              labelKey={"Title"}
              width={"450px"}
            />
            <Box mt={1.5}>
              {engSearchIsLoading && (
                <Progress type={ProgressTypes.CIRCULAR} status={"info"} size={20} />
              )}
            </Box>
          </Box>
          <Box pt={2}>
            {canAddEngagement && isUserInDefaultGeo(geoList, loggedInUser?.geoCode || "") && (
              <>
                <Button
                  buttonType={ButtonTypes.TEXT}
                  startIcon={addIcon}
                  onClick={handleNewEngagementButtonClick}
                >
                  <Text type={TextVariants.BODY_COPY_SEMI_BOLD} color={Colors.BLUE_4}>
                    {t("translations:landing.engagement.new.title")}
                  </Text>
                </Button>
              </>
            )}
          </Box>
        </Grid>
      </Box>
      {searchKeyWords && <>{setSearchMessage}</>}

      <Box mt={5} mb={3}>
        <Box pb={5}>
          <Text type={TextVariants.SUB_HEADING_1}>
            {t("translations:landing.engagement.recent.title")}
          </Text>
        </Box>
        <Box height="380px" overflow="none">
          {isLoading && !engList.length ? (
            <Box display="flex" height="100%" alignContent="center" justifyContent="center">
              <Progress type={ProgressTypes.CIRCULAR} status={"info"} />
            </Box>
          ) : (
            <Box pb="131px">
              <Grid container spacing={3} data-instance="landing_engagementGrid">
                {engList?.map((eng, engIndex) => {
                  return (
                    <>
                      {!isEngTileHidden(eng) ? (
                        <Grid
                          item
                          sm={12}
                          md={6}
                          lg={4}
                          xl={3}
                          key={engIndex}
                          data-instance={`eng-tile-${eng.id}`}
                        >
                          {eng?.createEngagementStatus?.toLowerCase() !==
                          engagementStatus.ENGAGEMENT_DELETE_STATUS.toLowerCase() ? (
                            <Box textAlign="right" className={engTileWrapper}>
                              <Box className={contextmenustyles}>
                                {displayContextMenu(eng, engIndex)}
                              </Box>
                              {isEngDeletionFailed(eng) && (
                                <Tooltip
                                  title={
                                    <Box p={2}>
                                      <Text
                                        type={TextVariants.BODY_COPY_SMALL}
                                        color={Colors.WHITE}
                                      >
                                        {t(
                                          "translations:landing.engagement.tool-tip.delete-failed-description"
                                        )}
                                      </Text>
                                    </Box>
                                  }
                                  arrow
                                >
                                  <span className={deleteFailedIndicatorStyles}>
                                    <Icon name={IconNames.RedAlert} fontSize={20} />
                                  </span>
                                </Tooltip>
                              )}
                            </Box>
                          ) : (
                            <></>
                          )}

                          <Card
                            disabled={
                              engagementDeletionInprogress?.includes(eng?.id) ||
                              engagementRollForwordInprogress?.includes(eng?.id) ||
                              isEngDeletionFailed(eng) ||
                              eng?.createEngagementStatus?.toLowerCase() ===
                                ENGAGEMENT_STATUS.IN_PROGRESS.toLowerCase() ||
                              eng?.deleteEngagementStatus ===
                                ENGAGEMENT_STATUS.ENGAGEMENT_DELETE_STATUS ||
                              eng?.rollForwardStatus === RollForwardStatus.PROGRESS
                            }
                          >
                            <a
                              className={engagementsTilesStyles}
                              onClick={(e: React.MouseEvent<HTMLElement>) =>
                                handleEngagementClick(e, eng)
                              }
                            >
                              <Box p={3} pr={3 / 2}>
                                <Box pb={1 / 2}>
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={
                                        eng &&
                                        (engLegalholdStatus(eng) ||
                                          engWrappedupStatus(eng) ||
                                          engRollForwardStatus(eng))
                                          ? 4
                                          : 7
                                      }
                                    >
                                      <Text noWrap={true} showTooltipOnWrap={true}>
                                        {eng?.name}
                                      </Text>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={
                                        eng &&
                                        (engWrappedupStatus(eng) ||
                                          engLegalholdStatus(eng) ||
                                          engRollForwardStatus(eng))
                                          ? 7
                                          : 4
                                      }
                                    >
                                      {!isEngDeletionFailed(eng) ? (
                                        <Grid container justifyContent="flex-end">
                                          {eng && engLegalholdStatus(eng) && (
                                            <Grid item xs={eng && engWrappedupStatus(eng) ? 6 : 12}>
                                              <Box textAlign="right">
                                                <Chip
                                                  label={t("translations:landing.legal-hold.title")}
                                                  type="status"
                                                  size={ChipOptions.ChipSize.SMALL}
                                                  color={Colors.ORANGE}
                                                  labelColor={Colors.ORANGE}
                                                  variant={ChipOptions.ChipVariant.OUTLINED}
                                                />
                                              </Box>
                                            </Grid>
                                          )}
                                          {eng &&
                                            engWrappedupStatus(eng) &&
                                            !engagementRollForwordInprogress?.includes(eng?.id) && (
                                              <Grid
                                                item
                                                xs={eng && engLegalholdStatus(eng) ? 6 : 12}
                                              >
                                                <Box textAlign="right">
                                                  <Chip
                                                    label={t(
                                                      "translations:landing.wrapped-up.title"
                                                    )}
                                                    type="status"
                                                    size={ChipOptions.ChipSize.SMALL}
                                                    color={Colors.GRAY_5}
                                                    labelColor={Colors.GRAY_5}
                                                    variant={ChipOptions.ChipVariant.OUTLINED}
                                                  />
                                                </Box>
                                              </Grid>
                                            )}
                                          {eng?.createEngagementStatus?.toLowerCase() ===
                                            engagementStatus.ENGAGEMENT_DELETE_STATUS.toLowerCase() ||
                                          eng?.rollForwardStatus?.toLowerCase() ===
                                            engagementStatus.IN_PROGRESS.toLowerCase() ? (
                                            <>
                                              <Box
                                                display="flex"
                                                height="100%"
                                                alignContent="center"
                                                justifyContent="center"
                                              >
                                                <Progress
                                                  type={ProgressTypes.CIRCULAR}
                                                  status={"info"}
                                                  size={25}
                                                />
                                              </Box>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </Grid>
                                      ) : (
                                        <></>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Text
                                  color={Colors.DISABLED_FONT_COLOR}
                                  type={TextVariants.BODY_COPY_SMALL}
                                >
                                  {(!engagementDeletionInprogress.includes(eng?.id) ||
                                    eng?.rollForwardStatus === RollForwardStatus.PROGRESS) &&
                                  eng?.deleteEngagementStatus !==
                                    ENGAGEMENT_STATUS.ENGAGEMENT_DELETE_STATUS &&
                                  eng.clientName !== null &&
                                  eng.clientName !== "Unknown"
                                    ? `${eng?.clientName} | ${eng?.countryName} | ${
                                        eng?.fiscalYearEnd &&
                                        new Date(eng?.fiscalYearEnd).getFullYear()
                                      }`
                                    : ""}
                                </Text>
                                <Text
                                  color={Colors.DISABLED_FONT_COLOR}
                                  type={TextVariants.BODY_COPY_SMALL}
                                >
                                  {getTileActionMessage(eng)}
                                </Text>
                              </Box>
                            </a>
                          </Card>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
                {engList && offset < totalEngagementCount && showViewMore && (
                  <Grid xs={12} alignContent="center">
                    <Box mt={-2} justifyContent="center">
                      <ViewMore
                        buttonText={t("translations:landing.buttons.view-more")}
                        isLoading={myEngListIsLoading}
                        isInfinite={true}
                        callback={() => {
                          setOffset((current: number) => current + OFFSET);
                          handleLoadMoreCall(offset);
                        }}
                      />
                    </Box>
                  </Grid>
                )}
                {engList && searchKeyWords && showLoadMoreButton && (
                  <Grid xs={12} alignContent="center">
                    <Box
                      data-instance="searchBox_LoadMore"
                      justifyContent="center"
                      mt={3}
                      className={loadMoreButton}
                      p={3}
                      display="grid"
                      alignContent="center"
                    >
                      {!engagementSearchList.length &&
                        !engSearchIsLoading &&
                        showLoadMoreButton &&
                        offset < totalEngagementCount && (
                          <Button
                            buttonType={ButtonTypes.PRIMARY}
                            onClick={handleLoadMoreButtonClick}
                          >
                            {t("translations:landing.engagement.search.load-more-button")}
                          </Button>
                        )}
                      {!engagementSearchList.length && engSearchIsLoading && (
                        <>
                          <Progress
                            type={ProgressTypes.CIRCULAR}
                            status={"info"}
                            className={searchProgressWrapper}
                          />
                          <Text type={TextVariants.BODY_COPY_SMALL}>
                            {t("translations:engagement.new.modal.loading")}
                          </Text>
                        </>
                      )}
                      {engagementSearchList.length > 0 && (
                        <>
                          <Text type={TextVariants.BODY_COPY_SMALL}>
                            {t("translations:landing.engagement.search.all-search-result")}
                          </Text>
                          <Button buttonType={ButtonTypes.PRIMARY} onClick={scrollToTop}>
                            {t("translations:landing.engagement.search.back-to-top-button")}
                          </Button>
                        </>
                      )}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
      <Modal
        open={isEditEngagementModalOpen}
        onCloseButtonClick={handleEditEngagementModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {editEngagementModalContent}
      </Modal>
      <Modal
        open={isViewClientModalOpen}
        onCloseButtonClick={handleViewClientModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {viewClientModalContent}
      </Modal>

      <RollForwardEngagementModal
        isOpen={isRollForwardOpen}
        onClose={closeRollForwardModal}
        engagement={rollForwardEngName}
        clientId={clientId}
        handleRollForward={handleRollForward}
        isAsyncMode={isAsyncRollforwardMode}
        handleAsyncRollforward={handleAsyncRollforward}
        rollForwardClientName={rollForwardClientName}
        selectedClient={clientInEditEngagement as unknown as Record<string, string>}
      />
      <>{completeToast}</>
      {isDeleteModalOpen && (
        <DeleteAlert
          isOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={closeDeleteModalHandler}
          onClickDeleteButton={onDeleteConfirm}
          modalTitle={t(`translations:delete-alert.engagement.title`, {
            engagementName: engagementToBeDeleted?.name,
          })}
          modalMessage={t(`translations:delete-alert.engagement.message`)}
          modalSize="md"
        />
      )}
      {isActiveOmniaEngModalOpen && (
        <WrapUpWarningModal
          isOpen={isActiveOmniaEngModalOpen}
          externalEngagementStatusResponse={externalEngagementStatusResponse}
          onClose={handleActiveOmniaEngModalClose}
          onUnlinkButtonClick={handleUnlinkButtonClick}
          unlinkedEngagementStatus={unlinkBannerState}
          unlinkingDetails={unlinkingDetails}
          modalTitle={t("translations:delete-alert.active-omnia-engagement-modal.title")}
          modalContent={t("translations:delete-alert.active-omnia-engagement-modal.if-the-cortex")}
        ></WrapUpWarningModal>
      )}
      {isUnlinkModelOpen && (engList[menuItem || 0]?.id || "") && (
        <UnlinkModal
          isOpen={isUnlinkModelOpen}
          onClose={() => setIsUnlinkModelOpen(false)}
          externalEngagementName={unlinkingDetails.name}
          handleUnlink={handleUnlink}
        />
      )}
    </Box>
  );
};
