import {
  Box,
  Container,
  BrandStyles,
  Text,
  TextVariants,
  Icon,
  Button,
  ButtonTypes,
} from "@cortex-libs/prism";
import { useHistory } from "react-router";
import { IconNames } from "@cortex-libs/assets";
import { useStyles } from "./@constants/LoginStyle";

import { LoginFooter } from "./components/LoginFooter";
import LoginPageImage from "./components/LoginPageImage";

import { MainHeader } from "../../components/main-header";

export const Login = (): JSX.Element => {
  const { outerCircule, container } = useStyles();
  const history = useHistory();

  const handleLoginClick = () => {
    history.push("/");
  };

  return (
    <Box className={container}>
      <MainHeader />
      <Container>
        <Box flexDirection="row" display="flex">
          <Box width="50%" ml={10} mt={7}>
            <Text type={TextVariants.HEADING_1}>Welcome to Omnia | Data</Text>
            <Text type={TextVariants.SUB_HEADING_2}>Harnessing the power of data</Text>
            <Box flexDirection="row" display="flex">
              <Box mt={5}>
                <Button buttonType={ButtonTypes.PRIMARY} onClick={() => handleLoginClick()}>
                  Login
                </Button>
              </Box>
            </Box>
            <Box mt={8}>
              <Text type={TextVariants.SUB_HEADING_2}>Key features</Text>
            </Box>
            <Box mt={3} flexDirection="row" display="flex">
              <Box className={outerCircule}>
                <Icon name={IconNames.Analytics} fontSize={28} outline />
              </Box>
              <Box mt={2} ml={3}>
                <Text type={TextVariants.BODY_COPY_REGULAR}>
                  Seamless connection to client audit data sources
                </Text>
              </Box>
            </Box>
            <Box mt={3} flexDirection="row" display="flex">
              <Box className={outerCircule}>
                <Icon name={IconNames.Locked} fontSize={28} outline />
              </Box>
              <Box mt={2} ml={3}>
                <Text type={TextVariants.BODY_COPY_REGULAR}>
                  Secure data acquisition, transfer, and staging
                </Text>
              </Box>
            </Box>
            {<></>}
            <Box mt={3} flexDirection="row" display="flex">
              <Box className={outerCircule}>
                <Icon name={IconNames.Move} fontSize={28} outline />
              </Box>
              <Box mt={2} ml={3}>
                <Text type={TextVariants.BODY_COPY_REGULAR}>
                  Repeatable and consistent processes
                </Text>
              </Box>
            </Box>
            <Box mt={3} flexDirection="row" display="flex">
              <Box className={outerCircule}>
                <Icon name={IconNames.Cortex} fontSize={28} outline />
              </Box>
              <Box mt={2} ml={3}>
                <Text type={TextVariants.BODY_COPY_REGULAR}>
                  Advanced data analytics enabling a smarter audit
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            bgcolor={BrandStyles.Colors.BACKGROUND_1}
            mb={23}
            mt={9}
            borderRadius="16px"
            pt={6}
            pr={6}
            pl={6}
            pb={4}
          >
            <LoginPageImage />
          </Box>
        </Box>
      </Container>
      <LoginFooter />
    </Box>
  );
};
