import { useCallback } from "react";
import { Header } from "@cortex-libs/prism";
import { useCurrentUser, useAnalyticsUtils, ActionNames } from "@cortex-libs/dmf";
import { Auth } from "../../utils/authHelper";

export const MainHeader = (): JSX.Element => {
  const { loggedInUser, addTokenToBlacklist } = useCurrentUser();
  const { updateJeAdobeAnalyticsInfo } = useAnalyticsUtils();

  const continueUserLogout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    Auth.logout();
  }, []);

  const user = Auth.getActiveAccount() ? loggedInUser : null;

  const handleLogOut = useCallback(() => {
    addTokenToBlacklist(continueUserLogout);
  }, []);

  const handleHeaderClick = (event: React.MouseEvent<HTMLElement>) => {
    const helpMenu = document.getElementById("Help-Menu");
    const clickedElement = event.target as Node;

    if (helpMenu?.contains(clickedElement)) {
      updateJeAdobeAnalyticsInfo({ actionName: ActionNames.HELP_TOPIC });
    }
  };

  return (
    <div onClick={handleHeaderClick}>
      <Header loggedInUser={user} onLogOut={handleLogOut} />
    </div>
  );
};
