import { User } from "@cortex-libs/dmf";
import { useTranslation } from "@cortex-libs/i18n";
import { Box, Text, TextVariants, Colors, Button, ButtonTypes, Modal } from "@cortex-libs/prism";

export const DeleteClientTeamMemberModal = (props: {
  modalOpen: boolean;
  handleClose: () => void;
  onClickDeleteButton: () => void;
  userInfo: User;
}): JSX.Element => {
  const { modalOpen, handleClose, onClickDeleteButton, userInfo } = props;
  const { t } = useTranslation();
  return (
    <Modal open={modalOpen} onCloseButtonClick={handleClose} closeAfterTransition>
      <Box bgcolor={Colors.WHITE} width={620} pt={3} pl={4} pr={4} pb={4}>
        {
          <>
            <Box flexDirection="row" alignItems="center" display="flex">
              <Box ml={3} className={"Delete_Engagement_member_title"}>
                <Text type={TextVariants.SUB_HEADING_1}>
                  {t("translations:landing.manage-client.table.delete-client-members.title")}
                </Text>
              </Box>
            </Box>
            <Box ml={3} mt={2}>
              <Text type={TextVariants.BODY_COPY_REGULAR} display="inline">
                {t("translations:landing.manage-client.table.delete-client-members.message1")}
              </Text>
              <Text type={TextVariants.BODY_COPY_BOLD} display="inline">
                {t("translations:management.delete-engagement-members.user-name", {
                  firstName: userInfo?.firstName,
                  lastName: userInfo?.firstName === userInfo?.lastName ? "" : userInfo?.lastName,
                })}
              </Text>
              <Text type={TextVariants.BODY_COPY_REGULAR} display="inline">
                {t("translations:landing.manage-client.table.delete-client-members.message2")}
              </Text>
            </Box>
            <Box alignItems="center" justifyContent="flex-end" display="flex" mt={4}>
              <Box mr={3}>
                <Button
                  buttonType={ButtonTypes.SECONDARY}
                  onClick={handleClose}
                  className={"Cancel_Button"}
                >
                  {t(
                    "translations:landing.manage-client.table.delete-client-members.cancel-button"
                  )}
                </Button>
              </Box>
              <Box>
                <Button
                  buttonType={ButtonTypes.PRIMARY}
                  onClick={onClickDeleteButton}
                  className={"Remove_Button"}
                >
                  {t(
                    "translations:landing.manage-client.table.delete-client-members.submit-button"
                  )}
                </Button>
              </Box>
            </Box>
          </>
        }
      </Box>
    </Modal>
  );
};
