import { useEffect, useState } from "react";
import { Container, Box, ProgressTypes, Progress, Tabs, Tab, TabPanel } from "@cortex-libs/prism";
import {
  useMyEngagementList,
  AppAdminRole,
  useCurrentUser,
  useUserGlobalPermissions,
  Permission,
  Action,
} from "@cortex-libs/dmf";
import { useStyles } from "./@constants/Styles";
import { useTranslation } from "@cortex-libs/i18n";
import { WithoutEngagements } from "./components/withoutEngagements";
import { LandingFramework } from "./LandingFramework";
import { LandingFooter } from "./components/LandingFooter";
import { ManageClient } from "./components/manageClient/ManageClient";

export const Landing = (): JSX.Element => {
  const { t } = useTranslation();
  const { loggedInUser } = useCurrentUser();
  const isAppAdmin = loggedInUser?.appRoles?.some((role) => role === AppAdminRole.name);
  const {
    myEngagementList,
    myEngListIsLoading,
    networkCheck,
    landingPageSelectedTabValue,
    setSelectedTabValue,
  } = useMyEngagementList(isAppAdmin as boolean);
  const { contentContainer } = useStyles();
  const [tab1Value, setTab1Value] = useState<number>(0);
  const [tab2Value, setTab2Value] = useState<number>(1);

  const handleTabChange = (newValue: number) => {
    setSelectedTabValue(newValue);
  };

  const { canExecuteAction: viewManageClient } = useUserGlobalPermissions(
    [Permission.MANAGE_CLIENTS_PAGE_ACCESS],
    Action.View
  );

  useEffect(() => {
    if (viewManageClient && myEngagementList?.length < 1) {
      setTab1Value(1);
      setTab2Value(0);
    } else {
      setTab1Value(0);
      setTab2Value(1);
    }
  }, [viewManageClient, myEngagementList]);

  return (
    <Box className={contentContainer}>
      <Container maxWidth={false}>
        {(!networkCheck || myEngListIsLoading) && !myEngagementList?.length ? (
          <Box display="flex" height="100vh" alignItems="center" justifyContent="center">
            <Progress type={ProgressTypes.CIRCULAR} status={"info"} />
          </Box>
        ) : (
          <>
            <Box data-instance="landing-my-engagements">
              <Tabs value={landingPageSelectedTabValue} onChange={handleTabChange}>
                <Tab
                  label={t("translations:landing.engagement.select.myEngagements")}
                  value={tab1Value}
                />
                {viewManageClient ? (
                  <Tab
                    label={t("translations:landing.engagement.select.manageClients")}
                    value={tab2Value}
                  />
                ) : (
                  <></>
                )}
              </Tabs>
            </Box>
            <Box mt={3}>
              <TabPanel value={landingPageSelectedTabValue} index={tab1Value}>
                {myEngagementList && myEngagementList?.length < 1 ? (
                  <WithoutEngagements />
                ) : (
                  <LandingFramework />
                )}
              </TabPanel>
              {viewManageClient ? (
                <TabPanel value={landingPageSelectedTabValue} index={tab2Value}>
                  <ManageClient />
                </TabPanel>
              ) : (
                <></>
              )}
            </Box>
          </>
        )}
      </Container>
      <LandingFooter />
    </Box>
  );
};
