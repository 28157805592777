import { Colors, Typography } from "@cortex-libs/prism";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  engagementsTilesStyles: () => ({
    cursor: "pointer",
  }),
  engTileWrapper: () => ({
    position: "relative",
  }),
  searchWrapper: () => ({
    fontSize: Typography.BODY_SMALL_BOLD_FONT_SIZE,
    fontWeight: Typography.BODY_SMALL_FONT_WEIGHT,
    lineHeight: Typography.BODY_SMALL_LINE_HEIGHT,
    marginLeft: "-4px",
    top: "-1px",
  }),
  loadMoreButton: () => ({
    border: "1px dashed",
    borderColor: Colors.GRAY_2,
    borderRedius: "4px",
  }),
  searchProgressWrapper: () => ({
    marginLeft: "24px",
  }),
  contextmenustyles: () => ({
    position: "absolute",
    right: "16px",
    top: "24px",
    zIndex: 2,
  }),

  deleteFailedIndicatorStyles: () => ({
    position: "absolute",
    right: "46px",
    top: "28px",
    display: "inline-block",
    zIndex: 2,
  }),

  linkStyle: {
    "& [class*='MuiTypography-colorPrimary']": {
      color: Colors.GRAY_7,
    },
  },
  footerText: {
    "& [class*=p]": {
      color: Colors.GRAY_7,
      fontSize: Typography.FIELD_LABEL_FONT_SIZE,
      fontWeight: Typography.SMALL_LINK_FONT_WEIGHT,
      lineHeight: Typography.FIELD_LABEL_LINE_HEIGHT,
    },
    "& [class*=a]": {
      color: Colors.GRAY_7,
      fontSize: Typography.FIELD_LABEL_FONT_SIZE,
      marginRight: "2px",
    },
  },
  footerFlexBox: {
    display: "flex",
    flexDirection: "row",
  },
  alignMargin: {
    marginLeft: "auto",
  },
  p0: {
    padding: 0,
  },
  container: {
    minHeight: "100vh",
  },
  tableManageClient: {
    height: "1050px",
  },
  contentContainer: {
    display: "flex",
    width: "100%",
    minHeight: "100vh",
    boxSizing: "border-box",
    paddingRight: "0",
    paddingLeft: "0",
    alignContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("xl")]: {
      marginLeft: 76,
      marginRight: 76,
      width: `calc(100% - 152px)`,
    },
    [theme.breakpoints.up("xxl")]: {
      maxWidth: 1768,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  footerStyles: {
    display: "flex",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    width: "calc(100% - 112px)",
    padding: "32px 56px",
    marginBottom: "0px",
    background: Colors.BACKGROUND_1,
    zIndex: 3,
  },
  cookieBtnStyles: {
    height: "0.9rem",
    textDecoration: "underline",
  },
}));
