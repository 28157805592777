import {
  AuthProvider,
  InteractionStatus,
  useIsAuthenticated,
  useMsal,
} from "cortex-shared-authenticator";
import env from "./env";

export const Auth = AuthProvider;
export const isAuthenticated = useIsAuthenticated;
export { InteractionStatus } from "cortex-shared-authenticator";

export const GetProgressStatus = (): InteractionStatus => {
  const { inProgress } = useMsal();
  return inProgress;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AuthHelperProps = { children: any };

const msalConfig = {
  auth: {
    clientId: env?.ClientId,
    authority: env?.Authority,
    redirectUri: env?.RedirectUri,
    postLogoutRedirectUri: env?.PostLogoutRedirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {},
  scopes: [env?.AuthScope],
};

const enum ErrorCodes {
  login_required = "login_required",
  interaction_required = "interaction_required",
  token_renewal_failed = "token_renewal_failed",
  invalid_grant = "invalid_grant",
}

export const init = (): void => Auth.init(msalConfig);

/**
 * When /token endpoint fails to retrive token, then logout the user to sign in again.
 */
const loginFromScratch = async () => {
  Auth.logout();
};

//eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const handleAuthError = (error: string) => {
  const errorCode = error?.toLowerCase()?.replace(/ /g, "_");
  switch (errorCode) {
    case ErrorCodes.login_required:
      // eslint-disable-next-line no-console
      console.warn("User login is required, will force logout");
      Auth.logout();
      break;
    case ErrorCodes.interaction_required:
    case ErrorCodes.token_renewal_failed:
    case ErrorCodes.invalid_grant:
      // eslint-disable-next-line no-console
      console.warn("User interaction is required, will try to login");
      loginFromScratch();
      break;
    default:
      throw new Error(error);
  }
};

/**
 * AuthenticationProvider will wrap the whole app using AuthProvider of MSAL.
 */

// eslint-disable-next-line
export const AuthenticationProvider = ({ children }: AuthHelperProps) => {
  return <AuthProvider.Provider>{children}</AuthProvider.Provider>;
};
