import { Box, Progress, ProgressTypes } from "@cortex-libs/prism";
import { useConfiguration } from "@cortex-libs/dmf";
import { OneTrustInitializerProps } from "./@constants/OneTrustInitializerProps";
import { useOneTrustInit } from "../../hooks/onetrust-init/use-onetrust-init";
import { isCurrentUrlExcuded } from "../../utils/onetrust-utils";
import env from "../../utils/env.js";

export const OneTrustInitializer = (props: OneTrustInitializerProps): JSX.Element => {
  const { children } = props;
  const { baseConfiguration } = useConfiguration();

  // If the current URL is excluded by OneTrust, then it will be disabled by assigning empty strings as its values.
  const shouldDisable = isCurrentUrlExcuded();
  const oneTrustUrl = shouldDisable ? "" : baseConfiguration?.oneTrustCookie || env?.OneTrustCookie;
  const oneTrustDomainScriptId = shouldDisable
    ? ""
    : baseConfiguration?.oneTrustDomainScriptId || env?.OneTrustDomainScriptId;

  const { isOneTrustReady, isOneTrustDisabled } = useOneTrustInit(
    oneTrustUrl,
    oneTrustDomainScriptId
  );

  return !isOneTrustReady && !isOneTrustDisabled ? (
    <Box display="flex" mt={10} width="100%" justifyContent="center">
      <Progress status="info" type={ProgressTypes.CIRCULAR} />
    </Box>
  ) : (
    children
  );
};
