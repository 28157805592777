import { _ as __awaiter, a as __generator } from './vendor-c6cd6ee0.js';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
export * from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import ApiBackend from 'i18next-http-backend';
import { get } from 'lodash';

var errors = {
	"general.item-not-found": "Item with the id ${request} was not found.",
	"general.oops": "Oops! Something went wrong! Please try again or contact support.",
	"analytics.bad-request.journal-entry-checkbox": "Analytic should be saved after updating Journal entry checkbox to attach workpaper. Please click X to close the message.",
	"engagement.item-not-found": "Not found.",
	"secure-agent.service-not-available": "'${clientName}' failed to switch into a Secure Agent client. Please try again or contact support.",
	"secure-agent.internal-server-error": "'${clientName}' failed to switch into a Secure Agent client. Please try again or contact support.",
	"secure-agent.bad-request": "'${clientName}' failed to switch into a Secure Agent client. Please try again or contact support.",
	"secure-agent.unauthorized": "'${clientName}' failed to switch into a Secure Agent client. Please try again or contact support.",
	"engagement.service-not-available": "Service ${serviceName} is not available.",
	"engagement.internal-server-error": "Internal server error.",
	"engagement.bad-request": "Bad request.",
	"engagement.unauthorized": "Unauthorized.",
	"engagement.forbidden": "Forbidden.",
	"engagement.conflict": "There was a conflict.",
	"engagement.bad-request.engagement-service-error-creating-trifacta-user-group": "${prefix}Error during creating corresponding trifacta user group.",
	"engagement.bad-request.security-service-user-failed-to-be-created-for-engagement-user-group": "User ${email} failed to be created/updated for Trifacta Engagement user group.",
	"engagement.bad-request.security-service-user-failed-to-be-deleted-for-engagement-user-group": "User ${email} failed to be deleted/updated for Trifacta Engagement user group.",
	"engagement.bad-request.client-service-client-with-name-already-exists": "This client ${name} already exists. Select this client from the drop down to gain access, or create a client with a unique name.",
	"engagement.bad-request.create-engagement-ex-specified-client-not-found-error": "Specified client does not exist.",
	"engagement.bad-request.create-engagement-ex-mat-engagement-custom-client-error": "Cannot add a MAT engagement to a custom client.",
	"engagement.bad-request.engagement-service-payload-is-null": "Request is empty.",
	"engagement.forbidden.engagement-service-not-permitted-to-add-engagement": "User has no permissions to add engagement.",
	"engagement.item-not-found.engagement-service-specified-engagement-does-not-exist": "Engagement doesn't exist.",
	"engagement.item-not-found.security-service-engagement-does-not-exist": "Engagement doesn't exist.",
	"engagement.forbidden.engagement-service-not-permitted-to-update-engagement": "User has no permissions to update engagement.",
	"engagement.forbidden.engagement-service-not-permitted-to-update-staging-folders": "User has no permissions to update staging folders.",
	"engagement.forbidden.engagement-service-mat-engagement-not-found": "MAT Engagement not found.",
	"engagement.forbidden.engagement-service-storage-not-allocated": "Storage is not allocated for the client.",
	"engagement.forbidden.engagement-service-client-not-found-by-mat-id": "Client not found by MAT ID: ${id}",
	"engagement.forbidden.security-service-user-not-added-to-engagement": "Failed to add the user as engagement owner.",
	"engagement.conflict.security-service-user-already-added-to-engagement": "User ${email} is already added to engagement ${name}",
	"engagement.bad-request.client-service-create-client": "Client cannot be created.",
	"engagement.bad-request.create-engagement-ex-different-geo": "Cannot create client under different geo.",
	"engagement.bad-request.engagement-service-create-engagement": "Engagement cannot be created.",
	"engagement.bad-request.security-service-cannot-add-owner": "Client owner cannot be added.",
	"engagement.bad-request.client-service-cannot-add-client-storage": "Client storage cannot be created.",
	"engagement.bad-request.client-service-client-with-mat-id-already-exists": "Client with MAT ID #${id} already exists.",
	"engagement.bad-request.client-service-entity-id-already-present": "Entity with MAT ID #${id} is already present in the client ${client}.",
	"engagement.bad-request.client-service-client-already-linked": "Client ${client} is already linked to a storage.",
	"engagement.bad-request.client-service-client-not-found-in-mat-database": "Client with MAT ID #${id} not found in MAT database.",
	"engagement.forbidden.security-service-user-not-permitted-to-perform-action": "User ${email} doesn't have permissions to perform this action.",
	"engagement.forbidden.security-service-user-is-external": "User ${email} is external.",
	"engagement.conflict.security-service-user-already-added-to-client": "User ${email} is already added to client #${id}.",
	"engagement.bad-request.security-service-roles-dont-belong-to-tier": "Roles ${roles} doesn't belong to ${name} tier.",
	"engagement.bad-request.security-service-invalid-roles": "Roles ${roles} are not ${name} roles.",
	"engagement.bad-request.security-service-user-must-be-admin": "The initial user added to an engagement must have the Engagement Admin role (in addition to other roles, if necessary). Please also ensure that the Engagement Admin role is appropriate for the assigned user.",
	"engagement.bad-request.security-service-roles-cannot-be-combined": "The following roles can not be combined: ${tier1} role ${name1} and ${tier2} role ${name2}",
	"engagement.forbidden.shared-user-missing-required-client-permissions": "User doesn't have required permissions for this client: ${name}.",
	"engagement.unauthorized.shared-user-missing-required-tier-permissions": "User doesn't have required ${tier} permissions: ${permissions}",
	"engagement.bad-request.security-service-user-removal-requires-confirmation": "User removal requires confirmation for the following reasons:\\n${reasons}\\nDo you want to remove user from engagement?",
	"engagement.bad-request.security-service-user-cannot-add-remove-self": "User can't add/remove himself to/from ${name} tier.",
	"data-kitchen-automated-data-request.internal-server-error.data-request-creation-failed": "Failed to create a data request. Please try again.",
	"data-kitchen-automated-data-request.item-not-found": "The engagement has not subscribed to the required data source. Please subscribe and then proceed."
};

var landing = {
	"analytcs-library": {
		link: "https://resources.deloitte.com/sites/us/PC/PracticeComm/functions/AERS/AERSAudit/Innovation/AuditAnalyticsLibrary/pages/home.aspx",
		label: "ANALYTICS LIBRARY"
	},
	"cortex-audit": {
		link: "https://resources.deloitte.com/sites/us/PC/PracticeComm/functions/AERS/AERSAudit/Innovation/Pages/Cortex.aspx"
	},
	"admin-tools": {
		label: "View Admin Tools"
	},
	"content-library": {
		label: "View Content Library"
	},
	engagement: {
		appear: {
			description: "Want to learn more about Omnia | Data ? Explore its range of capabilities and additional resources",
			title: "Your Engagements Will Appear Here"
		},
		"new": {
			title: "NEW ENGAGEMENT"
		},
		recent: {
			title: "Recent Engagements"
		},
		search: {
			title: "Search Engagement Name",
			"search-title": "Search engagement or client name",
			"load-more-button": "Load More",
			"search-result": "Showing results for your first “${engagementCount}” engagements. To search all “${totalCount}” engagements, click",
			"final-search-result": "Showing results for all of your engagements.",
			"no-search-result": "No matches out of your first  “${engagementCount}” engagements. To search all “${totalCount}” engagements, click",
			"back-to-top-button": "Back to top",
			"all-search-result": "All results loaded..."
		},
		select: {
			"engagement-to-start": "Begin by selecting an engagement that you would like to work on.",
			title: "Select an Engagement",
			myEngagements: "My Engagements",
			manageClients: "Manage Clients"
		},
		"delete": {
			"success-title": "Engagement Deleted",
			"success-message": "The engagement, “${engagementName}” has been deleted.",
			"failed-message": "Failed to Delete “${engagementName}”: Some files may have been purged, please try again. If the issue persists, contact support",
			"in-progress-modal-title": "Deleting '${engagementName}'...",
			"in-progress-modal-message": "This process will take 2-3 minutes, please wait.",
			"deletion-inProgress": "Deletion in progress...",
			"deletion-failed": "Failed to Delete"
		},
		"roll-forword": {
			title: "Rolled Forword",
			"rollforward-inProgress": "Rolling Forward..."
		},
		"tool-tip": {
			header: "Cannot Delete this Engagement",
			"legal-hold": "This engagement is on Legal Hold. To delete this engagement, lift legal hold status.",
			"delete-failed-description": "Engagement failed to delete. Try to delete again or contact support through the Help menu."
		},
		"no-access": "You don't have access for this engagement"
	},
	"manage-client": {
		table: {
			"client-name": "Client Name",
			"country-name": "Country",
			"fiscal-year": "Fiscal Year",
			"delete-client-members": {
				title: "Remove the user from the client owners list?",
				message1: "Doing so will revoke ",
				message2: " from all access to the current client.",
				"submit-button": "Remove",
				"cancel-button": "Cancel",
				"error-message": "Failed to Delete “{{email}}”"
			}
		},
		"setup-client-modal": {
			heading: "Setup Auto Data Extraction for New or Existing Client",
			description: "Begin by selecting a client or filling in new client details below.",
			"client-name-label": "Client Name",
			"client-name-placeholder": "Type client name",
			"create-new-client": "Create New Client:",
			"loading-results": "Loading results..",
			industry: "Industry",
			country: "Country",
			select: "Select",
			"fiscal-year": "Fiscal Year End",
			custom: "Custom",
			cancel: "Cancel",
			setup: "Setup"
		},
		"handle-failure-modal": {
			title: "  There was a problem creating the client",
			message: "Please try creating the client again, if the problem persists, contact support.",
			"contact-support-button": "Contact support",
			"create-client-button": "Create client"
		},
		title: "Manage Clients",
		"sub-heading-text": "Search for a client below that you would like to manage",
		"add-manage-client": "SET UP NEW OR EXISTING CLIENT",
		setup_auto_data_extraction: "setup auto data extraction",
		manage_client_team: "manage client team",
		manage_auto_data_extraction: "back to manage clients",
		search_text: "Search Client Name",
		team: {
			title: "Manage Client Team",
			description: "All internal team members are assigned 'Deloitte Client Owner' role, and client users will be assigned to 'Client Owner' role.",
			search: "Search Client Owner",
			add: "Add Client Owner",
			table: {
				name: "name",
				email: "email",
				role: "role",
				"edit-info": "edit info",
				remove: "remove"
			}
		}
	},
	"learning-library": {
		text: "here."
	},
	"legal-hold": {
		title: "Legal Hold"
	},
	closed: {
		title: "Closed"
	},
	"wrapped-up": {
		title: "Wrapped up"
	},
	"start-width-cortex": {
		description: "Omnia | Data accelerates your end-to-end auditing process by harnessing the true power of data. Create a new engagement to populate your dashboard, or view audit analytics in the analytics library.",
		title: "Get started with Omnia | Data"
	},
	"welcome-to-cortex": {
		title: "Welcome to Omnia | Data"
	},
	footer: {
		"need-help": {
			description: "© 2023 For information,contact Deloitte Global.",
			"terms-of-use": "Terms of Use",
			"privacy-statement": "Privacy Statement",
			notices: "Notices",
			see: "See",
			and: "and ",
			"for-more-info": "for more information.",
			"cookies-notice": "Cookies Notices",
			"cookies-setting": "Cookies Settings"
		}
	},
	buttons: {
		"view-more": "View More"
	}
};
var engagement = {
	"new": {
		create_title: "Create New Engagement",
		create_engagement_title: "Create Engagement",
		create_description: "Create a new engagement by selecting an existing client from the database or creating a new client.",
		button: {
			back: "Back",
			next: "Next",
			next_Step: "Next Step",
			"next-secure-agent": "Next: Secure Agent Setup"
		},
		step1: {
			header: "Step 1: Client Information",
			description: "Begin by selecting a client or filling in your client's information below.",
			form: {
				"client-name-label": "Client Name",
				"client-name-placeholder": "Type client name",
				"create-new-client": "Create New Client:",
				"loading-results": "Loading results..",
				industry: "Industry",
				country: "Country",
				select: "Select",
				"fiscal-year": "Fiscal Year End",
				custom: "Custom"
			},
			"secure-agent": {
				"use-secure-agent": "If you would like to use Secure Agent for data extraction, please ",
				"click-here": "click here",
				redirect: " to be redirected.",
				"error-prefix": "Secure Agent creation failed"
			}
		},
		step2: {
			header: "Step 2: Engagements",
			title: "Create Your First Engagement",
			description: "The client you've created will not be saved until you create an engagement. Once engagement(s) have been created, they will appear here.",
			button: {
				"new-engagement": "New Engagement"
			}
		},
		modal: {
			title: "Create Engagement",
			"modal-title": "Create Engagement",
			header: "Step 2: Engagement Information",
			description: "Fill in the details to create a new engagement.  To manage team members, visit the 'Management' page after creating the engagement.",
			"step2-description": "Fill in the details to create a new engagement.",
			form: {
				"engagement-name-label": "Engagement Name",
				"engagement-name-placeholder": "Enter engagement name",
				"audit-year-label": "Current Audit Year End",
				"estimable-deliverable-release-date-label": "Estimated Deliverable Release Date",
				error: {
					"invalid-year": "Please enter a valid year",
					"engagement-name-not-unique": "Engagement already exists for this client",
					"engagement-already-exists": "Engagement already exists with this name. Update to a unique engagement name and try again",
					"one-engagement-owner": "An engagement with that name already exists, please refer to {ownersEmail[0]} to request access",
					"two-engagement-owner": "An engagement with that name already exists, please refer to {ownersEmail[0]} or {ownersEmail[1]}  to request access",
					"three-engagement-owner": "An engagement with that name already exists, please refer to {ownersEmail[0]}, {ownersEmail[1]} or {ownersEmail[2]} to request access"
				},
				"create-new-engagement": "Create New Engagement:",
				"no-result": "No results"
			},
			"add-additional-users": "Add Additional Engagement Owners",
			"user-autocomplete-placeholder": "Search by team member names or e-mails",
			"user-client-autocomplete-placeholder": "Search practitioner names or email",
			loading: "Loading results...",
			"no-results": "No Results",
			"previous-button": "Previous",
			"create-engagement-button": "Create Engagement",
			"engagement-creation-in-progress-message": "Creating ",
			"in-progress-modal-message": "This process will take 2-3 minutes, please wait."
		},
		"handle-failure-modal": {
			title: " There was a problem creating a new engagement",
			message: "Please try creating a new engagement again, if the problem persists, contact support.",
			"contact-support-button": "Contact support"
		},
		"secure-agent-modal": {
			title: "Are you sure you want to use Secure Agent?",
			description: "If you choose to use Secure Agent, you will not be able to return to the current page. Are you sure you would like to setup this client with Secure Agent?",
			button: {
				cancel: "Cancel",
				"use-secure-agent": "Yes, Use Secure Agent"
			}
		},
		portal: {
			"back-to-home": "Back to home"
		},
		"engagements-table": {
			"engagement-name": "Engagement Name",
			"audit-year-end": "Current Audit Year End",
			status: "Status",
			description: "View engagement details for this client below or add new engagements to this client.",
			"new-engagement": "Add new Engagement",
			"pagination-label": "Showing {from} to {to} of {total} engagements",
			"legal-hold": {
				title: "Legal Hold"
			},
			closed: {
				title: "Closed"
			},
			"wrapped-up": {
				title: "Wrapped up"
			},
			active: {
				title: "Active"
			},
			"in-progress": {
				title: "In Progress"
			},
			error: {
				title: "Roll Forward Failed",
				message: "There was an error while rolling forward '{{engagementName}}'.",
				button: "Contact Support"
			},
			success: {
				title: "Roll Forward Complete",
				message: "Your Engagement '{{engagementName}}' has been rolled forward successfully.",
				button: "View Engagement"
			},
			rolledStatus: {
				rolling: "Rolling Forward...",
				rolled: "Rolled Forward",
				failed: "Failed"
			},
			"partially-complete": {
				title: "Roll Forward Partially Complete",
				message: "Engagement '{{engagementName}}' was created, but some data could not roll forward.",
				button: "Go to Engagement"
			}
		}
	},
	"add-team-member-modal": {
		"no-results": "No Results - press enter to add this practitioner as an external user",
		"deloitte-external-users": "Deloitte users can't be added as external. Search again and select user from the drop down",
		placeholder: "Search by team member names or client emails",
		header: "Add Team Members",
		button: "ADD TEAM MEMBER",
		"header-client": "Add Client Owners",
		"button-client": "Add Client Owner(s)",
		type: "Type ",
		"internal-user-names": "internal user names ",
		or: "or",
		"external-users-emails": " external users emails ",
		description: "configure roles in the team members section.",
		"description-client": "to add client owners.",
		"input-field-label": "Add Team Members",
		"input-client-field-label": "Add Client Owners",
		"internal-default-message": "Internal users default to the role of Team Member",
		"external-default-message": "External users default to the role of Client Member",
		"internal-client-default-message": "Internal users default to the role of Deloitte Client Owner",
		"external-client-default-message": "External users default to the role of Client Owner",
		"external-error-message": "Invalid client e-mail - please remove to proceed and contact your Client Owners.",
		"common-error": "Invalid team member - please remove to proceed",
		"common-client-error": "Invalid client team member - please remove to proceed",
		external: "External",
		"alert-message": "One or more users failed to be added to the engagement. Please try again or contact support.",
		"alert-client-message": "One or more users failed to be added to the Client. Please try again or contact support.",
		"disabled-button-tooltip-text": "This will be enabled once practitioners are added."
	},
	"client-access-required": {
		title: "Client Access Required",
		description: "The client “{client}” can only be edited by the Deloitte Client Owner(s). Please request access from the Deloitte Client Owner(s) below or ",
		"contact-support": "contact support"
	},
	"roll-forward-modal": {
		title: "Roll Forward Engagement",
		description: "To roll forward '{engagement}', fill in the details below.",
		information: {
			"what-will-roll-forward": {
				title: "What information will roll forward?",
				"info-1": "Analytics will retain only transformation steps and metadata",
				"info-2": "Internal engagement members, and data retained during wrap-up process",
				"info-3": "Data field mapping, entity and entity groups"
			},
			"what-will-not-roll-forward": {
				title: "What information will not roll forward, be modified, or erased?",
				"info-1": "Audit evidence, such as Tableau Visuals and Sampling Outputs, were purged at engagement wrap up",
				"info-2": "Once an engagement is rolled forward, the wrapped-up engagement can still be accessed",
				"info-3": "as read-only",
				"info-4": "Journal Entry Testing Analytics, Journal Entry Final Deliverable Reports, and the Data Quality Check/Reconciliation Outputs of the Journal Entry Golden Check Workflow were purged at engagement wrap up",
				"info-5": "Journal Entry Testing Analytics and Journal Entry Final Deliverable Reports were purged at engagement wrap up"
			},
			"learn-more": "Learn More",
			"learn-more-link": "https://www.km.deloitteresources.com/sites/live/audit/_layouts/DTTS.DR.KAMDocumentForms/KAMDisplay.aspx?List=c34e2990-1e93-4bce-9af9-0e4c21c16e50&ID=385703"
		},
		button: {
			cancel: "Cancel",
			"roll-forward": "Roll Forward"
		}
	},
	edit: {
		modal: {
			title: "Edit Engagement",
			form: {
				"engagement-name-label": "Engagement Name",
				"audit-year-label": "Select Current Audit Year End",
				"estimable-deliverable-release-date-label": "Estimated Deliverable Release Date"
			},
			actionButtonLabel: "Save Changes"
		}
	}
};
var client = {
	edit: {
		modal: {
			title: "Edit Client Info",
			form: {
				"client-name-label": "Client Name",
				"country-label": "Country",
				"industry-label": "Industry",
				"industry-placeholder": "Select",
				"fiscal-year-end-label": "Fiscal Year End"
			},
			actionButtonLabel: "Save Changes"
		}
	},
	view: {
		modal: {
			title: "View Client Info",
			form: {
				"client-name-label": "Client Name",
				"country-label": "Country",
				"industry-label": "Industry",
				"industry-placeholder": "Select",
				"fiscal-year-end-label": "Fiscal Year End"
			},
			actionButtonLabel: "Close"
		}
	},
	rollforward: {
		modal: {
			title: "Roll Forward",
			"error-modal-title": "The engagement couldn't be rolled forward at the moment.",
			"error-modal-description": "The system encountered an issue while initiating the roll-forward for this engagement. Please try again after some time."
		}
	},
	verification: {
		text: "The client team must be reviewed every 90 days. Review the client team access below.",
		complete: "MARK AS COMPLETE",
		type: "Review Required",
		"success-header": "Review Complete",
		"success-message": "You will receive another reminder to review client team in 90 days.",
		"success-button": "Got it"
	}
};
var analytics = {
	"je-test-status": {
		testprocessing: "Processing",
		testcomplete: "Complete",
		testerror: "Error",
		testresubmit: "Re-Submit",
		testpendinginput: "Pending Input",
		testnotrun: "Test Not Run"
	},
	flow: {
		status: {
			notStarted: "Not started",
			queued: "Queued",
			running: "Running",
			finished: "Completed",
			error: "Error",
			partiallyComplete: "Partially Complete",
			failedSubmission: "Failed Submission",
			failedExecution: "Failed Execution"
		}
	},
	"test-status": "Test status:",
	"table-no-records": "No Records available",
	"table-no-records-found": "No Records Found",
	"information-title": "Analytic Information",
	no_results_found_text: "No Results found",
	"published-to-draft-confirm-message": "Switching to Draft will create a new version of this analytic. Would you like to proceed?",
	"manage-title": "View & Manage Analytic",
	"create-title": "Create New Analytic",
	"navigateTo-analytic-dashboard": "Back to Analytics Library",
	add_new_analytic_button_text: "Add new analytic",
	search_placeholder: "Search",
	analytics_library_editor_title: "Analytics Library Editor",
	analytic_save_button_text: "Save",
	analytic_name_placeholder: "Analytic Name",
	analytic_duplicate_check_name: "This analytic name already exists. Please provide a unique name.",
	analytic_description_placeholder: "Analytic Description",
	audit_phase_dropdown_label: "Audit Phase",
	account_dropdown_label: "Account",
	industry_dropdown_label: "Industry",
	sector_dropdown_label: "Sector",
	suggested_by_gra_dropdown_label: "Suggested by GRA",
	analytic_type_dropdown_label: "Type",
	analytic_type_options: {
		standard: "Standard",
		self_service_standard: "Self Service/Standard",
		self_service_custom: "Self Service/Custom",
		custom: "Custom"
	},
	dropdown_label_placeholder: "Select",
	je_insights_analytic_checkbox: "JE & Insights Analytic",
	view_button_text: "View",
	test: {
		description: "The following section provides the test(s) available in the Management Override – JE Testing analytic. If a test reflects a characteristic of fraudulent entries that you have determined is not relevant to your engagement, you may de-select the test and document the supporting rationale.",
		"accordion-header": "Step 2: Select Tests to Run",
		"selection-subtext": "tests selected",
		"deselection-title": "reason for de-selecting ",
		"deselection-subtext": "Please provide supporting rationale for de-selecting this test or make reference to applicable documentation",
		"reason-save-message": "Reason saved successfully",
		"enter-reason-placeholder": "Enter reason",
		"navigate-to-insights": "OPTIONAL: JOURNAL ENTRY INSIGHTS",
		"learn-more": "https://kx.deloitte/documents/view/9355",
		"journal-entry-tooltip": "Omnia | Data JE Insights is a data visualization tool intended to support audit procedures and to deliver insights with entity management. For a more in-depth analysis in support of designing journal entry testing to address the risk of management override of controls, use the Management Override - JE Analytics instead of JE Insights."
	},
	"confirm-data": {
		"accordion-header": "Step 1: Confirm Data Scope & Data Integrity Checks",
		"disabledglass-tooltip": "Due to the large number of data validation lines, this feature is not available. If you wish to view the data validation lines, please contact your DA specialist or submit a helpdesk ticket.",
		"header-title": "Journal Entry Identifier Validation Summary",
		"header-description": "If the journal entry number is improperly defined, the results for Tests 1-9 and 11 may not be valid. Please review the data validation findings in the table below.",
		"jeid-description1": "Entries that do not net to Zero",
		"jeid-description2": "Entries that consist of 1 line only",
		"jeid-description3": "Entries that have multiple effective dates",
		"jeid-description4": "Entries that have multiple User ID’s",
		"jeid-description5": "Entries that were input on different dates",
		"nojeid-issues-message": "No data validation issues related to the Journal Entry Identifier were found.",
		"warning-message-prefix": "Before You Proceed",
		"warning-message": " Please confirm that the reconciliation has been performed for the standalone entity being subjected to testing",
		"cds-description": "Select the entities and time periods you want to include within this instance of Management Override – JE Testing. Updating the data scope will not affect existing Journal Entry test inputs. All tests will need to be re-run to view updated results.",
		"recon-checkbox-description": "I confirm that reconciliation has been performed for the standalone entity being subjected to testing.",
		"recon-profession-judgement-title": "Add reference to documentation of reconciliation and data validation checks for the standalone entity/periods being subjected to testing.",
		"transfer-status-inprogress-text": "This process may take a few hours. Once it is complete, you will be notifed by email to continue with JEID Data Validation.",
		"transfer-status-completed-text": "Transfer Status: Complete",
		"transfer-status-failed-text": "There was an error while transferring the data — Please try again.",
		"transfer-status-failed-je-text": "There was an error while transferring the JE data — Please try again.",
		"transfer-status-failed-tb-text": "There was an error while transferring the COA data — Please try again.",
		"transfer-status-acc-issues-text": "Transfer Status: Complete with Account Mapping Issues.",
		"transfer-status-key-issues-text": "Transfer Status: Complete with Key Data Integrity Issues.",
		"transfer-status-both-issues-text": "Transfer Status: Complete with Key Data Integrity and Account Mapping Issues.",
		"transfer-status-inprogress": "Transferring Data",
		"transfer-status-failed": "Error: Transfer failed",
		"transfer-status-completed": "Transfer complete",
		"transfer-status-queued": "Transfer Queued",
		"transfer-status-initated": "Transfer Initiated",
		"data-selection-title": "Select data to transfer",
		"journal-entry-label": "Journal Entry",
		"chart-of-accounts-label": "Chart of Accounts",
		"all-entities": "All Entities",
		"all-time-periods": "All Time Periods",
		"recon-placeholder": "Add reference and, if needed, further description regarding the results of reconciliation and data validation checks.",
		"transfer-toast-title": "Transfer queued",
		"transfer-toast-description": "Your data transfer request is in queue. It will be processed as soon as the other requests are complete.",
		"engagementnotprovisioned-toast-description": "Your engagement is not connected to Globalscape. Please ask your engagement owner or team member to access the step 3 of client setup page to connect this engagement to Globalscape, and try again.",
		"testsinprogress-toast-description": "Your request to run the JE test is still in progress. Please try again after the processing is complete.",
		"exclusioninprogress-toast-description": "Your request to save the exclusions is still in progress. Please try again after the processing is complete.",
		"exportsinprogress-toast-description": "Your request to generate the JE testing analytic deliverable is in progress. Please try again after the processing is complete.",
		"cloudrun-toast-description": "Your request to generate the JE insights is in progress. Please try again after the processing is complete.",
		"insightsinprogress-toast-description": "Insights in progress. ",
		"transfer-step": "Step (",
		"transfer-stepCount": "of 11)",
		"transfer-JE": "JE ",
		"transfer-COA": "COA ",
		"transfer-Status": "Status",
		"data-transfer-completed-text": "data transfer completed",
		"data-transfer-failed-text": "data transfer failed",
		"the-selection-of": "The selection of ",
		and: "and ",
		"results-in-records": " results in 50M or more records. To proceed with this selection, create a new analytic.",
		"currency-selected": "Currency Selected",
		"entity-currency": "Entity Currency",
		"group-currency": "Group Currency",
		"currency-selected-guidance-text": "This represents the currency selected in the Data Kitchen for this entity",
		"group-or-entity-currency": "Group or Entity Currency"
	},
	"copy-parameters-modal": {
		title: "Copy Parameters from Another Analytic",
		description: {
			"select-test-parameters": "Select an analytic and specify which test parameters you would like to use. Threshold amounts and filters cannot be copied. Copying parameters will ",
			override: "override existing inputs",
			"in-current": " in the current analytic."
		},
		"select-analytic": "Select Analytic",
		"apply-test-parameters": "Apply Test Parameters",
		"parameters-table": {
			"parameter-input": "Parameter Input",
			status: "Status",
			"no-results": "Select an analytic to view a list of parameters"
		}
	},
	"test-parameters-copy": {
		title: "Test Parameters Applied",
		description: "The test parameters you've selected have been applied successfully"
	},
	"confirm-copy-parameters-modal": {
		title: "Are you sure you want to override existing inputs?",
		description: "Copying the parameters selected will override existing inputs in the current analytic. Please confirm if you would like to proceed",
		button: {
			message: "Confirm Override"
		}
	},
	"parameter-input-description": {
		"@exclusions-and-prof-judgements": "Exclusions & Professional Judgement",
		"@quarter-and-closing-dates": "Quarter & Closing Period End Dates",
		"@test1-title": "Test 1: Entries Made to Unusual Accounts",
		"@test2-title": "Test 2: Entries Made to Seldom-Used Accounts",
		"@test3-title": "Test 3: Entries Made to Unrelated Accounts",
		"@test4-title": "Test 4: Debits to Revenue",
		"@test5-title": "Test 5: Users with Few Postings or Users of Interest",
		"@test6-title": "Test 6: Closing Entries",
		"@test7-title": "Test 7: Entries Posted After Closing Date for Quarter",
		"@test8-title": "Test 8: Entries Posted on Dates of Interest",
		"@test9-title": "Test 9: Entries with Keywords in Journal Entry and/or Line Description",
		"@test10a-title": "Test 10a: Entries with Round Amounts",
		"@test10b-title": "Test 10b: Entries with Recurring Ending Digits",
		"@test11-title": "Test 11: Duplicate Entries",
		"@insights-params": "Insights Parameters"
	},
	"parameter-input-status": {
		"@pending-input": "Input Needed",
		"@test-not-run": "Not Started",
		"@resubmit": "Re-Run",
		"@complete": "Complete"
	},
	"je-insights": {
		"back-to-journal-entry-test": "Back to Journal Entry Test",
		"header-title": "Omnia | Data JE Insights",
		"header-description-pre": "Omnia | Data JE Insights provides the ability to holistically view entire populations and drill down on specific trends and patterns through the use of visuals. For further information on how to use Omnia | Data JE Insights, please visit the ",
		"header-description-link-title": "Quick Reference Guide.",
		"header-description-link-url": "https://kx.deloitte/documents/view/8142",
		"header-description-post": " Omnia | Data JE Insights is optional, and is designed to provide incremental understanding of the journal entry population beyond the journal entry testing. Prior to creating your Omnia | Data JE Insights project, please adjust your input as needed. The Omnia | Data JE Insights parameters are strictly determined based on the input in this page. In the event the parameters are changed in Omnia | Data JE, and you want to replicate the changes in Deloitte Omnia | Data JE Insights, you must update the input in this page as well.",
		"header-description-note": "Note: Standard holidays entered in Test 08 are not automatically carry forwarded to JE Insights.",
		"category-mapping": {
			title: "Financial Statement Line Mapping",
			description: "The following table is the list of unique FS lines in your journal entry population. If you wish to use the standard FS Category for your Omnia | Data JE Insights project, please map your FS Line to the standard FS Category by using the dropdown box.",
			"checkbox-text": "Check this box if you wish to use the FS Line from your journal entry population.",
			"fs-line-column-title": "Financial Statement Line",
			"fs-category-column-title": "Financial Statement Category",
			"release-notification-message": "The recent release of Omnia | Data includes enhancements to data upload rules which now allow for the inclusion of new special characters (i.e., non alpha-numeric characters). If certain special characters exist in your data set, it may impact the comparability of data used in Management Override – Journal Entry (JE) testing and analytics performed in Omnia | Data.  For further assistance or questions,"
		},
		"quarter-date-title": "Quarter and Closing Period Dates",
		"quarter-date-description": "Note: Engagement teams are encouraged to tailor the closing dates within Management Override - JE Testing for the purpose of refining Test 6, Closing Entries, parameters. Consider the purpose of the JE Insights and if the same dates are applicable here or whether it would be appropriate to update the closing start and end dates to reflect different dates, for example the first and last days that the entity prepares and posts closing entries to the general ledger.",
		"quarter-date-label": "Quarter ",
		"quarter-date-start-date": "Start Date",
		"quarter-date-end-date": "End Date",
		"quarter-date-closing-period-start-date": "Closing Start Date",
		"quarter-date-closing-period-end-date": "Closing End Date",
		"quarter-date-invalid-date-validation-message": "Please enter valid date format",
		"quarter-date-quarters-date-validation-message": "is invalid",
		"quarter-date-closing-period-validation-message": "Quarter and closing period dates are required",
		"generate-outputs-title": "Step 2: Generate Output",
		"generate-outputs-description": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip",
		"generate-outputs-button": "Generate Outputs",
		"generate-outputs-refresh-button": "Refresh Outputs",
		"generate-outputs-access-insights-project-button": "Access Insights Project",
		"generate-outputs-card-title": "Insights Package",
		"generate-outputs-card-description": "Visualization",
		"generate-outputs-output-title": "Export Output",
		"generate-outputs-output-description": "Tableau Visualization",
		"generate-outputs-output-cta": "Create Export",
		"generate-outputs-error-description": "Support is needed to generate Omnia | Data JE Insights. Please contact Omnia | Data Support team (DeloitteCortexSupport@deloitte.com)",
		"generate-outputs-error-title": "Error Message",
		"refine-client-data-step-title": "Step 1: Refine Input",
		"refine-client-data-description": "The entity and time period defined in Step 1 of the Test Settings screen of Management Override-JE Testing will be applied here.",
		"refine-client-data-save-changes-button": "Save Changes",
		"generate-outputs-status": {
			running: "Running",
			completed: "Completed",
			"pending-input": "Pending Input",
			suspended: "Suspended",
			"not-accessible": "Not Accessible",
			submit: "Submit the Insights Project Request",
			"max-users": "Maximum Number of Users Reached",
			moved: "Project has been moved to cold storage",
			"create-deliverable-inprogress": "Create deliverable is in progress. Please try again when deliverable is completed.",
			"export-under-threshold-inprogress": "Export of Under Threshold Flagged lines To Excel is in progress. Please try after some time."
		}
	},
	"profession-judgement": {
		placeholder: "Provide explanation",
		"btn-save": "Save",
		"btn-cancel": "Cancel",
		"modal-title": "Add Professional Judgment: Deselecting Entry",
		"btn-savereason": "Save Reason",
		"btn-delete": "Delete",
		"delete-modal-heading": "Are you sure you want to delete the 'Tickmark'?",
		"delete-modal-description": "Deleting this tickmark will remove the applicable references wherever entered."
	},
	"jeid-tickmark": {
		add: "Tickmark detail is added successfully.",
		update: "Tickmark detail is updated successfully.",
		"delete": "Tickmark detail is deleted successfully.",
		"text-area-placeholder": "Document professional judgement",
		"add-a-tickmark": "Create a New Tickmark",
		"add-new": "Add New",
		"tickmarks-save-message": "Tickmark detail saved successfully.",
		"edit-tickmark": "Edit Tickmark",
		"tickmark-title": "Tickmark Directory",
		"tickmark-Guidance-Text": "Select at least one tickmark from the list to associate it. (Note - Creating/editing a new tickmark within this directory does not automatically associate it). All changes must be saved."
	},
	exclusions: {
		description1: "Exclude lines with zero value",
		description2: "Exclude entries with zero effect outside of Financial Statement",
		description3: "Exclude certain account numbers",
		description4: "Exclude lines based on custom criteria",
		"view-details": "View Details",
		"select-from-table": "Select from Table",
		placeholder: "Please provide a reason why you've included these exclusions",
		"save-button": "Save",
		exclusion: "Exclusions",
		"header-tooltip": "Use the options below to specify exclusions to be made and document the rationale for excluding those entries in the space provided below.",
		"accounts-placeholder": "Enter account numbers, comma separated or select from table",
		"lines-placeholder": "Enter line numbers, comma separated or select from table",
		"success-toaster": "All journal entries in scope of journal entry testing have been identified, you may now proceed further with running the tests",
		"accounts-validation": "Please select account numbers to exclude",
		"lines-validation": "Please select items to exclude",
		"exclusion-details-message": "Due to the large number of lines excluded, this feature is not available. If you wish to view the excluded lines, you may export the details to Excel.",
		"summary-information": "SUMMARY OF JOURNAL ENTRY DATA AND EXCLUSIONS",
		"summary-message": "Apply exclusions to update summary",
		updated: "Updated",
		"add-exclusions-accounts": "Exclude the following account numbers",
		"search-accounts-placeholder": "Search accounts",
		"add-exclusions-button": "Add Exclusions",
		"add-exclusions-entries-label": "Showing {from} to {to} of {total} accounts",
		"selected-accounts": "ACCOUNTS",
		"deselect-all-accounts": "DESELECT ALL",
		"add-exclusions-custom-criteria": "Add Exclusions: Custom Criteria",
		"add-exclusions-custom-criteria-guidance-text": "The custom exclusion is handled at the line level and is treated the same as the account exclusion. Custom exclusions included within this table are set up by DA specialist. They will use input from Audit to identify and/or create this custom criteria.",
		"search-custom-criteria-placeholder": "Search custom exclusions",
		"add-exclusions-entries-label-custom-exclusions": "Showing {from} to {to} of {total} custom exclusions",
		"selected-custom-criteria": "CUSTOM EXCLUSIONS",
		"view-exclusion-entries": "Details for Excluded Entries by FS Exclusion",
		"view-exclusion-accounts": "Details of Excluded Account Numbers",
		"view-exclusion-custom-lines": "Details for Excluded Lines based on Custom Criteria",
		"view-exclusion-details-lable-format": "SHOWING {from} TO {to} OF {total} ITEMS",
		"account-description-line-excluded": "Line-Excluded",
		"exclusion-export-link-text1": "Click",
		"exclusion-export-link-text2": "here",
		"exclusion-export-link-text3": "to view the excluded lines in Excel. Please note that only the first 150,000 lines will be included in this Excel export",
		"exclusion-export-toaster-success": "Request to process export of the details of Excluded lines report has been submitted. You will receive an email within 15 minutes with a link to the Excluded lines report once it is ready for download",
		"exclusion-export-toaster-inprogress": "Your Previous Request is being Processed. Please wait until that completes",
		"release-notification-message": "The recent release of Omnia | Data includes enhancements to data upload rules which now allow for the inclusion of new special characters (i.e., non alpha-numeric characters). If certain special characters exist in your data set, it may impact the comparability of data used in Management Override – Journal Entry (JE) testing and analytics performed in Omnia | Data.  For further assistance or questions,",
		"add-showing-text-entries-label": "SHOWING",
		"add-to-text-entries-label": "TO",
		"add-page-text-entries-labels": "PAGE {from} OF {to}",
		"add-page-total-entries": " OF {rowcount} ENTRIES",
		"add-page-rowcount-total-entries": "{rowcount}",
		"add-of-text-entries-label": " OF ",
		"add-records-text-records-label": " RECORDS",
		"add-from-text-entries-label": "{from}",
		"add-totext-entries-label": "{to}",
		"add-total-text-entries-label": "{total}",
		"add-page-table-text-entries-label": "{page}",
		"add-pagetext-table-entries-label": "{page} page of {total} pages",
		"add-fromto-text-table-stories-label": "{from}-{to} of {total}",
		"add-page-labels": "PAGE"
	},
	"confirm-migration-dialog": {
		"popup-title": "Omnia | Data JE Testing Analytic Upgrade",
		"mandatory-popup-body-text": "A new version of journal entry testing analytic is available. Clicking on “Upgrade” will apply the updates to your analytic. You will receive a notification once the upgrade is complete.",
		"optional-popup-body-text": "A new version of journal entry testing analytic is available.",
		upgrade: "Upgrade",
		"skip-upgrade": "Proceed without upgrade",
		note: "Note:",
		"optional-popup-sub-text-1": "Clicking on “Upgrade” will apply the updates to your analytic. You will receive a notification once the upgrade is complete.",
		"optional-popup-sub-text-2": "Clicking on “Proceed without upgrade” will not apply any new updates, you will be able to access this analytic as is."
	},
	"migration-error-message": {
		"migration-started": "Analytic upgrade in progress",
		"data-migration-inprogress": "Analytic upgrade in progress",
		"project-loading": "Your project is still being loaded into the system, please try again.",
		"migration-ignored-success": "Upgrade Ignored Successfully."
	},
	"journal-entry-common": {
		all: "ALL",
		Recur: "Recur",
		Amt: "Amt",
		And: "And",
		Unchecked: "Unchecked",
		"holiday-Postings": "Postings on Holidays",
		"saturday-Postings": "Postings on Saturdays",
		"sunday-Postings": "Postings on Sundays",
		"grouping-level": "Grouping Level",
		"type-of-entry": "Type of Entry",
		type: "Type",
		"number-of-lines": "Number of Lines",
		"number-of-journal-lines": "Number of Journal Lines",
		"journal-entry": "Journal Entry",
		"journal-entry-line-description": "Journal Entry/Line Description",
		"journal-entry-description": "Journal Entry Description",
		"line-description": "Line Description",
		description: "Description",
		"account-number": "Account Number",
		debit: "Debit",
		credit: "Credit",
		"total-debit-amount": "Total Debit Amount",
		"total-credit-amount": "Total Credit Amount",
		"user-id": "User ID",
		"user-name": "User Name",
		"date-user-input-entry": "Date that User Input Entry",
		"effective-date": "Effective Date",
		entity: "Entity",
		localAmount: "Amount Information (Shown in Local Currency)",
		account: "Account",
		"account-description": "Account Description",
		"fs-line": "FS Line",
		"fs-category": "FS Category",
		entries: "Entries",
		lines: "Lines",
		"account-subtype": "Account Subtype",
		subtype: "Subtype",
		"number-of-entries": "Number of Entries",
		"number-of-journal-entries": "Number of Journal Entries",
		"approver-id": "Approver ID",
		"approver-name": "Approver Name",
		"debit-amount": "Debit Amount",
		"credit-amount": "Credit Amount",
		"debit-amount-ec": "Debit Amount EC",
		"debit-amount-gc": "Debit Amount GC",
		"credit-amount-ec": "Credit Amount EC",
		"credit-amount-gc": "Credit Amount GC",
		net_amount_oc: "Net Amount OC",
		"custom-exclusions": "Custom Exclusions",
		"account-keyword": "Account/Keyword",
		"account-name": "Account Name",
		"test-number": "Test Number",
		"keyword-account-description-interest": "Keywords/Accounts of Interest",
		"keyword-in-je-or-journal-line-description": "Keyword in JE or Journal Line Description",
		keyword: "Keyword",
		"keyword-in-entry-line-description": "Keyword in Entry/Line Description",
		location: "Location",
		flagtype: "Flag Type",
		"posted-lines": "Posted Lines",
		indicator: "Indicator",
		nonStandard: "Nonstandard",
		standard: "Standard",
		"time-period": "Time Period",
		"flagged-account-validation": "Please select at least one flagged account",
		amounts: "Amounts",
		"entries-obtained-from-client": "A. Entries obtained from client",
		"entries-excluded-from-testing": "B. Entries excluded from testing",
		"lines-with-zero-amount": "1. Lines with zero amounts",
		"exclude-zero-effect-entries": "2. Exclude entries with zero effect outside of a financial statement line",
		"exclude-certain-account-numbers": "3. Exclude certain account numbers",
		"exclude-lines-based-on-custom-criteria": "4. Exclude lines based on custom criteria",
		"total-analyzed": "Total Analyzed",
		steps: {
			"test-settings": "Test Settings",
			"run-tests": "Run Tests",
			evaluation: "Evaluation"
		},
		"next-run-test": "Next : Run Tests",
		keywords: "Keywords",
		"word-string": "Word or String",
		word: "Word",
		string: "String",
		"test-navigation": "Prepopulated Tests",
		"customized-tests": "Custom Tests",
		"customized-test-add-button-text": "ADD CUSTOM TEST",
		"all-tests": {
			"@test1-desc": "TEST 1",
			"@test2-desc": "TEST 2",
			"@test3-desc": "TEST 3",
			"@test4-desc": "TEST 4",
			"@test5-desc": "TEST 5",
			"@test6-desc": "TEST 6",
			"@test7-desc": "TEST 7",
			"@test8-desc": "TEST 8",
			"@test9-desc": "TEST 9",
			"@test10a-desc": "TEST 10A",
			"@test10b-desc": "TEST 10B",
			"@test11-desc": "TEST 11"
		},
		"amount-info": "Amount Information (Shown in Local Currency)",
		"entity-id": "Entity ID",
		"journal-line-description": "Journal Line Description",
		"local-amount": "Local Amount",
		"test-parameter": "Test Parameter",
		"test-error-toast-message": "Test run is not successful. Please rerun the test by applying the necessary parameters. If the issue continues, please contact the support team.",
		"toaster-error": "Error",
		"error-key": {
			"quarter-dates-exclusions-inprogress": "Quarter Dates and/or Exclusion run in progress. Please try after sometime.",
			"test1-run-inprogress": "Test 1 run is in progress. Please Try After Sometime.",
			"test2-run-inprogress": "Test 2 run is in progress. Please Try After Sometime.",
			"test3-run-inprogress": "Test 3 run is in progress. Please Try After Sometime.",
			"test4-run-inprogress": "Test 4 run is in progress. Please Try After Sometime.",
			"test5-run-inprogress": "Test 5 run is in progress. Please Try After Sometime.",
			"test6-run-inprogress": "Test 6 run is in progress. Please Try After Sometime.",
			"test7-run-inprogress": "Test 7 run is in progress. Please Try After Sometime.",
			"test8-run-inprogress": "Test 8 run is in progress. Please Try After Sometime.",
			"test9-run-inprogress": "Test 9 run is in progress. Please Try After Sometime.",
			"test10a-run-inprogress": "Test 10A run is in progress. Please Try After Sometime.",
			"test10b-run-inprogress": "Test 10B run is in progress. Please Try After Sometime.",
			"test11-run-inprogress": "Test 11 run is in progress. Please Try After Sometime.",
			"create-deliverable-inprogress": "Export of Final Deliverable Report (FDR) in progress. Please try again once complete.",
			"export-under-threshold-inprogress": "Export of Under Threshold Flagged lines To Excel is in progress. Please try again once complete.",
			"export-excluded-lines-for-fslines-inprogress": "Export Excluded Lines for FS Lines, Account, Custom Exclusions is in progress. Please Try After Sometime.",
			"system-is-busy": "The system is currently busy. Please try your submission again in a few minutes. If you continue to receive this message, please contact the help desk at 1.800.Deloitte to create a ticket.",
			"complete-dvr": "Please complete the Journal Entry Identifier Data Validation before proceeding further."
		},
		"deselect-all": "Deselect all",
		"select-all": "Select all",
		"contact-support": "contact support",
		"release-notification-message-prefix": "Reminder",
		"post-contact-support-message": ", or contact your Data Analytics Specialist to assist in the team’s assessment of the impact of this enhancement.",
		note: "Note:"
	},
	"journal-entry": {
		"not-authorized": "Analytic not found or you don't have appropriate permission to access this analytic.",
		"project-locked": "The Analytic you are trying to access has been locked by {{CURRENTUSER}}",
		"session-time-out-error": "Your session is expired, your page will be refreshed automatically",
		"inactive-analytic": "The analytic you are trying to access is currently not active. You will be notified via email once the analytic is ready for use.",
		"validity-issue-template": {
			title: "Test-specific data validity issues",
			description: "The following test-specific dataset validity issues were found:",
			untitled: "Untitled",
			status: {
				"no-issues": "No issues found",
				"single-issue": "1 validity issue",
				"multiple-issues": "%d validity issues"
			},
			content: {
				"view-details-label": "View Details",
				"add-tickmark-label": "Add Professional Judgement",
				"add-desc-in-data-kitchenLabel": "Review in Data Kitchen"
			}
		},
		"custom-exclusions": "Custom Exclusions",
		entries: "Entries",
		lines: "Lines",
		amounts: "Amounts",
		amount: "Amount",
		"by-type-of-entry": "(By Type of Entry)",
		"test-not-requested": " Test not requested for this type of entry"
	},
	"refine-client": {
		"accordion-header": "Step 3: Refine Client Data",
		"step3-header-message": "COPY PARAMETERS FROM ANOTHER ANALYTIC",
		"accordion-description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		message: "Message"
	},
	"quarter-dates": {
		heading: "Quarter and Closing Period Dates",
		"guidance-text": "Quarter and closing period dates are required to be input before proceeding. The quarter start and end dates are inputs for Test 4 and the closing start and end dates are inputs for Tests 6 and 7. For test 6, teams may use more targeted closing start and end dates based on an understanding of key dates in the entity's financial close and reporting process.",
		"quarter-title": "Q",
		"quarter-start": "Start Date",
		"quarter-end": "End Date",
		"quarter-closing-start": "Closing Start Date",
		"quarter-closing-end": "Closing End Date",
		"quarter-closing-start-guidance-text": "The “Start Date” of the “Closing Period” is the day when the entity begins to prepare and post closing entries to the general ledger.",
		"quarter-closing-end-guidance-text": "The “End Date” is the date on which the last entry for a period is posted to the general ledger and may be either the last day of the quarter or several days after the last day of the quarter.",
		"quarter-date-validation-message": "Quarter dates are required for processing the JE tests. ",
		"quarter-startdate-validation-message": "Start Date cannot be greater than End Date",
		"quarter-closingstartdate-validation-message": "Closing Start date cannot be greater than Closing End Date",
		"date-validation": "Please enter valid date format",
		"quarter-date-success-message": "Quarter Dates Saved Successfully"
	},
	"test-common": {
		"reset-confirm": "Reset Test",
		"reset-cancel": "Cancel",
		"reset-title": "Are you sure you want to reset this test?",
		"reset-message": "If you reset this test, all the changes you've made will revert to it's default state. Please confirm if you would like to proceed.",
		"test-works-header": "How this test works:",
		"test-results": "Test Results",
		"summary-table-update-message": "Not Updated - Please re-run test to view updated results",
		"view-test-details-lable-format": "SHOWING {from} TO {to} OF {total} ITEMS",
		"flagged-accounts": "Flagged accounts",
		keywords: "Keywords",
		"fs-lines": "FS Lines",
		users: "Users",
		"threshold-error-text": "Enter up to 13 digits",
		"threshold-filter-field": "Set Grouping Threshold",
		"unsaved-changes-message": "The changes you've made will not be saved until you run the test. Would you like to continue without saving?",
		"unsaved-changes": "Unsaved Changes",
		"continue-without-saving": "Continue without Saving",
		"entry-type-validation-message": "You need to select at least 1 journal entry type",
		"grouping-threshold-validation-message": "Grouping threshold must be a number",
		"grouping-threshold-guidance-text": "Group multiple journal lines into one. Lines below this threshold will be grouped together if the lines have the same 1) account number, 2) flagged status, 3) keyfields.",
		"tickmark-unsaved-changes-Message": "The changes you've made have not been saved. Would you like to continue without saving?",
		"guidance-text-Detail-View": "If a flagged journal entry has more than 150 lines, the detail view will only display the first 150 lines, and the remaining lines will be shown as a combined summary line. However, in the Final Deliverable Report, all lines will be displayed separately.",
		"entries-flagged-table-guidance-text": "If the number of flagged lines is more than 10,000 records, the magnifying glass will be disabled. Generate the Excel output to review the complete details."
	},
	"test-footer": {
		"back-to-test-settings-button-text": "BACK TO TEST SETTINGS",
		"scroll-to-top-button-text": "SCROLL TO TOP",
		"view-flagged-entries-button-text": "Next : View Flagged Entries",
		"next-evaluation-button-text": "Next: Evaluation"
	},
	"custom-date": {
		"identify-custom-date-of-interest-title": "Identify custom dates of interest",
		"custom-dates-title": "Custom Dates",
		"identify-custom-date-of-interest-company-specific-event-title": "Add custom dates of interest based on company-specific events.",
		"add-custom-date-button-text": "ADD CUSTOM DATE",
		"enter-name-of-date-title": "Name of Date",
		"enter-name-of-date-place-holder": "Enter Name of Date",
		"calendar-date-of-interest-title": "Date of interest",
		"calendar-date-of-interest-placeholder": "DD/MM/YYYY",
		weekends: "Weekends",
		"all-saturdays": "All Saturdays",
		"all-sundays": "All Sundays"
	},
	evaluation: {
		"summaryByNumberOfTests-1Test": "1 Test",
		"summaryByNumberOfTests-2Test": "2 Tests",
		"summaryByNumberOfTests-3Test": "3 Tests",
		"summaryByNumberOfTests-4Test": "4 Tests",
		"summaryByNumberOfTests-5Test": "5 Tests",
		"summaryByNumberOfTests-6Test": "6 Tests",
		"summaryByNumberOfTests-7Test": "7 Tests",
		"summaryByNumberOfTests-8Test": "8 Tests",
		"summaryByNumberOfTests-9Test": "9 Tests",
		"summaryByNumberOfTests-10Test": "10 Tests",
		"summaryByNumberOfTests-11Test": "11 Tests",
		"summaryByNumberOfTests-12Test": "12 Tests",
		"summaryByNumberOfTests-1Test-or-more": "1 Test or more",
		"summaryByNumberOfTests-2Test-or-more": "2 Tests or more",
		"summaryByNumberOfTests-3Test-or-more": "3 Tests or more",
		"summaryByNumberOfTests-4Test-or-more": "4 Tests or more",
		"summaryByNumberOfTests-5Test-or-more": "5 Tests or more",
		"summaryByNumberOfTests-6Test-or-more": "6 Tests or more",
		"summaryByNumberOfTests-7Test-or-more": "7 Tests or more",
		"summaryByNumberOfTests-8Test-or-more": "8 Tests or more",
		"summaryByNumberOfTests-9Test-or-more": "9 Tests or more",
		"summaryByNumberOfTests-10Test-or-more": "10 Tests or more",
		"summaryByNumberOfTests-11Test-or-more": "11 Tests or more",
		"summaryByNumberOfTests-12Test-or-more": "12 Tests or more",
		"summaryByNumberOfTests-0Test": "Entries not flagged by any test",
		"summaryByNumberOfTests-Total": "Total",
		"summaryByNumberOfTests-numberOfTests-field": "Number of Tests",
		"summaryByNumberOfTests-nonStandard-field": "Nonstandard number of Entries",
		"summaryByNumberOfTests-standard-field": "Standard number of Entries",
		"summaryByNumberOfTests-title": "Summary of entries flagged by number of tests",
		"summaryByNumberOfTests-Description": "The following table shows the number of entries flagged by X number of tests. The table is updated automatically once you set parameters for a test.",
		"summary-of-entries-flagged-title": "Summary of Entries Flagged",
		"summary-of-entries-flagged-description": "The following table shows the summary of entries flagged. If a test reflects a characteristic of fraudulent entries that you have determined is not relevant to your engagement, you may uncheck the test in the Test Settings page and, when prompted, document the reason for not running the test. However, note that because an entry may be flagged by more than one test, the totals for the columns in this table may not agree with the totals in the other tables on this page.",
		"summary-of-entries-flagged-testName": "Test Names",
		"summary-of-entries-flagged-nonStandard": "Nonstandard",
		"summary-of-entries-flagged-standard": "Standard",
		"summary-of-entries-flagged-entries": "Entries",
		"summary-of-entries-flagged-flaggedLines": "Flagged lines",
		"summary-of-entries-flagged-lines": "Lines",
		"generate-outputs": "Generate Outputs",
		"release-notification-message": "The recent release of Omnia | Data includes enhancements to Test 1: Entries Made to Unusual Accounts and Test 9: Entries with Keywords in Journal Entry and/or Line Description. These changes may impact the comparability to previously completed Management Override – Journal Entry (JE) testing analytics Final Deliverable Report (FDR) Outputs generated in Omnia | Cortex. For further assistance or questions,",
		"generate-outputs-description": "Results of the analyses can be exported into an Excel report by clicking on the Generate Output button. This file will contain the summary results of each test, parameters used, professional judgments provided, and the details of the journal entries flagged by one or more tests. This report can also be included as part of the working papers in Omnia or EMS.",
		"total-entries-flagged-export": "Total Number of Journal Entries Flagged That Will Be Exported",
		"total-lines-flagged-export": "Total Number of Journal Lines Flagged That Will Be Exported",
		"lines-flagged-export-after-grouping": "Total Number of Lines That Will Be Exported (After Grouping)",
		"grouping-threshold-field": "Set Grouping Threshold",
		"edit-grouping-threshold-field": "Edit Grouping Threshold",
		"generate-output": "Generate Output",
		"generating-output": "Progress",
		"fdr-button-display-message": "Complete all tests selected in Test Settings to generate output.",
		"fdr-test-error-message": "Error: We have encountered an issue with JE test(s): {impactedTest}. Please rerun the impacted test(s) by applying the necessary parameters. If the issue continues, please contact Omnia | Data support team for further assistance.",
		"export-underthreshold-report-toaster-success": "Request to process export of the details of Under Threshold report has been submitted. You will receive an email within 15 minutes with a link to the Under Threshold report once it is ready for download",
		"export-underthreshold-report-toaster-inprogress": "Your Previous Request is being Processed. Please wait until that completes",
		"export-underthreshold-report-toaster-error": "Error in processing your Request",
		"group-rows-button": "Group Rows",
		"enter-grouping-threshold-field": "Enter Grouping Threshold",
		"grouping-threshold-description": "All journal lines below this threshold will be grouped together if the lines meet the following criteria:",
		"grouping-threshold-line1": "1. Same account number",
		"grouping-threshold-line2": "2. Same flagged status",
		"grouping-threshold-line3": "3. Same keyfields (ex. account description, user ID, approver ID, posted or effective dates)",
		"export-underthreshold-report-description-line1": "The Under Threshold Report is available for informational purposes and is not expected to be retained in the audit documentation.",
		"export-underthreshold-report-description-line2": "You may use the number of entries, lines, and the monetary amounts from the Total Entries Flagged table within each respective test tab under Run Tests when considering and documenting the quantitative effect of the entries that fall under the selected monetary threshold.",
		"export-underthreshold-report-description-line3": "You may use the Under Threshold Report to review the qualitative nature of the entries that fall under the selected monetary threshold. This export, generated by clicking the Generate Report button below, will contain details for entries where at least 1 line is flagged but under threshold for each test. Each test, which will display on a separate tab, will include a maximum of 150,000 lines for further review. If additional detail is needed,",
		"export-underthreshold-report-description-line4-part1": "The",
		"export-underthreshold-report-description-line4-part2": "Omnia | Data Management Override - JE Testing Explanations QRG",
		"export-underthreshold-report-description-line4-part3": "document provides explanations of the threshold logic for each test.",
		"export-underthreshold-report-contact-support": "contact support",
		"export-report-done-text": "Your request to generate the output is complete. Please download the output from the above section.",
		"export-report-inprogress-text": "Your Request is being Processed. Please wait until it completes.",
		"export-report-suspended-text": "Your Request is suspended. Please try again.",
		"generate-report": "GENERATE REPORT",
		"generate-additional-report": "GENERATE ADDITIONAL REPORT",
		"export-rows-warning-title": "NUMBER OF ROWS EXCEED LIMIT",
		"export-rows-warning-description-part1": "The number of rows exceeds 150,000. Please group entries together using",
		"export-rows-warning-description-part2": "to reduce the number of rows.",
		"generate-underthreshold-report": "GENERATE UNDER THRESHOLD REPORT",
		"generate-output-dialog-titile": "Select Output Format",
		"generate-output-dialog-description": "Select a format below to generate your output:",
		"generate-output-option-omnia": "Omnia",
		"generate-output-option-ems": "EMS",
		"generate-output-dialog-button-text": "Generate",
		"fdr-ready-for-download": "Ready to Download",
		"fdr-success-message": "Request to process the final deliverable has been submitted. You will receive an email within 15 minutes with a link to the deliverable once it is ready for download.",
		"all-tests-should-be-complete-fdr": "All tests must be in either a 'Complete' or 'Test Not Run' status to generate the Final Deliverable Report.",
		"output-file-name": "File name",
		"output-time-generated": "Time Generated (UTC)",
		"output-download": "Download",
		"back-to-test-button": "BACK TO EXECUTE TESTS",
		"download-evaluation-output-file-tooltil-message": "Download is in progress",
		"download-evaluation-output-failed-error-header-message": "Download failed for below files:"
	},
	"test-01": {
		testname: "Entries Made to Unusual Accounts",
		title: "Test 1: Entries Made to Unusual Accounts",
		description: "This test identifies entries made to unusual accounts. The identification of unusual accounts is based on the presence of certain keywords or phrases in the account description and/or the characteristic of, or activity in, certain account numbers. The search can be performed using either the account description or account number, or using both.",
		"identify-unusual-account-numbers-title": "Identify unusual account numbers*",
		"account-text-placeholder": "Search accounts",
		"identify-unusual-account-numbers-search-title": "Select from Table",
		"identify-unusual-account-numbers-guidance": "Some examples could be account numbers that do not follow the entity’s numbering convention, an unusual volume of activity for accounts opened during the year etc.",
		"search-account-placeholder": "Enter account numbers and separate them with semi-colon or select from table",
		"search-unusual-accounts-numbers-title": "Select Unusual Accounts Numbers",
		thresholdAmountSuggestionText: "Threshold amount",
		addAccountButton: "Add Accounts",
		"add-keywords-to-identify-unusual-accounts-title": "Add keywords to identify unusual accounts*",
		"add-keywords-to-identify-unusual-accounts-description": "Add keyword(s) to identify unusual account names or descriptions and separate multiple keyword(s) by semicolon (e.g. accounts receivable; inventory; revenue; cash).",
		"add-keywords-to-identify-unusual-accounts-placeholder": "Enter keywords, semicolon separated",
		"add-keywords-to-identify-unusual-accounts-description-add-keyword-text": "Add keyword(s) to identify unusual account names or descriptions. Remember to enter keywords",
		"add-keywords-to-identify-unusual-accounts-description-semicolon-text": "separated by a semicolon",
		"add-keywords-to-identify-unusual-accounts-description-do": "do",
		"add-keywords-to-identify-unusual-accounts-description-not": "not",
		"add-keywords-to-identify-unusual-accounts-description-separate-keywords": "use commas to separate keywords",
		"add-keywords-to-identify-unusual-accounts-description-maximum-char-limit": "Maximum character limit between semicolons is 500.",
		"add-keywords-to-identify-unusual-accounts-description-all-leading": "All leading and trailing spaces will be removed from the keywords upon running the test.",
		"add-keywords-to-identify-unusual-accounts-description-note": "NOTE",
		"add-keywords-to-identify-unusual-accounts-description-string-search": "This test uses a “string search.” A string search",
		"add-keywords-to-identify-unusual-accounts-description-exact-characters-match": "looks for the specified characters",
		"add-keywords-to-identify-unusual-accounts-description-set-characters": "which could appear as a standalone value or embedded in a longer set of characters.",
		"add-keywords-to-identify-unusual-accounts-description-characters-example": "For example, if the team desires to search for",
		"add-keywords-to-identify-unusual-accounts-description-example": "and if the description is connected to other characters (e.g.,",
		"add-keywords-to-identify-unusual-accounts-description-accounts-receivable": "Receivable",
		"add-keywords-to-identify-unusual-accounts-description-accounts-receivable-text": "Accounts-Receivable",
		"add-keywords-to-identify-unusual-accounts-description-or": "or",
		"add-keywords-to-identify-unusual-accounts-description-accounts-receivable-net": "Accounts_Receivable_net",
		"add-keywords-to-identify-unusual-accounts-description-end-test": "then the team can expect these accounts to be flagged.",
		"select-entry-reason-provide": "Provide Reason",
		"select-entry-label": "Please provide a reson why you've selected these type(s) of entries:",
		"select-entry-form-label": "Select the types of entries to include in this test",
		"threshold-amount-label": "Enter threshold amount for this test",
		"threshold-amount-information-tooltip": "Only journal entries with flagged lines in excess of the threshold amount are flagged by this test.",
		"number-of-lines-placeholder": "Number of Lines",
		"number-of-lines-helper-text": "Number of lines must be greater than 1",
		"professional-judgement-placeholder": "Provide explanation",
		"professional-fieldLabel-judgement-selectEntry": "Please provide a reason why you’ve selected these type(s) of entries:",
		"professional-fieldLabel-judgement-thresholdAmount": "Please provide a reason why you’ve entered this threshold amount:",
		"professional-fieldLabel-judgement-unusualAccountNumber": "Please provide a reason why you’ve identified these account numbers:",
		"professional-fieldLabel-judgement-unusualAccountKeyword": "Please provide a reason why you’ve identified these keywords:",
		"run-test": "Run Test",
		"reset-test-button": "RESET THIS TEST",
		"cancel-button": "Cancel",
		search: "Search",
		"reset-test": "Reset Test 1",
		"reset-popup-title": "Are you sure you want to reset this test?",
		"reset-popup-body-text": "If you reset this test, all the changes you've made will revert to its default state.  Please confirm if you would like to proceed.",
		"toptable-header": "Total Entries Flagged",
		"no-issues-found": "No issues found",
		"one-validity-issue": "1 validity issue",
		"validity-issues": "%d validity issues",
		"test-specific_data_validity_issues": "Test-Specific Data Validity Issues",
		"test-specific-dataset-validity": "The following test-specific dataset validity issues were found:",
		"view-details": "View Details",
		"add-tickmark": "Add Tickmark",
		"add-description-in-data-kitchen": "Add Description in Data Kitchen",
		"total-entries-flagged-title": "Details: Test 01 - Total Entries Flagged",
		"grouping-threshold-guidance-text": "Group multiple journal lines into one. Lines below this threshold will be grouped together if the lines have the same 1) account number, 2) flagged status, 3) keyfields.",
		"entry-type-validation-message": "You need to select at least 1 journal entry type",
		"account-keyword-validation-message": "Please select at least one filter",
		chkStandard: "chkStandard",
		chkNonStandard: "chkNonStandard",
		selectEntry: "selectEntry",
		thresholdAmount: "thresholdAmount",
		unusualAccountNumber: "unusualAccountNumber",
		unusualAccountKeyword: "unusualAccountKeyword",
		"missing-account-name": "Missing Account Descriptions",
		"missing-account-descriptions": "This test uses the Account Descriptions as input. Your dataset has missing account descriptions and might therefore not identify all unusual accounts within the keyword search of this test. Click here to insert the missing descriptions.",
		"input-chips-validation-message": "You have entered invalid filter inputs. Please remove the invalid inputs and resubmit the test.",
		"toster-error": "Error",
		"summary-entries-flagged-title": "Details: Test 01 - Summary of Entries Flagged",
		shortname: "Test 1",
		"release-notification-message": "The recent release of Omnia | Data includes enhancements to data upload rules which now allow for the inclusion of new special characters (i.e., non alpha-numeric characters). If certain special characters exist in your data set, it may impact the comparability of data used in Management Override – Journal Entry (JE) testing and analytics performed in Omnia | Data.  For further assistance or questions,",
		"release-notifications-message": "The recent release of Omnia | Data includes enhancements to this test. Test 1: Entries Made to Unusual Accounts runs on a \"string search,\" which looks for the specified characters that could appear as a standalone value or embedded in a longer set of characters. Previously, the test ran on \"word search,\" which would search for an exact match including any preceding or trailing characters. This change will impact the comparability to previously completed Management Override – Journal Entry (JE) testing analytics performed in Omnia | Cortex. For further assistance or questions,"
	},
	"test-02": {
		testname: "Entries Made to Seldom-Used Accounts",
		title: "Test 2: Entries Made to Seldom-Used Accounts",
		description: "This test identifies all entries made to accounts with less than X lines, both standard and non-standard entry types, posted during the period defined",
		"select-entry-form-label": "Select the types of entries to include in this test",
		"select-entry-label": "Please provide a reson why you've selected these type(s) of entries:",
		"select-entry-reason-provide": "Provide Reason",
		"number-of-lines-label": "Number of lines to use for this test*",
		"number-of-lines-information": "Enter the number of lines to use for seldom used accounts",
		"select-line-reason-provide": "# of Lines",
		thresholdAmountSuggestionText: "Threshold amount",
		"threshold-amount-label": "Enter threshold amount for this test",
		"threshold-amount-information-tooltip": "Only journal entries with flagged lines in excess of the threshold amount are flagged by this test.",
		"number-of-lines-placeholder": "Number of Lines",
		"number-of-lines-helper-text": "Number of lines must be greater than 1",
		"professional-judgement-placeholder": "Provide explanation",
		"professional-fieldLabel-judgement-selectEntry": "Please provide a reason why you’ve selected these type(s) of entries:",
		"professional-fieldLabel-judgement-thresholdAmount": "Please provide a reason why you’ve entered this threshold amount:",
		"professional-fieldLabel-judgement-numberOfLines": "Please provide a reason why you’ve entered the specified number of lines for this test:",
		"run-test": "Run Test",
		"reset-test-button": "RESET THIS TEST",
		"cancel-button": "Cancel",
		"reset-test": "Reset Test 2",
		chkStandard: "chkStandard",
		chkNonStandard: "chkNonStandard",
		selectEntry: "selectEntry",
		thresholdAmount: "thresholdAmount",
		"reset-popup-title": "Are you sure you want to reset this test?",
		"test-specific_data_validity_issues": "Test-Specific Data Validity Issues",
		"no-issues-found": "No issues found",
		"reset-popup-body-text": "If you reset this test, all the changes you've made will revert to its default state.  Please confirm if you would like to proceed.",
		"total-entries-flagged-title": "Details: Test 02 - Total Entries Flagged",
		"summary-entries-flagged-title": "Details: Test 02 - Summary of Entries Flagged",
		"entry-type-validation-message": "You need to select at least 1 journal entry type",
		"account-linesposted-validation-message": "Please select at least one Number of lines",
		"flagged-account-validation-message": "Please select at least one flagged account",
		"grouping-threshold-guidance-text": "Group multiple journal lines into one. Lines below this threshold will be grouped together if the lines have the same 1) account number, 2) flagged status, 3) keyfields.",
		"lines-posted": "No. of Lines posted to the account",
		"flagged-accounts": "Flagged Accounts",
		"entry-type": "Entry Type",
		"posting-to-account-or-account-of-interest": "No. of Postings to Account or Account of Interest",
		shortname: "Test 2"
	},
	"test-03": {
		testname: "Entries Made to Unrelated Accounts",
		title: "Test 3: Entries Made to Unrelated Accounts",
		description: "This test helps identify entries that record a transaction to unrelated accounts. For purposes of this test, unrelated accounts are any two accounts that are not typically used to record the accounting effect of a particular transaction. For example, to record a sale an entity credits sales and debits accounts receivable, not accounts payable. Therefore, a credit to sales and a debit to accounts payable would be considered an unrelated account combination for purposes of this test.",
		"one-combination-selected-status-text": "1 Combination Selected",
		"multi-combinations-selected-status-text": "%d Combinations Selected",
		"identify-unrelated-account-combinations": {
			title: "Identify unrelated account combinations*",
			"combinations-description": "Click “{{selectFromMatrixLabel}}” to see a summary of the account combinations identified in your population.",
			"select-from-matrix-button-label": "SELECT FROM MATRIX",
			"combinations-guidance": "Note that entries containing more than one unrelated account combination may be reflected multiple times in the summary information. For that reason, the number of entries and lines in the table may not agree with the totals in the Total Entries Flagged table.",
			description: "Click \"SELECT FROM MATRIX\" to see a summary of the account combinations identified in your population.",
			"add-combinations-dialog-title": "Add Combinations: Unrelated Accounts",
			"default-row-name": "Debit / Credit Financial Statement Lines"
		},
		"add-unrelated-account-combinations": {
			"add-combinations-label": "Apply Combination",
			"guidance-text": "Use the information in this table to identify potentially unrelated account combinations to use for your engagement. Select any number in the table where the Debit and Credit FS Lines meet to add that combination to the test. Hover over any cell and it will provide an amount associated with that combination. If a temporary threshold is applied to this view, the results in the table are displayed as NN:XX, where NN is the number of entries over the threshold entered and XX is the number of all entries for this combination."
		},
		"run-test": "Run Test",
		"reset-test-button": "RESET THIS TEST",
		"cancel-button": "Cancel",
		"reset-test": "Reset Test 3",
		"reset-popup-title": "Are you sure you want to reset this test?",
		"test-specific_data_validity_issues": "Test-Specific Data Validity Issues",
		"no-issues-found": "No issues found",
		"reset-popup-body-text": "If you reset this test, all the changes you've made will revert to its default state.  Please confirm if you would like to proceed.",
		"threshold-amount-label": "Enter threshold amount for this test",
		thresholdAmountSuggestionText: "Threshold amount",
		"threshold-amount-information-tooltip": "Only journal entries with flagged lines in excess of the threshold amount are flagged by this test.",
		"number-of-lines-placeholder": "Enter value",
		"number-of-lines-helper-text": "Number of lines must be greater than 1",
		"professional-judgement-placeholder": "Provide explanation",
		"professional-fieldLable-judgement-selectEntry": "Please provide a reason why you’ve selected these type(s) of entries:",
		"professional-fieldLable-judgement-thresholdAmount": "Please provide a reason why you’ve entered this threshold amount:",
		"professional-fieldLable-judgement-numberOfLines": "Please provide a reason why you’ve entered the specified number of lines for this test:",
		"professional-fieldLabel-judgement-unrelcombProfJudgment": "Document the professional judgments made in identifying the unrelated account combination(s) specified above",
		"set-temporary-threshold": "Set Temporary Threshold",
		"set-temporary-threshold-tooltip": "Apply a temporary threshold to help understand the amounts associated with the combinations. The temporary threshold will not affect the test threshold on the main test page.",
		"all-debit-fs-lines": "All Debit FS Lines",
		"multiple-debit-fs-lines": "Multiple Debit FS Lines",
		"debit-financial-statement-line": "Debit Financial Statement Line",
		"all-credit-fs-lines": "All Credit FS Lines",
		"multiple-credit-fs-lines": "Multiple Credit FS Lines",
		"credit-financial-statement-line": "Credit Financial Statement Line",
		apply: "Apply",
		"professional-fieldLabel-judgement-selectEntry": "Please provide a reason why you’ve selected these type(s) of entries:",
		"professional-fieldLabel-judgement-thresholdAmount": "Please provide a reason why you’ve entered this threshold amount:",
		"professional-fieldLabel-judgement-numberOfLines": "Please provide a reason why you’ve entered this maximum number of lines for compound entries:",
		"select-entry-form-label": "Select the types of entries to include in this test",
		"select-entry-label": "Please provide a reson why you've selected these type(s) of entries:",
		"select-entry-reason-provide": "Provide Reason",
		"number-of-lines-label": "Indicate maximum number of lines for compound entries*",
		"number-of-lines-information": "For most large compound entries, electronic tools cannot determine whether the accounts used to record the individual transactions summarized in the entry are unrelated. If you believe that the journal entry population contains large compound entries that may make the test less effective, specify in the space provided above the maximum number of lines for compound entries that you want to include in this test. Compound entries with lines in excess of the above number will be included in the results of this test only if one side of the entry consists of lines that affect only one FS line and an unrelated account is identified in the opposite side of the entry.",
		"select-line-reason-provide": "# of Lines",
		selectEntry: "selectEntry",
		thresholdAmount: "thresholdAmount",
		numberOfLines: "numberOfLines",
		"total-entries-flagged-title": "Details: Test 3 - Total Entries Flagged",
		"summary-entries-flagged-title": "Details: Test 3 - Summary of Entries Flagged",
		"urelated-account-validation-message": "Please select at least one Unrelated Combination",
		"unrelated-combinations": "Unrelated Combinations",
		"dr-cr-to-unrelated-accounts": "DR / CR to Unrelated Accounts ('Unrel-XX' Identifies Distinct Combinations – Use it to See All Occurrences of a Combination)",
		shortname: "Test 3",
		"release-notification-message": "The recent release of Omnia | Data includes enhancements to data upload rules which now allow for the inclusion of new special characters (i.e., non alpha-numeric characters). If certain special characters exist in your data set, it may impact the comparability of data used in Management Override – Journal Entry (JE) testing and analytics performed in Omnia | Data.  For further assistance or questions,"
	},
	"test-04": {
		testname: "Debits to Revenue",
		title: "Test 4: Debits to Revenue",
		description: "This test identifies all entries with a net debit to revenue by more than X and effective in the quarter selected. To improve effectiveness, exclude contra revenue accounts from the search. Also, you can limit the results of this test to show only net debits posted by certain individuals.",
		"select-entry-form-label": "Select the types of entries to include in this test",
		"professional-judgement-placeholder": "Provide explanation",
		optionalFilterTitle: "Optional",
		"select-from-table": "Select from table",
		"optionalFilter-label-1": "Exclude contra revenue accounts",
		"optionalFilter-placeholder-1": "Enter contra revenue account numbers, semicolon separated or select from table",
		"professional-fieldLabel-judgement-contraRevenueAccounts": "Please provide a reason why you’ve excluded these specific accounts:",
		"optionalFilter-label-2": "Limit test results by user IDs",
		"user-ids-table-header": "Limit test results by User IDs",
		apply: "Apply",
		addFilterButton: "Add Filter",
		"userIds-text-placeholder": "Search user ID",
		userIds: "USER IDS",
		"professional-fieldLabel-judgement-userIdFilter": "Please provide a reason why you’ve entered these specific user IDs:",
		"optionalFilter-placeholder-2": "Enter user IDs, semicolon separated or select from table",
		"professional-fieldLabel-judgement-selectEntry": "Please provide a reason why you’ve selected these type(s) of entries:",
		"professional-fieldLabel-judgement-thresholdAmount": "Please provide a reason why you’ve entered this threshold amount:",
		"threshold-amount-label": "Enter threshold amount for this test",
		thresholdAmountSuggestionText: "Threshold amount",
		"threshold-amount-information-tooltip": "Only journal entries with flagged lines in excess of the threshold amount are flagged by this test.",
		"guidance-textto-threshold-JElevel-tooltip": "Only journal entries in excess of the threshold amount with a net debit to revenue are flagged by this test.",
		"selectQuarters-Label": "Select quarters to run for this test",
		"selectQuarters-Tooltip": "Note: The search in Quarter 4 will be for debits at the beginning of that quarter that could indicate that those amounts recorded in Quarter 3 are being reversed. If you wish to search for potential reversals of current year Quarter 4 revenue that are recorded in the following period, contact your DA specialist. If the data is available, the DA Specialist will provide a separate deliverable listing all such entries.",
		"selectQuarters-ShowSummary-Label": "View summary",
		"professional-fieldLabel-judgement-quarters": "Please provide a reason why you’ve selected these specific quarters:",
		"account-numbers-text": "Account Numbers",
		"quarter1-name-label": "Q1",
		"quarter2-name-label": "Q2",
		"quarter3-name-label": "Q3",
		"quarter4-name-label": "Q4",
		"run-test": "Run Test",
		"reset-test-button": "RESET THIS TEST",
		"run-test-progress-label": "Running Test 4",
		"cancel-button": "Cancel",
		"reset-test": "Reset Test 4",
		"reset-popup-title": "Are you sure you want to reset this test?",
		"reset-popup-body-text": "If you reset this test, all the changes you've made will revert to its default state.  Please confirm if you would like to proceed.",
		"total-entries-flagged-title": "Details: Test 4 - Total Entries Flagged",
		"total-entries-flagged-guidance-text": "Entries shown below are entries greater than the applied threshold with a net debit to revenue. This may include JE lines below the applied threshold as well as JE lines with a credit to revenue.",
		"summary-entries-flagged-table-guidance-text": "Entries flagged include entries greater than the applied threshold with a net debit to revenue. Entries shown below are summarized by accounts, not entries. This may include accounts with JE lines below the applied threshold or accounts with JE lines with a credit to revenue.",
		"summary-entries-flagged-title": "Details: Test 4 - Summary of Entries Flagged",
		"used-id-combinations-validation-message": "Please select at least one User Id",
		"used-id-combinations": "User ID",
		"debit-posted-to-revenue-accounts": "Debit Posted to Revenue Accounts",
		"exclude-contra-revenue-account": "Select contra revenue accounts to exclude",
		search: "Search",
		"account-text-placeholder": "Search accounts",
		"exclude-accounts": "Exclude Accounts",
		"net-debit": "Net Debit",
		shortname: "Test 4",
		"dqcProfJudgment-title": "Add Professional Judgement",
		"dqcProfJudgment-label": "Please document the rationale for supporting this decision to proceed with this test.",
		"dqcProfJudgment-placeholder": "Provide explanantion...",
		"dqcProfJudgment-save-button-label": "Save",
		"dqcProfJudgment-cancel-button-label": "Cancel",
		"@dv-effective-date-same-contact-eda-title": "Input Date and Effective Date are the same",
		"@dv-effective-date-same-contact-eda-desc": "Date that user input entry is the same as effective date for all entries, this is unusual for journal entry data.",
		"test-specific-dataset-validity": "The following test-specific dataset validity issues were found:",
		"no-issues-found": "No issues found",
		"one-validity-issue": "1 validity issue",
		"validity-issues": "%d validity issues",
		"test-specific_data_validity_issues": "Test-Specific Data Validity Issues",
		"flagged-accounts": "Flagged Accounts",
		"tooltip-missing-userids": "There are missing user ids within your information. See Test 5 for further information.",
		"search-userid-placeholder": "Enter user IDs, separated by a semicolon or select from table",
		"search-accountno-placeholder": "Enter account number and separate them with a semicolon or select from table",
		"search-contra-placeholder": "Enter contra revenue account numbers, semicolon separated or select from table.",
		"toster-error": "Error",
		"userid-chip-validation-message": "User ID does not exist, please remove",
		"contra-revenue-account-validation-message": "Contra Revenue Account not exist, please remove",
		"userid-guidance-text": "Warning: If the test has already been run with this filter applied, this table will only show entry information for User IDs that were previously selected. All other User IDs will show as “-“ in the entry information. If you would like to view the complete journal data to confirm or adjust your previous User ID decision, please remove filter(s) and rerun the test; however previous selections will not be saved.",
		"chips-userid-contra-validation-message": "You have entered invalid filter inputs. Please remove the invalid inputs and resubmit the test.",
		"release-notification-message": "The recent release of Omnia | Data includes enhancements to data upload rules which now allow for the inclusion of new special characters (i.e., non alpha-numeric characters). If certain special characters exist in your data set, it may impact the comparability of data used in Management Override – Journal Entry (JE) testing and analytics performed in Omnia | Data.  For further assistance or questions,"
	},
	"test-05": {
		testname: "Users with Few Postings or Users of Interest",
		title: "Test 5: Users with Few Postings or Users of Interest",
		description: "This test identifies individuals who typically do not post journal entries, those who make one type of entry but suddenly records another type, as well as entries posted by specific users of interest.",
		"select-entry-form-label": "Select the types of entries to include in this test",
		"professional-judgement-placeholder": "Provide explanation",
		"professional-fieldLabel-judgement-selectEntry": "Please provide a reason why you’ve selected these type(s) of entries:",
		"professional-fieldLabel-judgement-thresholdAmount": "Please provide a reason why you’ve entered this threshold amount:",
		"professional-fieldLabel-judgement-identifyUsersInterest": "Please provide a reason why you’ve identified these users:",
		"threshold-amount-label": "Enter threshold amount for this test",
		"threshold-amount-information-tooltip": "Only journal entries with flagged lines in excess of the threshold amount are flagged by this test.",
		"test-specific_data_validity_issues": "Test-Specific Data Validity Issues",
		"test-specific-dataset-validity": "The following test-specific dataset validity issues were found:",
		"view-details": "View Details",
		"add-description-in-data-kitchen": "Add Description in Data Kitchen",
		"no-issues-found": "No issues found",
		"je-issue-document-judgement": "je-issue-document-judgement",
		"one-validity-issue": "1 validity issue",
		"validity-issues": "%d validity issues",
		"missing-account-name": "Missing User ID's",
		"missing-account-descriptions": "This test uses the User ID as input. Your dataset has missing User ID's and might therefore not identify all users of interest",
		"number-of-lines-label": "Number of postings to identify users with few postings*",
		"number-of-lines-information": "Indicate the number of postings (X) to use for identifying users with few postings",
		"number-of-lines-placeholder": "Number of postings",
		"professional-fieldLabel-judgement-numberOfPosting": "Please provide a reason why you’ve entered the above number of postings",
		"number-of-lines-helper-text": "Number should be greater than 0",
		"run-test": "Run Test",
		"reset-test-button": "RESET THIS TEST",
		"run-test-progress-label": "Running Test 5",
		"cancel-button": "Cancel",
		"reset-test": "Reset Test 5",
		"reset-popup-title": "Are you sure you want to reset this test?",
		"reset-popup-body-text": "If you reset this test, all the changes you've made will revert to its default state.  Please confirm if you would like to proceed.",
		"identify-users-of-interest-title": "Identify users of interest *",
		"users-of-interest-placeholder": "Enter User IDs and separate them with semi-colon or select from table",
		userIds: "USER IDS",
		"userIds-search-title": "Select from Table",
		"search-user-ids-title": "Select Users of Interest",
		"UserIds-text-placeholder": "Search user ID",
		addFilterButton: "Add User ID",
		shortname: "Test 5",
		"viewDetailsPopUp-standard": "Standard",
		"viewDetailsPopUp-nonStandard": "Nonstandard",
		"viewDetailsPopUp-close-button": "Close",
		"viewDetailsPopUp-7-title": "Details: Missing User IDs",
		"viewDetailsPopUp-7-Description": "There are missing User ID’s in your journal entry population. If this data quality issue is not corrected, the results of Test 5 may not be valid. This also affects results for Tests 4, 9, 8 and 10.",
		"total-entries-flagged-title": "Details: Test 5 - Total Entries Flagged",
		"summary-entries-flagged-title": "Details: Test 5 - Summary of Entries Flagged",
		"users-filter": "User ID",
		"users-filter-validation-message": "Please select at least one User",
		"user-number-of-entries": "No. of Entries Input by User or User of Interest",
		"user-of-interest": "User of Interest",
		search: "Search",
		"dqcProfJudgment-title": "Add Professional Judgement",
		"dqcProfJudgment-label": " Please document the rationale for supporting this decision to proceed with this test",
		"dqcProfJudgment-placeholder": "Provide explanation...",
		"dqcProfJudgment-cancel-button-label": "Cancel",
		"dqcProfJudgment-save-button-label": "Save",
		"release-notification-message": "The recent release of Omnia | Data includes enhancements to data upload rules which now allow for the inclusion of new special characters (i.e., non alpha-numeric characters). If certain special characters exist in your data set, it may impact the comparability of data used in Management Override – Journal Entry (JE) testing and analytics performed in Omnia | Data.  For further assistance or questions,"
	},
	"test-06": {
		testname: "Closing Entries",
		title: "Test 6: Closing Entries",
		description: "This test identifies entries made to the general ledger during the closing period that 1) are effective within the quarter closed and 2) increase or decrease assets, liabilities, revenue, or expenses by more than a specified amount. You may select the direction (ex. increase asset or liability) and limit the results to entries that have little or no description. Note: Although the “closing period” is the period that begins the day when the entity begins to prepare and post closing entries to the general ledger and ends on the date on which the last entry for a period is posted to the general ledger, for purposes of this test design, teams may use more targeted closing start and end dates within the Test Settings screen based on their understanding of key dates in the entity's financial close and reporting process (FCRP). For example, when management override may be more likely to occur after management first sees draft operating results.",
		"filter-heading-text": "Filter by",
		"word-count-filter-text": "Word count",
		"character-count-filter-text": "Character count",
		"word-count-filter-placeholder-text": "Enter word count",
		"character-count-filter-placeholder-text": "Enter character count",
		"filter-operator-name-and": "AND",
		"filter-operator-name-or": "OR",
		"professional-fieldLabel-judgement-quarters": "Please provide a reason why you’ve selected these specific quarters:",
		"increaseAssets-checkbox-label": "Increase Assets",
		"increaseLibality-checkbox-label": "Increase Liabilities",
		"increaseRevenue-checkbox-label": "Increase Revenue",
		"increaseExpense-checkbox-label": "Increase Expenses",
		"decreaseAssets-checkbox-label": "Decrease Assets",
		"decreaseLibality-checkbox-label": "Decrease Liabilities",
		"decreaseRevenue-checkbox-label": "Decrease Revenue",
		"decreaseExpense-checkbox-label": "Decrease Expenses",
		optionalFilterTitle: "Optional",
		"optionalFilter-label": "Limit test results to identify entries with little or no explanation",
		"accDirType-label": "Select directions that are relevant to your audit",
		"professional-fieldLabel-judgement-accTypeDirectionality": "Please provide a reason why you’ve selected the direction(s) above:",
		"selectQuarters-Label": "Select quarters to run for this test",
		"selectQuarters-Tooltip": "Select quarters to run for this test",
		"selectQuarters-ShowSummary-Label": "View summary",
		"select-entry-reason-provide": "Provide Reason",
		"select-entry-label": "Please provide a reson why you've selected these type(s) of entries:",
		"select-entry-form-label": "Select the types of entries to include in this test",
		"quarter1-name-label": "Q1",
		"quarter2-name-label": "Q2",
		"quarter3-name-label": "Q3",
		"quarter4-name-label": "Q4",
		"professional-fieldLabel-judgement-optionalFilter": "Please provide a reason why you’ve entered the word and/or character count above:",
		"threshold-amount-label": "Enter threshold amount for this test",
		thresholdAmountSuggestionText: "Threshold amount",
		"threshold-amount-information-tooltip": "Only journal entries with flagged lines in excess of the threshold amount are flagged by this test.",
		"professional-fieldLabel-judgement-thresholdAmount": "Please provide a reason why you’ve entered this threshold amount:",
		"thresholdAmt-reason-placeholder": "Provide explanation",
		"no-issues-found": "No issues found",
		"one-validity-issue": "1 validity issue",
		"validity-issues": "%d validity issues",
		"test-specific_data_validity_issues": "Test-Specific Data Validity Issues",
		"test-specific-dataset-validity": "The following test-specific dataset validity issues were found:",
		"run-test": "Run Test",
		"reset-test-button": "RESET THIS TEST",
		"run-test-progress-label": "Running Test 6",
		"cancel-button": "Cancel",
		"reset-test": "Reset Test 6",
		"reset-popup-title": "Are you sure you want to reset this test?",
		"reset-popup-body-text": "If you reset this test, all the changes you've made will revert to its default state.  Please confirm if you would like to proceed.",
		"professional-judgement-placeholder": "Provide explanation",
		"professional-fieldLabel-judgement-selectEntry": "Please provide a reason why you’ve selected these type(s) of entries:",
		"@dvname-all-effective-dates-same-dow-title": "Effective dates on same day of the week",
		"@dvname-all-effective-dates-same-dow-description": "All effective dates fall on the same day of the week, keep this in mind when setting parameters for this test.",
		"@dv-effective-date-same-contact-eda-title": "Input Date and Effective Date are the same",
		"@dv-effective-date-same-contact-eda-description": "Date that user input entry is the same as effective date for all entries, this is unusual for journal entry data.",
		"@dv-missing-dates-click-here-title": "Missing input dates",
		"@dv-missing-dates-click-here-description": "There are missing input dates in your dataset. Click into \"View Details\" for more information.",
		"view-summary-title": "Summary of Entries Posted During the Closing Period",
		"view-summary-description": "For purposes of this test design, teams may use more targeted closing start and end dates within the Test Settings screen based on their understanding of key dates in the entity's financial close and reporting process (FCRP). For example, when management override may be more likely to occur after management first sees draft operating results.",
		"view-summary-link": "Modify Closing Quarter Dates",
		"close-button": "Close",
		"dqcProfJudgment-title": "Add Professional Judgement",
		"dqcProfJudgment-label": "Please document the rationale for supporting this decision to proceed with this test.",
		"dqcProfJudgment-placeholder": "Provide explanantion...",
		"dqcProfJudgment-save-button-label": "Save",
		"dqcProfJudgment-cancel-button-label": "Cancel",
		shortname: "Test 6",
		"closing-period": "Closing Period:",
		"quarterly-close": "Quarterly Close",
		"financial-statement-effect": "Financial Statement Effect",
		quarters: "Quarters",
		"effect-on-fs": "Effect on FS",
		"fs-filter-validation-message": "Please select at least one Directions",
		"quarter-filter-validation-message": "Please select at least one Quarter",
		"viewDetailsPopUp-standard": "Standard",
		"viewDetailsPopUp-nonStandard": "Nonstandard",
		"viewDetailsPopUp-close-button": "Close",
		"missing-account-name": "Test 6: Closing Entries",
		"missing-account-descriptions": "There are missing Input Dates in your journal entry population. If this data integrity issue is not corrected, the results of Tests 6, 7, and 8 may not be valid. Please revisit how the input date is defined and confirm with the entity the appropriateness of the field used. If revisions to the definition of input date are not needed, obtain the missing dates from the client.  If revisions to the definition of input date are needed, please address accordingly and reload the updated data into common data model.",
		"viewDetailsPopUp-10-title": "Test 6: Closing Entries",
		"viewDetailsPopUp-10-Description": "There are missing Input Dates in your journal entry population. If this data integrity issue is not corrected, the results of Tests 6, 7, and 8 may not be valid. Please revisit how the input date is defined and confirm with the entity the appropriateness of the field used. If revisions to the definition of input date are not needed, obtain the missing dates from the client.  If revisions to the definition of input date are needed, please address accordingly and reload the updated data into common data model.",
		"total-entries-flagged-title": "Details: Test 6 - Total Entries Flagged",
		"summary-entries-flagged-title": "Details: Test 6 - Summary of Entries Flagged",
		"type-of-entry": "Type of Entry",
		entries: "Entries",
		"number-of-lines-with-relevant": "Number of Lines with Relevant Increase/Decrease",
		"net-increase-or-decrease": "Net Increase/Decrease",
		"release-notification-message": "The recent release of Omnia | Data includes enhancements to data upload rules which now allow for the inclusion of new special characters (i.e., non alpha-numeric characters). If certain special characters exist in your data set, it may impact the comparability of data used in Management Override – Journal Entry (JE) testing and analytics performed in Omnia | Data.  For further assistance or questions,",
		"optional-filter-guidance-message": "The Filter by Word Count and Character Count fields allow the numbers 1 through 999, and run on \"less than\" logic. For example, when using 3 as the Word Count and 20 as the Character Count, flagged entries will be limited to those that have 2 words or less and 19 characters or less."
	},
	"test-07": {
		testname: "Entries Posted After Closing Date for Quarter",
		title: "Test 7: Entries Posted After Closing Date for Quarter",
		description: "This test identifies entries made to the general ledger after the last day of the closing period. The same parameters used in Test 6 will populate here.",
		"professional-fieldLabel-judgement-quarters": "Please provide a reason why you’ve selected these specific quarters:",
		"increaseAssets-checkbox-label": "Increase Assets",
		"increaseLibality-checkbox-label": "Increase Liabilities",
		"increaseRevenue-checkbox-label": "Increase Revenue",
		"increaseExpense-checkbox-label": "Increase Expenses",
		"decreaseAssets-checkbox-label": "Decrease Assets",
		"decreaseLibality-checkbox-label": "Decrease Liabilities",
		"decreaseRevenue-checkbox-label": "Decrease Revenue",
		"decreaseExpense-checkbox-label": "Decrease Expenses",
		"accDirType-label": "Select directions that are relevant to your audit",
		"professional-fieldLabel-judgement-selectEntry": "Please provide a reason why you’ve selected these type(s) of entries:",
		"professional-fieldLabel-judgement-accTypeDirectionality": "Please provide a reason why you’ve selected the account types above:",
		"selectQuarters-Label": "Select quarters to run for this test",
		"selectQuarters-Tooltip": "Select quarters to run for this test",
		"selectQuarters-ShowSummary-Label": "View summary",
		"select-entry-reason-provide": "Provide Reason",
		"select-entry-label": "Please provide a reson why you've selected these type(s) of entries:",
		"select-entry-form-label": "Select the types of entries to include in this test",
		"quarter1-name-label": "Q1",
		"quarter2-name-label": "Q2",
		"quarter3-name-label": "Q3",
		"quarter4-name-label": "Q4",
		"professional-fieldLabel-judgement-optionalFilter": "Please provide a reason why you’ve entered the word and/or character count above:",
		"run-test": "Run Test",
		"reset-test-button": "RESET THIS TEST",
		"run-test-progress-label": "Running Test 7",
		"cancel-button": "Cancel",
		"reset-test": "Reset Test 7",
		"reset-popup-title": "Are you sure you want to reset this test?",
		"reset-popup-body-text": "If you reset this test, all the changes you've made will revert to its default state.  Please confirm if you would like to proceed.",
		"professional-judgement-placeholder": "Provide explanation",
		shortname: "Test 7",
		"dqcProfJudgment-title": "Add Professional Judgement",
		"dqcProfJudgment-label": "Please document the rationale for supporting this decision to proceed with this test.",
		"dqcProfJudgment-placeholder": "Provide explanation...",
		"dqcProfJudgment-save-button-label": "Save",
		"dqcProfJudgment-cancel-button-label": "Cancel",
		"@dv-effective-date-same-contact-eda-title": "Input Date and Effective Date are the same",
		"@dv-effective-date-same-contact-eda-desc": "Date that user input entry is the same as effective date for all entries, this is unusual for journal entry data.",
		"@dvname-all-effective-dates-same-dow-title": "Effective dates on same day of the week",
		"@dvname-all-effective-dates-same-dow-desc": "Date that user input entry is the same as effective date for all entries, this is unusual for journal entry data.",
		"@dv-missing-dates-click-here-title": "Missing input dates",
		"@dv-missing-dates-click-here-desc": "There are missing input dates in your dataset. Click into \"View Details\" for more information.",
		"test-specific-dataset-validity": "The following test-specific dataset validity issues were found:",
		"no-issues-found": "No issues found",
		"one-validity-issue": "1 validity issue",
		"validity-issues": "%d validity issues",
		"test-specific_data_validity_issues": "Test-Specific Data Validity Issues",
		"viewDetailsPopUp-standard": "Standard",
		"viewDetailsPopUp-nonStandard": "Nonstandard",
		"viewDetailsPopUp-close-button": "Close",
		"viewDetailsPopUp-13-title": "Test 7: Entries Posted After Closing Date for Quarter",
		"viewDetailsPopUp-13-Description": "There are missing Input Dates in your journal entry population. If this data integrity issue is not corrected, the results of Tests 6, 7, and 8 may not be valid. Please revisit how the input date is defined and confirm with the entity the appropriateness of the field used. If revisions to the definition of input date are not needed, obtain the missing dates from the client.  If revisions to the definition of input date are needed, please address accordingly and reload the updated data into common data model.",
		"closing-period": "Closing Period",
		"end-date": "End Date:",
		"closing-date-of-quarter": "Closing Date of Quarter In Which Entry Is Effective",
		"financial-statement-effect": "Financial Statement Effect",
		"quarterly-close": "Quarterly Close",
		"effect-on-fs": "Effect on FS",
		quarters: "Quarters",
		"total-entries-flagged-title": "Details: Test 7 - Total Entries Flagged",
		"summary-entries-flagged-title": "Details: Test 7 - Summary of Entries Flagged",
		"fs-filter-validation-message": "Please select at least one Directions",
		"quarter-filter-validation-message": "Please select at least one Quarter",
		"release-notification-message": "The recent release of Omnia | Data includes enhancements to data upload rules which now allow for the inclusion of new special characters (i.e., non alpha-numeric characters). If certain special characters exist in your data set, it may impact the comparability of data used in Management Override – Journal Entry (JE) testing and analytics performed in Omnia | Data.  For further assistance or questions,"
	},
	"test-08": {
		testname: "Entries Posted on Dates of Interest",
		title: "Test 8: Entries Posted on Dates of Interest",
		description: "This test identifies entries posted on Saturdays, Sundays or holidays. It can also identify custom dates, for example, dates where no entries are expected or dates around certain entity-specific events.",
		"search-account-placeholder": "Enter accounts, separated by a semicolon or select from table",
		"search-fsline-placeholder": "Enter FS Lines, separated by a semicolon or select from table",
		"search-userid-placeholder": "Enter user IDs, separated by a semicolon or select from table",
		"select-entry-label": "Please provide a reson why you've selected these type(s) of entries:",
		"select-entry-form-label": "Select the types of entries to include in this test",
		"threshold-amount-label": "Enter threshold amount for this test",
		thresholdAmountSuggestionText: "Threshold amount",
		"threshold-amount-information-tooltip": "Only journal entries with flagged lines in excess of the threshold amount are flagged by this test.",
		"professional-fieldLabel-judgement-thresholdAmount": "Please provide a reason why you’ve entered this threshold amount:",
		"addFilters-title": "After running the test, add filters",
		"addFilters-description": "After running this test, you may add filters to limit the test results. Filter by specific account numbers, financial statement lines, or user IDs.",
		"addFilters-filter-title": "Filter",
		"addFilters-filter-by": "Filter By",
		"addFilters-filter-selectFromTable": "Select from table",
		"addFilters-filter-addFilter": "Add filter",
		"addFilters-filter-addAnotherFilter": "Add another filter",
		"addFilters-tooltip-missing-userids": "There are missing user ids within your information. See Test 5 for further information.",
		"professional-fieldLabel-judgement-dynFilter": "Please provide a reason why you’ve selected the above filters:",
		"btnViewDatesOfInterest-Label": "View Dates of Interest Summary",
		"run-test": "Run Test",
		"reset-test-button": "RESET THIS TEST",
		"run-test-progress-label": "Running Test 8",
		"cancel-button": "Cancel",
		"reset-test": "Reset Test 8",
		"reset-popup-title": "Are you sure you want to reset this test?",
		"reset-popup-body-text": "If you reset this test, all the changes you've made will revert to its default state.  Please confirm if you would like to proceed.",
		"professional-judgement-placeholder": "Provide explanation",
		"professional-fieldLabel-judgement-selectEntry": "Please provide a reason why you’ve selected these type(s) of entries:",
		"viewDateSummaryPopUp-title": "Summary of Journal Entries Posted on Dates of Interest",
		"viewDateSummaryPopUp-Description": "All journal entries by each user or by date on weekends and standard holidays. The information in the table may help you identify users to whom you wish to limit the results of Test 8.",
		"viewDateSummaryPopUp-close-button": "Close",
		"doi-popUp-byUser-radioBtn": "By User",
		"doi-popUp-byDate-radioBtn": "By Date",
		"doI-userTable-ToE-standard": "Standard",
		"doI-userTable-ToE-nonStandard": "Nonstandard",
		"doI-userTable-ToE-total": "Total",
		"doI-userTable-ToE-field": "Type of Entry",
		"doI-userTable-userId-field": "User ID",
		"doI-userTable-userName-field": "User Name",
		"doI-userTable-approverId-field": "Approver ID",
		"doI-userTable-approverName-field": "Approver Name",
		"doI-userTable-sat-field": "Saturdays",
		"doI-userTable-sun-field": "Sundays",
		"doI-userTable-entriesPostedOn-field": "Number of Entries Posted On",
		"doI-userTable-holidayPostings-field": "Number of Postings on Holidays",
		"doI-userTable-avgEntries-field": "Average Entries on Weekdays (Non Holidays)",
		"professional-fieldLabel-judgement-customDate": "Please provide a reason why you've identified these dates of interest",
		"doi-information-tooltip": "The 50 users who posted the highest number of entries are listed individually (in descending number of entries posted on holidays and weekends). The number of entries posted for all other users will appear as a single line item at the end of the list.",
		"doI-DateTable-dateType-field": "Date",
		"doI-DateTable-dateOfInterest-field": "Date of Interest",
		"doI-DateTable-typeOfEntry-field": "Type of Entry",
		"doI-DateTable-entries-field": "Entries",
		"doI-DateTable-weekEndsOnly": "Weekends Only",
		"doI-DateTable-standardHoliday": "Standard Holiday",
		shortname: "Test 8",
		"dqcProfJudgment-title": "Add Professional Judgement",
		"dqcProfJudgment-label": "Please document the rationale for supporting this decision to proceed with this test.",
		"dqcProfJudgment-placeholder": "Provide explanantion...",
		"dqcProfJudgment-save-button-label": "Save",
		"dqcProfJudgment-cancel-button-label": "Cancel",
		"@dv-all-input-dates-same-dow-title": "Input dates fall on the same day of the week",
		"@dv-all-input-dates-same-dow-desc": "All input dates fall on the same day of the week, keep this in mind when setting parameters for this test.",
		"@dv-effective-date-same-contact-eda-title": "Input Date and Effective Date are the same",
		"@dv-effective-date-same-contact-eda-desc": "Date that user input entry is the same as effective date for all entries, this is unusual for journal entry data.",
		"@dv-missing-dates-click-here-title": "Missing input dates",
		"@dv-missing-dates-click-here-desc": "There are missing input dates in your dataset. Click into \"View Details\" for more information.",
		"test-specific-dataset-validity": "The following test-specific dataset validity issues were found:",
		"no-issues-found": "No issues found",
		"one-validity-issue": "1 validity issue",
		"validity-issues": "%d validity issues",
		"test-specific_data_validity_issues": "Test-Specific Data Validity Issues",
		"viewDetailsPopUp-standard": "Standard",
		"viewDetailsPopUp-nonStandard": "Nonstandard",
		"viewDetailsPopUp-close-button": "Close",
		"missing-account-name": "Test 8: Entries Posted on Dates of Interest",
		"missing-account-descriptions": "There are missing Input Dates in your journal entry population. If this data integrity issue is not corrected, the results of Tests 6, 7, and 8 may not be valid. Please revisit how the input date is defined and confirm with the entity the appropriateness of the field used. If revisions to the definition of input date are not needed, obtain the missing dates from the client.  If revisions to the definition of input date are needed, please address accordingly and reload the updated data into common data model.",
		"viewDetailsPopUp-16-title": "Test 8: Entries Posted on Dates of Interest",
		"viewDetailsPopUp-16-Description": "There are missing Input Dates in your journal entry population. If this data integrity issue is not corrected, the results of Tests 6, 7, and 8 may not be valid. Please revisit how the input date is defined and confirm with the entity the appropriateness of the field used. If revisions to the definition of input date are not needed, obtain the missing dates from the client.  If revisions to the definition of input date are needed, please address accordingly and reload the updated data into common data model.",
		"total-entries-flagged-title": "Details: Test 8 - Total Entries Flagged",
		"summary-entries-flagged-title": "Details: Test 8 - Summary of Entries Flagged",
		"date-of-interest-filter": "Dates of Interest",
		"date-of-interest-column": "Date of Interest the Entry Was Input in GL",
		"date-of-interest-title": "Select Dates of Interest",
		"doI-all-saturdays": "All Saturdays",
		"doI-all-sundays": "All Sundays",
		"doI-apply": "Apply",
		addDatesofInterestButtonTitle: "Add Dates of Interest",
		"users-filter": "Users",
		"account-filter": "Account",
		"fs-line-filter": "FS lines",
		"select-dates-of-interest-by-country-label": "Select dates of interest by country",
		"select-dates-of-interest-by-country-description": "Select countries then select dates of interest to use for this test. A list of statutory holidays and weekends will be shown based on the countries selected.",
		"Select-dates-of-interest-btn-label": "Select Dates of Interest",
		"Edit-dates-of-interest-btn-label": "Edit Dates of Interest",
		"dates-of-interest-selected-countries-label": "Filtered Countries",
		"dates-of-interest-include-label": "Include",
		"select-doi-multiselect-header": "Country",
		"doi-dates-selected-label": "Dates Selected",
		"addFilters-filter-accounts": "Accounts",
		"addFilters-filter-fs-lines": "FS Lines",
		"addFilters-filter-user-ids": "User IDs",
		search: "Search",
		"viewfilterAccountDetails-search-placeholder": "Search Accounts",
		"viewfilterAccountDetails-title": "The check-box at the column header applies only to the respective page shown (i.e., this is not a “select all” across all pages in this table).",
		"viewfilterAccountDetails-warning": "Warning: If the test has already been run with this filter applied, this table will only show entry information for Accounts that were previously selected. All other Accounts will show as “-“ in the entry information. If you would like to view the complete journal data to confirm or adjust your previous Account decision, please remove filter(s) and rerun the test; however previous selections will not be saved.",
		"viewfilterAccountDetails-save-button": "Save",
		"account-table-account-field": "Account",
		"account-table-accountDescription-field": "Account Description",
		"account-table-fsline-field": "FS Line",
		"account-table-subtype-field": "Account Subtype",
		"account-table-fs-category": "FS Category",
		"account-table-std-field": "Standard",
		"account-table-nstd-field": "Nonstandard",
		"account-table-entries-field": "Number of entries",
		"account-table-linecount-field": "Number of lines",
		"account-table-debit-field": "Debit Amount",
		"account-table-credit-field": "Credit Amount",
		"addFilters-filter-accounts-placeholder": "Enter accounts, separated by a comma or select from table",
		"addFilters-filter-fs-lines-placeholder": "Enter FS Lines, separated by a comma or select from table",
		"addFilters-filter-user-ids-placeholder": "Enter user IDs, separated by a comma or select from table",
		dateofInterestText: "Date of Interest",
		locationText: "Location",
		"viewFSLinesDetails-title": "The check-box at the column header applies only to the respective page shown (i.e., this is not a “select all” across all pages in this table).",
		"viewFSLinesDetails-warning-text": "Warning: If the test has already been run with this filter applied, this table will only show entry information for FS Lines that were previously selected. All other FS Lines will show as “-“ in the entry information. If you would like to view the complete journal data to confirm or adjust your previous FS Line decision, please remove filter(s) and rerun the test; however previous selections will not be saved.",
		"viewFSLinesPopUp-save-button": "Save",
		"viewUserIdDetails-title": "The check-box at the column header applies only to the respective page shown (i.e., this is not a “select all” across all pages in this table).",
		"viewUserIdDetails-warning": "Warning: If the test has already been run with this filter applied, this table will only show entry information for User IDs that were previously selected. All other User IDs will show as “-“ in the entry information. If you would like to view the complete journal data to confirm or adjust your previous User ID decision, please remove filter(s) and rerun the test; however previous selections will not be saved.",
		"viewfilterUserIdDetails-search-placeholder": "Search User IDs",
		"input-chips-validation-message": "You have entered invalid filter inputs. Please remove the invalid inputs and resubmit the test.",
		"dates-of-interest-error-message": "Select at least one date of interest.",
		"date-of-interest": "Date Of Interest",
		country: "Country",
		"notification-filter-message": "The recent release of Omnia | Data includes enhancements to data upload rules which now allow for the inclusion of new special characters (i.e., non alpha-numeric characters). If certain special characters exist in your data set, it may impact the comparability of data used in Management Override – Journal Entry (JE) testing and analytics performed in Omnia | Data.  For further assistance or questions,"
	},
	"test-09": {
		testname: "Entries with Keywords in Journal Entry and/or Line Description",
		title: "Test 9: Entries with Keywords in Journal Entry and/or Line Description",
		header: "How this test works:",
		"input-chips-validation-message": "You have entered invalid filter inputs. Please remove the invalid inputs and resubmit the test.",
		"search-account-placeholder": "Enter accounts, separated by a semicolon or select from table",
		"search-fsline-placeholder": "Enter FS Lines, separated by a semicolon or select from table",
		"search-userid-placeholder": "Enter user IDs, separated by a semicolon or select from table",
		description: "This test identifies entries with keywords of interest in the journal entry and/or journal line description.",
		"select-entry-form-label": "Select the types of entries to include in this test",
		"professional-judgement-placeholder": "Provide explanation",
		"professional-fieldLabel-judgement-selectEntry": "Please provide a reason why you’ve selected these type(s) of entries:",
		"professional-fieldLabel-judgement-thresholdAmount": "Please provide a reason why you’ve entered this threshold amount:",
		"professional-fieldLabel-judgement-keywordsOfInterest": "Please provide a reason why you’ve identified these keywords:",
		"threshold-amount-label": "Enter threshold amount for this test",
		thresholdAmountSuggestionText: "Threshold amount",
		"threshold-amount-information-tooltip": "Only journal entries with flagged lines in excess of the threshold amount are flagged by this test.",
		"identifyKeywords-label": "Identify keywords of interest*",
		"identifyKeywords-description": "Enter keywords below for your search.",
		"identify-keywords-description-remember-keywords-note": "Remember to enter keywords",
		"identify-keywords-description-separated-semicolon": "separated by a semicolon",
		"identify-keywords-description-warning-part-do": "(do",
		"identify-keywords-description-warning-part-not": "not",
		"identify-keywords-description-warning-part-keywords": "use commas to separate keywords).",
		"identify-keywords-description-ending-part": "Maximum character limit between semicolons is 252. All leading and trailing spaces will be removed from the keywords upon running the test.",
		"identify-keywords-notfication-word-search": "This test uses a “string search.” A string search",
		"identify-keywords-notfication-exact-match": "looks for the specified characters",
		"identify-keywords-notfication-trailing-chars": "which could appear as a standalone value or embedded in a longer set of characters.",
		"identify-keywords-notfication-second-note-point": "A “string search” looks for the specified characters, which could appear as a standalone value or embedded in a longer set of characters.",
		"identify-keywords-notfication-note": "NOTE",
		"identify-keywords-notfication-third-note-partial": "It is",
		"identify-keywords-notfication-third-note-highlight": "strongly encouraged",
		"identify-keywords-notfication-third-note-ending": "for teams to use “string search” unless an exact match of all characters (and only those characters) between spaces is desired.",
		"identify-keywords-example-partial": "For example, if the team desires to search for the word",
		"identify-keywords-example-error-highlight": "error",
		"identify-keywords-example-or-highlight": "or",
		"identify-keywords-example-word-chars": "and if the word is connected to other characters (e.g.,",
		"identify-keywords-example-errors-highlight": "errors",
		"identify-keywords-example-xxxx-error-highlight": "xxxx-error",
		"identify-keywords-example-ending": "then the team can expect entries including these words to be flagged.",
		"wordSearch-label": "Word search",
		"stringSearch-label": "String search",
		"wordSearch-placeholder": "Enter keywords for word search, separated by a semicolon",
		"stringSearch-placeholder": "Enter keywords, semicolon separated",
		"wordSearch-tooltip": "A “word search” looks for the exact word, acronym, or abbreviation that you specify. For example, if you specify “depreciation” as a word search, this test will flag “depreciation” but not “depreciated.”",
		"stringSearch-tooltip": "A “string search” looks for the letters that you specify. Those could appear in a journal entry or journal line description either as a standalone term or embedded within another word. For example, if your keyword is “dep” and you want this test to perform a string search using that keyword, in addition to dep, this test will flag depreciation, depreciated, and adept if those words are in the journal entry data.",
		"run-test": "Run Test",
		"cancel-button": "Cancel",
		"reset-test": "Reset Test 9",
		"reset-test-button": "RESET THIS TEST",
		"reset-popup-title": "Are you sure you want to reset this test?",
		"reset-popup-body-text": "If you reset this test, all the changes you've made will revert to its default state.  Please confirm if you would like to proceed.",
		"entry-type-validation-message": "You need to select at least 1 journal entry type",
		"account-keyword-validation-message": "Please select at least one filter",
		"total-entries-flagged-title": "Details for: Test 09 - Entries with Keywords in Journal Entry Description",
		"summary-entries-flagged-title": "Details: Test 09 - Summary of Entries Flagged",
		"grouping-threshold-guidance-text": "Group multiple journal lines into one. Lines below this threshold will be grouped together if the lines have the same 1) account number, 2) flagged status, 3) keyfields.",
		shortname: "Test 9",
		"dqcProfJudgment-title": "Add Professional Judgement",
		"dqcProfJudgment-label": "Please document the rationale for supporting this decision to proceed with this test.",
		"dqcProfJudgment-placeholder": "Provide explanantion...",
		"dqcProfJudgment-save-button-label": "Save",
		"dqcProfJudgment-cancel-button-label": "Cancel",
		"dqc-18-title": "Missing descriptions for Journal Entry and Lines",
		"dqc-18-desc": "Your dataset has missing descriptions for both the journal entry and lines. Click into \"View Details\" for more details.",
		"dqc-17-title": "Journal Entry and Lines descriptions are identical",
		"dqc-17-desc": "Journal entry descriptions and journal line descriptions are identical. If this issue is noted within all entries, this could be a result of a single description field mapped to both entry and line description. Obtain from the entity the name of the field that contains the journal entry and/or journal line description.",
		"test-specific-dataset-validity": "The following test-specific dataset validity issues were found:",
		"no-issues-found": "No issues found",
		"one-validity-issue": "1 validity issue",
		"validity-issues": "%d validity issues",
		"test-specific_data_validity_issues": "Test-Specific Data Validity Issues",
		"viewDetailsPopUp-standard": "Standard",
		"viewDetailsPopUp-nonStandard": "Nonstandard",
		"viewDetailsPopUp-close-button": "Close",
		"addFilters-filter-accounts": "Accounts",
		"addFilters-filter-fs-lines": "FS Lines",
		"addFilters-filter-user-ids": "User IDs",
		"addFilters-filter-accounts-placeholder": "Enter accounts, separated by a semicolon or select from table",
		"addFilters-filter-fs-lines-placeholder": "Enter FS Lines, separated by a semicolon or select from table",
		"addFilters-filter-user-ids-placeholder": "Enter user IDs, separated by a semicolon or select from table",
		"addFilters-title": "After running the test, add filters",
		"addFilters-description": "After running this test, you may add filters to limit the test results. Filter by specific account numbers, financial statement lines, or user IDs.",
		"addFilters-filter-title": "Filter",
		"addFilters-filter-by": "Filter By",
		"addFilters-tooltip-missing-userids": "There are missing user ids within your information. See Test 5 for further information.",
		"addFilters-filter-selectFromTable": "Select from table",
		"addFilters-filter-addFilter": "Add filter",
		"addFilters-filter-addAnotherFilter": "Add another filter",
		"viewFSLinesDetails-title": "The check-box at the column header applies only to the respective page shown (i.e., this is not a “select all” across all pages in this table).",
		"viewFSLinesDetails-warning-text": "Warning: If the test has already been run with this filter applied, this table will only show entry information for FS Lines that were previously selected. All other FS Lines will show as “-“ in the entry information. If you would like to view the complete journal data to confirm or adjust your previous FS Line decision, please remove filter(s) and rerun the test; however previous selections will not be saved.",
		"viewFSLinesPopUp-save-button": "Save",
		"viewUserIdDetails-title": "The check-box at the column header applies only to the respective page shown (i.e., this is not a “select all” across all pages in this table).",
		"viewUserIdDetails-warning": "Warning: If the test has already been run with this filter applied, this table will only show entry information for User IDs that were previously selected. All other User IDs will show as “-“ in the entry information. If you would like to view the complete journal data to confirm or adjust your previous User ID decision, please remove filter(s) and rerun the test; however previous selections will not be saved.",
		"viewfilterAccountDetails-title": "The check-box at the column header applies only to the respective page shown (i.e., this is not a “select all” across all pages in this table).",
		"viewfilterAccountDetails-warning": "Warning: If the test has already been run with this filter applied, this table will only show entry information for Accounts that were previously selected. All other Accounts will show as “-“ in the entry information. If you would like to view the complete journal data to confirm or adjust your previous Account decision, please remove filter(s) and rerun the test; however previous selections will not be saved.",
		"viewfilterAccountDetails-save-button": "Save",
		"viewfilterAccountDetails-search-placeholder": "Search Accounts",
		"viewfilterUserIdDetails-search-placeholder": "Search User IDs",
		search: "Search",
		"professional-fieldLabel-judgement-dynFilter": "Please provide a reason why you’ve selected the above filters:",
		"missing-account-name": "Test 9: Entries with Keywords in Journal Entry and/or Journal Line Descriptions",
		"missing-account-descriptions": "Journal entry descriptions and journal line descriptions are identical. If this issue is noted within all entries, this could be a result of a single description field mapped to both entry and line description. Obtain from the entity the name of the field that contains the journal entry and/or journal line description needed to run Deloitte Optix. Contact your EDA Specialist to discuss any necessary revisions.",
		"viewDetailsPopUp-17-title": "Test 9: Entries with Keywords in Journal Entry and/or Journal Line Descriptions",
		"viewDetailsPopUp-17-Description": "Journal entry descriptions and journal line descriptions are identical. If this issue is noted within all entries, this could be a result of a single description field mapped to both entry and line description. Obtain from the entity the name of the field that contains the journal entry and/or journal line description.",
		"viewDetailsPopUp-18-title": "Test 9: Entries with Keywords in Journal Entry and/or Journal Line Descriptions",
		"viewDetailsPopUp-18-Description": "There are missing descriptions for both the journal entry and lines. If this data quality issue is not corrected, the results of Test 9 may not be valid. Additionally, without any description, a decision as to whether to test an entry will likely require examination of supporting documentation. This makes journal entry testing less effective. Obtain from the entity the name of the field that contains the journal entry and/or journal line description.",
		"notification-filter-message": "The recent release of Omnia | Data includes enhancements to data upload rules which now allow for the inclusion of new special characters (i.e., non alpha-numeric characters). If certain special characters exist in your data set, it may impact the comparability of data used in Management Override – Journal Entry (JE) testing and analytics performed in Omnia | Data.  For further assistance or questions,",
		"string-search-error-notification-message": "Test run is not successful because there are more than 252 characters within the input field. Ensure you are entering less than or equal to 252 characters and using semicolons as a separator. Then rerun the test by applying the necessary parameters. If the issue continues,",
		"contact-support-team": "contact the support team.",
		"release-notifications-message": "The recent release of Omnia | Data includes enhancements to this test. Test 9: Entries with Keywords in Journal Entry and/or Line Description runs on a \"string search,\" which looks for the specified characters that could appear as a standalone value or embedded in a longer set of characters. Previously, the test provided options to use \"word search\" and/or \"string search.\" A word search would search for an exact match including any preceding or trailing characters. This change will impact the comparability to previously completed Management Override – Journal Entry (JE) testing analytics performed in Omnia | Cortex. For further assistance or questions,"
	},
	"test-10a": {
		testname: "Entries with Round Amounts",
		title: "Test 10a: Entries with Round Amounts",
		description: "This test identifies journal entry line amounts that are divisible by 1,000, 10,000, 100,000 or 1,000,000 (round amounts). The test is performed using the original currency amount and flags lines with round amounts. The threshold is applied to entity/group currency.",
		"select-entry-form-label": "Select the types of entries to include in this test",
		"input-chips-validation-message": "You have entered invalid filter inputs. Please remove the invalid inputs and resubmit the test.",
		"cancel-button": "Cancel",
		"run-test": "Run Test",
		"run-test-progress-label": "Running Test 10a",
		"reset-test": "Reset Test 10a",
		"reset-popup-title": "Are you sure you want to reset this test?",
		"reset-popup-body-text": "If you reset this test, all the changes you've made will revert to its default state.  Please confirm if you would like to proceed.",
		"reset-test-button": "reset this test",
		"denominators-Label": "Select denominator(s) to identify lines with round amounts*",
		"denominators-ShowDetails-Label": "View Details",
		"denominator-popup-close-button": "Close",
		"dollarTable-lineAmountDivisibleBy-field": "Line Amount Divisible By",
		"dollarTable-typeOfEntry-field": "Type of Entry",
		"dollarTable-entries-field": "Entries",
		"dollarTable-lines-field": "Lines",
		"dollarTable-amount-field": "Amount",
		"dollarTable-ToE-nonStandard": "Nonstandard",
		"dollarTable-ToE-standard": "Standard",
		"denominator-popup-title": "Journal Entry Lines with Round Amounts",
		"denominator-popup-description-text": "The information below will help you exercise judgment regarding the denominator to use for your engagement. This test uses the Original Currency. Note that if a line is flagged in one category, it cannot be flagged by another category. For example, if a line is flagged as divisible by 100,000, then it will not be flagged for 10,000 or 1,000.",
		"denominators-divisible-by-label": "Denominators divisible by:",
		"round-dollar-amounts": "Round Amounts",
		account: "Account",
		fslines: "FS Lines",
		users: "Users",
		"amount-information": "Amount Information (Shown in Local Currency)",
		shortname: "Test 10a",
		search: "Search",
		"viewfilterAccountDetails-search-placeholder": "Search Accounts",
		"viewfilterUserIdDetails-search-placeholder": "Search User IDs",
		"fs-table-user-id-field": "User ID",
		"fs-table-user-name-field": "User Name",
		"fs-table-approver-id-field": "Approver ID",
		"fs-table-approver-name-field": "Approver Name",
		"viewUserIdDetails-title": "The check-box at the column header applies only to the respective page shown (i.e., this is not a “select all” across all pages in this table).",
		"viewUserIdDetails-warning": "Warning: If the test has already been run with this filter applied, this table will only show entry information for User IDs that were previously selected. All other User IDs will show as “-“ in the entry information. If you would like to view the complete journal data to confirm or adjust your previous User ID decision, please remove filter(s) and rerun the test; however previous selections will not be saved.",
		"viewfilterAccountDetails-title": "The check-box at the column header applies only to the respective page shown (i.e., this is not a “select all” across all pages in this table).",
		"viewfilterAccountDetails-warning": "Warning: If the test has already been run with this filter applied, this table will only show entry information for Accounts that were previously selected. All other Accounts will show as “-“ in the entry information. If you would like to view the complete journal data to confirm or adjust your previous Account decision, please remove filter(s) and rerun the test; however previous selections will not be saved.",
		"viewfilterAccountDetails-save-button": "Save",
		"account-table-account-field": "Account",
		"account-table-accountDescription-field": "Account Description",
		"account-table-fsline-field": "FS Line",
		"account-table-subtype-field": "Account Subtype",
		"account-table-std-field": "Standard",
		"account-table-nstd-field": "Nonstandard",
		"account-table-entries-field": "Number of entries",
		"account-table-linecount-field": "Number of lines",
		"account-table-debit-field": "Debit Amount",
		"account-table-credit-field": "Credit Amount",
		"addFilters-title": "After running the test, add filters",
		"addFilters-description": "After running this test, you may add filters to limit the test results. Filter by specific account numbers, financial statement lines, user IDs or locations.",
		"addFilters-filter-title": "Filter",
		"addFilters-filter-by": "Filter By",
		"addFilters-filter-selectFromTable": "Select from table",
		"addFilters-filter-addFilter": "Add filter",
		"addFilters-filter-addAnotherFilter": "Add another filter",
		"addFilters-filter-accounts": "Accounts",
		"addFilters-filter-fs-lines": "FS Lines",
		"addFilters-filter-user-ids": "User IDs",
		"addFilters-filter-location": "Locations",
		"addFilters-tooltip-missing-userids": "There are missing user ids within your information. See Test 5 for further information.",
		"professional-fieldLabel-judgement-dynFilter": "Please provide a reason why you’ve selected the above filters:",
		"viewFSLinesDetails-title": "The check-box at the column header applies only to the respective page shown (i.e., this is not a “select all” across all pages in this table).",
		"viewFSLinesDetails-warning-text": "Warning: If the test has already been run with this filter applied, this table will only show entry information for FS Lines that were previously selected. All other FS Lines will show as “-“ in the entry information. If you would like to view the complete journal data to confirm or adjust your previous FS Line decision, please remove filter(s) and rerun the test; however previous selections will not be saved.",
		"viewFSLinesPopUp-save-button": "Save",
		"fs-table-fsline-field": "FS Line",
		"fs-table-subtype-field": "Subtype",
		"fs-table-std-field": "Standard",
		"fs-table-nstd-field": "Nonstandard",
		"fs-table-entries-field": "Entries",
		"fs-table-linecount-field": "Lines",
		"fs-table-debit-field": "Debit Amount",
		"fs-table-credit-field": "Credit Amount",
		"search-account-placeholder": "Enter accounts, separated by a semicolon or select from table",
		"search-fsline-placeholder": "Enter FS Lines, separated by a semicolon or select from table",
		"search-userid-placeholder": "Enter user IDs, separated by a semicolon or select from table",
		"search-location-placeholder": "Enter Location, separated by a semicolon",
		"professional-judgement-placeholder": "Provide explanation",
		"professional-fieldLabel-judgement-selectEntry": "Please provide a reason why you’ve selected these type(s) of entries:",
		"professional-fieldLabel-judgement-denominator": "Please provide a reason why you’ve selected the denominator(s) above:",
		"professional-fieldLabel-judgement-thresholdProfJudgement": "Please provide a reason why you’ve entered this threshold amount:",
		"@dv-amt-zero-local-amt-nonzero-title": "Reporting amount = 0 but local amount <> 0",
		"@dv-amt-zero-local-amt-nonzero-desc": "There are journal lines with 0 amount in reporting currency, but amount in original currency is not 0. This is unusual for journal entry data. Click into \"View Details\" for more information.",
		"@dv-amt-nonzero-local-amt-zero-title": "Reporting amount <> 0 but local amount = 0",
		"@dv-amt-nonzero-local-amt-zero-desc": "There are journal lines with 0 amount in original currency, but amount in reporting currency is not 0. This is unusual for journal entry data. Click into \"View Details\" for more information. ",
		"dqcProfJudgment-title": "Add Professional Judgement",
		"dqcProfJudgment-label": "Please document the rationale for supporting this decision to proceed with this test.",
		"dqcProfJudgment-placeholder": "Provide explanantion...",
		"dqcProfJudgment-save-button-label": "Save",
		"dqcProfJudgment-cancel-button-label": "Cancel",
		"test-specific-dataset-validity": "The following test-specific dataset validity issues were found:",
		"no-issues-found": "No issues found",
		"one-validity-issue": "1 validity issue",
		"validity-issues": "%d validity issues",
		"test-specific_data_validity_issues": "Test-Specific Data Validity Issues",
		"viewDetailsPopUp-standard": "Standard",
		"viewDetailsPopUp-nonStandard": "Nonstandard",
		"viewDetailsPopUp-close-button": "Close",
		"viewDetailsPopUp-19-title": "Reporting amount = 0 but local amount <> 0",
		"viewDetailsPopUp-19-Description": "There are journal lines with 0 amount in reporting currency, but amount in original currency is not 0. This is unusual for journal entry data. Click into \"View Details\" for more information.",
		"viewDetailsPopUp-20-title": "Reporting amount <> 0 but local amount = 0",
		"viewDetailsPopUp-20-Description": "There are journal lines with 0 amount in original currency, but amount in reporting currency is not 0. This is unusual for journal entry data. Click into \"View Details\" for more information.",
		"threshold-amount-label": "Enter threshold amount for this test",
		"threshold-amount-information-tooltip": "Only journal entries with flagged lines in excess of the threshold amount are flagged by this test.",
		"total-entries-flagged-title": "Details: Test 10a - Total Entries Flagged",
		"summary-entries-flagged-title": "Details: Test 10a - Summary of Entries Flagged",
		"notification-filter-message": "The recent release of Omnia | Data includes enhancements to data upload rules which now allow for the inclusion of new special characters (i.e., non alpha-numeric characters). If certain special characters exist in your data set, it may impact the comparability of data used in Management Override – Journal Entry (JE) testing and analytics performed in Omnia | Data.  For further assistance or questions,"
	},
	"test-10b": {
		testname: "Entries with Recurring Ending Digits",
		"professional-fieldLabel-judgement-dynFilter": "Please provide a reason why you’ve selected the above filters:",
		"input-chips-validation-message": "You have entered invalid filter inputs. Please remove the invalid inputs and resubmit the test.",
		"search-account-placeholder": "Enter accounts, separated by a semicolon or select from table",
		"search-fsline-placeholder": "Enter FS Lines, separated by a semicolon or select from table",
		"search-userid-placeholder": "Enter user IDs, separated by a semicolon or select from table",
		"search-location-placeholder": "Enter locations, separated by a semicolon",
		title: "Test 10b: Entries with Recurring Ending Digits",
		description: "This test identifies journal entry line amounts that contain repetitive ending digits and exceed a specified amount. The test is performed using the original currency amount and flags lines with amounts with recurring ending digits. The threshold is applied to entity/group currency.",
		"select-entry-form-label": "Select the types of entries to include in this test",
		"professional-fieldLabel-judgement-selectEntry": "Please provide a reason why you’ve selected these type(s) of entries:",
		"recurranceRate-label": "Indicate recurrence rate",
		"recurranceRate-text-tooltip": "The recurrence rate is the number of times that each digit (1-9) repeats at the end of an amount, ignoring decimal places. For example, if you specify a recurrence rate of 4, this test will flag line amounts of 8,888.89 and 8,888.38, but not 88.88. When setting the recurrence rate, consider the combined effect of the rate and the threshold amount you set for this test. For example, if you set the recurrence rate at 5, this test will flag entries with amounts such as 1,411,111 and 299,999 because the digit ‘1’ repeats 5 times at the end of 1,411,111 and the digit ‘9’ recurs 5 times at the end of 299,999. If you set the threshold amount for this test at 125,000, the test will flag all entries with amounts of 133,333 or higher, including 1,411,111 and 299,999. However, if you set the recurrence rate at 10, this test will flag entries with amounts such as 141,111,111,111 and 299,999,999,999. In this case, the recurrence rate of 10 overrides the threshold amount of 125,000 because the results will include only entries with line amounts exceeding 1,111,111,111 (this is the smallest amount flagged when the recurrence rate is set at 10). Your knowledge of the entity is key to establishing an effective combination of parameter and threshold amount for this test.",
		"recurranceRate-showSummary-label": "View Recurrence Rate",
		"recurranceRate-description": "Specify the recurrence rate for identifying journal line amounts with recurring ending digits. The recurrence rate is the number of times each digit (1-9) repeats at the end of an amount.",
		"inputValue-placeholder": "Enter value",
		"nonStandard-label": "Nonstandard",
		"standard-label": "Standard",
		"professional-judgement-placeholder": "Provide explanation",
		"professional-fieldLabel-judgement-recurranceRate": "Please provide a reason why you’ve selected these recurrence rate(s):",
		"viewRecurranceRatePopUp-title": "Journal Entry Lines with Recurring Ending Digits",
		"viewFSLinesDetails-title": "The check-box at the column header applies only to the respective page shown (i.e., this is not a “select all” across all pages in this table).",
		"viewFSLinesDetails-warning-text": "Warning: If the test has already been run with this filter applied, this table will only show entry information for FS Lines that were previously selected. All other FS Lines will show as “-“ in the entry information. If you would like to view the complete journal data to confirm or adjust your previous FS Line decision, please remove filter(s) and rerun the test; however previous selections will not be saved.",
		"viewRecurranceRatePopUp-Text-Description": "The information below will help you exercise judgment regarding the recurrence rate to use for your engagement. Note: The recurrence rate is the number of times that each digit (1-9) repeats at the end of a line amount, ignoring decimal places, and using the local currency. For example, if you specify a recurrence rate of 4, this test will flag line amounts of 8,888.89 and 8,888.38, but not 88.88. Also note that if a line was flagged in one category, it cannot be flagged by another category. For example, if the line 8,888.89 will be flagged for a recurrence rate of 4, it will not be included in the count for lower recurrence rates of 3 or 2.",
		"rr-statTable-digits-field": "Digits (1-9)",
		"rr-table-rr-field": "Recurrence Rate",
		"rr-table-entryType-field": "Type of Entry",
		"rr-table-d1-field": "1",
		"rr-table-d2-field": "2",
		"rr-table-d3-field": "3",
		"rr-table-d4-field": "4",
		"rr-table-d5-field": "5",
		"rr-table-d6-field": "6",
		"rr-table-d7-field": "7",
		"rr-table-d8-field": "8",
		"rr-table-d9-field": "9",
		"viewRRPopUp-close-button": "Close",
		"run-test": "Run Test",
		"cancel-button": "Cancel",
		"reset-test": "Reset Test 10b",
		"reset-popup-title": "Are you sure you want to reset this test?",
		"reset-test-button": "RESET THIS TEST",
		"reset-popup-body-text": "If you reset this test, all the changes you've made will revert to its default state.  Please confirm if you would like to proceed.",
		shortname: "Test 10b",
		"addFilters-title": "After running the test, add filters",
		"addFilters-description": "After running this test, you may add filters to limit the test results. Filter by specific account numbers, financial statement lines, user IDs or locations.",
		"addFilters-filter-title": "Filter",
		"addFilters-filter-by": "Filter By",
		"addFilters-filter-selectFromTable": "Select from table",
		"addFilters-filter-addFilter": "Add Filter",
		"addFilters-filter-addAnotherFilter": "Add another filter",
		"addFilters-tooltip-missing-userids": "There are missing user ids within your information. See Test 5 for further information.",
		"professional-fieldLabel-judgement-recurringAmountThresholdProfJudgment": "Please provide a reason why you’ve entered this threshold amount:",
		"threshold-amount-label": "Enter threshold amount for this test",
		thresholdAmountSuggestionText: "Threshold amount",
		"threshold-amount-information-tooltip": "Only journal entries with flagged lines in excess of the threshold amount are flagged by this test.",
		"recurring-enging-degits": "Recurring Ending Digits",
		"entry-type-validation-message": "You need to select at least 1 journal entry type",
		"recurring-digit-validation-message": "Please select at least one Recurring Ending Digit",
		"recurring-ending-digit": "Recurring Ending Digits",
		"total-entries-flagged-title": "Details for: Test 10b - Entries with Recurring Ending Digits",
		"summary-entries-flagged-title": "Details: Test 10b - Summary of Entries Flagged",
		"@dv-amt-zero-local-amt-nonzero-title": "Reporting amount = 0 but local amount <> 0",
		"@dv-amt-zero-local-amt-nonzero-desc": "There are journal lines with 0 amount in reporting currency, but amount in original currency is not 0. This is unusual for journal entry data. Click into \"View Details\" for more information.",
		"@dv-amt-nonzero-local-amt-zero-title": "Reporting amount <> 0 but local amount = 0",
		"@dv-amt-nonzero-local-amt-zero-desc": "There are journal lines with 0 amount in original currency, but amount in reporting currency is not 0. This is unusual for journal entry data. Click into \"View Details\" for more information.",
		"dqcProfJudgment-title": "Add Professional Judgement",
		"dqcProfJudgment-label": "Please document the rationale for supporting this decision to proceed with this test.",
		"dqcProfJudgment-placeholder": "Provide explanantion...",
		"dqcProfJudgment-save-button-label": "Save",
		"dqcProfJudgment-cancel-button-label": "Cancel",
		"test-specific-dataset-validity": "The following test-specific dataset validity issues were found:",
		"no-issues-found": "No issues found",
		"one-validity-issue": "1 validity issue",
		"validity-issues": "%d validity issues",
		"test-specific_data_validity_issues": "Test-Specific Data Validity Issues",
		"viewDetailsPopUp-standard": "Standard",
		"viewDetailsPopUp-nonStandard": "Nonstandard",
		"viewDetailsPopUp-close-button": "Close",
		"viewDetailsPopUp-19-title": "Reporting amount = 0 but local amount <> 0",
		"viewDetailsPopUp-19-Description": "There are journal lines with 0 amount in reporting currency, but amount in original currency is not 0. This is unusual for journal entry data. Click into \"View Details\" for more information.",
		"viewDetailsPopUp-20-title": "Reporting amount <> 0 but local amount = 0",
		"viewDetailsPopUp-20-Description": "There are journal lines with 0 amount in original currency, but amount in reporting currency is not 0. This is unusual for journal entry data. Click into \"View Details\" for more information.",
		"viewDetailsPopUp-21-title": "Reporting amount = 0 but local amount <> 0",
		"viewDetailsPopUp-21-Description": "There are journal lines with 0 amount in reporting currency, but amount in original currency is not 0. This is unusual for journal entry data. Click into \"View Details\" for more information.",
		"viewDetailsPopUp-22-title": "Reporting amount <> 0 but local amount = 0",
		"viewDetailsPopUp-22-Description": "There are journal lines with 0 amount in original currency, but amount in reporting currency is not 0. This is unusual for journal entry data. Click into \"View Details\" for more information.",
		"addFilters-filter-accounts": "Accounts",
		"addFilters-filter-fs-lines": "FS Lines",
		"addFilters-filter-user-ids": "User IDs",
		"addFilters-filter-location": "Locations",
		"addFilters-filter-accounts-placeholder": "Enter accounts, separated by a semicolon or select from table",
		"addFilters-filter-fs-lines-placeholder": "Enter FS Lines, separated by a semicolon or select from table",
		"addFilters-filter-user-ids-placeholder": "Enter user IDs, separated by a semicolon or select from table",
		"addFilters-filter-location-placeholder": "Enter Location, separated by a semicolon",
		"viewUserIdDetails-title": "The check-box at the column header applies only to the respective page shown (i.e., this is not a “select all” across all pages in this table).",
		"viewUserIdDetails-warning": "Warning: If the test has already been run with this filter applied, this table will only show entry information for User IDs that were previously selected. All other User IDs will show as “-“ in the entry information. If you would like to view the complete journal data to confirm or adjust your previous User ID decision, please remove filter(s) and rerun the test; however previous selections will not be saved.",
		"viewfilterAccountDetails-title": "The check-box at the column header applies only to the respective page shown (i.e., this is not a “select all” across all pages in this table).",
		"viewfilterAccountDetails-warning": "Warning: If the test has already been run with this filter applied, this table will only show entry information for Accounts that were previously selected. All other Accounts will show as “-“ in the entry information. If you would like to view the complete journal data to confirm or adjust your previous Account decision, please remove filter(s) and rerun the test; however previous selections will not be saved.",
		"viewfilterAccountDetails-save-button": "Save",
		"viewFSLinesPopUp-save-button": "Save",
		search: "Search",
		"viewfilterAccountDetails-search-placeholder": "Search Accounts",
		"viewfilterUserIdDetails-search-placeholder": "Search User IDs",
		"fs-table-user-id-field": "User ID",
		"fs-table-user-name-field": "User Name",
		"fs-table-approver-id-field": "Approver ID",
		"fs-table-approver-name-field": "Approver Name",
		"account-table-account-field": "Account",
		"account-table-accountDescription-field": "Description",
		"account-table-fsline-field": "FS Line",
		"account-table-subtype-field": "Subtype",
		"account-table-std-field": "Standard",
		"account-table-nstd-field": "Nonstandard",
		"account-table-entries-field": "Entries",
		"account-table-linecount-field": "Lines",
		"account-table-debit-field": "Debit Amount",
		"account-table-credit-field": "Credit Amount",
		"account-table-description-field-text": "Description",
		"account-table-entries-field-text": "Entries",
		"account-table-linecount-field-text": "Lines",
		"account-table-subtype-field-text": "Subtype",
		"addFilters-accounts-placeholder-text": "Enter accounts, separated by a semicolon or select from table",
		"addFilters-fs-lines-placeholder-text": "Enter FS Lines, separated by a semicolon or select from table",
		"addFilters-user-ids-placeholder-text": "Enter user IDs, separated by a semicolon or select from table",
		"addFilters-location-placeholder-text": "Enter Location, separated by a semicolon",
		"dollarTable-typeOfEntry-field": "Type of Entry",
		"dollarTable-entries-field": "Entries",
		"dollarTable-lines-field": "Lines",
		"dollarTable-amount-field": "Amount",
		"dollarTable-ToE-nonStandard": "Nonstandard",
		"dollarTable-ToE-standard": "Standard",
		"fs-table-fsline-field": "FS Line",
		"fs-table-subtype-field": "Subtype",
		"fs-table-std-field": "Standard",
		"fs-table-nstd-field": "Nonstandard",
		"fs-table-entries-field": "Entries",
		"fs-table-linecount-field": "Lines",
		"fs-table-debit-field": "Debit Amount",
		"fs-table-credit-field": "Credit Amount",
		"professional-fieldLabel-judgement-filterProfJudgement": "Please provide a reason why you’ve selected the above filters:",
		"nonstandard-error-message": "Nonstandard recurrence rate must be greater than 1",
		"standard-error-message": "Standard recurrence rate must be greater than 1",
		"guidance-textTo-detail-view": "Entries shown below may be rounded. Scroll to the right to identify the line amounts that contain repetitive ending digits.",
		"notification-filter-message": "The recent release of Omnia | Data includes enhancements to data upload rules which now allow for the inclusion of new special characters (i.e., non alpha-numeric characters). If certain special characters exist in your data set, it may impact the comparability of data used in Management Override – Journal Entry (JE) testing and analytics performed in Omnia | Data.  For further assistance or questions,"
	},
	"test-11": {
		testname: "Duplicate Entries",
		title: "Test 11: Duplicate Entries",
		description: "This test identifies entries that 1) contain the same combination of line accounts/amounts, 2) occur X or more times in the population, and 3) total more than a specified amount.",
		"select-entry-form-label": "Select the types of entries to include in this test",
		chkStandard: "chkStandard",
		chkNonStandard: "chkNonStandard",
		"professional-fieldLabel-judgement-selectEntry": "Please provide a reason why you’ve selected these type(s) of entries:",
		"professional-fieldLabel-judgement-occurencesEntry": "Please provide a reason why you’ve entered this number for this test:",
		"run-test": "Run Test",
		"run-test-progress-label": "Running Test 11",
		"reset-test-button": "RESET THIS TEST",
		"cancel-button": "Cancel",
		"reset-test": "Reset Test 11",
		"reset-popup-title": "Are you sure you want to reset this test?",
		"reset-popup-body-text": "If you reset this test, all the changes you've made will revert to its default state.  Please confirm if you would like to proceed.",
		"threshold-amount-label": "Enter threshold amount for this test",
		thresholdAmountSuggestionText: "Threshold amount",
		"threshold-amount-information-tooltip": "Only journal entries with flagged lines in excess of the threshold amount are flagged by this test.",
		"professional-fieldLabel-judgement-thresholdAmount": "Please provide a reason why you’ve entered this threshold amount:",
		"addCombinations-fs-lines-header": "After running the test, filter by financial statement line combinations",
		"addCombinations-fs-lines-title": "FINANCIAL STATEMENT LINE COMBINATIONS",
		"addCombinations-fs-lines-description": "If you wish to limit the results of this test to entries that contain certain FS lines, indicate those below. Only duplicate entries containing the FS lines specified will be flagged. Note that the FS line combinations between Debit and Credit can occur in various combinations. For instance, if you select Combination 1 - Debit: FS Line 01 and Credit: FS Line 02, and Combination 2 - Debit: FS Line 03 and Credit: FS Line 04, the test results will include the following possibilities:",
		"addCombinations-fs-lines-description-example-1": "Debit: FS Line 01 and Credit: FS Line 02",
		"addCombinations-fs-lines-description-example-2": "Debit: FS Line 03 and Credit: FS Line 04",
		"addCombinations-fs-lines-description-example-3": "Debit: FS Line 01 and Credit: FS Line 04",
		"addCombinations-fs-lines-description-example-4": "Debit: FS Line 03 and Credit: FS Line 02",
		"addCombinations-fs-lines-allDebit": "All Debit FS Lines",
		"addCombinations-fs-lines-allCredit": "All Credit FS Lines",
		"addCombinations-account-header": "After running the test, filter by account combinations",
		"addCombinations-account-title": "ACCOUNT COMBINATIONS",
		"addCombinations-account-description": "If you wish to limit the results of this test to entries that contain certain account numbers, indicate those below. Only duplicate entries containing the accounts specified will be flagged. Note that the account combinations between Debit and Credit can occur in various combinations. For instance, if you select Combination 1 - Debit: Account 1000 and Credit: Account 2000, and Combination 2 - Debit: Account 3000 and Credit: Account 4000, the test results will include the following possibilities:",
		"addCombinations-account-description-example-1": "Debit: Account 1000 and Credit: Account 2000",
		"addCombinations-account-description-example-2": "Debit: Account 3000 and Credit: Account 4000",
		"addCombinations-account-description-example-3": "Debit: Account 1000 and Credit: Account 4000",
		"addCombinations-account-description-example-4": "Debit: Account 3000 and Credit: Account 2000",
		"addCombinations-combination-addCombination": "ADD COMBINATION",
		"addCombinations-combination-addAnotherCombination": "ADD ANOTHER COMBINATION",
		"addCombinations-accounts-allDebit": "All Debit Accounts",
		"addCombinations-accounts-allCredit": "All Credit Accounts",
		"addCombinations-filter-reason-title": "Please provide a reason why you’ve selected the above filters:",
		"addCombinations-filter-reason-placeholder": "Provide explanation",
		"addCombinations-debit-account": "Debit Account",
		"addCombinations-credit-account": "Credit Account",
		"professional-fieldLabel-judgement-combinationsEntry": "Please provide a reason why you've selected these combinations:",
		"professional-fieldLabel-judgement-fsCombinationsEntry": "Please provide a reason why you've selected these combinations:",
		"number-of-occurence-title": "Number of occurrences to use for this test*",
		view_duplicate_summary: "View Duplicate Summary",
		"number-of-occurence-placeholder": "Number of occurences",
		"number-of-occurence-helper-text": "Number of occurrences must be greater than 1",
		"professional-judgement-placeholder": "Provide explanation",
		"number-of-occurences-title": "Summary of Duplicate Journal Entries",
		"number-of-occurence-guidance": "Indicate the number of occurrences to limit the test results to duplicate entry occurrences equal to or greater than the number specified. View the Duplicate Summary to assist in your selection.",
		"number-of-occurences-description": "This table helps establish the number of occurrences (X) to use for this test. Only journal entries containing less than 500 unique accounts are included.",
		shortname: "Test 11",
		"no-issues-found": "No issues found",
		"one-validity-issue": "1 validity issue",
		"validity-issues": "%d validity issues",
		"test-specific_data_validity_issues": "Test-Specific Data Validity Issues",
		"test-specific-dataset-validity": "The following test-specific dataset validity issues were found:",
		"viewDetailsPopUp-close-button": "Close",
		"viewDetailsPopUp-19-title": "Reporting amount = 0 but local amount <> 0",
		"viewDetailsPopUp-19-Description": "There are journal lines with 0 amount in reporting currency, but amount in original currency is not 0. This is unusual for journal entry data. Click into \"View Details\" for more information.",
		"viewDetailsPopUp-20-title": "Reporting amount <> 0 but local amount = 0",
		"viewDetailsPopUp-20-Description": "There are journal lines with 0 amount in original currency, but amount in reporting currency is not 0. This is unusual for journal entry data. Click into \"View Details\" for more information.",
		"viewDetailsPopUp-23-title": "Reporting amount = 0 but local amount <> 0",
		"viewDetailsPopUp-23-Description": "There are journal lines with 0 amount in reporting currency, but amount in original currency is not 0. This is unusual for journal entry data. Click into \"View Details\" for more information.",
		"viewDetailsPopUp-24-title": "Reporting amount <> 0 but local amount = 0",
		"viewDetailsPopUp-24-Description": "There are journal lines with 0 amount in original currency, but amount in reporting currency is not 0. This is unusual for journal entry data. Click into \"View Details\" for more information.",
		"viewDetailsPopUp-standard": "Standard",
		"viewDetailsPopUp-nonStandard": "Nonstandard",
		"dqcProfJudgment-title": "Add Professional Judgement",
		"dqcProfJudgment-label": "Please document the rationale for supporting this decision to proceed with this test.",
		"dqcProfJudgment-placeholder": "Provide explanantion...",
		"dqcProfJudgment-save-button-label": "Save",
		"dqcProfJudgment-cancel-button-label": "Cancel",
		duplication: "Duplication",
		"accounts-credited": "Accounts Credited",
		"accounts-debited": "Accounts Debited",
		"amounts-credited": "Amounts Credited",
		"amounts-debited": "Amounts Debited",
		"total-entries-flagged-title": "Details: Test 11 - Total Entries Flagged",
		"summary-entries-flagged-title": "Details: Test 11 - Summary of Entries Flagged",
		"num-times-entry-appears": "No. of Times Entry Appears in Population (To See All Entries Making Up the Number, Filter by the Entry's 'Dup-XX' number)",
		"summary-entries-guidance-text": "Note: This summary is based on the complete population, including accounts which have been excluded on the Test Settings screen. If no entries are displayed within the Details view (i.e., within magnifying glass), then all entries related to duplication have been removed from the test as part of Exclusions selected. Such excluded entries are not included in the Total Entries Flagged above or in the Excel output.",
		"release-notification-message": "The recent release of Omnia | Data includes enhancements to data upload rules which now allow for the inclusion of new special characters (i.e., non alpha-numeric characters). If certain special characters exist in your data set, it may impact the comparability of data used in Management Override – Journal Entry (JE) testing and analytics performed in Omnia | Data.  For further assistance or questions,"
	},
	"custom-test": {
		"customized-test-guidance": "You can add up to three custom tests. Please note the exclusions applied in the ‘Test Settings’ page are not applied to the custom tests.",
		"dropdown-placeholder-text": "Select option",
		"input-box-placeholder-text": "Type here",
		"drop-area-text": "Drag and drop test parameters from the side panel here to build your custom test",
		"period-label-text": "Period",
		"quarter-label-text": "Quarter",
		"year-label-text": "Year",
		"classification-title": "Classification / Allocation / Attributes",
		"journal-gl-account-number": "Journal includes a GL Account whose number is…",
		"journal-entry-type": "Journal entry type is…",
		"journal-line-items": "Journal has >=X line items",
		"dates-title": "Dates / Age / Cut-off",
		"journal-dates": "Journal entered between the dates X and Y",
		"journal-effective-periods": "Journal effective during a specific periods",
		"journal-effective-dates": "Journal effective between the dates X and Y",
		"journal-back-posted-period": "Journal back posted into a prior period",
		"journal-credit-lines": "Journal has IS credit lines entered within X days before or Y days after period end",
		"journal-debit-lines": "Journal has IS debit lines entered within X days before or Y days after period end",
		"revenue-journal-entered": "Revenue journal entered from the last X periods of the financial year",
		"journal-forward-posted-period": "Journal forward posted into a following period",
		"description-title": "Descriptions / Title / Text",
		"journal-gl-account-keywords": "Journal to a GL Account whose name contains keywords",
		"filters-title": "Filters",
		"entity-filter": "Entity is…",
		"journal-number-filter": "Journal number is…",
		"journal-includes-line-filter-dropdown-label": "Journal Lines",
		"journal-includes-line-filter": "Journal includes a line posted to account mapping tier 1 category…",
		"journal-includes-category-filter": "Tier 1 Category",
		"user-title": "User",
		"journal-entered-text": "Journal entered by…",
		"existence-title": "Validity / Existence / Fraud / Error",
		"journal-gl-account-validity": "Journal to a GL account with {isLessThanOperator} X Journals in…",
		"valuation-title": "Value / Valuation",
		"journal-includes-more-lines": "Journal includes 1 or more entry/line items with a value greater than X",
		"journal-includes-more-lines-threshold-label": "Threshold Type",
		"line-level-predicate": "Line Level",
		"entry-level-predicate": "Entry Level",
		"journal-includes-more-lines-threshold-amount-label": "Threshold Amount (X)",
		"journal-gl-account-number-description": "Identifies journals containing journal lines matching GL account numbers in a user defined list.",
		"journal-entry-type-description": "Identifies Journals based on their entry type: Manual - Entered by a person directly into the GL Automatic - Generated by the system from a sub-ledger posting",
		"journal-line-items-description": "Identifies Journals based on an analysis of the number of line items in the journal",
		"journal-dates-description": "All journals entered between two user-supplied dates (inclusive)",
		"journal-effective-periods-description": "Identifies journals entered / made effective on the selected dates",
		"journal-effective-dates-description": "Identifies journals based on an analysis of the journal effective date",
		"journal-back-posted-period-description": "Identifies journal entries back-posted into a prior period. Back posting means a journal was entered into the system in a period that is after the period of that financial transaction is effective in.",
		"journal-credit-lines-description": "Identifies journals with IS credit lines entered {isLessThanOperator} X days before or {isLessThanOperator} Y days after period end. If X = 1 and Y = 3, and Time period is Year, the analytic will return journals entered between 31 Dec and 03 Jan inclusive.",
		"journal-debit-lines-description": "Identifies journals with IS debit lines entered {isLessThanOperator} X days before or {isLessThanOperator} Y days after period end. If X = 1 and Y = 3, and Time period is Year, the analytic will return journals entered between 31 Dec and 03 Jan inclusive.",
		"revenue-journal-entered-description": "Identify revenue journals entered from the last X periods of the financial year.",
		"journal-forward-posted-period-description": "Identifies journals based on the entry date and the effective period",
		"journal-gl-account-keywords-description": "Identifies journals based on the words contained within the GL Account description which each journal line posts to.",
		"entity-filter-description": "Identifies journals based an analysis of the entity",
		"journal-number-filter-description": "Identifies journals by analysing the journal number (unique identifier)",
		"journal-includes-line-filter-description": "Identifies journals based on postings made to an account mapping tier 1 category",
		"journal-entered-text-description": "Identifies journals based on the userID of the user who entered it",
		"journal-gl-account-validity-description": "Identifies journals to a GL account meeting the threshold number of journals effective in the period.",
		"journal-includes-more-lines-description": "Identifies if a journal has entry/line items with a value greater than X",
		"filter-input-guidance": "Separate the inputs with a semicolon. Ex: 1;2;3",
		"select-from-table": "Select from table",
		"account-numbers": "Account Numbers",
		"type-here-input-placeholder": "Type here",
		"delete-title": "Are you sure you want to delete the test?",
		"delete-description": "Deleting the custom test would remove the underlying results as well.",
		"btn-cancel": "CANCEL",
		"btn-delete": "Delete",
		test: "TEST",
		custom: "(CUSTOM)",
		"custom-test-title": "Custom Test {{customTestNumber}}",
		"input-text-placeholder": "Input text",
		"input-box-placeholder": "Type here",
		"q1-closing-start": "Quarter 1 Closing Start",
		"q1-closing-end": "Quarter 1 Closing End",
		"q2-closing-start": "Quarter 2 Closing Start",
		"q2-closing-end": " Quarter 2 Closing End",
		"period-start": "Period Start",
		"period-end": " Period End",
		"x-period": " X-Period",
		"after-period-end-filter": "Number of days after period end (Y)",
		"backposted-filter": "Backposted after this many days",
		"before-period-end-filter": "Number of days before period end (X)",
		"between-predicate": "Between",
		"effective-end-date-filter": "Effective End Date",
		"effective-start-date-filter": "Effective Start Date",
		"entered-end-date-filter": "Entered End Date",
		"entered-start-date-filter": "Entered Start Date",
		"entity-id-filter": "Entity ID",
		"entry-type-filter": "Entry Type",
		"equals-predicate": "Equals",
		"gl-account-number-filter": "GL Account Number",
		"journal-includes-line-filter-label": "Tier 1 Category for Debit and/or Credit Lines",
		"journal-number-filter-label": "Number of Journals (X)",
		"keywords-filter": "Keywords",
		"line-items-filter": "Line Items",
		"not-equals-predicate": "Not Equals",
		"period-end-filter": "Period End",
		"period-predicate": "Period",
		"period-start-filter": "Period Start",
		"quarter-predicate": "Quarter",
		"threshold-amount-filter": "Journal Number",
		"time-period-filter": "Time Period",
		"user-id-filter": "User ID",
		"x-periods-filter": "X periods",
		"year-predicate": "Year",
		"run-test": "Run Test",
		"toster-error": "Error",
		"input-parameters-validation-message": "Please provide valid input parameters in the highlighted test parameters.",
		"threshold-amount-information-tooltip": "Only journal entries with flagged lines in excess of the threshold amount are flagged by this test.",
		"threshold-amount-label": "Enter threshold amount for this test",
		standard: "Standard",
		"non-standard": "Nonstandard",
		"journal-filter-label": "Journal Number",
		"debit-lines-predicate": "Debit Lines",
		"credit-lines-predicate": "Credit Lines",
		"debit-and-credit-lines-predicate": "Debit and Credit Lines",
		"asset-option": "Assets",
		"liability-option": "Liabilities",
		"equity-option": "Equity",
		"revenue-option": "Revenue",
		"expense-option": "Expenses",
		"export-excel": "Export to Excel",
		"export-excel-user-notification": "The request to export the custom test results has been submitted. You will receive an email with the link to download the file",
		"professional-judgement-placeholder": "Provide explanation",
		"professional-fieldLabel-judgement-custom-test": "Please provide a reason why you've entered these test parameters:",
		"quarter-dates-validation": "One of the selected test parameters require quarter dates. Please navigate to Step 3 of 'Test Settings', update the required information and re-submit the custom test.",
		"details-view-modal-title": "Details: Custom Test {{customTestNumber}} - Total Entries Flagged",
		"run-test-error": "Test run is not successful. Please rerun the test by applying the necessary parameters. If the issue continues, please contact the support team",
		"error-toaster-custom-test-message": "The test component that you are trying to add is already added to the analysis area.",
		"entries-flagged-table-guidance-text": "If the number of flagged lines is more than 50,000 records, the magnifying glass will be disabled. Generate the Excel output to review the complete details.",
		"user-max-test-components-info-message": "You can only use six test parameters at any given time."
	},
	"custom-test-header": {
		"edit-test-details": "edit test details",
		"delete-test": "delete test"
	},
	"custom-test-filter": {
		"account-number": {
			"select-filter-title": "Select Account Numbers",
			"search-placeholder": "Search accounts",
			"add-filter": "Add Accounts",
			name: "accounts"
		},
		"fs-line": {
			"select-filter-title": "Select Financial Statement Lines",
			"search-placeholder": "Search financial statement lines",
			"add-filter": "Add FS Lines",
			name: "fs lines"
		},
		entity: {
			"select-filter-title": "Select Entity IDs",
			"search-placeholder": "Search entity ids",
			"add-filter": "Add Entity IDs",
			name: "entity IDs"
		},
		"journal-number": {
			"select-filter-title": "Select Journal Numbers",
			"search-placeholder": "Search journal numbers",
			"add-filter": "Add Journal Numbers",
			name: "Journal Number"
		},
		"user-id": {
			"select-filter-title": "Select User IDs",
			"search-placeholder": "Search user ids",
			"add-filter": "Add Users",
			name: "users"
		},
		column: {
			account: "Account",
			entity: "Entity",
			"fs-line": "FS Line",
			jenumber: "Journal Number",
			"user-id": "User ID"
		}
	},
	runAnalytics: {
		breadcrumb: {
			home: "Home",
			jet: "Journal Entry Test"
		},
		info: {
			fy: "FY",
			auditPhase: "AUDIT PHASE",
			account: "ACCOUNT",
			industry: "INDUSTRY",
			sector: "SECTOR"
		},
		"rename-parameter-modal": {
			header: "Rename Parameter",
			label: "Parameter Value"
		},
		indicator: {
			message: "The entity and/or time period has been changed since the last time outputs were generated."
		},
		"outdated-analytic": "There is a new version available for this analytic in the Analytic Center. Consider re cloning the analytic and generating outputs.",
		"generate-output-error": {
			message: "There was an error while generating output(s). Please try again or contact support if the issue persists."
		},
		"refine-client-data-accordion": {
			header: "Step 1: Refine Client Data",
			"entity-label": "Entity",
			"timeperiod-label": "Current Testing Period",
			"tooltip-message": "This is only a preview of the dataset uploaded.",
			parameters: "Parameters",
			keyinformation: "Key Information",
			"data-model-update-info": "A new version of this data model is available. Clone the analytic from the analytic center to proceed with generating outputs.",
			updated: "Updated",
			pendingData: "Pending Data",
			"download-input": "Download",
			"total-record-count": "Total record count: ",
			"time-period-tooltip": "At least one time period is required",
			"centralizedDataSet-message": "This is a centralized data set required for this analytic. Open the accordion to view a preview of this data set.",
			"upload-data": "Upload Data",
			"upload-data-message": "The data needed to run this analytic has not been uploaded to the data kitchen. To continue, please upload client data and refresh this page.",
			all: "all",
			"edit-time-period-button": "Edit",
			"edit-current-testing-period-label": "Edit Current Testing Period",
			"edit-accordion-button": "Save",
			"cancel-accordion-button": "Cancel",
			"input-timeperiod-label": "Time Period",
			"mandatory-star_label": "*   Mandatory",
			"display-currency-label": "Group or Entity Currency",
			"display-currency-ibutton": "This represents the type of currency selected in the Data Kitchen for this entity",
			currency_Change_Alert_Message: "Currency has been changed in Data Kitchen. Please confirm to proceed with updated currency.",
			parameters_iButton: "These fields change the key information displayed on the visualization outputs that help the user understand and interpret the visualization. Users can edit these fields below. It is important to input information (e.g., dates) that is consistent with the filters (e.g., entity and current testing period) selected to perform the analytic. Please reach out to a specialist for further guidance.",
			validate_generate_output_confirmation_check: "I confirm that the key information has been reviewed and the input data has been subjected to the validation process.",
			editButton_information_icon: "This feature selects the prior period to the be used in this analytic. By default, the prior period is set to one calendar year before the current testing period selected for the analytic (and so on when there are multiple prior periods). It is important to select a prior period that is consistent with those periods input in the Key Information section above. If needed, the user may modify the prior period using the drop-down options which represent the audit calendars previously configured by the engagement team in the Data Kitchen.",
			edit_accordion_ok_button: "Ok",
			edit_accordion_alert_message: "Audit calendar of prior period can't be ahead of the current testing period.",
			download_submitted: "The request to download the file is received. You will receive a separate notification when the file is ready to be downloaded."
		},
		"generate-outputs-accordion": {
			header: "Step 2: Generate Outputs",
			"select-all-checkbox": "Select all",
			"quality-checks": "Data Integrity Checks:",
			outputs: "Outputs:",
			tableauOutputs: "Visualization Outputs:",
			tableauOutputsDisabled: "Underlying data for this analytic is no longer available.",
			"generate-outputs-button": "Generate outputs",
			dqc_issues_found: "Issues found",
			dqc_no_issues_found: "No issues found",
			data_table: "Data table",
			generate_output_tooltip: "In order to generate outputs, the analytic must have data, parameters, and at least one output must be selected.",
			modal_title: "Generate Outputs?",
			modal_message: "This analytic has empty parameter values. Are you sure you want to proceed?",
			modal_button_proceed: "Proceed",
			modal_button_cancel: "Cancel",
			generate_tableau: "Generate Tableau",
			tableau_error_cloning: "Tableau visualizations failed. Select Generate Tableau to try again or run the workpaper again.",
			tableau_error_sql: "Support is needed for Save to SQL to proceed. Please contact the Omnia | Data Support team (DeloitteCortexSupport@deloitte.com).",
			tableau_error_default: "An error has occured. Please contact the Omnia | Data Support team (DeloitteCortexSupport@deloitte.com).",
			disabled_output_tooltip: "This output has not been generated yet. Only outputs which have been generated can be viewed.",
			"generate-selected-outputs": "Refresh Outputs",
			"parquet-creation-failure-message": "Error loading data from data kitchen. Please try again or contact support if the issue persists.",
			visualization: "Visualization",
			tag_description: "New",
			same_time_period_error: "Same time period datasets cannot have different Audit Calendars.",
			model_button_ok: "Ok",
			"generate-selected-outputs-warning": "Selecting this option will overwrite existing outputs that were generated."
		}
	},
	"analytic-details": {
		"accordion-header": "Analytic Details",
		description: "Add additional details which will be displayed on the learn more page of this analytic.",
		button: "Save",
		"cannot-add-details-not-saved-analytic": "Analytic should be saved before adding details."
	},
	attach: {
		workpaper: {
			description: "Choose a published workpaper to attach to this standard analytic.",
			note: "Note: If you do not attach a workpaper, this analytic will not show as supported in Omnia | Data.",
			button: "ATTACH WORKPAPER",
			"accordion-header": "Attach Workpaper",
			tertiary: "Attach Workpaper",
			tags: "Tags:",
			data: "Data:",
			"view-details": "View Details",
			"input-sources": "Input Sources",
			transformation: {
				tables: "Transformation Tables",
				steps: "Transformation Steps"
			},
			outputs: "Outputs",
			design: {
				title: "Design:",
				pages: "pages",
				workbooks: "workbooks"
			},
			table: {
				workpapers: "Workpapers",
				overview: "Overview",
				"last-modified": "Last Modified"
			},
			"remove-message": "Are you sure you want to remove the relationship?",
			versionNumber: "WP Version #:",
			lastModified: "Last Modified:"
		},
		materials: {
			description: "Add supplementary materials to the “learn more” section for this analytic. Only links to Deloitte Resources may be added.",
			button: "ADD MATERIALS",
			"accordion-header": "Attach Files and Supplemental Materials",
			"material-type": "Material Type",
			link: "Link",
			"material-type-placeholder": "Ex. article, video, tutorial",
			"link-placeholder": "Enter URL",
			"delete-message": "Are you sure you want to remove the material?",
			"not-saved-analytic": "Analytic should be saved before adding supplementary materials",
			"inValid-url-check": "Invalid URL"
		}
	},
	"reject-confirm-message": "Please confirm that you would like to reject this analytic.",
	"approve-confirm-message": "Please confirm that you would like to approve this analytic to be published.",
	"submit-for-review-confirm-message": "Please confirm that you would like to submit this analytic for review.",
	"switch-to-draft-confirm-message": "Please confirm you would like to switch this analytic to draft.",
	"professional-judgement": {
		heading: "Provide professional judgement for the test(s) INCLUDED",
		placeholder: "Provide explanation"
	},
	"button-display-text": "Next Step",
	"professional-judgement-error-toaster": "Please provide professional judgement to proceed further",
	"professional-judgement-character-limit-toaster": "You're exceeding 5000 characters",
	"professional-judgement-success-toaster": "Test(s) selection criteria has been saved successfully",
	"tests-complete": "Tests Complete",
	"cannot-review-own-submissions": "Content team members cannot approve or reject their own submissions",
	"cannot-attach-wp-not-saved-analytic": "Analytic should be saved before attaching workpaper",
	"search-placeholder": "Search analytics",
	"summary-entries-flagged": "Summary of Entries Flagged",
	version: "Version",
	generate_additional_outputs: "Refresh Outputs",
	"create-custom-test-modal": {
		placeholder: "Provide explanation",
		"btn-create": "Create Custom Test",
		"btn-cancel": "CANCEL",
		"modal-title": "Create a Custom Test",
		"modal-description": "Fill in the details below to create a custom test.",
		"test-name-character-limit-toaster": "You're exceeding 150 characters",
		"test-description-character-limit-toaster": "You're exceeding 5000 characters",
		"toaster-title": "Message",
		"test-name-input": "Test Name*",
		"test-description-input": "Test Description*",
		"test-name-placeholder": "Enter test name",
		"test-description-placeholder": "Enter test description"
	},
	"edit-custom-test-modal": {
		"btn-edit": "Save Changes",
		"modal-title": "Edit Custom Test",
		"modal-description": "Edit the details of your custom test"
	}
};
var login = {
	heading: "Welcome to Omnia | Data",
	"sub-heading": "Harnessing the power of data",
	"learn-more": "Learn more",
	button: "Login",
	"key-features": {
		heading: "Key features",
		analytics: "Seamless connection to client audit data sources",
		lock: " Secure data acquisition, transfer, and staging",
		move: " Repeatable and consistent processes",
		cortex: " Advanced data analytics enabling a smarter audit"
	},
	footer: {
		"need-help": {
			label: "Need help? Email us at :",
			email: "DeloitteCortexSupport@deloitte.com",
			copyright: "Copyright © 2021 Deloitte Development LLC. All rights reserved.",
			description: " Deloitte referes to one or more of Deloitte Touche Tohmatsu Limited, a UK private company limited by gurantee (“DTTL”), its network of member firms, and their related entities. DTTL and each of its member firms are legally separate and independent entities. DTTL (also referred to as “Deloitte Global”) does not provide services to clients. Please see ",
			"bottom-description": "for a more detailed description of DTTL and its member firms.",
			"terms-of-use": "Terms of Use",
			"privacy-statement": "Privacy Statement",
			notices: "Notices",
			see: "See",
			and: "and ",
			"for-more-info": "for more information."
		},
		"about-us-url": "www.deloitte.com/about"
	}
};
var entities = {
	titles: {
		entities: "Entities",
		"create-entity": "Add Entity",
		"delete-entity": "Delete Entity(s)",
		"update-entity": "Edit Entity",
		"import-entities": "Import Entities from JE data",
		more: "MORE"
	},
	buttons: {
		"add-new-entity": "ADD NEW ENTITY",
		"delete-entity-table": "DELETE",
		"delete-entity-modal": "Delete",
		"create-entity-modal": "Create",
		"update-entity-modal": "Update",
		"upload-entities-csv": "UPLOAD ENTITIES",
		"import-entities-from-je": "IMPORT ENTITIES FROM JE DATA",
		"upload-group-entities-csv": "UPLOAD GROUP ENTITIES",
		"download-entities-csv": "DOWNLOAD ENTITIES",
		"download-csv-error-message": "Unable to download file",
		"proceed-btn": "PROCEED",
		"ok-btn": "OK",
		"cancel-btn": "CANCEL"
	},
	labels: {
		name: "Name",
		"entity-id": "Entity Id",
		description: "Description",
		"required-field-legend": "Required Fields",
		"required-field-symbol": "*",
		"inactive-status-note": "Note: ",
		"num-entities-selected": "Selected",
		"entity-currency": "Entity Currency"
	},
	messages: {
		"entities-present-in-group-note": "One or more selected entities are present in an entity group. Delete Anyway?",
		"entities-not-present-in-group-note": "One or more selected entities will be Deleted. Delete Anyway?",
		"no-entities-selected-note": "There are no entities selected for deletion.",
		httprespons_conflict: "Another Entity/Entity Group with same name {name}/Entity id {entityId} exists. Please update and re-try.",
		httpresponse_unauthorized: "Invalid session. Please re-login and try again.",
		"httpresponse_internal-server-error": "Invalid entity request. Please try again.",
		"httpresponse_bad-request": "Invalid entity request. Please try again.",
		httpresponse_undefined: "Invalid entity request. Please try again.",
		"import-entities-from-je-data": "Omnia | Data will analyze the data that you have already loaded into the Journal Entry data model and add them to the Entities listing.",
		"import-entities-from-je-data-processing": "This process will take sometime, please wait.",
		"import-entities-from-je-data-success": "The following entities have been imported from JE data",
		"import-entities-from-je-data-success-1": "Note: This is ‘read only’ information, pop-up can be closed by clicking on close icon",
		"import-entities-from-je-duplicate-info-message": "Entities in Red were not added to the entity list because it is duplicate of an existing Entity Group.",
		"import-entities-from-je-truncated-info-message": "Certain Entity values below have been truncated to 150 characters.  Please review Entity Name and ID in Data Settings and make any necessary updates.",
		"entities-not-found-error": "No new entities identified through analysis of Journal Entry Data",
		"je-data-model-not-found-error": "JE data model data does not exists",
		"dm-schema-not-found": "No data model found for the EngagementID",
		"input-file-schema-not-found": "File structure not recognized. Please update and re-upload",
		"entity-missing-required-props": "Missing values or exceeding allowed character limit within the uploaded document. Please update and re-upload",
		"entity-props-not-unique": "There are duplicate records present in JE data model file, please review and update accordingly.",
		"dk-bad-request": "Unable to proccess the request. Please try again!",
		"duplicate-entity": "Another entity with the same name / entity id exists",
		"entity-added": "Entity added successfully"
	},
	constants: {
		required_engagementid_invalid: "EngagementId is required",
		required_name_empty: "Name is required",
		required_name_invalid: "Name is invalid",
		required_entityid_empty: "Entity id is required",
		required_entityid_invalid: "Entity id is invalid",
		required_description_invalid: "Description is required",
		"char-count": "characters",
		required_entitycurrency_empty: "Entity currency is required",
		required_entitycurrency_invalid: "Entity currency is invalid",
		special_char_detected: "Note: Beginning special character has been prefixed with a single quote (')"
	},
	placeholders: {
		name: "Give this entity a name...",
		"entity-id": "Give this entity an id...",
		description: "Give this entity a description...",
		"entity-currency": "Give this entity a currency..."
	}
};
var management = {
	members: "members",
	"engagement-management": "Engagement Management",
	"engagement-members": {
		title: "Engagement Members",
		name_column: "NAME",
		email_column: "EMAIL",
		role_column: "ROLE",
		error_title: "Invalid Assignment",
		error_message: "Users outside the country container can't be assigned as Engagement Owners",
		tooltip_cant_modify_user: "You can’t modify your own role"
	},
	"download-roll-forward-error-report": "DOWNLOAD ROLL FORWARD ERROR REPORT",
	"extraction-members": {
		title: "Client Owners",
		name_column: "NAME",
		email_column: "EMAIL",
		role_column: "ROLE"
	},
	"edit-info": {
		title: "Edit Client User Information",
		"first-Name": "First Name",
		"enter-first-name": "Enter First Name",
		"last-Name": "Last Name",
		"enter-last-name": "Enter Last Name",
		email: "Email"
	},
	"engagement-linking-successful": "Engagement linking was successful.",
	"deleting-user": "Deleting user...",
	"adding-user": "Adding user...",
	"search-extraction-members": "Search Client Owners",
	"search-engagement-members": "Search Engagement Members",
	"management-section-description": "Within the engagement management section you can add, remove, and manage team member roles, wrap-up the engagement, put it on legal hold, and view integrations with Omnia and Globalscape.",
	"engagement-wrap-up-tile": {
		title: "Engagement wrap-up",
		description: "Wrap-up this engagement. Once wrapped-up, interactions will be limited/read-only."
	},
	"disabled-wrapup-tooltip": {
		title: "Cannot Wrap-Up This Engagement",
		description: "This engagement is on Legal Hold. To Wrap-Up this engagement, Lift Legal Hold."
	},
	"engagement-legal-hold-tile": {
		title: "Put on legal hold",
		description: "Put this engagement on legal hold. Files marked for legal hold cannot be deleted."
	},
	"engagement-lift-legal-hold-tile": {
		title: "Lift Legal Hold",
		description: "Lift this engagement from being marked as legal hold."
	},
	"team-members": {
		title: "Team Members",
		"entity-unit": "Members",
		"column-header-name": "Name",
		"column-header-email": "Email",
		"column-header-role": "Role(s)",
		"engagement-owner-button": "ENGAGEMENT OWNER",
		"team-member-button": "TEAM MEMBER",
		"client-member-button": "CLIENT MEMBER",
		"external-link-client-owners": "VIEW CLIENT OWNERS",
		"external-link-engagement-members": "VIEW ENGAGEMENT MEMBERS"
	},
	integrations: {
		title: "Integrations",
		description: "View and Setup new integrations to this engagement. To integrate with Globalscape and Omnia, you must reconcile to database.",
		button: "CONVERT ENGAGEMENT TO MAT ENGAGEMENT",
		omnia: {
			title: "Omnia"
		},
		"linked-engagements": "Linked Engagements",
		"no-engagements-linked": "No Engagements Linked",
		"no-linked-engagements-description": "No Linked Engagements. To use features like TrialBalance and output sharing link your engagement to Omnia by navigating to Omnia | Data through the",
		"no-linked-engagements-description2": "Digital Audit Enablers menu",
		"external-engagement-error": "Omnia | Data is experiencing technical issues at the moment. Please try again after sometime or contact support",
		unlinking: {
			heading: "Are you sure you want to unlink {{ externalEngagementName }} from Omnia | Data?",
			"modal-description-text": "Trial Balance and Output Sharing will be disabled. In order to re-enable this functionality, you will need to relink the files by navigating to the digital audit enablers section of Omnia and selecting Omnia | Data.",
			"cancel-button": "Cancel",
			"unlink-button": "Unlink"
		},
		"delink-engagement-error": "There was an issue unlinking the engagement. Please contact support if the issue persists.",
		"unlinked-successful-message": "Omnia Engagement {{ engagementName }} unlinked successfully."
	},
	"convert-engagement-modal": {
		title: "Convert to MAT Database",
		description: "In order to convert this engagement to a MAT Engagement, select from the MAT database engagements below. The client name can’t be changed.",
		"current-engagement-title": "CURRENT ENGAGEMENT",
		"mat-engagement-title": "MAT ENGAGEMENT",
		"engagement-name": "Engagement Name"
	},
	"back-to-engagement-management": "Back to engagement management",
	"wrap-up-modal": {
		title: "Engagement Wrap-Up",
		"once-wrapped-up": "Once wrapped-up, the engagement will be read-only, but data can be viewed and downloaded & users can be managed.",
		"acknowledge-information": "Acknowledge information below in order to proceed with wrapping up this engagement.",
		"i-confirm-that": "I confirm that:",
		checkboxes: {
			"data-stored-retention": "All data stored in the data kitchen of this engagement will be retained for {{ retentionYears }} years based on the Member Firm settings.",
			"data-stored-retention-one": "All data stored in the data kitchen of this engagement will be retained for {{ retentionYears }} year based on the Member Firm settings.",
			"included-in-audit": "Any data considered audit evidence has been exported and uploaded to my audit file as they will be purged at wrap up:",
			"info-1": "Tableau visuals",
			"info-2": "Sampling outputs",
			"info-3": "Management Override Standard Analytics",
			"info-4": " Final Deliverable Reports",
			"info-5": "Data Quality Check/Reconciliation Outputs within the Journal Entry Golden Check Workflow",
			"read-only": "Once the engagement is wrapped-up it will become read-only, but data can be downloaded and users can still be managed.",
			"no-placeholder": {
				"data-stored-retention": "All data stored in the data kitchen of this engagement will be retained for ",
				"retention-years": " years based on the Member Firm settings.",
				"retention-years-one": " year based on the Member Firm settings."
			}
		},
		"wrap-up-engagement": "Wrap-Up Engagement",
		"async-wrap-up-engagement": " Async Wrap-Up Engagement",
		"confirm-modal": {
			title: "Engagement Wrap-Up Initiated",
			"bold-body": "This engagement is being wrapped-up and you will not be able to modify it at this time.",
			body: "This process may take up to 30 minutes. Once the engagement is wrapped-up, all contents will be read-only.",
			confirm: "Got it",
			"title-failed": "Engagement Wrap-Up Failed to Initiate",
			"body-failed": "The wrap-up process failed to initiate. Please contact support."
		}
	},
	"wrap-up-warning-modal": {
		title: "Unable to Wrap-Up this Engagement",
		"this-engagement-is": "This engagement is linked to one or more active Omnia Engagements.",
		"if-the-cortex": "If the Omnia | Data engagement is wrapped up, then all linked Omnia engagements no longer can make Trial Balance updates. Either unlink the Omnia engagements below, or wait until all are archived to proceed.",
		unlink: "UNLINK",
		table: {
			"omnia-engagement-name": "Omnia Engagement Name",
			"country-name": "Country",
			"fiscal-year": "Fiscal Year"
		},
		"unlink-banner": {
			"success-title": "Unlinking Successful",
			"success-message": "The engagement was successfully unlinked.",
			"failure-title": "Unlinking Failed",
			"failure-message": "The engagement could not be unlinked."
		}
	},
	"legal-hold-modal": {
		title: "Initiate Legal Hold",
		"once-legal-hold": "Once an engagement is on legal hold, data and outputs marked as legal hold cannot be deleted.",
		"acknowledge-information": "Acknowledge information below in order to proceed with marking files on legal hold.",
		checkboxes: {
			"eng-delete": "I understand that all engagement data marked as legal hold cannot be deleted."
		}
	},
	"status-notifications": {
		"wrapup-pending": {
			title: "Engagement Wrap-Up In Progress",
			message: "\"{{engagementName}}\" cannot be interacted with until Wrap-Up is complete."
		},
		"wrapup-success": {
			title: "This Engagement is Wrapped-Up",
			message: "Contents are read-only, but data can still be downloaded and users can still be managed."
		},
		"wrapup-failed": {
			title: "Wrap-Up Failed",
			message: "A wrap-up error occured and some files may have been purged, please try again. If the issue persists, contact support."
		},
		"legal-hold": {
			title: "Legal Hold",
			message: "Some files in this engagement are marked for legal hold and cannot be deleted or edited.",
			content: " See ",
			banner: "Data Kitchen",
			msg: " for details"
		},
		"matconvert-success": {
			title: "Engagement Converted",
			message: "The Engagement was successfully converted to a MAT Engagement."
		},
		"matconvert-failed": {
			title: "Convert Engagement to MAT failed",
			message1: "The Engagement could not be converted to MAT. You can ",
			message2: "try again",
			message3: " or ",
			message4: "contact support",
			message5: " ."
		},
		"rollforward-partial-complete": {
			title: "Roll forward complete",
			message1: "Engagement “{{engagementName}}” was created, but some data did not roll forward. View what did not roll forward ",
			message2: "here",
			message3: " . After review, you can proceed with your engagement, delete the engagement and try to roll forward again, or  ",
			message4: "contact support",
			message5: " ."
		}
	},
	"delete-engagement-members": {
		title: "Remove the user from the team members list?",
		message1: "Doing so will revoke ",
		message2: " from all access to the current engagement.",
		"user-name": "{{firstName}} {{lastName}}",
		"submit-button": "Remove",
		"cancel-button": "Cancel",
		"error-message": "Failed to Delete “{{email}}”",
		"last-user-warning-header-1": "Are you sure you want to remove ",
		"last-user-warning-header-2": " from the team?",
		"last-user-warning-header-message-1": " is part of a configured workflow. If ",
		"last-user-warning-header-message-2": "  is the ",
		"last-user-warning-header-message-3": " last approver ",
		"last-user-warning-header-message-4": "in the workflow. all previously approved data requests will revert to ",
		"last-user-warning-header-message-5": "draft. ",
		"last-user-warning-header-message-6": "Please add another user before removing to prevent this reversion to draft."
	},
	verification: {
		text: "All Omnia | Data team members must be reviewed every 90 days. Review the team members access below.",
		complete: "MARK AS COMPLETE",
		type: "Review Required",
		"success-header": "Review Complete",
		"success-message": "You will receive another reminder to review team member access in 90 days.",
		"success-button": "Got it"
	}
};
var translations = {
	landing: landing,
	"view-more-component": {
	"view-more-button": "View More"
},
	"data-kitchen": {
	"upload-new-data": "Select Data",
	"upload-new-data-description": "Select here to upload the data file to the Omnia | Data and make it available for the analytics.",
	"new-data-upload-title": "upload and select data",
	"account-mapping-save-success-message": "Changes have been saved successfully.",
	"account-mapping-bulk-filter-apply-message": "The Account Grouping filter has been applied successfully. You can either edit additional Account Grouping values or Save/Discard changes accordingly.",
	"account-mapping-bulk-ag-save-success": "Account Grouping filter has been applied and the changes has been saved successfully.",
	"account-mapping-custom-option-save-success": "Custom value edits has been applied and changes has been saved successfully",
	"account-mapping-error-duplicate-account-group-name-msg": "Each unique Account Number and COA ID combination can not be mapped to single Account Grouping Number. None of the changes have been saved.",
	"account-mapping-error-duplicate-financial-statement-line-item-msg": "Each unique Account Grouping Number and COA ID combination can be mapped to single FSL Number. None of the changes have been saved.",
	"account-mapping-error-duplicate-financial-statement-sub-type-msg": "Each unique Financial Statement Line Number and COA ID combination can be mapped to single FSL SubType. None of the changes have been saved.",
	"account-mapping-error-save-failure": "Failed to save the changes, please update and apply the changes again",
	"new-data-upload-description": "Drag and drop data files to upload to Omnia | Data, upload files through a secure file transfer protocol (SFTP), and select files for use in analytics.",
	"generate-data-request": "Request data",
	"generate-data-request-description": "Download a Data Request Input Form, create an automated extraction request, or manage existing data.",
	"add-new-table": "Add new table",
	reconciliation: "Reconciliation",
	"add-new-context": "Add new context",
	"audit-calendars": "Audit Calendars",
	"data-settings": "Data Settings",
	"back-to-datakitchen": "Back To Data Kitchen",
	"back-to-datasettings": "Back To Data Settings",
	"back-to-tb": "Back to Trial Balance",
	"error-title": "Error Message",
	"success-title": "Status Message",
	"dic-alert-title": "Alert Message",
	"error-calling-filevalidation": "Error calling File Validation service.",
	"error-calling-headerselection": "Header row selection is not complete due to technical error. Please reselect the header row",
	"error-calling-fileupload": "Error uploading the file, please  try again.",
	"error-calling-cdm-removed": "CDM no longer exists. Unable to load data tables.",
	"error-calling-get-data-tables": "An error occurred retrieving the data tables.",
	"dcrm-file-error-description": "The file you are trying to upload has non-default data classification labels on it that prevents the file uploading into Omnia | Data. Please change the data classification labels on the file and re-try. ({fileName})",
	"non-dcrm-file-error-description": "The file you are trying to upload has non-default data classification labels on it that prevents the file uploading into Omnia | Data. Please change the data classification labels on the file and re-try.",
	"password-protected-file-error-description": "The file you are trying to upload is password protected and cannot be uploaded into Omnia | Data. Please remove the password protection on the file and re-try.",
	serach: "Search",
	feildNameTech: "Field Name(Tech)",
	fieldName: "Field Name",
	type: "Type",
	desc: "Description",
	aliases: "Aliases",
	key: "Key",
	required: "Required",
	"account-mapping-title": "Chart of Accounts Mapping",
	"file-preview-delayed-message": "The generation of preview is taking longer than usual. Please wait.",
	"key-mode": {
		MODE1: "Mapping currently updated. Map accounts and export or import mapping templates.",
		MODE2: "The accounts information has been updated with a new Trial Balance(s). Review and approve the mapping.",
		MODE3: "Chart of accounts mapping detail is missing. Please update chart of accounts mapping."
	},
	"confirmation-alert": {
		title: "You are about to save the data to the standard table. Please confirm that you have reviewed the following before proceeding",
		"point-1": "Source data field to standard table field mapping",
		"point-2": "Source date format and decimal separator format",
		"point-3": "Data Integrity Check result",
		"button-cancel": "Cancel",
		"button-confirm": "Confirm"
	},
	data_integrity_checks: {
		uploadToRaw: "Preparing data to run the Data Integrity Checks.",
		dicInProgress: "Data Integrity Checks validation is in progress.",
		dicComplete: "Execution of the Data Integrity Checks completed. Please see the results below.",
		dic_execution_failure: "Data Integrity check execution failed. Please click here to ",
		"dic_execution_failure-additional-info": "If the problem persists, please contact support.",
		loading: "Loading...",
		step_1: "Step 1 of 3",
		step_2: "Step 2 of 3",
		step_3: "Step 3 of 3",
		"dic-job-success": "Preview of Data Integrity Matters and Data Integrity Check summary report generation in progress.",
		"dic-report-success": "Execution of Data Integrity Checks completed. Please see the results below.",
		"learn-more": "LEARN MORE",
		"view-details": "VIEW DETAILS",
		"preview-affected-records": "PREVIEW AFFECTED RECORDS",
		"preview-affected-dic-records": "Preview Data Integrity Matters",
		"dic-pending-status": "Checking for Data Integrity Matters",
		"preview-details-tooltip-disabled": {
			"default": "Preview of Data Integrity Matters generation is currently in progress.",
			"multiple-currencies": "Multiple group currencies are flagged. Check group currency field for accuracy."
		},
		"report-link-disabled-tooltip": "The Data Integrity Check summary report generation is currently in progress.",
		"dic-record-status": "{unexpectedCount} Data Integrity Matters Flagged",
		dicNotAavailable: "There are no Data Integrity Checks(DIC) available for this Standard Table: {dataModel}",
		"init-message": "Initializing...",
		retry: "retry"
	},
	"engagement-data-input": {
		"httpresponse_internal-server-error": "Error calling Engagement Data Input endpoint, please try again.",
		httpresponse_conflict: "Error uploading the file, please  try again.\n The File \"{{fileName}}\" already exists.",
		httpresponse_unauthorized: "Error calling Engagement Data Input endpoint: Invalid Session. Please re-login and try again.",
		"httpresponse_bad-request": "Incorrect parameters calling service, please check your input.",
		"httpresponse_item-not-found": "Error accessing Input. Input not existing or not accesible to the user.",
		httpresponse_undefined: "Error calling Engagement Data Input endpoint, please try again.",
		httpresponse_NotAcceptable: "The file you have selected is password-protected and cannot be uploaded into Omnia | Data. Please remove the password-protection and try again.",
		httpresponse_NotExtended: "The file you have selected has non-default data classification labels on it that prevents the file uploading into Omnia | Data. Please change the data classification labels on the file and re-try."
	},
	"file-preview": {
		http_response_conflict: "Error getting preview: Duplicate Column Names found in file.",
		http_response_PreconditionFailed: "One or more instances of an invalid hyperlink was found in the dataset being uploaded. Please review the hyperlink(s) and re-try uploading the dataset.",
		"http_response_internal-server-error": "Error calling File Preview endpoint, please try again.",
		http_response_unauthorized: "Error calling File Preview endpoint: Invalid Session. Please re-login and try again.",
		"http_response_bad-request": "Incorrect parameters calling service, please check your input.",
		http_response_undefined: "Error calling File Preview endpoint, please try again.",
		http_response_NotAcceptable: "The file you have selected is password-protected and cannot be uploaded into Omnia | Data. Please remove the password-protection and try again.",
		http_response_NotExtended: "The file you have selected has non-default data classification labels on it that prevents the file uploading into Omnia | Data. Please change the data classification labels on the file and re-try."
	},
	"file-upload": {
		"prompt-warning": "The file upload hasn't finished, are you sure you want to leave?",
		httpresponse_undefined: "Error uploading the file, please  try again.",
		"httpresponse_item-not-found": "Error uploading the file, incorrect File System structure.",
		"httpresponse_internal-server-error": "Error uploading the file, please  try again.",
		httpresponse_unauthorized: "Error uploading the file: Invalid Session. Please re-login and try again.",
		"file-upload-delayed-message": "This process is taking 5-10 minutes longer than usual. Please wait.",
		httpresponse_conflict: "Error uploading the file, please  try again.\n The File \"{fileName}\" already exists.",
		"encryption-error": "Error encrypting uploaded file, please try again."
	},
	"file-preview-explorer": {
		title: "Import Existing File",
		"select-file-message": "Select a new existing file below to upload to the data kitchen. Only folders with uploadable files will be displayed.",
		"select-file-button-label": "Select File",
		"preview-file-message": "Preview your selected data file below, or replace the file with another you wish to upload.",
		"import-file-button-label": "Import"
	},
	"mapping-fields": {
		"auto-mapping-data-fields": {
			title: "All source data fields are automatically mapped",
			description: "Omnia | Data has managed to automatically map all the source data fields for you. If you would like to alter the mapping you may do so. Please review the field mappings before proceeding."
		},
		"manual-mapping-data-fields": {
			title: "Some Source Data File Fields Require Manual Mapping",
			description: "Omnia | Data was unable to automatically map some of the source data file's fields to the standard table’s fields. Please map those fields manually.",
			"warning-files-desc": "Data records in the source data file with invalid date or number formats will be converted to null values during the upload to the standard tables. If you are not sure about the date and number formats of the source data file, you can use the PREVIEW functionality at the top right of this screen to see the preview of the file before selecting the data formats associated with the source file and uploading the data into the standard data table."
		},
		errors: {
			httpresponse_unauthorized: "Invalid session. Please re-login and try again.",
			"httpresponse_bad-request": "Error calling Input Schema Mappings endpoint, please try again.",
			httpresponse_undefined: "Error calling Input Schema Mappings endpoint, please try again.",
			"httpresponse_internal-server-error": "Error calling Input Schema Mappings endpoint, please try again.",
			"httpresponse_not-found": "Error loading input schema mappings for the input, please try again."
		},
		warnings: {
			"missing-mandatory-fields": "One or more required fields in the standard table are not mapped to a source data field. Data cannot be saved to the standard table until all the required fields are mapped to a source data field.",
			"unmapped-non-mandatory-fields": {
				title: "Source Fields Not Mapped",
				"warnings-description": "One or more source data file fields are not mapped to a corresponding standard table's fields and they will be ignored if you proceed to click the \"Finish\" button."
			},
			"confirmation-modal": {
				title: "Have you reviewed all mappings before proceeding?",
				description: "Please confirm that you have reviewed all field mappings prior to proceeding.",
				"submit-button": "Confirm",
				"cancel-button": "Cancel"
			},
			"datamodel-update-warning": {
				title: "Datamodel update warning",
				description: "Standard table associated with this uploaded data underwent structural changes. This might require some field mappings to get updated. You will be redirected back to the General Info screen so that you can select the standard table again and continue with the subsequent steps.",
				"submit-button": "OK"
			},
			"datarequest-trigger-message": {
				title: "Datamodel update warning",
				description: "There is a new standard table version available, please trigger the data request with the latest standard table version."
			},
			"no-dic-available": "There are no Data Integrity Checks(DIC) available for this Standard Table. Clicking on Finish button would save the data to the Standard Table."
		},
		labels: {
			"previous-mapping-found": "Automapping based on previous {{dataModel}} mapping done on {{date}} by {{author}}",
			"historical-mapping-found": "Auto-mapping of the source data field to the standard table field has been performed based on the mapping done on the previously uploaded source data set on {{date}}",
			"automapped-plain-text-data-fields": "Automapping of the source data fields is done based on matching field names",
			"num-all-mapping-fields-complete": "All source data fields have been mapped ({number})",
			"num-unmapped-fields-available": "{number} unmapped fields",
			"all-mapping-fields-complete-number": "All source data fields have been mapped ({{number}})",
			"unmapped-fields-available": "{{number}} unmapped fields",
			"required-fields": "*Required fields",
			"client-column-name": "Source Data Field Name",
			"standard-table-field": "Standard Table Field Name",
			"data-preview-disclaimer": "*The preview above reflects the changes made via mapping below; as you edit the mapping the preview changes in real time.",
			"all-mapping-fields-complete": "All source data fields have been mapped",
			"define-data-format-title": "Define Source Data Format",
			"st-define-data-format-description": "There are one or more date and numeric columns in the standard table. Please specify the date and number format for the uploaded data file. The Date Format field allows you to select from various options to specify the format and position of the Month, Day, and Year. Omnia | Data will automatically detect the separator between them. You will have an option to select a different date format and decimal separator for the individual fields."
		},
		"action-labels": {
			"create-custom-field": "Create Custom Field",
			"reset-all": "Reset All",
			"data-preview": "Data Preview: {{dataModel}}",
			"updated-data-preview": "Updated Data Preview: {{dataModel}}",
			"unmapped-filter": "Show unmapped fields only"
		},
		"mapping-dropdown-options": {
			placeholder: "Select",
			"static-ignore": "Ignore",
			"static-import-new": "Import as additional column",
			"blank-source-column": "Blank Header {{key}}"
		},
		"format-selector": {
			"date-title-source": "Source Date Format",
			"decimal-title-source": "Source Decimal Separator",
			"date-placeholder-source": "Select source date format",
			"decimal-placeholder-source": "Select source decimal separator",
			date: {
				"guidance-text-mm-dd-yyyy": "E.g. : 02-17-2009, 02/17/2009, 02.17.2009, etc.",
				"guidance-text-dd-mm-yyyy": "E.g. : 17-02-2009, 17/02/2009, 17.02.2009, etc.",
				"guidance-text-yyyy-mm-dd": "E.g. : 2009-02-17, 2009/02/17, 2009.02.17, etc.",
				"guidance-text-mon-dd-yyyy": "E.g.: Feb-17-2009, Feb/17/2009, Feb.17.2009, etc.",
				"guidance-text-dd-mon-yyyy": "E.g.: 17-Feb-2009, 17/Feb/2009, 17.Feb.2009, etc.",
				"guidance-text-yyyy-mon-dd": "E.g.: 2009-Feb-17, 2009/Feb/17, 2009.Feb.17, etc."
			},
			decimal: {
				"dot-seperator": "Period (\"1000.00\")",
				"comma-seperator": "Comma (\"1000,00\")",
				"na-seperator": "NA",
				"guidance-text-na": "Select this option when there are no decimal separators for the client data in this field"
			}
		},
		"data-settings-errors": {
			conflicts: "Duplicate Entities (Name or ID) within the uploaded document.  Please update and re-upload",
			"bad-request": "File structure not recognized.  Please update and re-upload.",
			"input-file-schema-not-found": "File structure not recognized. Please update and re-upload",
			"entity-missing-required-props": "Missing values or exceeding allowed character limit within the uploaded document. Please update and re-upload",
			"entity-props-not-unique": "Duplicate Entities (Name or ID) within the uploaded document.  Please update and re-upload.",
			"all-duplicate-entities": "All entities listed in the file already exists. Please add new entities and re-upload.",
			"entitygroups-props-not-unique": "Duplicate Entity Groups within the uploaded document. Please update and re-upload.",
			"engagement-id-required": "EngagementId is required",
			"entity-associated-with-entity-group": "Entity Groups contain entities that do not exist in Entities listing. Please update and re-upload.",
			"success-confirmation-info": "Changes have been saved successfully",
			"entity-currency-not-supported-special-character": "Uploaded document contains special characters within entity currency column that are not supported.  Please update and re-upload.",
			"centralized-engagement-mapping-props-not-unique": "Duplicate ID found. Please update and re-upload.",
			"centralizedEngagementMapping-missing-required-props": "Missing values within the uploaded document.  Please update and re-upload.",
			"engagement-not-found": "Omnia | Data engagement ID not found. Please update and re-upload.",
			"entity-group-currency-selected-not-valid": "Invalid values within currencies selected field. Please update and re-upload.",
			"entity-entitygroup-name-exists": "Another Entity/Entity Group with the same name exist. Please update and Re-try.",
			"error-centralized-engagement-mapping-limit-reached": "Maximum {EngagementMappingLimit} engagement mappings allowed for Engagement ID: {cortexEngagementId}",
			"error-centralized-engagement-mapping-limit-reached-upload": "Maximum {EngagementMappingLimit} engagement mappings allowed per Engagement ID."
		},
		"alias-info": {
			mapped: "Mapped",
			"source-column-name": "Source column name:",
			"data-model-field": "Data model field:",
			alias: "Alias:",
			conflict: "Conflict"
		},
		"auto-map": {
			"button-title": "Auto-Map",
			"button-tooltip": "This is to automap based on matching field names",
			"modal-title": "Perform auto-map based on column name match",
			"modal-message": "You are about to erase the existing field mapping and perform auto-mapping of the source fields to the target fields based on the matching field names. Do you wish to proceed",
			"button-cancel": "Cancel",
			"button-proceed": "Proceed",
			"import-additional-column": "Found one or more fields mapped with Import as Additional Column, which is no longer supported. These mappings would default to Ignore."
		},
		"auto-detect": {
			"button-title": "Auto-Detect",
			"btn-tooltip": "This is to auto detect date format and decimal seperator",
			"modal-title": "Auto-Detect",
			"modal-notification": "The following fields: {{keys}} have date format and decimal seperator already selected. This process will overwrite them with date format and decimal seperator detected by the system. Do you wish to proceed?",
			"button-cancel": "Cancel",
			"button-proceed": "Proceed",
			"warning-title": "Warning Message",
			"success-title": "Success Message",
			"warning-info": "Omnia | Data finished the detection of the date formats and decimal seperators, and found one or more fields which the system could not detect. Review the source date format or source decimal separator next to the Source Data Field Name (Highlighted in Red) and update the date or decimal format manually.",
			"success-msg": "Omnia | Data finished the detection of the date formats and decimal seperators, and found all date formats and decimal seperators."
		}
	},
	"supported-file-size-validation-message": "The file that is being uploaded is larger than allowed size of {maxFileSize}. Navigate to SFTP to upload the data file for a faster upload. Note: You will need a Engagement Owner/Team Member role in order to perform this action.",
	labels: {
		"file-upload-step-creatinginput": "Creating Data Input...",
		"file-upload-step-uploadingfile": "Uploading File...",
		"file-upload-step-encryptingfile": "Encrypting File...",
		"file-upload-step-completing": "Completing Data Input..."
	},
	"sample-data-page": {
		title: "Sample Data",
		description: "Shown below are the recent samples that are created.",
		"fiscal-year": "FY "
	},
	"recent-samples": {
		title: "Recent Samples",
		"create-sample": "Create sample",
		"rename-sample": {
			header: "Rename Sample",
			label: "Sample Name",
			"character-limit": "{{characterLimit}} character limit"
		},
		"delete-sample": {
			header: "Delete Sample?",
			description: " The sample, {{sampleName}} will be deleted."
		}
	},
	"new-sample": {
		create: "Create sample",
		"sample-name": "Sample Name",
		"enter-name": "Enter the sample name",
		"choose-sampling-method": "Please select the sampling method:",
		"invalid-name": "Sample name is required",
		"choose-cdm-data": "Choose a Client Data",
		"choose-cortex-folder-data": "Choose an Unstructured Data File",
		"upload-data": "Upload File from Desktop",
		"invalid-data-source": "Data Source is required",
		title: "Create New Sample and Select Data",
		preferred: "Preferred",
		"client-data-cdm": {
			title: "CLIENT DATA STORED IN CDM",
			description: "The golden data stored in the Common Data Model."
		},
		"raw-data-cortex": {
			title: "UNSTRUCTURED DATA FILE IN OMNIA | DATA FOLDER",
			description: "Unmapped data that is already in Omnia | Data but not assigned to a CDM."
		},
		"file-from-desktop": {
			title: "USE FILE FROM DESKTOP",
			description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."
		}
	},
	"data-settings-content": {
		buttons: {
			"view-more-cards": "View More"
		}
	},
	"cdm-data-preview": {
		rowscount: " rows",
		seprator: " | ",
		columncount: " columns"
	},
	"data-inbox": {
		"data-inbox-title": "Data Inbox",
		"files-number": "{{number}} Files",
		"trifacta-data-request-input-filename-column-error-message": "This request could not be processed further as it uses Trifacta based transformation steps. Please recreate the data request",
		"filename-column": "File Name",
		"cdm-column": "Table Category",
		"dm-column": "Table Name",
		"data-source-column": "Data Source",
		"uploaded-on-column": "Uploaded On",
		"status-column": "Status",
		"not-yet-mapped": "not yet mapped",
		"no-data-message-inbox": "You’re all caught up. There are no new data files that require your attention at the moment.",
		"inputs-selected": "Files Selected",
		"file-search": "Search File",
		"hide-inputs": {
			title: "Hide File(s)",
			description: "The selected file(s) will be not be shown in the Data Inbox. However, the file will still remain in the data store and can be accessed by navigating to the \"Existing File\" section when you select a data file for uploading. Do you want to proceed?",
			"success-confirmation": "The file(s): {files} are successfully hidden from the Data Inbox."
		},
		"get-inputs-errors": {
			"text-InitialLoadError": "Failed to fetch Data Inbox content. Please refresh the page or contact support.",
			"text-UpdateInputsError": "Data Inbox content is not in sync. Please refresh the page or contact support."
		},
		"data-inbox-status": {
			Ready: "User Input Needed",
			UploadInProgress: "Upload in progress",
			UploadError: "Error",
			MappingInProgress: "Field Mapping in Progress",
			DataModelUploadInProgress: "Upload to the standard table in progress",
			Complete: "Complete",
			SplitInProgress: "Splitting of the data file is in progress",
			DicInProgress: "Data Integrity Checks Execution is in progress",
			DataRequestInProgress: "Data Request is in progress",
			DataRequestNotSubmitted: "Data Request not submitted",
			DataRequestFailed: "Data Request failed",
			DataRequestComplete: "Data Request completed",
			DicCompleteWithWarning: "Data Integrity Checks Execution Completed with Some Findings",
			DicCompleteWithError: "Data Integrity Checks Execution Completed with Some Findings",
			DicCompleteWithObservation: "Data Integrity Checks Execution Completed with Some Findings",
			DicReportGenerationInProgress: "DIC Report Generation In Progess",
			DicComplete: "Data Integrity Checks Execution Completed Successfully",
			DicNotAvailable: "DIC Not Available",
			DicFailure: "Data Integrity Checks Failed",
			FieldMappingFailed: "Error: Field Mapping Failed",
			AwaitingDataRequest: "Awaiting Data Request",
			AwaitingAuditorApproval: "Awaiting Auditor Approval",
			AwaitingExtractionApproval: "Awaiting Extraction Approval",
			AwaitingTransferApproval: "Awaiting Transfer Approval",
			NoRecordsFound: "No Records Found",
			ErrorRequestFailed: "Error: Request Failed",
			ParametersInputNeeded: "Parameter Input Needed",
			TransformationIsInProgress: "Transformation is in progress",
			JudgementPending: "Judgement Required Pending",
			NoDataFound: "No data found",
			TransformationFailed: "Transformation is failed",
			TransformationCompleted: "Transformation is completed",
			CsvConversionInProgress: "CSV conversion is in progress",
			SplitXlsxError: "Error splitting the XLSX file",
			DatasetAppendFailed: "Error: Append failed",
			RetryDataKitchenAutomationWorkflowExecution: "Error: Automapping failure.",
			DataModelUploadError: "Error: Data Model upload failure",
			CompletedWithErrors: "Completed with Errors",
			RawUploadComplete: "Raw Upload Completed",
			RawUploadInProgress: "Raw Upload In Progress",
			Retry: "Retry"
		}
	},
	"data-request-page": {
		title: "Request Data",
		"button-cancel": "Cancel",
		"button-proceed": "Proceed",
		"create-request-form": {
			"title-page": "CREATE DATA REQUEST",
			desc: "Download Data Request Form or Data Request Input Form for collecting client data in Omnia | Data format.",
			"failed-download-message": "Failed to download the Request Form. Please try again."
		},
		"use-secure-agent": {
			title: "USE AUTOMATED EXTRACTION",
			description: "Generate and/or schedule extraction requests using Automated Extraction.",
			"extraction-with-transformation": "CONNECT AUTOMATED EXTRACTION TO DATAMODEL",
			"select-data-model": "Select Data Model",
			"select-data-model-description": "Select a data model to which the extracted data will be mapped",
			"select-dataModel": "Select",
			"loading-results": "Loading results..",
			"datamodel-label": "Datamodel",
			"bundle-label": "Bundle",
			"datamodel-header": "Datamodel Name",
			"select-data-model-tb-description": "Below standard tables are also part of the subsequent outputs. Please select the desired standard tables to include them as a part of the data request."
		},
		"access-data-staging": {
			title: "ACCESS DATA STAGING",
			description: "View the complete list of datasets within this engagement."
		},
		"request-centralized-source-data": {
			title: "Request Data From Centralized Data Source",
			description: "Pull data related to this engagement from a Omnia | Data centralized data repository.",
			"engagement-request-title": "Engagement Request",
			"engagement-request-description": "To check and select the datasets you want to request to your provider, please fill the next filters.",
			"centralized-datasets-filter-title": "Centralized Dataset",
			"select-dataModel": "Select",
			"loading-results": "Loading results..",
			"date-range-filter-title": "Date Range",
			"date-range-filter-start-date-label": "Start Date",
			"date-range-filter-end-date-label": "End Date",
			"date-range-filter-placeholder": "MM/DD/YYYY",
			"button-request-datasets": "Request Datasets",
			"dataset-header": "Datasets",
			"description-header": "Description",
			"num-datasets-selected": "Selected",
			"engagement-request-datasets-title": "Datasets",
			"engagement-request-datasets-note": "Select the datasets you want to request.",
			"cortex-engagement-mapping-not-found": "No mapping found",
			"common-datamodel-mapped-dms-not-found": "No Datasets found in the repository",
			"engagement-request-success-note": "Datasets successfully requested from the centralized data source. See Data Inbox below.",
			"additonal-filters-placeholder": "Input text",
			required_additional_filter: "is required",
			number_filter_validation_msg: "Please enter numbers only",
			centralized_datasets_not_available: "Centralized Dataset is currently not available"
		},
		messages: {
			httpresponse_error: "Oops! Something went wrong. Please try again."
		}
	},
	"client-cdm-tables": {
		"data-tables-title": "Data Tables",
		"description-cdm": "This section shows the summary of the data that was uploaded to the standard tables.",
		"no-data-message-cdm": "Your team has not yet saved any data to the standard tables within this engagement. When the standard tables are saved with the data, they will begin to appear here.",
		"cdm-name": "Table Category",
		"dm-name": "Table Name",
		"last-updated": "Last Updated",
		"table-search": "Search Table",
		"row-count": "Record Count",
		"data-table-reminder": "Reminder",
		"data-table-info-msg1-1": "The recent release of Omnia | Data includes enhancements to data upload rules which now allow for the inclusion of new special characters (i.e., non alpha-numeric characters). If certain special characters exist in your data set, it may impact the comparability of data used in Management Override – Journal Entry (JE) testing and analytics performed in Omnia | Data.  For further assistance or questions, ",
		"data-table-info-msg1-2": ", or contact your Data Analytics Specialist to assist in the team's assessment of the impact of this enhancement.",
		"data-table-info-msg1-3": "The recent release of Omnia | Data also requires values in the financial statement category field in the Chart of Accounts (COA) standard data table to be plural (e.g., Assets, Liabilities). It is recommended to upload a new COA standard data table and select \"Replace existing data\". Do not use \"Add new data\" or \"Replace only a subset of existing data.\""
	},
	"client-data-details": {
		constants: {
			"filepath-split-index": "dev"
		},
		alerts: {
			"file-download-error-prefix": "Download Unsuccessful",
			"file-download-error": "Error downloading the file, please  try again."
		},
		"client-data-preview": {
			title: "Preview Data",
			"overview-prefix": "Note:",
			"overview-record-count-message": "Data preview only shows the first 100 records out of {totalRowCount} records. To see all data, click download.",
			"centralized-overview-row-cnt-message": "Data preview only shows the first 100 rows out of {totalRowCount} rows.",
			"large-dataset-download-notification": "Reminder! Opening this file in Excel will limit display to 1 million records.",
			buttons: {
				"download-data": "Download Data"
			}
		},
		"source-to-table-mapping": {
			"source-data-field-name": "Source Data Field Name",
			"source-date-decimal-format": "Source Date/Decimal Format",
			"standard-table-field-name": "Standard Table Field Name",
			title: "Source to Standard Field Mapping",
			description: "This section shows the mapping of the source data file field to the standard table field for the selected dataset/file that has been saved to the Standard Table.",
			"file-selector-title": "File Name",
			"no-data-message": "There's no data to be displayed here yet.",
			"file-selector-placeholder": "Select file",
			"append-subtitle": "Added new data on",
			"overwrite-subtitle": "Replaced existing data on",
			"replace-subtitle": "Partially replaced data on",
			"no-dic-report": "There are no Data Integrity Checks(DIC) report available for this file at the time when this file was uploaded",
			"view-filter-table": "View Applied Filters"
		},
		"applied-filter-modal": {
			title: "Filters Preview",
			Operations: "Operations",
			Field: "Field",
			Operator: "Operator",
			Value: "Value"
		}
	},
	"file-previewer": {
		title: "Data Preview",
		description: "This section shows a preview of 25 rows of the data file.",
		"cancel-btn": "Cancel",
		"submit-btn": "Proceed",
		"save-btn": "Save",
		"delimiter-field-label": "Delimiter field",
		"delimiter-info-tooltip": "Select the field delimiter relevant to this data file and verify the preview before proceeding to the subsequent steps.",
		"custom-delimiter-label": "Custom Delimiter",
		"sheet-list-label": "Current sheet",
		"preview-row-count-pre-text": "This section shows",
		"preview-row-count-mid-text": "records that matched the filter criteria out of",
		"preview-row-count-post-text": "records present in the Standard Table.",
		"preview-data-count": "This section shows a preview of {{totalRowCount}} rows of the data file",
		"header-selection": {
			"note-to-user-csv": "Data field would be mapped, as per header row and columns selection",
			"row-selector-label": "Select Header Row",
			"column-selector-label": "Select Start Column",
			"apply-header": "Apply",
			"note-to-user-prefix": "NOTE:",
			"note-to-user-xlsx": "This is a preview (max. 25 rows) subset of actual data uploaded. For single sheet xslx, the header selection is not allowed and the first row will be considered as a default header",
			"row-selector-error": "Incorrect cell value for header row selection. Please input row value which is within the preview window to proceed",
			"preview-info-note": "This is a preview (max. 25 rows), subset of actual data uploaded, mapping may vary as per header row selection.",
			"selected-columns-header-info": "columns are identified,",
			"selected-header-row-info": "is a header row"
		}
	},
	"input-parameter": {
		"modal-header": "Parameters",
		"text-secondary": "Please input the required parameter value(s) to continue.",
		"modal-button-cancel": "Cancel",
		"modal-button-save": "Save",
		"count-of-rows": "row(s) displayed",
		"table-column-name": "Name",
		"table-column-value": "Value",
		"read-more-text": "Read more",
		"read-less-text": "Read less",
		fetchParametersError: "Failed to fetch input paramaters. Please try again or contact support.",
		serParametersError: "Failed to save input paramaters. Please try again or contact support."
	},
	"transformation-in-progress-modal": {
		label: {
			header: "Transformation Status",
			"sub-header": "Below Progress bar depicts the latest status of transformations.",
			"progress-status": {
				notStarted: "Not Started",
				waiting: "Waiting",
				unknown: "Unknown",
				reset: "Reset",
				queued: "Queued",
				running: "Running",
				finished: "Finished",
				error: "Error",
				canceled: "Canceled",
				failedExecution: "Failed Execution",
				failedSubmission: "Failed Submission"
			}
		},
		error: {
			"status-get-error": "Failed to fetch transformation status. Please try again."
		}
	},
	"transformation-failed": {
		button: {
			Run_Databricks_Notebook: "Run Databricks Notebook"
		},
		text: {
			header: "Transformation Failed",
			description: "Do you want to re-trigger the databricks notebook execution ?"
		}
	},
	"data-request-failed": {
		button: {
			retry: "Retry",
			cancel: "Cancel",
			"back-to-datakitchen": "Back To Data Kitchen"
		},
		label: {
			"retry-header": "Data Request Failed",
			"retry-sub-header": "Data Transfer is complete, Consolidation of the data files failed. Please click on re-try to re-trigger the consolidation of the files and proceed."
		},
		error: {
			"dr-status-error": "Failed to get data request status"
		}
	},
	"dm-schema-dialog": {
		serach: "Search",
		"no-data-text": "No Results Found",
		"no-record-text": "No Records Found",
		"note-prefix": "NOTE:",
		"note-desc": "The below table represents the details of the selected standard table including the names, attributes and indication of which fields are required."
	},
	"xls-file-previewer": {
		title: "Data Preview",
		desc: "For new header row selection, file needs to be converted to csv type.",
		"sub-description": "You will now be re-directed to the data kitchen home screen while the csv conversion is in progress. The file will be shown in the Data Inbox section.",
		"proceed-text": "Do you want to proceed?",
		"cancel-btn": "Cancel",
		"submit-btn": "Proceed"
	},
	"upload-file-action-container": {
		"header-info-note": "By default, the first record in the uploaded data will be treated as the header row. In order to select a custom header row, click on the 'PREVIEW' and follow the on-screen guidance.",
		"note-prefix": "NOTE",
		"reconcile-data-reminder": "The auditor is responsible for evaluating that the data uploaded to Omnia | Data reconciles to the appropriate source (e.g., the financial statements).  Documentation of the reconciliation is maintained in the audit file.",
		"reminder-prefix": "REMINDER"
	}
},
	"content-library": {
	"back-to-data-model": "Back to Data Model",
	"data-integrity-check": {
		title: "Add Data Integrity Checks",
		desc: "This process allows you to add and validate Data Integrity Checks on data model '{dataModelName}'.",
		"select-sample-data-accordion": {
			title: "Step 1 : Select Data Integrity Checks and Sample Dataset",
			description: "Select or upload your Data Integrity Check along with your Sample Dataset to proceed.",
			"file-uploader": {
				"btn-message": "Please upload Data Integrity Checks in JSON file and Sample Dataset in CSV file format.\n Only one of each file format should be uploaded.",
				primary: "Drag and drop files here",
				alt: "or import files from:",
				"json-mismatch-validation": "The uploaded Data Integrity Checks file (*.JSON) has references to the fields '{fields}' that are not present in the data model. Please correct the file and re-try",
				"invalid-json": "Incorrect JSON file. Please try uploading a valid file",
				"invalid-csv": "The field names '{fields}' of the data model are missing in the uploaded sample dataset (.csv file). Please upload the correct dataset and re-try.",
				"no-data": "No data found",
				"invalid-files": "Invalid files received.",
				"replace-file": "You are about to replace a previously uploaded file. This will reset any validations that are already run in the past. Do you wish to continue?",
				button: {
					back: "Back",
					upload: "Upload",
					"upload-source": "My Computer",
					"existing-file": "Existing File"
				},
				"file-status-done": "Uploaded",
				"delete": {
					title: "Are you sure you want to delete '{fileName}'?",
					message: "Please note that deleting the file will also delete any validations that were executed on the sample data set.",
					"title-delete-toaster": "File Deleted",
					"message-delete-toaster": "File has been deleted successfully."
				},
				download: {
					"download-toaster-title": "File Downloaded",
					"download-toaster-message": "File has been downloaded successfully."
				}
			},
			"warning-msg": "The Data Model has undergone changes and the DIC's may be out of date / obsolete. To ensure completeness, update the linked Data Integrity checks as per latest Data Model.",
			"warning-prefix": "Warning"
		},
		"validation-result-accordion": {
			title: "Step 2 : Validation Results",
			description: "Run the validations to view the results below.",
			"run-validation-disabled": "Conversion of the input file to parquet format is in progress. Run validations button will be enabled once the parquet file is generated.",
			csv_pqt_conversion_failed: "Conversion of the input file to parquet format has failed. Please reupload the file and try again.",
			"download-consolidated-report": "Download Consolidated Report",
			"validation-in-progress-download-report-tooltip": "The Data Integrity Check summary report generation is currently in progress",
			"validation-in-progress-view-details-tooltip": "Preview of Data Integrity Matters generation is currently in progress",
			buttons: {
				"run-validations": "Run Validations"
			},
			download: {
				"download-toaster-title": "File Downloaded",
				"download-toaster-message": "File has been downloaded successfully."
			},
			"learn-more": "LEARN MORE",
			"preview-data-integrity-matters": "PREVIEW DATA INTEGRITY MATTERS",
			"pending-status": "Checking for affected records",
			legend: "Legend",
			success: "No Data Integrity Matters Identified",
			error: "Error",
			warning: "Warning",
			observation: "Observation",
			err_type: "Type: ",
			err_title: "Title: ",
			err_details: "Details: ",
			search_box_placeholder: "Search Table",
			desc: "Description: ",
			modal: {
				"missing-values": "Missing Values",
				"data-integrity-check": "Data Integrity Check",
				"this-is": "this is",
				type: "type",
				"records-affected": "records affected",
				details: "Details",
				"exception-message": "An exception has occurred due to which this data integrity check could not be executed on all records of the column."
			},
			"dic-data-preview": {
				rowscount: " rows",
				seprator: " | ",
				columncount: " columns",
				"table-subheading": "NOTE: This preview shows a maximum of 500 records and might not include all the Data Integrity Matters flagged. In order to see the entire list of findings, examine the data file that has been uploaded to the data kitchen or contact Data Specialists team for assistance."
			},
			"run-validation-msgs": {
				"pending-message": "Data Integrity Checks validation is in progress, please check after some time. To abort the ongoing Data Integrity Checks execution, Please click ",
				"job-progress-updated-message": " Preview of Data Integrity Matters and Data Integrity Check summary report generation in progress.",
				"job-completed-message": "Execution of Data Integrity Checks completed. Please see the results below.",
				"completed-message": " Data Integrity Checks validation is completed. ",
				"error-message": "Data Integrity Checks validation is completed with errors. Please solve the issues to continue.",
				"warning-message": "Data Integrity Checks validation is completed with warnings or observations. You still can proceed.",
				"failure-message": "An error occurred during the execution of the Data Integrity Checks. Please try again. ",
				"view-details": "Click here for details",
				here: "here",
				"cancel-api-message": "The Data Integrity Checks execution is aborted. To start over, click on Run Validations.",
				"validation-incomplete-message": "Data Integrity Checks validation could not be completed for all Data Integrity Checks. Check the data for the referenced column and re-upload your data."
			},
			"status-text": {
				"step-1-job-prefix": "Step 1 of 3 ",
				"step-2-job-prefix": "Step 2 of 3 ",
				"step-3-job-prefix": "Step 3 of 3 ",
				"pending-prefix": "Status Message",
				"completed-prefix": "Status Message",
				"warning-prefix": "Warning issues detected",
				"error-prefix": "Multiple issues detected",
				"failure-prefix": "Run Validations failed",
				"cancel-prefix": "Run Validations canceled"
			},
			"result-records": "{unexpectedCount} Data Integrity Matters Flagged",
			"re-run-validation-msg": "The Data Integrity Check Validations are in progress. Re-running the validations will cancel the previous run and start over. Do you wish to continue?"
		},
		"add-dic-to-dm-accordion": {
			title: "Step 3: Add Data Integrity Checks To The Data Model",
			description: "This section allows you to save the Data Integrity checks to the data model.",
			"add-dic-to-dm-btn": "Add DIC to Data Model",
			"confirm-message": "The Data Integrity checks have been successfully added to the data model '{dataModelName}'.",
			"error-prefix": "Add Data Integrity Checks To The Data Model failed"
		}
	}
},
	"sample-details-page": {
	"last-updated": "Last Updated On: {{date}}",
	"last-extracted": "Last Extracted On: {{date}}",
	"sample-page-summary": "This screen enables the user to analyze the data, determine sample sizes, stratify the population, and extract the samples. User can then download the sampling output and/or the filled TOD template.",
	"data-source": {
		title: "Data",
		"data-profiling-title": "Data Profiling",
		"data-model": "Data",
		"data-model-title": "Data Model",
		header: "Data",
		reconciled: "Reconciled to TB?",
		"last-update": "Last Updated",
		"replace-data-file": "Replace Data File",
		select: "Select Data",
		"empty-data-source": "No data source mapped, you can select one in ",
		"replace-data-warning-msg": "All data corresponding to data analysis, determine minimum samples and stratification will be erased once you replace the data file.",
		"confirm-warning": "Confirm",
		"cancel-warning": "Cancel"
	},
	"data-source-modal": {
		"cdm-option-title": "Data file stored in CDM",
		"cdm-option-description": "The golden data stored in the Common Data Model.",
		"cdm-option-empty-data-sources": "There are no data in CDM.",
		"cortex-folder-option-title": "Unstructured data file not stored in CDM",
		"cortex-folder-option-description": "Unmapped data that is already in Omnia | Data but not assigned to a CDM.",
		"upload-file-option": "Upload New Data",
		"upload-file-option-description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
		"source-not-ready": "Data source is not ready for Sampling"
	},
	"sampling-error": {
		generic: "Something went wrong. Please try again.",
		"summary-failed": "Failed to generate a sample size.",
		"output-failed-message": "Failed to extract sample.",
		"data-analysis-failed": "Failed to generate a data analysis.",
		DatabricksLibraryNotPresentException: "Requested data is not available in the system.",
		DatabricksTokenException: "You are not authorized to perform this operation.",
		DatabricksClusterTerminatedException: "Backend Job execution has stopped.",
		DatabricksQuotaExceededException: "Backend Job execution has stopped.",
		DatabricksClusterRestartedException: "Backend Job execution has stopped.",
		DatabricksNotebookNotFoundException: "Requested data is not available in the system.",
		DatabricksDriverTimeoutException: " Your request has timed out. Please try again later.",
		BadRequestException: "Something went wrong. Please try again later.",
		DatabricksJobRunFailedException: "Something went wrong. Please try again later.",
		DWWTemplateNotFoundException: "Requested TOD template is not available in the system.",
		LimitExceededException: "Failed to extract samples due to number of rows exceeding the maximum limit i.e 25000.",
		amount_column_invalid_values: "The amount column selected does not have any valid inputs."
	},
	"set-up-section": {
		"section-title": "Determine the sample size",
		select: "Select",
		"input-fields-message": "Input the sample parameters",
		"entity-param": "Entity",
		"entity-param-strata": "Entity Name Value",
		"entity-param-value": "Value",
		entities: {
			title: "Entities",
			"warning-message": "Entity group has been modified. Re-selecting the Entity group will re-trigger the sample size with updated selection."
		},
		"all-entities": "All entities",
		"no-entities": "No entities",
		"single-entity": "Single Entity",
		"stratify-by": "Stratify By",
		"stratify-amount-type": "Amount Type",
		stratification: "Stratification",
		"duplicate-strata-name": "Name already exists. Please modify and continue.",
		"duplicate-strata-name-msg": "Text selection already exists. Please modify and continue.",
		"duplicate-strata-amount": "Given Strata already exist. Please modify and continue.",
		"exceeds-strata-allocation-percentage": "Allocation percentage has exceeded 100%.",
		"amount-negative-value-error": "Negative values can't be accepted.",
		"duplicate-strata-entity": "Entity selection already exists. Please modify and continue.",
		"duplicate-strata-date": "Selected date range is overlapping with the date range of other stratas. Please modify and continue.",
		"column-value-error": "Column Value Error",
		"column-edit-info-txt": "Note: You have updated this strata. Click on Create strata to confirm your updates.",
		"invalid-text-column-message": "There were no records present in the filtered data for the Text String you entered. Please consider changing the Text string or delete the strata to proceed further.",
		"invalid-date-column-message": "There were no records present in the filtered data for the Date Range you entered. Please consider changing the Date Range or delete the strata to proceed further.",
		"invalid-entity-column-message": "There were no records present in the filtered data for the Entity name you entered. Please consider changing the Entity Name or delete the strata to proceed further.",
		"invalid-amount-column-msg": "There were no records present in the filtered data for the Amount Range you entered. Please consider changing the Amount Range or delete the strata to proceed further.",
		"invalid-sample-size-message": "The allocated sample size is more than the population. Hence, you have to update the allocation percentage.",
		"idle-time-out-info-msg": "The processing of the results is taking longer than expected due to the volume of data being processed.",
		"prefix-Infomessage": "Informational Message",
		"amount-column-message": "Please note that the amount column selected should have data with a single currency",
		"time-period-param": {
			title: "Time Period",
			"start-date": "Start Date",
			"max-date-error": "Start date can't be above end date",
			"end-date": "End Date",
			"min-date-error": "End date can't be below start date"
		},
		"stratification-allocation-change-pop-up-title": "Are you sure you want to change the allocation percentage?",
		"stratification-edit-pop-up-title-txt": "You have updated the strata conditions. The sample will be updated with following changes",
		"Stratification-edit-pop-up-msOne": "All strata result will be regenerated based on the recently updated conditions.",
		"Stratification-edit-pop-up-msTwo": "Unallocated Population will be updated to reflect the changes made.",
		"Stratification-edit-pop-up-msThree": "Strata created of unallocated population will be deleted.",
		"Stratification-edit-pop-up-msFour": "Allocation percentages and samples size generated for each strata will be erased.",
		"stratification-allocation-change-pop-up-confirm-message": "The allocated percentages and Sample size will be erased for all strata's.",
		"sampling-method-param": {
			title: "Sampling Method",
			mus: "MUS"
		},
		"amount-column-param": "Amount Column",
		"date-column-param": "Date Column",
		"column-param": "Column",
		"performance-param": {
			title: "Performance Materiality",
			placeholder: "0.00",
			"pm-tooltip-message": "Please ensure the performance materiality input value entered to be a whole number and doesn't exceed more than 16 digits."
		},
		"select-all-items-above-param": "Select all items above Performance Materiality",
		"sample-size-small-population": "Sample size for small population",
		"yes-no-options": {
			"option-yes": "Yes",
			"option-no": "No"
		},
		"assessed-risk-param": {
			title: "Assessed Risk",
			"option-significant": "Significant",
			"option-higher": "Higher",
			"option-lower": "Lower"
		},
		"control-reliance-param": {
			title: "Control Reliance approach",
			"option-reliant": "With control reliance",
			"option-non-reliant": "Without control reliance"
		},
		"type-items-param": {
			title: "Type of Items",
			"option-all-values": "All items (absolute values)",
			"option-positive-values": "Positive value items",
			"option-negative-values": "Negative value items"
		},
		"stratify-data-param": "Stratify Data",
		"stratify-start-value": "Start Value",
		"stratify-end-value": "End Value",
		"stratify-amount-start-value-error": "Start value can't be above end value.",
		"stratify-equal-value-error": "Start value and end value can't be equal.",
		"stratify-amount-end-value-error": "End value can't be below start value.",
		"stratify-unallocated-population": "UNALLOCATED Population",
		"stratify-unallocated-percentage": "UNALLOCATED Percentage: ",
		"stratify-add-new-strata": "Add New Strata",
		"strata-name-edit-placeholder": "Give this strata a name..",
		"strata-delete-title": "Delete",
		"strata-delete-desc": "Are you sure you want to delete the Strata ?",
		"strata-allocation-percentage": "Allocation Percentage",
		"strata-sample-size": "Sample Size",
		note: "Note: ",
		"note-text-delete-alert": "Note: This will delete data corresponding to all the strata's, population results and samples allocated. Please click on Create Strata button to regenerate the strata results.",
		"stratify-change-error_message": "This selection cannot be changed. Please remove all the other stratas to continue.",
		"stratify-sample-size-error_message": "The sum of the allocated samples is greater than the total number of samples.",
		"stratify-selected-population-zero_message": "One or More strata's contain Zero population. Please delete/update the strata to proceed forward.",
		"determine-minimum-sample-size-button": "Determine Minimum Sample Size",
		"analyze-data-button": "Analyze Data",
		"profile-data-accordion-title": "Data Analysis",
		warning: {
			"modified-entity-group": "Entity group has been modified after generating the sample size. Re-selecting the Entity group will re-trigger the sample size with updated selection.",
			"unstructured-data": "This feature is limited for unstructured data files."
		},
		"strata-result-summary": {
			"population-results": "Population Results",
			"total-population-count": "Total Population Count: ",
			"total-population-value": "Total Population Value: ",
			"number-of-remaining-items": "Number of remaining items: ",
			"number-of-remaining-items-in-random": "Number of items including items over performance materiality: ",
			"value-of-remaining-items-in-population": "Value of remaining items in population: ",
			"absolute-values": "Absolute Values: ",
			"positive-value-items": "Positive Value Items: ",
			"negative-value-items": "Negative Value Items: ",
			"value-of-net-population": "Net Population subject to sampling: ",
			"value-remaining-items-population": "Value of remaining items in population: ",
			"value-items-above-performance-materiality": "Value of items above performance materiality: ",
			"number-items-above-performance-materiality": "Number of items above performance materiality: ",
			"no-records-display": "No Records to display"
		}
	},
	"sample-results": {
		"results-title": "Sample Results",
		"sample-summary-empty": "Input parameters and click Determine minimum sample size button above in order to populate sample results.",
		"results-subtitle": "Sample Results",
		"data-analysis-results-title": "Results",
		"data-analysis-empty-data": "No data to display",
		"filtered-results": "Filtered Results",
		"before-applying-filters": "Before Applying Filters",
		"total-population-count": "Total Population Count: ",
		"total-population-value": "Total Population Value: ",
		"net-population-subject-sampling": "Net Population subject to sampling: ",
		"absolute-values": "Absolute Values: ",
		"positive-value-items": "Positive Value Items: ",
		"negative-value-items": "Negative Value Items: ",
		"total-samples": "Total Number of Samples: ",
		"updated-status": "Updated",
		"total-number-samples-invalid": "Invalid value, must be a number greater than {{val}}",
		"minimum-sample-size": "Minimum sample size: ",
		"maximum-size": "Maximum Size",
		seed: "Seed for Sampling: ",
		"population-use-for-sample": "Net population subject to sampling: ",
		"selected-population": "Selected Population: ",
		"selected-population-value": "Selected Population Value: ",
		"show-visualizations": "Show Visualizations",
		"hide-visualizations": "Hide Visualizations",
		"samples-bar-title": "Samples",
		"samples-bar-description": "Number of Samples",
		"high-value-bar-title": "Proportion of High Value Items to Population",
		"high-value-bar-description": "Number of High Value Items:",
		"hvi-bar-title": "Proportion of Value of HVI to Population",
		"hvi-bar-description": "Value of High Value Items:",
		"number-of-high-value-items-title": "Number of items above Performance Materiality:",
		"value-of-high-value-items-title": "Value of items above Performance Materiality:",
		"value-of-remaining-population-title": "Value of remaining items in population:"
	},
	"sample-disaggregation": {
		"toggle-text": "Disaggregate Data",
		"disaggregation-criteria": "Disaggregate Data Criteria:",
		"no-criteria": "There is no disaggregation criteria",
		"disaggregate-by": "Disaggregate By",
		amount: "Amount",
		date: "Date",
		"date-column": "Date Column",
		"amount-column": "Amount Column",
		"filter-value": "Value",
		"greater-than": "Greater Than",
		"less-than": "Lower Than",
		"add-criteria": "Add",
		"remove-criteria": "Remove",
		"remove-criteria-toggle-off": "On toggle off this, all disaggregation criteria will be removed",
		"filter-number-condition": "Condition",
		"start-date": "Start Date",
		"max-date-error": "Start date can't be above end date",
		"end-date": "End Date",
		"min-date-error": "End date can't be below start date",
		"no-criteria-tooltip-message": "There are no more criteria available",
		"filtered-proportion": "Proportion of Disaggregated Selection to Population",
		"filtered-selection": "Disaggregated Selection",
		"high-value-item": "High Value Items Within Disaggregated Selection",
		"total-population": "Total Population"
	},
	"sample-buttons": {
		"send-engagement-team": "Send to Engagement Team",
		"extract-sample": "Extract Sample",
		download: "Download",
		"create-strata": "Create Strata",
		"generate-samples-for-strata": "Generate Samples for Strata",
		"dww-tod-download": "Download TOD Template"
	},
	"warning-messages": {
		"prefix-message": "Parameter Warning",
		"total-population-warning": {
			message: "The population value is greater than 100 times the performance materiality value.",
			"view-details": "View details",
			"ok-button": "Ok",
			"detailed-message": "The sample size calculated by Omnia | Data is, when the population is less than 100x PM, the minimum sample size depicted in AAM Figures 23002-4.1 or 23003-4.2. If population is greater than 100x PM, Omnia | Data has calculated a sample size by interpolating on a straight line basis the largest suggested sample size in AAM Figure 23002-4.1 or 23002-4.2. In such circumstances, before proceeding with selecting the interpolated sample size, consider the guidance set forth in the application AAM 23002-4 which suggests that the engagement team evaluate whether to: \n\n(i) further disaggregate the population;\n(ii) utilize a profiling approach (as specifically described in Section 23002-4C, The Profiling Approach - Appendix C);\n(iii) combine substantive audit evidence obtained from sampling population with other substantive audit procedures;\n\nIn making the determination of the appropriate sample size, engagement teams may consider discussing with the EQCR as to testing approach."
		},
		"sample-size-lower-than-recommended": {
			message: "You have specified a sample size that is lower than the recommended sample size."
		},
		"totalPopulation-lower-than-totalSampleSize": {
			message: "You have specified a sample size that is higher than the total population count."
		}
	},
	"alert-messages": {
		"prefix-title": "Alert Message",
		"random-sample-notes": {
			message: "View the details on the population used for Random Sampling.",
			"view-details": "View details",
			"ok-button": "Ok",
			"detailed-message": "If there are items in the population that you plan to test 100% (e.g., items over performance materiality), separate such items from the population prior to performing the sample using the Random sampling method. Minimum sample size calculated by Omnia | Data when using Random Sampling, is based on the absolute value of all items in the population. Consider whether to disaggregate negative value items from the population and perform separate procedures."
		},
		"mus-sample-notes": {
			message: "View the details on the population used for MUS Sampling.",
			"detailed-descriptionMessage": "If there are items in the population that you plan to test 100% (e.g., items over performance materiality), separate such items from the population prior to performing the sample using the MUS sampling method. When the population contains negative value items, the minimum sample size calculated by Omnia | Data is based on the absolute value of all items in the population. Negative value items may be disaggregated from the population and separate procedures planned."
		}
	},
	"selected-population-warning-msg": "Stratification cannot be performed on this sample as the Remaining population size is zero.",
	"generate-sample-re-run-msg": "Please re-create strata for the updated sample size results"
},
	engagement: engagement,
	"external-engagement-manual-linking": {
	"engagement-linking": "Engagement Linking",
	description: "Within the engagement management linking you can view existing engagements you have access to and manually link one, if you don’t find the engagement you’re searching for you can try creating a new one below.",
	"existing-engagements": "Existing Engagements",
	"search-box-placeholder-text": "Search Client or Engagement Name",
	button: {
		"link-button": "Link Engagement"
	},
	confirmation: {
		"info-message-text": "Are you sure you want to manually link ",
		"auto-linking-info-message": "Are you sure you want to link the following engagements?",
		description: "You are about to link Omnia engagement to a Omnia | Data engagement. Please review the information below and confirm to proceed.",
		"omnia-app": "Omnia logo and name of the app",
		"cortex-app": "Omnia | Data",
		button: {
			cancel: "Cancel",
			confirm: "Yes, link engagement",
			"link-engagement": "Link Engagement",
			"without-linking": "Proceed Without Linking"
		},
		"auto-linking-failed": "Linking Failed.",
		"auto-linking-failed-description": "Your Engagement has failed to link. Please try again or contact support."
	},
	"mat-id-engagement-does-not-exist-warning": "We couldn’t find a MAT ID associated to the engagement.",
	"engagement-creation-in-progress": "Engagement creation in progress, please wait.",
	"manual-engagement-creation-failed": "There was an issue creating and linking your engagement in Omnia | Data. Please try again after sometime or contact support.",
	"engagement-linking-failed-error": "Apologies ! We seem to be having some trouble linking your engagement at the moment. Please try after sometime or contact your administrator. ",
	"invalid-token-error": "There was an issue linking the engagement. Please contact support if the issue persists.",
	"internal-server-error": "Apologies ! We seem to be having some trouble linking your engagement at the moment. Please try after sometime or contact your administrator.",
	"get-unlinked-engagements-error": "Omnia | Data is experiencing technical issues at the moment. Please try again after sometime or contact support.",
	"no-engagements-found": "No engagements found. You can create and link to a new Omnia | Data engagement by selecting the New Engagement button below.",
	"fiscal-year": "Fiscal Year",
	all: "All",
	"no-engagements-found-filter": "No engagements found.",
	client: "Client",
	country: "Country",
	year: "Year",
	"searching-for-engagement": "Searching for engagements, please wait."
},
	"external-engagement-request-access": {
	heading: "Engagement Linking",
	"sub-heading-text": "You are not a member of the Omnia | Data engagement ",
	"description-text": "You are not member of the engagement {{engagementName}} so you cannot link the engagements. ",
	"description-bold": "Would you like to request access from the engagement owner?",
	"engagement-name": "Engagement Name",
	"engagement-owner-name": "Engagement Owner Name",
	"engagement-owner-email": "Engagement Owner Email",
	button: {
		"request-access": "Request access",
		cancel: "Cancel"
	},
	success: {
		heading: "You need permission",
		"description-line-1": "Your request to access this engagement has been sent to the engagement owner.",
		"description-line-2": "Once your request has been approved you will be notified by email.",
		"description-text-line-3": "You can then re-initiate linking by going to Digital Audit Enablers of Omnia and clicking Omnia | Data.",
		"back-to-omnia-button": "Back to Omnia",
		"proceed-to-cortex-button": "Proceed to Omnia | Data"
	},
	failed: {
		heading: "Request to access this engagement failed.",
		description: "Your request to access this engagement could not be sent to the engagement owner(s). Please try again or contact support."
	},
	"page-error-line-1": "Omnia | Data is experiencing technical issues at the moment.",
	"page-error-line-2": "Please try again after sometime or contact support."
},
	client: client,
	"learning-library": {
	welcome: "Welcome to the learning library page."
},
	"specialist-tools": {
	welcome: "Welcome to the Specialist Tools page.",
	"specialist-tools-title": "Specialist Tools",
	"specialist-description": "Workflows define the inputs, transformations and outputs used in standard analytic templates. As a specialist, you can view existing workflows or perform advanced scripting here.",
	"search-placeholder": "Search workflows",
	"data-transformations-custom-tile": {
		header: "Data Transformations (Custom)",
		description: "Create a workflow to define all inputs, transformation and outputs."
	},
	"data-transformations-from-template-tile": {
		header: "Data Transformations (from Template)",
		description: "Select from a list of existing workflows and modify transformations to fit your needs"
	},
	"data-transformations-template-tile": {
		header: "Data Transformations (from Template)",
		desc: "Select from a list existing workflows and modify transformations to fit your needs."
	},
	"advanced-scripting-tile": {
		header: "Advanced Scripting",
		description: "Perform advanced data preparation operations using 'Databrick Notebooks' in 'Databricks'.",
		"confirm-message": "If you have a business need to perform advanced scripting using Databricks Notebooks, please select 'Request Access' to be granted access.",
		"request-access-btn": "Request Access",
		"acls-max-limit-title": "Important Note:",
		"acls-max-limit-message": "The Databricks Notebook user access limit has been met for this engagement. You will be able to access notebooks for this engagement and clone notebooks to other engagements, but you will not be able to excute any of the notebooks located in the {engagementName} folder within the Databricks Workspace."
	},
	"manage-pipelines": "Manage Pipelines",
	"manage-pipelines-desc": "Add or select a pipeline to flow data from one analytic to another.",
	"no-workflow-yet": "No Workflows Yet",
	"no-workflow-yet-description": "Some engagements may require custom workflows. When you add them, they will begin to appear here where you can work on them and track their progress.",
	"back-to-specialist-tools": "BACK TO SPECIALIST TOOLS",
	"data-transformations-custom": {
		title: "Data Transformations (Custom)",
		description: "New workflows will appear under \"In Progress\" in",
		"description-No": "New workflows will appear under \"Workflows In Progress\" in",
		"analytics-center": "Analytics Center",
		"workflow-name": "Name",
		"workflow-name-placeholder": "Enter name",
		"cancel-button": "Cancel",
		"add-workflow-button": "Add Workflow",
		"duplicate-workflow": "Workflow name already exists."
	},
	"data-transformations-template": {
		title: "Data Transformations (from Template)",
		description: "Select existing data transformations from the list below.",
		link: "Analytics Center",
		"select-type": {
			header: "Select before continuing",
			question: "Will you be using this as an analytic?",
			tooltip: "If used as an analytic, it will appear under \"In-Progress\" in the Analytics Center. If used as a workflow, it will appear under \"In-Progress\" in Specialist Tools.",
			"option-yes": "Yes",
			"option-no": "No"
		},
		"labels-conflict-workflow": "If there are duplicate Workflows added, all labels will be moved from original workflow to newly created workflow.",
		"labels-conflict-analytic": "If there are duplicate Analytics added, all labels will be moved from original analytic to newly created analytic",
		search: {
			placeholder: "Search workflows"
		},
		table: {
			title: "Select Existing Workflow to Tailor",
			name: {
				"column-name": "Workflow Name (Link to Audit Analytics Library)",
				link: "Link"
			},
			description: "Description",
			"last-modified": "Last Modified"
		},
		footer: {
			workflows: "Workflows",
			workflow: "Workflow",
			"add-workflows": "Add Workflows"
		}
	},
	"advanced-scripting": {
		breadcrumb: {
			home: "Specialist Tools",
			"advanced-scripting": "Advanced Scripting"
		},
		"cluster-monitoring": {
			table: {
				"cluster-name": "Cluster Name",
				"notebooks-attached": "Notebooks Attached",
				"memory-usage": "Memory Usage",
				"cluster-termination": "Cluster Termination",
				"time-since-last-termination": "Time Since Last Termination",
				header: "Available Clusters"
			},
			"recommended-cluster": "Recommended Cluster"
		}
	},
	"workflows-details": {
		workflows: "Workflows",
		"workflows-in-progress": "Workflows In-Progress",
		"workflows-completed": "Completed Workflows",
		name: "Name",
		type: "Type",
		"created-on-date": "Created On",
		"created-by-person": "Created By",
		"last-updated-on": "Last Updated On",
		"last-updated-by": "Last Updated By",
		"workpaper-source": "Workpaper Source",
		status: "Status",
		tailored: "Tailored",
		custom: "Custom",
		"table-pagination-label": "Showing {from} to {to} of {total} workflows",
		"in-progress-workflows-emtpy": "There are no workflows in progress for this engagement",
		"completed-workflows-emtpy": "There are no completed workflows for this engagement",
		rename: {
			header: "Rename Workflow",
			label: "Workflow Name",
			"char-limit": "{characterLimit} character limit",
			"character-limit": "{{characterLimit}} character limit"
		},
		"delete": {
			header: "Delete Workflow?",
			"workflow-description": " The workflow, {workflowName} will be deleted.",
			description: " The workflow, {{workflowName}} will be deleted."
		},
		"icon-tooltip": "A new version of this analytic is available."
	},
	"workflow-modal": {
		"close-button": "Specialist Tools",
		breadcrumb: "Specialist Tools"
	}
},
	analytics: analytics,
	"engagement-select-analytics": {
	title: "Select Analytics",
	description: "Shown below are the standard analytics. Filter or search by keyword to identify analytics that can be applied on your audit engagement. Click 'Learn More' to view the description and details of the analytic. Click 'Select' to add the analytic to your engagement.",
	"fiscal-year": "FY ",
	all: "All Analytics",
	select: "Select",
	deselect: "Deselect",
	"non-selectable-analytic": "This analytic is not yet available in platform. Please use the supplemental materials on the learn more page to complete this procedure.",
	footer: {
		analytic: "ANALYTIC ",
		analytics: "ANALYTICS ",
		selected: "SELECTED",
		"deselect-all": "DESELECT ALL",
		"review-and-confirm": "Review & Confirm"
	},
	filter: {
		title: "Filters",
		"clear-all": "Clear All",
		"clear-all-filters": "Clear All Filters",
		"selected-text": "Selected",
		"category-industry": "Industry",
		"category-financial-account": "Accounts",
		"category-audit-phase": "Audit Phase",
		"category-sector": "Sector",
		"category-suggested-by-gra": "Suggested by GRA",
		"suggested-by-gra-yes-chip": "Suggested by GRA",
		"suggested-by-gra-no-chip": "Not suggested by GRA",
		"category-type": "Type",
		"category-ready-for-testing-only": "Show 'Ready for Testing' only",
		"search-placeholder": "Search for Analytics"
	},
	"learn-more-modal": {
		"audit-phase": "Audit Phase",
		account: "Account",
		industry: "Industry",
		sector: "sector",
		suggested_by_gra: "Suggested by GRA",
		analytic_type: "Type",
		"select-analytic": "Select This Analytic",
		"deselect-analytic": "Deselect This Analytic",
		"details-section": {
			title: "Details",
			"no-details": "No details available."
		},
		"supplemental-materials-section": {
			title: "Supplemental Materials",
			"no-supplemental-materials": "No supplemental materials available."
		}
	}
},
	"engagement-review-and-confirm-analytics": {
	title: "Review & Confirm",
	description: "Shown below are the standard analytics selected. Review and confirm to add analytics to the engagement.",
	"fiscal-year": "FY ",
	"analytics-section": {
		title: "Selected Analytics",
		"section-description": "All these analytics will be added to the Analytics Center and will be available as soon as the data is added from the Data Kitchen.",
		"download-extraction-template-label": "Download Data Extraction Template",
		"extraction-template-file-label": "Consolidated Data Request Form.xlsx"
	},
	footer: {
		"back-text": "Back to analytic selection",
		"add-analytics": "Add analytics"
	},
	"learn-more-modal": {
		"remove-analytic": "Remove This Analytic"
	}
},
	"left-menu": {
	option: {
		home: "Home",
		"data-kitchen": "Data Kitchen",
		outputs: "Outputs",
		management: "Management",
		"learning-library": "Learning Library",
		"specialist-tools": "Specialist Tools",
		"open-navigation": "Open Navigation",
		"close-navigation": "Close Navigation"
	}
},
	login: login,
	"page-header": {
	"content-body": {
		title: "Support Contact Information",
		"deloitte-users": "Deloitte Users:",
		"deloitte-users-support-contact": "For support, please call 1-800-877-0145. For non-urgent support requests, submit a",
		"deloitte-users-support-ticket": "support ticket",
		"client-users": "Client Users:",
		"client-users-support-contact": "For support, please call 1-800-877-0145, or email",
		"client-users-contact-team": ", or contact your Deloitte Engagement Team.",
		content: "The support team is available 24/7. For urgent weekend support, please leave a voice mail and you will receive a response back in 1-2 hours."
	},
	about: {
		title: "About Omnia | Data",
		content: "Omnia | Data is up to date - Version"
	},
	"help-menu": {
		"option-0": "Guided tours",
		"option-1": "International learning resources",
		"option-2": "US learning resources",
		"option-3": "Contact Support",
		"option-4": "Deloitte Data Extraction Process - Terms of Use",
		"option-5": "Submit Enhancement Request",
		"option-6": "About Omnia | Data",
		"option-7": "Release notes"
	},
	"user-profile-menu": {
		option0: "Profile",
		option1: "Select Language",
		option2: "Log Out"
	}
},
	"user-notifications": {
	"delete-success-title": "Engagement deleted successfully",
	"delete-failure-title": "Engagement deletion failed",
	"delete-success-description": "The engagement “${engagementName}” has been deleted",
	"delete-failure-description": "The engagement “${engagementName}” has failed to delete. Please try again or ",
	"delete-failure-description-link": "contact support",
	"async-eng-creation-success-title": "Engagement Created",
	"async-eng-creation-success-description-1": "The engagement",
	"async-eng-creation-success-description-2": "has successfully been created.",
	"async-eng-creation-success-button": "Go to Engagement",
	"async-eng-creation-failure-title": "Engagement Creation Failed",
	"async-eng-creation-failure-description": "There was an error while creating the engagement",
	"async-eng-creation-failure-button": "Contact Support"
},
	"user-notification-toaster": {
	"method-name-of-notification": {
		"completion-state-of-notification": {
			title: "Failed to create new Engagement.",
			description: "The engagement “{{engagementName}}” failed to create. Please try again after some time or {{__link1__}}",
			link1: "Contact Support",
			button1: "Go to Engagement"
		}
	},
	"create-async-engagement": {
		failure: {
			title: "Engagement Creation Failed",
			description: "There was an error while creating the engagement “{{engagementName}}”.",
			button1: "Report issue"
		},
		success: {
			title: "Engagement Created",
			description: "The engagement “{{engagementName}}” has successfully been created.",
			button1: "Go to Engagement"
		}
	},
	"rollforward-engagement": {
		failure: {
			button1: "Report issue"
		}
	},
	"rename-engagement": {
		failure: {
			title: "Engagement couldn't be renamed.",
			description: "An engagement with the name “{{engagementName}}” already exists within the client “{{clientName}}”. Please choose a different name."
		}
	},
	"create-support-ticket": {
		failure: {
			title: "Issue not reported",
			description: "There was a problem creating the support ticket. The ticket can be manually created below, and the details previously entered can be pasted into the description box.",
			button1: "Contact Support"
		},
		success: {
			title: "Issue reported successfully",
			description: "A ticket has been opened for this issue in Service Now. You will receive an e-mail containing the ticket ID."
		}
	},
	"default-button-text": "CLICK ME",
	"something-went-wrong": "Oops! Something went wrong. Please try again."
},
	"create-support-ticket-modal": {
	title: "Would you like to report an issue?",
	description: "Please fill the information below to report the issue to our support team.",
	"subject-title": "Title",
	"detail-title": "Provide additional details",
	"detail-placeholder": "Write the details here.",
	"failed-title-create-async-engagement": "Engagement Creation Failed",
	"failed-title-rollforward-engagement": "Roll Forward Failed",
	urgency: "Urgency",
	"urgency-details": "Provide details about this urgency",
	urgencyVeryLow: "Very Low - 10 Business Days or Less",
	urgencyLow: "Low - 5 Business Days or Less",
	urgencyMedium: "Medium - 2 Business Days or Less"
},
	"analytics-progress": {
	"analytics-pagination-label": "Showing {from} to {to} of {total} analytics",
	"in-progress": "Analytics In-Progress",
	"new-to-oldest": "New to Oldest",
	"old-to-newest": "Old to Newest",
	filters: "FILTERS",
	"analytics-name": "Analytic Name",
	"time-period": "Time Period",
	"created-on": "Created On",
	"completed-on": "Completed On",
	status: "Status",
	analytics: " Analytics",
	"in-progress-sub-text": "Shown below are the standard, tailored, and custom analytics that are in-progress. Select an item below to continue your work.",
	type: "Type",
	completed: "Completed Analytics",
	"completed-sub-text": "Shown below are the standard, tailored, and custom analytics that are completed.",
	"delete-toaster-title": "Analytic Deleted",
	"delete-error-title": "Analytic Delete Failed",
	"delete-toaster-message": "The analytic, “{{currentAnalyticName}}” has been deleted.",
	"delete-error-message": "Failed to delete, “{{currentAnalyticName}}”. Please try again.",
	"duplicate-toaster-title": "Analytic Duplicated",
	"duplicate-error-title": "Analytic Duplicate Failed",
	"duplicate-toaster-message": "The analytic, “{{currentAnalyticName}}” has been duplicated.",
	"duplicate-error-message": "Failed to duplicated, “{{currentAnalyticName}}”. Please try again.",
	"rename-toaster-title": "Analytic Renamed",
	"rename-error-title": "Analytic Rename Failed",
	"rename-toaster-message": "The analytic “{{currentAnalyticName}}” has been renamed to “{{updatedAnalyticName}}”.",
	"rename-error-message": "Failed to rename, “{{currentAnalyticName}}”. Please try again.",
	"cloning-fails-error-message": "The analytic failed to add. You can delete this analytic or wait for it to be removed. Please try again or contact support if the issue persists.",
	"analytic-fails-cloning-error-message": "The JE testing analytic is not created successfully. Please create a new analytic to proceed further. If you continue to see this issue, please create a ServiceNow incident for the necessary assistance.",
	"analytic-fails-cloning-error-title": "Error"
},
	"engagement-details": {
	"fiscal-year": "FY "
},
	"engagement-home": {
	"trifacta-read-only-warning": "This analytic is in read-only state and cannot be edited due to the removal of Trifacta wrangler from Omnia | Data.  Any outputs that have been generated previously are still available below.  Please leverage current DataBricks based analytics for future analytics.",
	"audit-phases-section-title": "Omnia | Data helps automate the Deloitte Way. You can add, run and track the progress of analytics here.",
	"audit-phase-description": "Select here to choose standard analytics related to ",
	"analytics-center": "Analytics Center",
	"substantive-procedure": "Substantive Procedures",
	"risk-assessment": "Risk Assessment",
	"journal-entry-module": "Journal Entry Module",
	"recent-samples": {
		header: "Audit Samples",
		sample: "Sample",
		samples: "Samples",
		"create-sample": "Create sample",
		to: "to",
		"sample-with-same-sample-name-exists": "Sample with same sample name already exists",
		"rename-sample": {
			header: "Rename Sample",
			label: "Sample Name",
			"character-limit": "{{characterLimit}} character limit",
			success: {
				title: "Sample Renamed",
				message: "The sample “{{currentSampleName}}” has been renamed to “{{renamedSampleName}}”."
			},
			error: {
				title: "Rename failed",
				message: "The sample rename failed."
			}
		},
		"delete-sample": {
			header: "Delete Sample?",
			description: " The sample, {{sampleName}} will be deleted.",
			success: {
				title: "Sample Deleted",
				message: "The sample “{{currentSampleName}}” has been deleted."
			},
			error: {
				title: "Delete failed",
				message: "The sample deletion failed."
			}
		},
		table: {
			"data-file-column": "Data File",
			"sample-method-column": "Sampling Method",
			"pagination-label": "Showing {from} to {to} of {total} samples"
		}
	},
	"new-sample": {
		create: "Create sample",
		"sample-name": "Sample Name",
		"enter-name": "Enter the sample name",
		"invalid-name": "Sample name is required",
		"choose-cdm-data-file": "Choose a Data File stored in CDM",
		"choose-sampling-method": "Please select the sampling method:",
		"choose-cortex-unstructured-data-file-not-cdm": "Choose an Unstructured Data File not stored in CDM",
		"guidance-message-unstructured-duplicate-data-file": "Please ensure the selected file does not contain any duplicate column names.",
		"upload-data": "Upload File from Desktop",
		"invalid-data-source": "Data Source is required",
		header: "Create New Sample and Select Data File",
		preferred: "Preferred",
		"client-data-file-cdm": {
			"title-cdm": "DATA FILE STORED IN CDM"
		},
		"raw-data-file-cortex": {
			"option-title": "UNSTRUCTURED DATA FILE NOT STORED IN CDM",
			"select-file": "Select file",
			"change-file": "Change file"
		},
		"file-from-desktop": {
			title: "USE FILE FROM DESKTOP",
			description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."
		}
	},
	"sample-data-tile": {
		header: "Audit Sampling",
		"description-tile": "Select here to use audit sampling in performing audit procedures."
	},
	"sample-data-page": {
		header: "Audit Sampling",
		"description-page": "Shown below are the audit samples that have been created.",
		"fiscal-year": "FY "
	},
	"omnia-trial-balance-tile": {
		header1: "Trial Balance",
		"description-tile1": "Add a trial balance or chart of accounts to import trial balances into Omnia."
	},
	"info-tooltip": "A new version of this analytic is available. Clone the analytic from the analytic center to proceed with generating outputs."
},
	"file-preview": {
	"file-preview-xls-no-support": "File preview is not supported for legacy excel file (.xls)."
},
	"upload-existing-file": {
	title: "Select Existing Data",
	selectFileMessage: "Select a new existing file below to upload to the data kitchen. Only folders with uploadable files will be displayed.",
	previewFileMessage: "Preview your selected data file below, or replace the file with another you wish to upload.",
	cancelButtonLabel: "Cancel",
	backButtonLabel: "Cancel",
	selectFileButtonLabel: "Select File",
	importFileButtonLabel: "Import",
	delimeterLabel: "Delimiter Field",
	delimiterInfoTooltip: "Select the field delimiter relevant to this data file and verify the preview before proceeding to the subsequent steps.",
	sheetListLabel: "Sheet",
	customDelimiterLabel: "Custom Delimiter",
	previewDataTitle: "Preview Data",
	contentNotFoundLabel: "This folder does not contain any data files"
},
	"upload-new-data": {
	"warning-message": "To avoid canceling file upload, do not close the browser until file upload is complete.",
	"warning-message-prefix": "Upload in Progress",
	"warning-message-auto-mapping": "Note: Omnia | Data has automatically selected a data model to map this dataset to based on file naming.  Please review this selection before proceeding.",
	"dic-in-progress-warning": "Data Integrity Checks validation is currently in progress, please come back later if you wish to make any changes in this screen.",
	description: "Select files to upload to this engagement. To upload larger files, it is recommended to use SFTP.",
	"return": "BACK TO DATA KITCHEN",
	"view-table-structure": "View Table Structure",
	"info-activate-preview-inprogress": "Run preview is currently in progress, please check after some time.",
	"info-activate-preview-completed": "Run preview is currently completed.",
	"download-consolidated-report": "DATA INTEGRITY CHECK SUMMARY REPORT",
	"reminder-to-upload-type-of-data": "Important items to keep in mind while adding new data.",
	"reminder-to-upload-type-of-data-step1-1": "When making changes to the Chart of Accounts (COA) standard table (i.e., Common Data Model (CDM)), you are strongly encouraged to download COA data from CDM, revise the exported COA data as necessary, and “Replace existing data” after all changes are made. This is due to the recent changes where the Financial Statement Category (Account Subtype) requires pluralization. For further assistance or questions, ",
	"reminder-to-upload-type-of-data-step1-2": ", or contact your Data Analytics Specialist to assist in the team's assessment of the impact of this enhancement.",
	"reminder-to-upload-type-of-data-step2-1": "The recent release of Omnia | Data includes enhancements to data upload rules which now allow for the inclusion of new special characters (i.e., non alpha-numeric characters). If certain special characters exist in your data set, it may impact the comparability of data used in Management Override – Journal Entry (JE) testing and analytics performed in Omnia | Data.  For further assistance or questions, ",
	"reminder-to-upload-type-of-data-step2-2": ", or contact your Data Analytics Specialist to assist in the team's assessment of the impact of this enhancement.",
	"data-mapping-modal": {
		"modal-title": "Data Model Confirmation",
		"modal-button-cancel": "Cancel",
		"modal-button-proceed": "Proceed",
		"modal-message": "You are about to change the standard table. This action will reset the field mapping that has been done already for this source data file. Do you wish to proceed?"
	},
	"data-mapping-warning-modal": {
		"modal-title": "Confirmation Required",
		"modal-button-cancel": "Cancel",
		"modal-button-proceed": "Proceed",
		"modal-message-replace-existing-data": "You selected the option to 'Replace existing data'. This will erase all the existing data in the selected standard table and replace it with the uploaded dataset. Do you wish to proceed?",
		"modal-message-replace-subset-of-existing-data": "You selected the option to 'Replace only a subset of existing data'. This will replace the portion of your existing data based on the filters selected with new data. Unless a specific period is chosen in the date filter, this will replace all periods. Do you wish to proceed ?",
		"modal-message-replace-subset-of-existing-data-1": "You selected the option to 'Replace only a subset of existing data'. This will replace the portion of your existing data based on the filters selected with new data. Unless a specific period is chosen in the date filter, this will replace all periods. Do you wish to proceed ?"
	},
	button: {
		back: "Back",
		upload: "Upload",
		"upload-source": "My Computer",
		"existing-file": "Existing File",
		"add-new-custom-filter": "Add a New Custom Filter",
		"add-new-custom-filter-disable-text": "Maximun custom filters has been added",
		showAll: "Show All",
		showLess: "Show Less"
	},
	"value-constants": {
		"upload-action-overwrite": "OVERWRITE",
		"upload-action-append": "APPEND",
		"upload-action-replace-subset": "REPLACE"
	},
	labels: {
		"new-key": "NEW!",
		"contact-support": "contact support",
		"link-here-key": "here.",
		"st-table": "Which Standard Table are you mapping to? ",
		"upload-action-overwrite": "Replace existing data",
		"upload-action-overwrite-guidance-tooltip-text": "This option enables you to overwrite all the data (including prior year data) in the Standard Table with new data. Before using this option, it is important to verify that you want to delete and replace all existing current and prior year data with the new data being uploaded.",
		"upload-action-replace-subset-data": "Replace only a subset of existing data",
		"upload-action-replace-subset-data-guidance-tooltip-text": "This option enables you to overwrite only a portion of the data in the Standard Table based on filter criteria selected. Refer to the “Append data/Replace existing data/Replace subset of data in Standard Table” section within the Uploading and Mapping Quick Reference Guide for how to navigate the filters appropriately.",
		"upload-action-append-data": "Append data (add additional data to the existing dataset)",
		"upload-action-append-data-guidance-tooltip-text": "This option enables you to append (or add) data to the data already in the Standard Table.",
		select: "Select",
		"required-field-legend": "Required Fields",
		"required-field-symbol": "*",
		"save-mapping-progress-confirmation": "Field Mappings have been saved successfully",
		"client-erp-system": "Client ERP System",
		"select-filters-heading": "Select Filters",
		"select-filters-subHeading": "Select and fill atleast one filter for replacing a subset of existing data",
		"filter-date-range": "Date Range",
		"filter-custom-date-range": "Custom date range(s)",
		"filter-custom-from-date-range": "Start Date",
		"filter-custom-to-date-range": "End Date",
		"filter-custom-date-range-date-format-invalid-message": "Please enter a valid Date.",
		"filter-custom-date-range-from-date-invalid-message": "From Date must be lessar than To Date.",
		"filter-custom-date-range-to-date-invalid-message": "To Date must be greater than From Date.",
		"filter-entity-header": "Entity",
		"distinct-filter-values-fetch-option-error": "Failed to Fetch options for column {{col-name}}",
		"filter-option-search-placeholder": "Select",
		"entityFilter-option-more-chipLabel": "More",
		"entityFilter-option-selectAll-textLabel": "SELECT ALL ENTITIES",
		"entityFilter-option-selectAll-chipLabel": "All Entities {{count}}",
		"entityFilter-option-unSelectAll-textLabel": "UNSELECT ALL ENTITIES",
		"activate-preview-heading": "Preview",
		"activate-preview-subHeading": "Select and fill atleast one filter to proceed",
		"activate-preview-inprogress": "View your Run preview results below once the progress is completed",
		"activate-preview-info": "Check the preview of the file with the filters applied from Replace only a subset of existing data",
		"custom-filter-count-label": "filter",
		"custom-filter-row-element-action": "Action",
		"custom-filter-row-element-operator": "Operator",
		"custom-filter-row-element-Column-Field": "Field",
		"custom-filter-row-element-value": "Value",
		"custom-filter-row-element-value-blank-error": "Value shouldn't be left blank",
		"no-erp-system": "No matching records found"
	},
	"tool-tip-text": {
		"client-erp-system-title": "This field can be used to specify the source (Client's ERP system) from where the data originated. This information is being collected for potential enhancement to the field mapping process within Omnia | Data."
	},
	steps: {
		"step-1": {
			title: "General Info",
			"step-1-description": "This Screen allows you to select a standard table to which the source data file should be uploaded."
		},
		"step-2": {
			title: "Map Data Fields",
			"step-2-description": "This Screen allows you to map the structure of the uploaded file to the standard table."
		},
		"step-3": {
			title: "Reconcile",
			description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
		},
		"step-4": {
			title: "DQC",
			description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
		},
		"step-5": {
			title: "Data Integrity Checks",
			"step-5-description": "This screen shows the executed Data Integrity matters and whether Data Integrity matters were identified in the dataset. Click here to view the ",
			"faqs-link": "DIC FAQs."
		}
	},
	buttons: {
		cancel: "Cancel",
		"next-step-mapping": "Next Step: Mapping",
		finish: "Finish",
		"map-account-details": "Map Account Details",
		"add-new-datamodel": "Add New Datamodel: ",
		back: "Back",
		"save-progress": "Save Progress For Later",
		"globalscape-link": "Upload Large Files",
		"data-preview": "Preview",
		"ok-button": "Ok",
		"data-preview-tooltip": "This section displays the preview of the uploaded data file and allows the user to select a new header row for the data file as desired",
		"run-preview": "Run preview",
		"re-run-preview": "Re-run preview",
		"next-step-dic": "Next Step: Data Integrity Checks"
	},
	feedback: {
		"loading-results": "Loading results...",
		"status-information-icon": "This section allows you to select a standard table to which the data file can be uploaded. This selection is mandatory for you to move to the next step that allows you to map source data file fields to the common data model fields.",
		"existing-dm-data-desc": "There is already data in this table. Are you replacing existing data or replacing a subset of existing data or adding new data?"
	},
	files: {
		"supported-files-upload-disclaimer": "Valid files: {files}. Supported encodings: {encoding}.",
		"supported-files-upload-disclaimer-without-encoding": "Valid files: {files}.",
		"invalid-files": "Invalid files received.",
		"current-file-label": "Current file: ",
		"sheet-list-label": "Current sheet: ",
		"delimiter-field-label": "Delimiter field",
		"custom-delimiter-label": "Custom Delimiter",
		"delimiter-info-tooltip": "Select the field delimiter relevant to this data file and verify the preview before proceeding to the subsequent steps."
	},
	"file-upload": {
		primary: "Drag and drop files here",
		alt: "or import files from:"
	},
	"multi-sheet-data": {
		"identified-message": "We have detected #SHEET_COUNT# sheets in this excel file. Would you like to separate the sheets or combine the data?",
		labels: {
			"separate-tabs": "Upload each worksheet separately.",
			"guidance-text-separate-tabs": "Select this option if you intend to map each worksheet to different CDM tables.",
			"keep-as-is": "Keep the file as-is in the data storage. I don't want to map the file.",
			"guidance-text-keep-as-is": "Select this option in scenarios such as: you do not intend to split the worksheets of the file or if you want to defer the decision to split this worksheets or if you do not intend to upload this file to a CDM table.",
			"select-tabs": "Select sheets",
			"select-placeholder": "Select",
			"select-all": "Select All",
			"unselect-all": "Unselect All",
			more: "More"
		},
		"redirect-modal": {
			title: "Excel Worksheet",
			description: "You will now be re-directed to the data kitchen home screen while the excel workbook sheets are split as individual files. The individual files will be shown in the Data Inbox section. Please check back after some time and click on the individual file to continue to the remaining steps in the file upload process."
		},
		"value-constants": {
			"separate-tabs": "Split",
			"keep-as-is": "KeepSame"
		},
		buttons: {
			"save-and-exit": "Save and Exit",
			"separate-sheets-to-file": "Separate Sheets into files"
		}
	}
},
	"empty-analytics": {
	"no-analytics-yet-heading": "No Analytics Yet",
	"no-analytics-yet-sub-text": "Your team has not yet added any analytics to this engagement. When you add them, they will begin to appear here where you can work on them and track their progress.",
	"add-new-analytics": "Add New Analytics"
},
	"empty-auditsamples": {
	"no-auditsamples-yet-heading": "No Audit Samples Yet",
	"no-auditsamples-yet-sub-text": "Your team has not yet added any audit samples to this engagement. When you add them, they will begin to appear here where you can work on them and track their progress.",
	"add-new-auditsamples": "Add New Audit Sample"
},
	"data-settings": {
	titles: {
		"data-settings": "Data Settings"
	},
	labels: {
		"entity-groups-tab": "Entity Groups",
		"audit-calendars-tab": "Audit Calendars",
		"engagement-mapping-tab": "Engagement Mappings",
		"active-entity-groups-count": "{numEntityGroups} Entity Groups",
		"active-audit-calendars-count": "{numCalendars} Audit Calendars",
		"important-dates-tab": "Important Dates",
		"engagement-parameters-tab": "Engagement Parameters",
		"account-mapping-tab-title": "Chart of Accounts Mapping"
	}
},
	"entity-groups": {
	"create-entity-group": {
		title: "Create New Entity Group",
		"submit-button": "Create Group",
		"cancel-button": "Cancel"
	},
	"update-entity-group": {
		title: "Edit Entity Group",
		"submit-button": "Save Changes",
		"cancel-button": "Cancel"
	},
	placeholders: {
		"entities-select": "Select",
		"group-name": "Give this entity group a name...",
		"group-description": "Give this entity group a description..."
	},
	error: {
		"entity-groups-name-not-unique": "Another Entity/Entity Group with the same name exist. Please update and Re-try."
	},
	titles: {
		"entity-groups": "Entity Groups",
		"file-upload-entity-group-duplicate-title": "Existing entity group(s) will be edited or modified"
	},
	buttons: {
		"create-new-entity-group": "CREATE NEW ENTITY GROUP",
		"upload-entity-group-csv": "UPLOAD ENTITY GROUPS",
		"download-entity-groups": "DOWNLOAD ENTITY GROUPS"
	},
	labels: {
		"entity-note": "Note",
		"entity-group-name": "Entity Group Name",
		"entity-group-description": "Description",
		"selected-entities": "Selected Entities",
		entities: "Entities",
		"entities-count": "{numEntities} Entities",
		active: "Active",
		inactive: "Inactive",
		"entity-groups-total": "Total",
		"active-status": "Active Status",
		"no-results": "No results",
		"entities-surplus": "+ {{count}} more entities",
		"entities-report-info": "To access the complete list of entities, please navigate back to the previous page and download the list from the More option.",
		"characters-label": "{{count}} / {{limit}} characters",
		"entity-group-currencies": "Currencies",
		"single-currency": "Single Currency",
		"multiple-currency": "Multiple Currency",
		"entity-groups-currency-create-warning-msg": "You have added entities with multiple currency types to this entity group.  As a result, you will only be able to select the group currency (not entity currency) option in downstream analytics and Journal Entry testing.",
		"entity-groups-currency-update-warning-msg": "You have updated entities with multiple currency types to this entity group. As a result, you will only be able to select the group currency (not entity currency) option in downstream analytics and Journal Entry testing.",
		"entity-groups-currency-warning-title": "Warning message",
		"currency-selected": "Currency Selected",
		"group-currency": "Group Currency",
		"entity-currency": "Entity Currency",
		"entity-group-user-info-notification-msg": "To upload Entities/Entity Groups, select the vertical ellipses next to ”More” and download and re-upload the template with added or updated records. To create an Entity Group, select the checkboxes next to the Entities below and select “Create New Entity Group”. ",
		"group-or-entity-currency": "Group or Entity Currency",
		"manual-updation-entity-entitygrp-info-msg": "To avoid any issues with manual entry, please ensure the fields below do not contain any of the following special characters in the beginning of the text: = + - @",
		"file-upload-entity-entitygrp-info": "To avoid any issues during upload, please ensure the file does not contain any of the following special characters at the beginning of a record: = + - @",
		"override-entitygroup-data-msg": "Click on Ok to proceed with updating the existing Entity Group or Cancel this transaction to re-upload a unique Entity Group."
	}
},
	"data-table": {
	buttons: {
		"download-data-table-csv": "Download Mapping",
		"download-dic-report": "Data Integrity Check Summary Report"
	}
},
	entities: entities,
	"client-calendars": {
	titles: {
		"audit-calendars": "Audit Calendars",
		"create-calendar": "Add Time Period",
		"update-calendar": "Edit Time Period"
	},
	buttons: {
		"add-time-period": "Add Time Period",
		create: "Create",
		update: "Update"
	},
	labels: {
		name: "Name",
		"start-date": "Start Date",
		"end-date": "End Date",
		"active-status": "Active Status",
		"required-field-legend": "Required Fields",
		"required-field-symbol": "*",
		"inactive-status-note": "Note: "
	},
	messages: {
		"inactive-status-note": "Any time period that's disabled will not be available for filtering the data for running the analytics.",
		httpresponse_conflict: "Another calendar with the same name {name} exists. Please update and re-try.",
		httpresponse_unauthorized: "Invalid session. Please re-login and try again.",
		"httpresponse_internal-server-error": "Invalid calendar request. Please try again.",
		"httpresponse_bad-request": "Invalid calendar request. Please try again.",
		httpresponse_undefined: "Invalid calendar request. Please try again.",
		"duplicate-calendar": "Testing Period with the same name exists. Please re-try.",
		"calendar-added": "Testing period added successfully"
	},
	"select-options-status": {
		active: "Active",
		inactive: "Inactive"
	},
	constants: {
		invalid_startdate: "StartDate is invalid",
		invalid_enddate: "EndDate is invalid",
		enddate_less_than_startdate: "End Date > Start Date required",
		startdate_greater_than_enddate: "Start Date < End Date required",
		required_engagementid_invalid: "EngagementId is required",
		required_name_empty: "Name is required",
		required_name_invalid: "Name is invalid",
		null_date: "Date field cannot be empty",
		invalid_date_format: "Invalid date format"
	},
	placeholders: {
		name: "Calendar Name",
		"start-date": "Start Date",
		"end-date": "End Date"
	}
},
	"engagement-mapping": {
	titles: {
		"engagement-mapping": "Engagement Mappings",
		"add-engagement-mapping": "Add Engagement Mapping",
		"delete-engagement-mapping": "Delete Engagement Mapping(s)",
		"update-engagement-mapping": "Edit Engagement Mapping"
	},
	buttons: {
		"add-new-engagement-mapping": "ADD NEW ENGAGEMENT MAPPING",
		"delete-engagement-mapping-table": "DELETE",
		"delete-engagement-mapping-modal": "Delete",
		"create-engagement-mapping-modal": "Create",
		"update-engagement-mapping-modal": "Update",
		"upload-engagement-mappings-csv": "UPLOAD MAPPING",
		"download-engagement-mappings-csv": "DOWNLOAD MAPPING",
		"download-csv-error-message": "Unable to download file"
	},
	labels: {
		"data-source-id": "Data Source ID",
		"cortex-engagement-id": "Omnia | Data Engagement ID",
		"required-field-legend": "Required Fields",
		"required-field-symbol": "*",
		"inactive-status-note": "Note: ",
		"num-engagement-mapping-selected": "Selected"
	},
	messages: {
		"no-engagement-mapping-selected-note": "There are no engagement-mapping selected for deletion.",
		httpresponse_conflict: "Another Engagement Mapping with the same data-source id {dataSourceId} / Omnia | Data-engagement id {cortexEngagementId} exists. Please update and re-try.",
		"httpresponse_bad-request": "Invalid Engagement Mapping request. Please try again.",
		"error-centralized-engagement-mapping-limit-reached": "Maximum {EngagementMappingLimit} engagement mappings allowed for Engagement ID: {cortexEngagementId}",
		"error-centralized-engagement-mapping-limit-reached-upload": "Maximum {EngagementMappingLimit} engagement mappings allowed per Engagement ID."
	},
	constants: {
		required_engagementid_invalid: "Engagement Id is required",
		required_data_source_id_empty: "Data Source Id is required",
		required_data_source_id_invalid: "Data Source Id is invalid",
		required_cortex_engagement_id_empty: "Omnia | Data Engagement Id is required",
		required_cortex_engagement_id_invalid: "Omnia | Data Engagement Id is invalid",
		"char-count": "characters"
	},
	placeholders: {
		"cortex-engagement-id": "Engagement Identifiers provided in Source File"
	}
},
	"account-mapping": {
	titles: {
		"account-mapping-title": "Chart of Accounts Mapping",
		"create-calendar": "Add Time Period",
		"update-calendar": "Edit Time Period"
	},
	labels: {
		"chart-of-accounts": "Chart of Accounts",
		"view-account-mapping-key-name": "View Chart of Accounts Mapping",
		"view-label": "View"
	}
},
	"delete-alert": {
	analytic: {
		title: "Delete Analytic?",
		message: "The analytic “{{analyticName}}” will be permanently deleted. Are you sure you would like to delete?"
	},
	engagement: {
		title: "Are you sure you want to delete “{{engagementName}}”?",
		message: "Deleting this engagement would permanently delete all data and analytics."
	},
	"active-omnia-engagement-modal": {
		title: "Unable to Delete this Engagement",
		"if-the-cortex": "If the Omnia | Data engagement is deleted when Omnia is still using Omnia | Data, then all linked Trial Balance will also be deleted. Either unlink the Omnia engagements below, or wait until all are archived to proceed."
	}
},
	"rename-modal": {
	title: "Rename Analytic",
	"character-limit": "{{characterLimit}} character limit"
},
	"access-denied": {
	"access-denied-header": "You are currently either:",
	"access-denied-description1": "Not accessing Omnia | Data via the Deloitte Network or Deloitte VPN",
	"access-denied-description2": "Not assigned to a Omnia | Data role",
	"access-denied-description3": "Not assigned to a Client/Engagement",
	"access-denied-description4": "If you are part of an audit engagement, please contact your Deloitte engagement team members.",
	"access-denied-description5": "If you are a COE user, please contact Deloitte Omnia | Data Support at ",
	"access-denied-email": "DeloitteCortexSupport@deloitte.com",
	"access-denied-mailTo": "mailto:DeloitteCortexSupport@deloitte.com"
},
	"analytics-output": {
	"back-to-analytic": "Back to analytic",
	"back-to-jeinsights": "Back To Journal Entry Insights",
	download: "Download",
	"Capture-Data": "Capture Data",
	"Capture-Data-Warning": "Moving out to other pages or refreshing the screen will cause the captured data to be lost",
	"Capture-And-Process-Data": "Capture and Process Data",
	"New-Data-Capture-Warning": "Capture and Processing Data will overwrite any existing captured data. Consider Downloading to Excel before proceeding.",
	Warning: "Warning Message",
	Cancel: "Cancel",
	Proceed: "Proceed",
	"No-Data-Captured": "No Data Captured",
	"Data-Captured": "Data Captured",
	"Capturing-Data": "Capturing Data",
	"Capturing-Data-Notification-Msg": "Successfully Captured data",
	"Extracting-Data": "Extracting Data",
	"Data-Extracted": "Data Extracted",
	"Data-Extraction-Failed": "Data Extraction Failed",
	"Data-Capture-Failed": "Data Capture Failed",
	"Extract-All-Captured-Data": "Extract Captured Data",
	"Download-DWW-Template": "Download into Excel",
	"Column-Name": "Name",
	"Column-Status": "Status",
	"Cycle-Based-data": " Cycle Based data",
	"Burst-Out": "Burst Out",
	Drilldown: "DrillDown",
	"transaction-analysis-header": "In this view, you are able to export data directly into Excel once data has been captured and extracted.",
	"Journal-Entry-Analytics": "Journal Entry Analytics",
	tiles: {
		title: "Outputs",
		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		"read-more": "Read more",
		"read-less": "Read less"
	},
	"tableau-error": "Error displaying the data visualization",
	"tableau-notification-msg": "Job is successfully submitted. You will receive another notification when the file is ready to be downloaded.",
	"ProgressBar-Step-Start-Review": "Start Review",
	"ProgressBar-Step-Data-Captured": "Data Captured",
	"ProgressBar-Step-Data-Extraction": "Data Extraction",
	"zip-download-error": "Unable to download file. Please try again or reach out to support."
},
	management: management,
	"workflow-modal": {
	"close-button": "Analytics Center",
	breadcrumb: "Analytics Center"
},
	"tou-modal": {
	"heading-default": "Terms of Use",
	"heading-client-tou": "Client Terms of Use",
	"heading-data-extraction": "Data Extraction Terms of Use",
	description: "Please read through the document below and accept in order to proceed with using Omnia | Data.",
	"button-text": "Accept Terms of Use"
},
	"display-idle-modal": {
	heading: "Your Session Will Time Out ",
	description: "Would you like to continue your session?",
	"time-remaining": "Time Remaining",
	"stay-logged-in": "STAY LOGGED IN",
	logOut: "LOG OUT"
},
	"mui-pagination": {
	"tickmark-modal-custom-pagination-label": "{to} OF {total}"
},
	"engagement-omnia-trial-balance-page": {
	"page-path": "Home/Trial Balance",
	"main-heading": "Trial Balance",
	"sub-heading-1": "Trial Balance Data",
	"sub-heading-3": "View Trial Balance  and Chart of Accounts Data",
	"sub-heading-description": "Select entity, testing period, chart of accounts options to check if Omnia | Data has Trial Balance data with matching criteria.",
	"sub-heading-description-updated": "Select entity, testing period and chart of accounts filters to check if Omnia | Data has Trial Balance or Chart of Accounts data with matching criteria.",
	"sub-heading-description-updated1": "Select entity, period end date and chart of accounts filters to check if Omnia | Data has Trial Balance or Chart of Accounts data with matching criteria.",
	"sub-heading-description-new": "For larger files, it is recommended to download the Chart of Accounts and Trial Balance from Data Kitchen.",
	"sub-heading-description-new-updated": "For larger files, it is recommended to download the Chart of Accounts and Trial Balance from the Data Kitchen.",
	"select-dropdown-1": "Entity",
	"select-dropdown-2": "Testing Period",
	"select-dropdown-2-updated": "Period End Date",
	"select-dropdown-3": "Chart of Accounts",
	"trial-balance-data-text": "Check your Trial Balance Data results.",
	"account-mapping-title": "Account mapping",
	"account-mapping-description": "Check your Chart of Accounts results.",
	"tb-coa-rows-displayed": "rows displayed",
	"error-message-tbcoa-preview": "We cannot generate the Trial Balance preview at the moment. Please ensure that Trial Balance and Chart of Accounts is present in Omnia | Data for your engagement.",
	"error-message-tbcoa-preview-updated": "We cannot generate the Trial Balance preview at the moment. Please ensure that your Omnia | Data engagement contains Trial Balance and Chart of Accounts data.",
	"accordion-titles": {
		"chart-of-accounts": "CHART OF ACCOUNTS",
		"trial-balance": "TRIAL BALANCE"
	},
	"accordion-status": {
		"default-status-tb": "Choose an entity, testing period and chart of accounts to see if you have data",
		"default-status-coa": "Choose an entity and chart of accounts to see if you have data",
		"records-found": "Records Found",
		"pending-data": "Pending Data",
		"help-text-coa-account-mapping": "Resolve add Trial Balance data, to continue with Account Mapping"
	},
	buttons: {
		"chart-of-accounts": "ADD OR MODIFY CHART OF ACCOUNTS",
		"trial-balance": "ADD OR REPLACE TRIAL BALANCE",
		"apply-filters": "Apply Filters"
	},
	"tb-data-description": {
		"trial-balance": "Select entities, testing periods and chart of accounts options to check if Omnia | Data has Chart of Accounts and Trial Balance data with matching criteria."
	},
	tiles: {
		"trial-balance-tile-heading": "Add or replace Trial Balance",
		"trial-balance-tile-description": "Select 'General Ledger - Trial Balance' as Standard Table while mapping.",
		"trial-balance-tile-description-1": "Add or replace trial balance data",
		"trial-balance-tile-description-1-updated": "Add or replace trial balance data.",
		"chart-of-account-heading": "add or modify chart of accounts",
		"chart-of-account-description": "Add a new chart of accounts or edit an existing chart of accounts.",
		"updated-tiles": {
			"download-template-description": "Download Template for Trial Balance or Chart of Accounts",
			"download-template-description-updated": "Download Template for Trial Balance or Chart of Accounts.",
			"download-template-heading": "download template"
		}
	},
	"add-files-section": {
		"add-files-description": "Add Trial Balance and Chart of Accounts Data to your Omnia engagement. If the preview tiles below show pending data for Chart of Accounts or Trial Balance, data must be added to Omnia | Data using the quick options below before it can be imported to Omnia.",
		"add-files-description-updated-line1": "Add Trial Balance and Chart of Accounts Data to your Omnia Engagement. ",
		"add-files-description-updated-line2": "If you do not see any preview, please add data to Omnia | Data using the tiles below, before it can be imported to Omnia.",
		"sub-heading-2-add-files": "Add or Modify Trial Balance Data"
	},
	"pending-trial-balance-tile": {
		note: "The data needed to run this operation has not been uploaded yet. To continue, please use the following template to add Trial Balance data.",
		title: "TRIAL BALANCE PENDING DATA",
		"add-trial-balance-data-button": "Add Trial Balance Data",
		"download-link": "DOWNLOAD TRIAL BALANCE TEMPLATE"
	},
	"account-mapping-details-missing-tile": {
		note: "To continue, please update account mapping.",
		title: "ACCOUNT MAPPING DETAILS MISSING",
		"map-account-details-button": "Map Account Details"
	},
	"banner-alerts": {
		"pending-tb": "The trial balance data has not been uploaded yet. You can download the Trial Balance template and upload trial balance.",
		"pending-tb-prefix": "Pending Trial Balance: ",
		"pending-coa": "The chart of accounts data has not been uploaded yet. Please add your chart of accounts in order to proceed.",
		"pending-coa-prefix": "Pending Chart of Accounts: ",
		"pending-tb-coa": "The Trial Balance and Chart of Accounts data has not been uploaded yet. Please add your the data in order to proceed.",
		"pending-tb-coa-prefix": "Pending Trial Balance and Chart of Accounts: ",
		"success-tb-coa": "The Trial Balance and Chart of Accounts data has been uploaded ",
		"success-tb-coa-prefix": "Success: ",
		"coa-missing-data-prefix": "Account mapping details missing",
		"coa-missing-data": "To continue, please check and update the missing account details: ",
		"coa-missing-data-link": " Check Missing Account Details.",
		"dic-issues-data-prefix": "Data Integrity Checks Found",
		"dic-issues-data-1": "We found data integrity issues with the file you have uploaded. Please click ",
		"dic-issues-data-2": " to review the issues found. ",
		"dic-issues-data-link": "here",
		"dic-issues-in-progress-prefix": "Data Integrity Checks in Progress",
		"dic-issues-in-progress-prefix-updated": "Checking for Data Issues",
		"dic-issues-in-progress-description": "Data will be ready for Omnia after the process completes.",
		"dic-issues-in-progress-description-updated": " Additional checks are being run to identify and prevent data quality issues and system errors when importing trial balances to Omnia.",
		"dic-issues-in-progress-description-updated-1": " Additional checks are being run on the combined Trial Balance and Chart of Accounts to identify and prevent system errors when importing trial balances to Omnia. ",
		"dic-issues-in-progress": "Data Integrity Checks in Progress. Please wait for sometime.",
		"dic-issues-error": "Data Integrity check failed. Please try again or contact support.",
		"dic-issues-error-prefix": "Error",
		"dic-completed-no-issues-success-header": "No Data Integrity Check Issues Found",
		"dic-completed-no-issues-success": "File upload is completed and no data integrity check issues have been found.",
		"tb-file-upload-success-header": "File Upload Completed",
		"tb-file-upload-success": "Your file has been uploaded in Omnia | Data.",
		"tb-file-upload-failure-header": "File Upload Failed",
		"tb-file-upload-failure": " There was an issue uploading your file in Omnia | Data. Please try after sometime or contact support.",
		"tb-file-upload-pending-header": "Combining Trial Balance and Chart of Accounts",
		"tb-file-upload-pending-header-new": "Combining Trial Balance and Chart of Accounts",
		"tb-file-upload-inprogress-header": "File Upload in Progress",
		"tb-file-upload-pending": "Your file is being uploaded in Omnia | Data. Please wait for some time."
	},
	"missing-account-details": {
		"missing-account-details-title": "Missing Account Details",
		"missing-account-details-description": "The following Chart of Accounts and Entities combinations are missing account details. Navigate to Account Mapping module to add missing account details.",
		"missing-account-details-column1": "Chart of Accounts ",
		"missing-account-details-column2": "Entity ID",
		"navigate-to-account-mapping-button": "Navigate to Account Mapping"
	},
	"data-integrity-check-issues": {
		"data-integrity-check-issues-title": "Data Integrity Check Issues",
		"data-integrity-check-issues-title-updated": "Data Integrity Matters Identified",
		"data-integrity-check-issues-description": "Please correct the issues by fixing the account mapping issues or upload a clean trial balance.",
		"data-integrity-check-issues-description-updated-coa": "Evaluate the data integrity matters identified. Correct the data integrity matters by clicking 'Add or Modify Chart of Accounts'.",
		"data-integrity-check-issues-description-updated-tb": "Evaluate the data integrity matters identified. Correct the data integrity matters by clicking 'Add or Replace Trial Balance'.",
		"data-integrity-check-issues-column1": "Issues Found",
		"data-integrity-check-issues-column1-updated": "Data Integrity Check",
		"data-integrity-check-issues-column2": "Impacted Column",
		"data-integrity-check-issues-column3": "Entity Name",
		"data-integrity-check-issues-column4": "Chart of Accounts",
		"data-integrity-check-issues-column5": "Period End Date",
		"data-integrity-check-issues-column6": "Period Type",
		"data-integrity-check-issues-column7": "Actions to Resolve",
		"account-mapping-button": "Account Mapping",
		"add-trial-balance-button": "Add Trial Balance",
		"add-replace-tb-button": "Add or Replace Trial Balance",
		"add-modify-coa-button": "Add or Modify Chart of Accounts",
		"no-tb-record-text": "No Data Integrity Issues found in Trial Balance.",
		"no-coa-record-text": "No Data Integrity Issues found in Chart of Accounts."
	},
	"data-integrity-affected-records": {
		"data-integrity-affected-records-title": "DIC Affected Records",
		"data-integrity-affected-records-entity-id": "Entity Id",
		"data-integrity-affected-records-entity-name": "Entity Name",
		"data-integrity-affected-records-account-number": "Account Number",
		"data-integrity-affected-records-account-desc": "Account Description",
		"data-integrity-affected-records-ped": "Period End Date",
		"data-integrity-affected-records-pt": "Period Type",
		"data-integrity-affected-records-entity-currency-ec": "Entity Currency EC",
		"data-integrity-affected-records-preliminary-ec": "Preliminary EC",
		"data-integrity-affected-records-group-currency-gc": "Group Currency GC",
		"data-integrity-affected-records-preliminary-gc": "Preliminary GC",
		"data-integrity-affected-records-coa": "Chart Of Accounts",
		"data-integrity-affected-records-account-grouping-one-num": "Account Grouping One Num",
		"data-integrity-affected-records-account-grouping-one": "Account Grouping One",
		"data-integrity-affected-records-financial-statement-line-num": "Financial Statement Line Num",
		"data-integrity-affected-records-financial-statement-line": "Financial Statement Line",
		"data-integrity-affected-records-financial-statement-subtotal-cat": "Financial Statement SubTotal Category",
		"data-integrity-affected-records-financial-statement-cat": "Financial Statement Category",
		"data-integrity-affected-records-financial-statement-type": "Financial Statement Type",
		"data-integrity-affected-records-ending-currency-ec": "Ending Currency EC",
		"data-integrity-affected-records-ending-currency-gc": "Ending Currency GC"
	},
	preview: {
		"preview-in-data-kitchen": "PREVIEW IN DATA KITCHEN",
		download: "DOWNLOAD"
	},
	"dic-rules-actions-to-resolve": {
		"financial_statement_category--COA_Blank_Values": "Blank values found in 'financial_statement_category'. Please ensure that 'financial_statement_category' is not null.",
		"financial_statement_category--COA_Valid_financial_statement_category": "Invalid values found in  financial_statement_category. Please ensure that the values are one of the following - Assets,Liabilities, Equity, Revenue, Expenses. ",
		"financial_statement_type--COA_Blank_Values": "Blank values found in 'financial_statement_type.  Please ensure that 'financial_statement_type' is not null.",
		"financial_statement_type--COA_Valid_financial_statement_type": "Please ensure 'financial_statement_type'  has the following values only - Balance Sheet / Income Statement. ",
		"financial_statement_subtotal_category--COA_Blank_Values": "Blank values found in 'financial_statement_subtotal_category'. Please ensure that 'financial_statement_subtotal_category' is not null.",
		"financial_statement_subtotal_category--COA_Valid_financial_statement_subtotal_category": "Invalid values found in  financial_statement_subtotal_category. Please ensure that the values are one of the following - Current Assets, Current Liabilities, Net Income, Shareholders Equity and contains below 150 charaters.",
		"account_number--COA_Blank_Values": "Blank values found in 'account_number'. Please ensure that 'account_number' is not null.",
		"account_grouping_1_num--COA_Blank_Values": "Blank values found in ' account_grouping_1_num'. Please ensure that ' account_grouping_1_num' is not null.",
		"financial_statement_line_num--COA_Blank_Values": "Blank values found in ' financial_statement_line_num'. Please ensure that ' financial_statement_line_num' is not null.",
		"group_currency_gc--TB_Blank_Values": "Blank values found in 'group_currency_gc'. Please ensure that  'group_currency_gc' values are not null.",
		"entity_currency_ec--TB_Blank_Values": "Blank values found in 'entity_currency_ec'. Please ensure that  'entity_currency_ec' values are not null.",
		"group_currency_gc--TB_Valid_group_currency_gc": "Invalid values found in 'group_currency_gc'. Please ensure that 'group_currency_gc' contain unique values.",
		"entity_currency_ec--TB_Valid_entity_currency_ec": "Invalid values found in 'entity_currency_ec'. Please ensure that 'entity_currency_ec' contain unique values.",
		"chart_of_accounts--COA_Valid_chart_of_accounts": "Invalid values found in 'chart_of_accounts'. Please ensure that 'chart_of_accounts' contain below 150 charaters.",
		"entity_name--COA_Valid_entity_name": "Invalid values found in 'entity_name'. Please ensure that 'entity_names' contain below 150 charaters.",
		"financial_statement_line_num--COA_Valid_financial_statement_line_num": "Invalid values found in 'financial_statement_line_num'. Please ensure that 'financial_statement_line_num' contain below 150 charaters.",
		"account_grouping_1_num--COA_Valid_account_grouping_1_num": "Invalid values found in 'account_grouping_1_num'. Please ensure that 'account_grouping_1_num' contain below 150 charaters.",
		"account_number--COA_Valid_account_number": "Invalid values found in 'account_number'. Please ensure that 'account_number' contain below 150 charaters.",
		"financial_statement_line--COA_Valid_financial_statement_line": "Invalid values found in 'financial_statement_line'. Please ensure that 'financial_statement_line' contain below 150 charaters.",
		"account_grouping_1--COA_Valid_account_grouping_1": "Invalid values found in 'account_grouping_1'. Please ensure that 'financial_statement_line' contain below 150 charaters.",
		"account_description--COA_Valid_account_description": "Invalid values found in 'account_description'. Please ensure that 'account_description' contain below 150 charaters.",
		"entity_name--TB_Blank_Values": "Blank values found in 'entity_name'. Please ensure that 'entity_name' is not null.",
		"period_type--TB_Valid_period_type": "Invalid values found in 'period_type'. Please ensure that the 'period_type' contain the following values only: YTD/QTD/MTD.",
		"period_type--TB_Blank_Values": "Blank values found in 'period_type'. Please ensure that 'period_type' is not null.",
		"ending_balance_ec--TB_Valid_RowCount": "The monetary value for an account cannot be more than 999 trillion and less than -999 trillion. Please check the values for 'ending_balance_ec' and 'preliminary_ec' in the Trial Balance data.",
		"ending_balance_gc--TB_Valid_RowCount": "The monetary value for an account cannot be more than 999 trillion and less than -999 trillion. Please check the values for 'ending_balance_ec' and 'preliminary_ec' in the Trial Balance data.",
		"preliminary_ec--TB_Valid_RowCount": "The monetary value for an account cannot be more than 999 trillion and less than -999 trillion. Please check the values for 'ending_balance_ec' and 'preliminary_ec' in the Trial Balance data.",
		"preliminary_gc--TB_Valid_RowCount": "The monetary value for an account cannot be more than 999 trillion and less than -999 trillion. Please check the values for 'ending_balance_ec' and 'preliminary_ec' in the Trial Balance data.",
		"preliminary_gc--TB_Blank_Values": "Blank values found in ' preliminary_gc'. Please ensure that 'preliminary_gc' values are not null.",
		"preliminary_ec--TB_Blank_Values": "Blank values found in '  preliminary_ec'. Please ensure that ' preliminary_ec' values are not null.",
		"ending_balance_ec--TB_Blank_Values": "Blank values found in ' ending_balance_ec'. Please ensure that  'ending_balance_ec' values are not null.",
		"ending_balance_gc--TB_Blank_Values": "Blank values found in ' ending_balance_gc'. Please ensure that  'ending_balance_gc' values are not null.",
		"chart_of_accounts_count_validate--COA_Valid_RowCount": "Invalid entires found in  Chart of Accounts.Please ensure that the following conditions are met: Account number should be mapped to only one Account Grouping and FSLI for a given Chart of Accounts ID. Unique AG entry for each account number and COA id, Unique FSLI name entry for each AG value being mapped and Unique FSLI subtype entry for each  FSLI name value being mapped",
		"account_number_count_validate--COA_Valid_RowCount": "Invalid entires found in  Chart of Accounts.Please ensure that the following conditions are met: Account number should be mapped to only one Account Grouping and FSLI for a given Chart of Accounts ID. Unique AG entry for each account number and COA id, Unique FSLI name entry for each AG value being mapped and Unique FSLI subtype entry for each  FSLI name value being mapped",
		"account_grouping_count_validate--COA_Valid_RowCount": "Please check your chart of accounts file and ensure that there are unique AG entry for each account number and COA id being mapped.",
		"financial_statement_line_count_validate--COA_Valid_RowCount": "Please check your chart of accounts file and ensure that there are unique FSLI name entry for each AG value being mapped.",
		"financial_statement_subtotal_category_count_validate--COA_Valid_RowCount": "Please check your chart of accounts file and ensure that there are unique FSLI subtype entry for each FSLI name value being mapped.",
		"tb_duplicate_records_count_validate--TB_Duplicate_Record_Count": "Please check your trial balance data and ensure that there are are no records with duplicate values for all the following columns - chart_of_accounts,entity_id,account_number,period_end_date,period_type",
		"account_grouping_num_validate--COA_Unique_Name_Number": "An account grouping number is mapped to multiple financial statement line numbers. You will not be able to transfer data to Omnia until this data integrity matter is addressed. Update your chart of accounts so that a given account_grouping_number is only mapped to a single financial_statement_line_number.",
		"account_number_validate--COA_Unique_Name_Number": "An account number is mapped to multiple account grouping numbers or financial statement line numbers. You will not be able to transfer data to Omnia until this data integrity matter is addressed. Update your chart of accounts so that a given account_number is only mapped to a single account_grouping_number and financial_statement_line_number.",
		"account_grouping_name_validate--COA_Unique_Name_Number": "An account grouping number is mapped to multiple account groupings. You will not be able to transfer data to Omnia until this data integrity matter is addressed. Update your chart of accounts so that each account_grouping_number is only associated with one account_grouping name.",
		"financial_statement_line_validate--COA_Unique_Name_Number": "A financial statement line number is mapped to multiple financial statement lines. You will not be able to transfer data to Omnia until this data integrity matter is addressed. Update your chart of accounts so that each financial_statement_line_number is only associated with one financial_statement_line name.",
		"entity_unique--TB_Unique_Entities": "An entity_id is mapped to multiple entity names. You will not be able to transfer data to Omnia until this data integrity matter is addressed. Update your trial balance so that each entity_id is only associated with one entity name."
	},
	"preview-loader": "Getting data...",
	"view-records": "View",
	"affected-records": "Affected Records",
	"view-tb-preview-lable-format-text": "SHOWING {from} TO {to} OF {total} RECORDS",
	"trial-balance-tab-dic": "Trial Balance",
	"chart-of-accounts-tab-dic": "Chart Of Accounts",
	"tb-coa-initiated": "Please wait. Preparing your data for preview."
},
	"send-to-omnia": {
	"send-to-omnia-button": "SEND TO ENGAGEMENT FILE",
	"sending-in-progress": "SENDING TO ENGAGEMENT FILE",
	"send-to-omnia-modal-title": "You're about to send this {outputType} output to your Engagement File",
	"send-to-omnia-modal-description": "This output will be available in Omnia's unfiled section",
	"select-omnia-engagement-text": "Select Engagement File",
	"cancel-button": "Cancel",
	"yes-send-to-omnia-button-text": "Yes, Send to Engagement File",
	"toaster-success-message-title": "Success",
	"toaster-success-message-description-text": "{{outputType}} output sent to Engagement File successfully.",
	"toaster-error-message-title": "File Sending Failed.",
	"toaster-error-message-description-text": "There was an error transferring your {{outputType}} output to Engagement File.\nPlease try again or contact support.",
	"toaster-file-size-error-message-description": "Omnia | Data cannot transfer file(s) more than 500 mb in size. Please check your file size.",
	"file-size-exceeded-tooltip": "Output cannot be sent since file size is above 500 mb.",
	"user-has-no-access-to-external-engagement-error-message": "The analytics output to the selected engagement file could not be sent. Please make sure you are a member of the engagement you have selected.",
	sampling: "Sampling",
	"journal-entry": "Journal Entry Deliverable",
	"std-analytic-data-table": "Standard Analytic Data Table"
},
	"output-sharing-history-modal": {
	heading: "{{engagementName}} - Status",
	"internal-error": "Omnia | Data is experiencing technical issues at the moment. Please try again after sometime or contact support.",
	"no-history-found": "No file sending history found.",
	status: {
		"In Progress": "In Progress",
		Completed: "Completed",
		Failed: "Failed"
	},
	"done-button": "Done"
},
	"account-mappings": {
	titles: {
		"account-mapping-title": "Chart of Accounts Mapping",
		"account-mapping-warning": "Unsaved records",
		"account-mapping-bulk-update-warning": "Apply Account Grouping Filters",
		"account-mapping-add-custom-accountGroupDisplayName": "Add Custom Account Grouping",
		"account-mapping-add-custom-fsLineItemDisplayName": "Add Custom Financial Statement Line Name",
		"account-mapping-add-custom-fsSubTypeDisplayName": "Add Custom Financial Statement Line Sub Type",
		"account-mapping-edit-custom-accountGroupDisplayName": "Edit Custom Details for Account Grouping",
		"account-mapping-edit-custom-fsLineItemDisplayName": "Edit Custom Details for Financial Statement Line Name",
		"account-mapping-edit-custom-fsSubTypeDisplayName": "Edit Custom Details for Financial Statement Line Sub Type",
		"import-from-tb": "Import Accounts from TB data",
		"mapping-information": "mapping information"
	},
	labels: {
		"rows-displayed": "rows displayed",
		"account-grouping-bulk-save-warning-msgs": "If the below changes are acceptable, please proceed by either clicking on Save or Discard changes. For additional account grouping changes, please apply a new filter and click on “Edit Additional Account Grouping”.",
		"edit-individual-records": "edit individual records",
		"edit-bulk-ag-records": "Edit Account Grouping",
		"edit-addition-bulk-ag-records": "Edit Additional Account Grouping",
		"save-edited-records": "save edited records",
		"chart-of-accounts": "Chart of Accounts",
		"entity-id": "Entity ID",
		"detail-account-ids": "Detail Account #",
		"detail-account-name": "Detail Account Name",
		"account-grouping": "Account Grouping",
		"fs-line-name": "Financial Statement Line Name",
		"fs-line-sub-type": "Financial Statement Line Sub Type",
		"fs-category": "Financial Statement Category",
		"warning-save-changes": "Save changes",
		"warning-save-changes-ag": "Apply Filter",
		"save-custom-option": "Create and select",
		"save-edit-custom-option": "Apply change",
		"required-field-legend": "Required Fields",
		"add-custom-option": "add custom",
		"edit-custom-option": "Edit Custom Values",
		"name-already-exists": "Option name is already exists",
		"import-export-mapping-info-key": "You can export the file to make changes in excel and re-upload to capture those changes in chart of accounts mapping.",
		"show-empty-records-msg": "Show Unmapped Records",
		"show-all-records": "show all records",
		"apply-filters-btn": "Apply filters",
		"filter-chips-label": "{value} Selected",
		"cancel-edited-records": "Cancel",
		"apply-account-grouping": "Apply Account Grouping",
		"account-number": "Account ID",
		"option-name-accountGroupDisplayName": "Account Grouping",
		"option-name-fsLineItemDisplayName": "Financial Statement Line Name",
		"option-name-fsSubTypeDisplayName": "Financial Statement Line Sub Type"
	},
	placeholders: {
		"name-accountGroupName": "Give this account grouping a name ...",
		"account-number": "Give this account number",
		"name-fsLineItemName": "Give this FSL Name a name ...",
		"name-fsSubTypeName": "Give this FSL Sub Type a name ...",
		"name-fsStatementType": "Give this FS Category a name ...",
		"filters-selection": "Make a selection"
	},
	constants: {
		"char-count": "characters"
	},
	messages: {
		"no-records-found": "No records found!",
		"httpresponses_internal-server-error": "Error calling chart of accounts mapping endpoint, please try again.",
		"http_response_coa-data-model-not-found-error": "Chart of Accounts Submodel is not present for this engagement.",
		"http_response_internal-server-error": "Details are not loaded correctly, please retry your action again. If issue still persists, please contact support.",
		"http_response_dm-schema-not-found": "Chart of Accounts Submodel is not present for this engagement.",
		"httpresponse_conflict-errors": "Custom chart of accounts mapping Option '{name}' already exists.",
		"httpresponse_conflict-id-error": "'{name}' already exists.",
		"httpresponse_conflict_id-length-error": "Minimum length should be 4",
		"httpresponse_filters_no-records-found": "No record found, for the selected filters. The table was not updated, please make other selections.",
		"import-accounts-from-tb-data-info": "Omnia | Data will analyze the data that you have already loaded into the TB data model and add them to the chart of accounts mapping.",
		"import-accounts-from-tb-data-processing": "This process will take sometime, please wait.",
		"import-accounts-from-tb-data-success": "The following accounts have been imported from TB",
		"import-accounts-from-tb-data-success-note": "Note: This is ‘read only’ information, pop-up can be closed by clicking on close icon",
		"httpresponse_tb-data-model-not-found": "Trial Balance data model does not exist.",
		"httpresponses_failed-to-execute": "Error calling chart of accounts mapping endpoint, please try again.",
		"import-mapping-signalr-success": "Import Mapping has been mapped successfully.",
		"httpresponse_import-mapping-signalr-failure": "Import Mapping failed to upload. You must use the export mapping format.",
		"httpresponse_engagement-id-required": "Engagement ID is required",
		"httpresponse_data-model-id-required": "Data Model ID is required",
		"httpresponse_engagement-db-not-found": "Engagement Database not found",
		"httpresponse_no-records-found": "No new account identified through analysis of Trial Balance Data",
		"total-records-added": "{totalRows} accounts have been added from TB data",
		"httpresponse_custom-option-values-required": "One or more required fields are missing values",
		"duplicate-account-group-name-error": "Each unique Account Number and COA ID combination can be mapped to single Account Grouping Number. Applied changes will be saved accordingly.",
		"duplicate-financial-statement-line-item-error": "Each unique Account Grouping Number and COA ID combination can be mapped to single FSL Number. Applied changes will be saved accordingly.",
		"duplicate-financial-statement-sub-type-error": "Each unique Financial Statement Line Number and COA ID combination can be mapped to single FSL SubType. Applied changes will be saved accordingly.",
		"hive-query-execution-failed-error": "Failed to save the changes, please update and apply the changes again.",
		"info-msg-text-for-fsli-standard-value-list": "For the following three dropdown fields below (Account Grouping, Financial Statement Line Name, and Financial Statement Sub Type) there is a pre-defined list of values/IDs to select from. Teams can either use the values uploaded from the COA dataset, select from a predefined list, or add their own custom values.",
		"account-grouping-bulk-update-info": "Select or add an Account grouping for the current values.  Once you select “Apply Account Grouping”, it will update all the values in the grid for the account grouping field and auto-map the other Financial Statement Line columns."
	},
	"account-mapping-bulk-update-changes-tooltip-info": "This functionality helps you to fill the empty Account Grouping records for this grid. Also the current Account Grouping records are replaced by the Account Grouping applied. This happens under the criteria of the filters selected.",
	"account-mapping-new-option": "New Option has been added.",
	"account-mapping-description": "Map the detailed accounts below to their respective Account Groupings, Financial Statement Line Names, and Financial Statement Sub Types. You can edit mappings below or export the chart of accounts mapping dataset and re-import after making changes.",
	"account-mapping-unsaved-changes-warning": "There was an edit made to the mapping, please save before proceeding further",
	"account-mapping-bulk-update-changes-warning": "There are {count} number of records which will get updated with your Bulk update option selected for Account Grouping value. Please click on Ok to apply the bulk updates.",
	"account-mapping-add-custom-note-accountGroupDisplayName": "Create a custom selection for Account Grouping. This new option will be available in other dropdowns related to Account Grouping.",
	"account-mapping-add-custom-note-fsLineItemDisplayName": "Create a custom selection for Financial Statement Line Name. This new option will be available in other dropdowns related to Financial Statement Line Name.",
	"account-mapping-add-custom-note-fsSubTypeDisplayName": "Create a custom selection for Financial Statement Line Sub Type. This new option will be available in other dropdowns related to Financial Statement Line Sub Type.",
	"account-mapping-edit-custom-note-accountGroupDisplayName": "Please edit the custom values you need for this Account Grouping selection.",
	"account-mapping-edit-custom-note-fsLineItemDisplayName": "Please edit the custom values you need for this Financial Statement Line selection.",
	"account-mapping-edit-custom-note-fsSubTypeDisplayName": "Please edit the custom values you need for this Financial Statement Line Sub Type selection.",
	"back-to-accountmapping-button": "Back To chart of accounts mapping",
	"account-mapping-filters-success-message": "The filters have been applied successfully.",
	"account-mapping-filters-description-text": "The table below will display the filter values applied.",
	buttons: {
		"import-accounts-from-tb": "Import Accounts from TB",
		"export-mapping": "Export Mapping",
		"import-mapping": "Import Mapping",
		"account-mapping-bulk-save": "Save Records",
		"account-mapping-bulk-save-cancel": "Discard changes",
		"account-mapping-bulk-cancel": "Cancel"
	}
},
	"data-integrity-check": {
	"learn-more": "LEARN MORE",
	"view-details": "VIEW DETAILS",
	"pending-status": "Loading records affected",
	"record-status": "{unexpectedCount} records affected ({unexpectedPercent}% of {elementCount})",
	"dic-data-preview": {
		rowscount: "{{count}} rows",
		seprator: " | ",
		columncount: "{{count}} columns",
		"preview-table-sub-heading1": "This is a preview of 500 rows out of {count} affected records.",
		"preview-table-sub-heading2": "Showing all {count} affected records.",
		"preview-table-note": "NOTE: ",
		"preview-table-message": "This preview shows a maximum of 500 records and might not include all the Data Integrity Matters Flagged. In order to see the entire list of Data Integrity Matters, examine the source data file that has been uploaded to the Data Kitchen or contact Data Specialists team for assistance."
	},
	"dic-learn-more": {
		type: "Type",
		"type-desc": "Any records affected by this Data Integrity Check should be treated as {severity}.",
		"table-fields-title": "Standard Table Fields",
		"table-fields-desc": "The following standard table fields are examined as part of this Data Integrity Check: "
	}
},
	"engagement-switcher": {
	"tooltip-label": "Engagement name"
},
	"data-condition-change": {
	"message-prt1": "The recent release of Omnia | Data includes enhancements to data upload rules which now allow for the inclusion of new special characters (i.e., non alpha-numeric characters). If certain special characters exist in your data set, it may impact the comparability of data used in Management Override – Journal Entry (JE) testing and analytics performed in Omnia | Data.  For further assistance or questions, ",
	"message-prt2": ", or contact your Data Analytics Specialist to assist in the team’s assessment of the impact of this enhancement.",
	"contact-support-link": "contact support",
	prefix: "Reminder"
}
};

var ok = "Ok";
var back = "Back";
var cancel = "Cancel";
var share = "share";
var duplicate = "Duplicate";
var success = "Success";
var closed = "Closed";
var greetings = "Greetings";
var no = "No";
var yes = "Yes";
var close = "Close";
var attach = "Attach";
var search = "Search";
var open = "Open";
var remove = "Remove";
var reject = "Reject";
var approve = "Approve";
var confirm = "Confirm";
var characters = "characters";
var total = "total";
var proceed = "Proceed";
var apply = "Apply";
var save = "Save";
var entry = "Entry";
var line = "Line";
var standard = "Standard";
var the = " the ";
var entries = "Entries";
var lines = "Lines";
var amount = "Amount";
var under = "Under";
var over = "Over";
var updated = "Updated";
var description = "Description";
var typeofentry = "Type of Entry";
var journalentries = "Number of Journal Entries";
var journallines = "Number of Journal Lines";
var debitamount = "Total Debit Amount";
var creditamount = "Total Credit Amount";
var tickmark = "Tickmark";
var add = "Add";
var edit = "Edit";
var edit_info = "Edit Info";
var flagged = "Flagged";
var rename = "Rename";
var nonstandard = "Nonstandard";
var selected = "Selected";
var accounts = "Accounts";
var multiple = "Multiple";
var all = "All";
var error = "Error";
var cortex = "Omnia | Data";
var summary = "Summary";
var status = {
	adding: "Adding",
	draft: "Draft",
	failed: "Failed",
	"ready-for-review": "Ready for Review",
	rejected: "Rejected",
	published: "Published",
	"pending-data": "Pending Data",
	"input-needed": "Input Needed",
	"in-progress": "In Progress",
	completed: "Completed",
	error: "Error",
	"ready-for-sampling": "Ready For Sampling",
	"sample-size-generated": "Sample size generated",
	"strata-created": "Strata's Created",
	"sample-for-strata-generated": "Samples for Strata Generated",
	pending: "Pending",
	running: "Getting Data",
	success: "Success",
	failure: "Failure",
	"quality-check-completed": "Quality Check Completed",
	"migration-needed": "Migration Needed",
	"upgrade-available": "Upgrade Available"
};
var permissions = {
	"access-denied-message": "You don’t have permission to view this page"
};
var select = "Select";
var severity = {
	message: "Message",
	info: "Info",
	warning: "Warning",
	error: "Error",
	success: "Success",
	alert: "Alert",
	information: "Information"
};
var hide = "Hide";
var download = "Download";
var convert = "Convert";
var submit = "Submit";
var common = {
	ok: ok,
	back: back,
	cancel: cancel,
	share: share,
	"delete": "Delete",
	duplicate: duplicate,
	success: success,
	closed: closed,
	greetings: greetings,
	"no-apply": "N/A",
	"not-yet-selected": "Not yet selected",
	"view-all": "View All",
	no: no,
	yes: yes,
	close: close,
	attach: attach,
	search: search,
	open: open,
	remove: remove,
	reject: reject,
	approve: approve,
	"submit-for-review": "Submit for Review",
	"switch-to-draft": "Switch to Draft",
	confirm: confirm,
	"select-all": "Select all",
	characters: characters,
	"learn-more": "Learn more",
	total: total,
	proceed: proceed,
	apply: apply,
	save: save,
	entry: entry,
	line: line,
	"mansys-std": "STD",
	"mansys-nst": "NST",
	"non-standard": "Nonstandard",
	standard: standard,
	"deselect-all": "Deselect All",
	the: the,
	entries: entries,
	lines: lines,
	amount: amount,
	under: under,
	over: over,
	updated: updated,
	description: description,
	typeofentry: typeofentry,
	journalentries: journalentries,
	journallines: journallines,
	debitamount: debitamount,
	creditamount: creditamount,
	tickmark: tickmark,
	add: add,
	edit: edit,
	edit_info: edit_info,
	flagged: flagged,
	rename: rename,
	nonstandard: nonstandard,
	selected: selected,
	"flagged-lines": "Flagged Lines",
	"other-lines": "Other Lines",
	accounts: accounts,
	multiple: multiple,
	all: all,
	error: error,
	cortex: cortex,
	"unrelated-account-combinations": "Unrelated Account Combinations",
	summary: summary,
	status: status,
	"continue": "Continue",
	permissions: permissions,
	select: select,
	severity: severity,
	hide: hide,
	"upload-action": {
	append: "Append",
	overwrite: "Overwrite",
	replace: "replace"
},
	download: download,
	convert: convert,
	submit: submit
};

/**
 * Fallback to fetch missing keys in l10n portal to local bundled
 * translations files.
 * @param {string} key
 * @returns {string} localized value from bundled translations
 */
var parseMissingKeyHandler = function (key) {
    var splitKey = key.split(":")[1];
    var fallbackValue = "";
    switch (key.split(":")[0]) {
        case "errors":
            fallbackValue = get(errors, splitKey);
            break;
        case "translations":
            fallbackValue = get(translations, splitKey);
            break;
        case "common":
            fallbackValue = get(common, splitKey);
            break;
    }
    return fallbackValue;
};
/**
 * Initialized i18n library. Only one call per application instance.
 * @param {I18nConfig} config i18n configuration.
 */
var i18nInit = function (config) { return __awaiter(void 0, void 0, void 0, function () {
    var initOptions;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                initOptions = {
                    debug: false,
                    lng: "en-US",
                    fallbackLng: "en-US",
                    nsSeparator: false,
                    supportedLngs: ["en-US", "fr-CA", "de-DE", "zh-Hant", "ja-JP", "es-419"],
                    load: "currentOnly",
                    initImmediate: false,
                    interpolation: {
                        escapeValue: false,
                    },
                    react: {
                        useSuspense: false,
                        wait: false,
                    },
                    parseMissingKeyHandler: parseMissingKeyHandler,
                    backend: {
                        backends: [LocalStorageBackend, ApiBackend],
                        backendOptions: [
                            {
                                prefix: "cortex-",
                                store: window.localStorage,
                            },
                            {
                                loadPath: config.apiUrl + "/CortexContent/translations?appName=r2AuditCortex2&localeCode={{lng}}",
                                parse: function (data) {
                                    try {
                                        return JSON.parse(data).values;
                                    }
                                    catch (err) {
                                        // eslint-disable-next-line no-console
                                        console.log("err", err);
                                    }
                                },
                                customHeaders: { authorization: "Bearer " + (config === null || config === void 0 ? void 0 : config.token) },
                            },
                        ],
                    },
                };
                return [4 /*yield*/, i18n.use(ChainedBackend).use(initReactI18next).init(initOptions)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };

export { i18nInit };
