import { useState, useEffect } from "react";
import { useCurrentUser, useConfiguration } from "@cortex-libs/dmf";
import { ONE_TRUST_ACTIVE_GROUPS } from "../../utils/onetrust-utils";
import { useWalkMe } from "../walk-me/use-walk-me";
import { useAdobeInit } from "../adobe-analytics/use-adobe-init";
import env from "../../utils/env.js";

declare global {
  interface Window {
    OnetrustActiveGroups: string;
  }
}
interface serviceInitializerReturnType {
  categoriesStatus: {
    strictlyNecessary: boolean;
    analytics: boolean;
    functional: boolean;
    advertising: boolean;
  };
  requiredServicesReady: boolean;
  otherServicesReady: boolean;
}

export const useServiceInitializer = (): serviceInitializerReturnType => {
  const { baseConfiguration } = useConfiguration();
  const { loggedInUser } = useCurrentUser();
  const [activeGroups, setActiveGroups] = useState<string>("");

  const isOneTrustDisabled =
    (!baseConfiguration?.oneTrustCookie ||
      baseConfiguration?.oneTrustCookie === "" ||
      !baseConfiguration?.oneTrustDomainScriptId ||
      baseConfiguration?.oneTrustDomainScriptId === "") &&
    (!env?.OneTrustCookie ||
      env?.OneTrustCookie === "" ||
      !env?.OneTrustDomainScriptId ||
      env?.OneTrustDomainScriptId === "");

  const updateActiveGroups = (): void => {
    setActiveGroups(window.OnetrustActiveGroups);
  };

  // After the cookie consent is changed, One Trust will trigger a script that will dispatch this event and let us know about the update.
  useEffect(() => {
    window.addEventListener("oneTrustActiveGroupChange", updateActiveGroups, false);

    return () => window.removeEventListener("oneTrustActiveGroupChange", updateActiveGroups);
  }, []);

  // category status depends on one trust being disabled
  const categoriesStatus = {
    strictlyNecessary:
      isOneTrustDisabled ||
      (activeGroups !== "" && activeGroups?.includes(ONE_TRUST_ACTIVE_GROUPS.STRICTLY_NECESSARY)) ||
      (window?.OnetrustActiveGroups !== "" &&
        window?.OnetrustActiveGroups?.includes(ONE_TRUST_ACTIVE_GROUPS.STRICTLY_NECESSARY)),
    analytics:
      isOneTrustDisabled ||
      (activeGroups !== "" &&
        activeGroups?.includes(ONE_TRUST_ACTIVE_GROUPS.ANALYTICS_AND_PERFORMANCE)) ||
      (window?.OnetrustActiveGroups !== "" &&
        window?.OnetrustActiveGroups?.includes(ONE_TRUST_ACTIVE_GROUPS.ANALYTICS_AND_PERFORMANCE)),
    functional:
      isOneTrustDisabled ||
      (activeGroups !== "" && activeGroups?.includes(ONE_TRUST_ACTIVE_GROUPS.FUNCTIONAL)) ||
      (window?.OnetrustActiveGroups !== "" &&
        window?.OnetrustActiveGroups?.includes(ONE_TRUST_ACTIVE_GROUPS.FUNCTIONAL)),
    advertising:
      isOneTrustDisabled ||
      (activeGroups !== "" &&
        activeGroups?.includes(ONE_TRUST_ACTIVE_GROUPS.ADVERTISING_AND_TARGETING)) ||
      (window?.OnetrustActiveGroups !== "" &&
        window?.OnetrustActiveGroups?.includes(ONE_TRUST_ACTIVE_GROUPS.ADVERTISING_AND_TARGETING)),
  };

  if (window?.OnetrustActiveGroups !== "" && activeGroups == "") {
    setActiveGroups(window?.OnetrustActiveGroups);
  }

  const { isWalkMeReady } = useWalkMe({
    allowed: categoriesStatus.functional && categoriesStatus.analytics,
  });
  const { isAdobeReady, isUserReady, isAdobeDisabled } = useAdobeInit({
    loggedInUser: loggedInUser,
    adobeAnalyticsUrl: baseConfiguration?.adobeAnalytics,
    geoLocation: baseConfiguration?.geoLocation,
    allowed: categoriesStatus.analytics,
  });

  // if adobe is not ready yet, it might be because the user has not logged in yet, or it also may be blocked.
  return {
    categoriesStatus,
    requiredServicesReady: isAdobeReady || (!isAdobeReady && !isUserReady) || isAdobeDisabled,
    otherServicesReady: isWalkMeReady,
  };
};
