// IdleIcon.tsx
import React from "react";
import { Icon } from "@cortex-libs/prism";
import { IconNames } from "@cortex-libs/assets";
import { useStyles } from "./@Constants/Styles";

const IdleIcon = () => {
  const { IconsPos } = useStyles();

  return <Icon name={IconNames.Watch} className={IconsPos} fontSize={"medium"} />;
};

export default React.memo(IdleIcon);
