import env from "./env.js";

declare global {
  interface Window {
    OneTrust: {
      ToggleInfoDisplay: () => void;
      OnConsentChanged: (fn: unknown) => void;
    };
  }
}
/**
 * One Trust cookie categories.
 */
export const ONE_TRUST_ACTIVE_GROUPS = {
  STRICTLY_NECESSARY: "1",
  ANALYTICS_AND_PERFORMANCE: "2",
  FUNCTIONAL: "3",
  ADVERTISING_AND_TARGETING: "4",
  SOCIAL_MEDIA: "5",
};

/**
 * Opens the One Trust cookie settings banner by using the OneTrust API.
 */
export const openOneTrustPopup = (): void => {
  if (window.OneTrust) {
    window.OneTrust.ToggleInfoDisplay();
  }
};

/**
 * Declare function to be executed after cookie consent is changed.
 */
export const setConsentChangeAction = (fn: unknown): void => {
  if (window.OneTrust && fn instanceof Function) {
    window.OneTrust.OnConsentChanged(fn);
  }
};

/**
 * Searches through the URLs that we have excluded for OneTrust's
 * functionality to see if the current URL is excluded.
 */
export const isCurrentUrlExcuded = (): boolean => {
  // For comparison, we will grab the current URL without the protocol, nor any / characters.
  const currentUrl = window.location.host;
  return env?.OneTrustExclude.includes(currentUrl);
};
