import {
  MyEngagements,
  WrapupStatus,
  LegalHoldStatus,
  RollForwardStatus,
  equals,
} from "@cortex-libs/dmf";

export const sortEngagements = (
  myEngagementList: Array<MyEngagements>
): (MyEngagements | undefined)[] => {
  return myEngagementList?.sort((a: MyEngagements | undefined, b: MyEngagements | undefined) => {
    return (a?.name || "").toLowerCase().localeCompare((b?.name || "").toLowerCase());
  });
};

export const getPrimaryList = (engList: MyEngagements[]): MyEngagements[] => {
  return engList?.filter(
    (eng) =>
      (equals(eng?.wrapupStatus, WrapupStatus.COMPLETE) &&
        !equals(eng?.legalHoldStatus, LegalHoldStatus.APPROVED) &&
        !equals(eng?.legalHoldStatus, LegalHoldStatus.PENDING_REMOVAL_APPROVAL) &&
        !equals(eng?.rollForwardStatus, RollForwardStatus.PROGRESS)) ||
      (equals(eng?.wrapupStatus, WrapupStatus.COMPLETE) &&
        (equals(eng?.legalHoldStatus, LegalHoldStatus.APPROVED) ||
          equals(eng?.legalHoldStatus, LegalHoldStatus.PENDING_REMOVAL_APPROVAL)))
  );
};
