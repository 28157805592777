import { AlertVariants, Box, Button, ButtonTypes, Icon, Notification } from "@cortex-libs/prism";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { clientBannerStyles } from "./@constants/ClientBannerStyles";
import { ClientVerificationBannerProps } from "./@constants/ClientVerificationBanner";
import { IconNames } from "@cortex-libs/assets";

export const ClientVerificationBanner = memo(
  (props: ClientVerificationBannerProps): JSX.Element => {
    const classes = clientBannerStyles();
    const { isOpen, markAsComplete } = props;
    const { t } = useTranslation();
    const [showBanner, setShowBanner] = useState<boolean>(true);
    const handleClose = () => {
      setShowBanner(false);
    };

    return (
      <>
        {isOpen && (
          <Box className={classes.VerificationBanner}>
            <Notification
              isNotificationBanner
              icon={<Icon name={IconNames.Warning} />}
              severity={AlertVariants.WARNING}
              message={
                <>
                  <Box className={classes.alertMessage}>
                    <span className={classes.alertMessagePrefix}>
                      {t("translations:client.verification.type")}:
                    </span>
                    <span style={{ marginRight: "15px" }}>
                      {t("translations:client.verification.text")}
                    </span>
                  </Box>
                  <Button
                    buttonType={ButtonTypes.TEXT}
                    className={classes.MarkAsComplete}
                    onClick={markAsComplete}
                    startIcon={<Icon name={IconNames.StatusTick} fontSize="small" />}
                  >
                    {t("translations:client.verification.complete")}
                  </Button>
                </>
              }
              isOpen={isOpen && showBanner}
              handleClose={handleClose}
              showClose={true}
            />
          </Box>
        )}
      </>
    );
  }
);
