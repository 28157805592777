import { useState, useEffect, useRef } from "react";
import { useTranslation } from "@cortex-libs/i18n";
import copy from "copy-to-clipboard";
import { useContactSupport } from "@cortex-libs/dmf";
import {
  Modal,
  Box,
  Text,
  TextVariants,
  Input,
  Colors,
  ButtonTypes,
  Button,
  Tooltip,
  Typography,
  Select,
  MenuItem,
} from "@cortex-libs/prism";
import { CreateSupportTicketModalProps } from "./CreateSupportTicketModalProps";
import { useStyles } from "./Styles";
import {
  URGENCY_OPTIONS,
  URGENCY_OPTIONS_DEFAULT,
  URGENCY_OPTIONS_MEDIUM,
  MODAL_CONSTANTS,
} from "./@constants/constants";

export const CreateSupportTicketModal = (props: CreateSupportTicketModalProps): JSX.Element => {
  const translationKey = "translations:create-support-ticket-modal";
  const { createSupportTicket } = useContactSupport();
  const inputRef = useRef(null);
  const { open, onClose, modalDetails } = props;
  const { t } = useTranslation();
  const { CreateSupportTicketModalStyles } = useStyles();
  const [ticketDetails, setTicketDetails] = useState<Record<string, string>>({
    title: "",
    description: "",
    ticketUrgency: "",
    ticketUrgencyDesc: "",
  });
  const [disableTooltip, setDisableTooltip] = useState<boolean>(true);
  const [isSubjectInputFocused, setIsSubjectInputFocused] = useState<boolean>(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(true);

  // Triggers when user changes value in input fields.
  const updateTicketDetails = (updatedDetails: Record<string, string>) => {
    setTicketDetails({ ...ticketDetails, ...updatedDetails });
  };

  const getCopyContent = (): string =>
    `Title: ${ticketDetails.title}\n\nDescription: ${ticketDetails.description}${
      Number(ticketDetails.ticketUrgency) > Number(URGENCY_OPTIONS_DEFAULT)
        ? `\n\nUrgency Reason: ${ticketDetails.ticketUrgencyDesc}`
        : ""
    }\n\nError Code : ${modalDetails?.errorCode}\nConversation ID: ${modalDetails?.conversationId}`;

  // Triggers when user clicks on Submit button.
  const handleSubmit = () => {
    const content = getCopyContent();
    copy(content, {
      format: MODAL_CONSTANTS.COPY_FORMAT,
    });
    createSupportTicket({ ...ticketDetails, ...modalDetails });
    onClose();
  };

  useEffect(() => {
    setTicketDetails({
      title: `${t(`${translationKey}.failed-title-${modalDetails?.methodName}`)} - ${
        modalDetails?.engagementName
      }`,
      description: "",
      ticketUrgency: URGENCY_OPTIONS_DEFAULT,
      ticketUrgencyDesc: "",
    });
  }, [modalDetails]);

  useEffect(() => {
    if (inputRef === null) return;
    const ref = inputRef?.current as unknown as Record<string, string>;
    const isEllipsisDisplayed = ref?.clientWidth + 1 > ref?.scrollWidth;
    setDisableTooltip(isEllipsisDisplayed || isSubjectInputFocused);
  }, [ticketDetails.title, isSubjectInputFocused]);

  useEffect(() => {
    if (
      (ticketDetails.ticketUrgency === URGENCY_OPTIONS_MEDIUM &&
        (!ticketDetails.ticketUrgencyDesc.trim().length ||
          !ticketDetails.description.trim().length)) ||
      !ticketDetails.description.trim().length ||
      !ticketDetails.title.length
    ) {
      setIsSubmitButtonDisabled(true);
    } else setIsSubmitButtonDisabled(false);
  }, [ticketDetails]);

  //Runs when the drop down value in the Urgency Field Changes
  useEffect(() => {
    setTicketDetails({ ...ticketDetails, ticketUrgencyDesc: "" });
  }, [ticketDetails.ticketUrgency]);

  return (
    <>
      {open && (
        <Modal open onCloseButtonClick={onClose} data-test-id="support-ticket-modal">
          <Box
            maxWidth={Typography.SUPPORT_TICKET_MODAL_WIDTH}
            width={Typography.SUPPORT_TICKET_MODAL_WIDTH}
            p={5}
            pt={3}
            zIndex={1500}
            className={CreateSupportTicketModalStyles}
          >
            <Box pb={2}>
              <Text
                type={TextVariants.SUB_HEADING_1}
                color={Colors.BLACK_86}
                data-test-id="support-ticket-modal-title"
              >
                {t(`${translationKey}.title`)}
              </Text>
            </Box>
            <Box pb={2.1}>
              <Text
                type={TextVariants.BODY_COPY_REGULAR}
                color={Colors.BLACK_86}
                data-test-id="support-ticket-modal-description"
              >
                {t(`${translationKey}.description`)}
              </Text>
            </Box>
            <Box pb={1}>
              <Text
                type={TextVariants.FIELD_LABEL}
                color={Colors.BLACK_86}
                data-test-id="support-ticket-modal-subject-title"
              >
                {t(`${translationKey}.subject-title`)}
              </Text>
            </Box>
            <Box mb={2.2}>
              <Tooltip
                title={
                  disableTooltip ? (
                    ""
                  ) : (
                    <Box p={1.5}>
                      <Text type={TextVariants.BODY_COPY_SMALL} color={Colors.WHITE}>
                        {ticketDetails.title}
                      </Text>
                    </Box>
                  )
                }
                arrow
              >
                <span>
                  <Input
                    value={ticketDetails.title}
                    required
                    maxRows={1}
                    onChange={(e) => updateTicketDetails({ title: e.target.value })}
                    id="support-ticket-modal-subject"
                    inputRef={inputRef}
                    onFocus={() => setIsSubjectInputFocused(true)}
                    onBlur={() => setIsSubjectInputFocused(false)}
                    textFieldVariant="outlined"
                  />
                </span>
              </Tooltip>
            </Box>
            <Box pb={1}>
              <Text
                type={TextVariants.FIELD_LABEL}
                color={Colors.BLACK_86}
                data-test-id="support-ticket-modal-urgencyLabel"
              >
                {t(`${translationKey}.urgency`)}
              </Text>
            </Box>
            <Box mb={5} width="100%">
              <Select
                id="support-ticket-modal-urgency"
                value={ticketDetails.ticketUrgency}
                onChange={(e) => updateTicketDetails({ ticketUrgency: e.target.value as string })}
                fullWidth={true}
              >
                {URGENCY_OPTIONS.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {t(item.text)}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            {ticketDetails.ticketUrgency === URGENCY_OPTIONS_MEDIUM && (
              <>
                <Box pb={1}>
                  <Text
                    type={TextVariants.FIELD_LABEL}
                    color={Colors.BLACK_86}
                    data-test-id="support-ticket-modal-urgencyDetailsLabel"
                  >
                    {t(`${translationKey}.urgency-details`)}
                  </Text>
                </Box>
                <Box mb={5}>
                  <Input
                    value={ticketDetails.ticketUrgencyDesc}
                    required
                    placeholder={t(`${translationKey}.detail-placeholder`)}
                    onChange={(e) =>
                      updateTicketDetails({ ticketUrgencyDesc: e.target.value as string })
                    }
                    multiline
                    maxRows={4}
                    id="support-ticket-modal-urgencyDetails"
                    textFieldVariant="outlined"
                  />
                </Box>
              </>
            )}
            <Box pb={1}>
              <Text
                type={TextVariants.FIELD_LABEL}
                color={Colors.BLACK_86}
                data-test-id="support-ticket-modal-detail-title"
              >
                {t(`${translationKey}.detail-title`)}
              </Text>
            </Box>
            <Box mb={5}>
              <Input
                value={ticketDetails.description}
                required
                placeholder={t(`${translationKey}.detail-placeholder`)}
                onChange={(e) => updateTicketDetails({ description: e.target.value })}
                multiline
                maxRows={4}
                id="support-ticket-modal-description"
                textFieldVariant="outlined"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button
                buttonType={ButtonTypes.SECONDARY}
                onClick={onClose}
                data-test-id="support-ticket-modal-cancel-button"
              >
                {t(`common:cancel`)}
              </Button>
              <Box pl={2}>
                <Button
                  buttonType={ButtonTypes.PRIMARY}
                  disabled={isSubmitButtonDisabled}
                  onClick={handleSubmit}
                  data-test-id="support-ticket-modal-submit-button"
                >
                  {t(`common:submit`)}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};
