import { Avatar } from "@material-ui/core";
import {
  Text,
  Colors,
  TextVariants,
  Box,
  Icon,
  ContextMenuOptions,
  ContextMenu,
  Progress,
} from "@cortex-libs/prism";
import { IconNames } from "@cortex-libs/assets";
import { User, UserRole } from "@cortex-libs/dmf";
import { TFunction } from "@cortex-libs/i18n";
import { manageClientTeamEnum } from "../../../@constants/constants";

const getAvatar = (row: Record<string, unknown>, avatarImg: JSX.Element) => {
  if (row?.additionInProgress) {
    return <Progress type="circular" size={40} thickness={3} status="info" />;
  } else if (row?.deletionInProgress) {
    return <Progress type="circular" size={40} thickness={3} status="error" />;
  } else {
    return <Avatar id="HeaderAvatar">{avatarImg}</Avatar>;
  }
};

export const UserName = (
  avatarName: string,
  firstName: string,
  lastName: string,
  jobTitle: string,
  row?: Record<string, unknown>
): JSX.Element => {
  let avatarImg;
  const userName = firstName === lastName ? firstName : `${firstName} ${lastName}`;
  if (jobTitle === manageClientTeamEnum.EXTERNAL) {
    avatarImg = <Icon fontSize={30} name={IconNames.AvatarGuest} color={Colors.BLACK_86} />;
  } else {
    avatarImg = <Text type={TextVariants.BODY_COPY_REGULAR}> {avatarName} </Text>;
  }

  return (
    <>
      <Box display="flex" width="248px" height="40px">
        <Box width="16%">{getAvatar(row as Record<string, unknown>, avatarImg)}</Box>
        <Box ml={2} width="84%">
          <div style={{ position: "relative" }}>
            <Text color={Colors.BLACK_FAINT} noWrap={true} showTooltipOnWrap={true}>
              {userName}
            </Text>
          </div>
          <div style={{ position: "relative" }}>
            <Text color={Colors.BLACK_FAINT} type={TextVariants.FIELD_LABEL}>
              {jobTitle}
            </Text>
          </div>
        </Box>
      </Box>
    </>
  );
};

export const UserEmail = (email: string): JSX.Element => (
  <>
    <Box width="248px">
      <Text color={Colors.GRAY_7} noWrap={true} showTooltipOnWrap={true}>
        {email}
      </Text>
    </Box>
  </>
);

export const RoleUser = (role: UserRole[]): JSX.Element => (
  <>
    <Text color={Colors.GRAY_7}>{role[0]?.name}</Text>
  </>
);

export const contextMenuColumn = (
  email: string,
  t: TFunction<"translation">,
  handleContextMenuClickAway: () => void,
  handlerContextMenu: (client: User) => void,
  handleContextItemClick: (currentItem: ContextMenuOptions | undefined, email: string) => void,
  selectedClientUserEmail: string,
  isloginUser: boolean,
  isExternal: boolean,
  row: Record<string, unknown>,
  disable: boolean
): JSX.Element => {
  const getOptions = isExternal
    ? [
        {
          item: t(manageClientTeamEnum.EDIT_INFO)?.toUpperCase(),
        },
        {
          item: t(manageClientTeamEnum.REMOVE)?.toUpperCase(),
        },
      ]
    : [
        {
          item: t(manageClientTeamEnum.REMOVE)?.toUpperCase(),
        },
      ];
  const isDisabled = Boolean(
    row?.additionInProgress || row?.deletionInProgress || disable || row?.disableAllRemoveOptions
  );

  return (
    <>
      {!isloginUser ? (
        <ContextMenu
          isOpen={selectedClientUserEmail === email}
          height="70vh"
          iconSize="medium"
          handleClickAway={() => handleContextMenuClickAway()}
          handleMenuList={() => handlerContextMenu(row as User)}
          onClickItem={(currentItem) => handleContextItemClick(currentItem, email)}
          options={getOptions}
          width="15ch"
          disabled={isDisabled}
        />
      ) : (
        <></>
      )}
    </>
  );
};
