import { useState, useEffect, useRef } from "react";
import { useLanguage } from "@cortex-libs/dmf";
import { useConfiguration } from "@cortex-libs/dmf";

type WalkMeProps = {
  allowed: string | boolean;
};

const DEFAULT_LANG = "en-US";
const WALKME_SCRIPTS_TO_VALIDATE = document.getElementsByClassName("walkme-to-remove");

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _walkmeConfig?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    walkme_ready?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    WalkMeAPI?: any;
  }
}

export function useWalkMe(props: WalkMeProps): {
  isWalkMeReady: boolean;
  isWalkMeInjected: boolean;
} {
  const { isLanguageChange } = useLanguage();
  const [isWalkMeReady, setIsWalkMeReady] = useState(false);
  const [isWalkMeInjected, setIsWalkMeInjected] = useState(false);
  const { baseConfiguration } = useConfiguration();
  const { allowed } = props;

  const scriptsRef = useRef<Array<Element>>([]);
  const numScriptsRef = useRef<number>(0);

  useEffect(() => {
    if (!allowed || isWalkMeInjected || !baseConfiguration?.walkMeSrc) {
      return;
    }

    // Preparing WalkMe external script markup.
    const walkme = document.createElement("script");
    walkme.type = "text/javascript";
    walkme.async = true;
    walkme.src = `${baseConfiguration?.walkMeSrc}`;
    walkme.className = "optanon-category-3";
    walkme.crossOrigin = "";

    // Declaring initialization function.
    window.walkme_ready = () => {
      let i;
      scriptsRef.current = Array.prototype.slice.call(WALKME_SCRIPTS_TO_VALIDATE);
      numScriptsRef.current = scriptsRef.current.length;

      for (i = 0; i < numScriptsRef.current; i++) {
        const updatedScript = scriptsRef.current[i];

        if (updatedScript) {
          updatedScript?.removeAttribute("integrity");
        }
      }
      setIsWalkMeReady(true);
    };

    // Configuring WalkMe
    window._walkmeConfig = { smartLoad: true }; // eslint-disable-line no-underscore-dangle

    // Adding WalkMe script into the DOM.
    const s = document.getElementsByTagName("script")[0];
    s?.parentNode?.insertBefore(walkme, s);

    document.querySelectorAll(".walkme-custom-icon-outer-div")[0]?.remove();

    setIsWalkMeInjected(true);
  });

  useEffect(() => {
    // Syncing Cortex and WalkMe locales.
    if (isWalkMeReady && isLanguageChange) {
      const currentLang = window.WalkMeAPI.getCurrentLanguage();
      if (currentLang === isLanguageChange) {
        return;
      }
      const success = window.WalkMeAPI.changeLanguage(isLanguageChange);
      // Falls-back to default language mechanism.
      if (!success && currentLang !== DEFAULT_LANG) {
        window.WalkMeAPI.changeLanguage(DEFAULT_LANG);
      }
    }
  }, [isWalkMeReady, isLanguageChange]);

  return { isWalkMeReady, isWalkMeInjected };
}
