/*
This component will be deleted in future as we will use new structure to display notification.
All the notifications will be created automatically based on notification payload.
We don't have need to add additional cases here.
*/
import { useHistory } from "react-router-dom";
import { TFunction } from "react-i18next";
import {
  useConfiguration,
  ENGAGEMENT_ROUTES,
  useMyEngagementList,
  useCurrentUser,
  AppAdminRole,
} from "@cortex-libs/dmf";
import { Link, Button, ButtonTypes } from "@cortex-libs/prism";
import { useTranslation } from "@cortex-libs/i18n";
import { getCompletePath } from "@cortex-modules/data-kitchen";

import { UserNotification } from "../UserNotificationToasterProps";
import {
  UserNotificationMethods,
  UserNotificationStatus,
  UserNotificationConstants,
} from "./constant";

export const AdditionalTitleCases = (
  t: TFunction<"translation">,
  notification: UserNotification
): string => {
  const { payload } = notification;
  switch (payload?.methodName) {
    case UserNotificationMethods.DELETE_ENGAGEMENT:
      switch (payload?.completionState?.toLowerCase()) {
        case UserNotificationStatus.SUCCESS:
          return t("translations:user-notifications.delete-success-title");
        case UserNotificationStatus.FAILED:
          return t("translations:user-notifications.delete-failure-title");
      }
      break;

    case UserNotificationMethods.ROLLFORWARD_ENGAGEMENT:
      switch (payload?.completionState?.toLowerCase()) {
        case UserNotificationStatus.SUCCESS:
          return t("translations:engagement.new.engagements-table.success.title");
        case UserNotificationStatus.FAILED:
          return t("translations:engagement.new.engagements-table.error.title");

        case UserNotificationStatus.PARTIALLYCOMPLETE:
          return t("translations:engagement.new.engagements-table.partially-complete.title");

        default:
          return "";
      }
  }
  return "";
};

export const AdditionalDescriptionCases = (props: {
  notification: UserNotification;
}): JSX.Element => {
  const interpolation = {
    prefix: "${",
    suffix: "}",
  };
  const { notification } = props;
  const { t } = useTranslation();
  const { baseConfiguration } = useConfiguration();
  const { payload } = notification;
  switch (payload?.methodName) {
    case UserNotificationMethods.DELETE_ENGAGEMENT:
      switch (payload?.completionState?.toLowerCase()) {
        case UserNotificationStatus.SUCCESS:
          return t("translations:user-notifications.delete-success-description", {
            engagementName: String(payload?.engagementName),
            interpolation,
          });
        case UserNotificationStatus.FAILED:
          return (
            <>
              {t("translations:user-notifications.delete-failure-description", {
                engagementName: String(payload?.engagementName),
                interpolation,
              })}
              <Link
                size="medium"
                href={
                  baseConfiguration?.contactSupportURL ||
                  UserNotificationConstants.CONTACT_SUPPORT_LINK
                }
                target="_blank"
                alwaysCursor
              >
                {t("translations:user-notifications.delete-failure-description-link")}
              </Link>
            </>
          );
      }
      break;
    case UserNotificationMethods.ROLLFORWARD_ENGAGEMENT:
      switch (payload?.completionState?.toLowerCase()) {
        case UserNotificationStatus.PARTIALLYCOMPLETE:
          return (
            <>
              {t("translations:engagement.new.engagements-table.partially-complete.message", {
                engagementName: String(payload?.engagementName),
              })}
            </>
          );

        case UserNotificationStatus.SUCCESS:
          return (
            <>
              {t("translations:engagement.new.engagements-table.success.message", {
                engagementName: String(payload?.engagementName),
              })}
            </>
          );
        case UserNotificationStatus.FAILED:
          return (
            <>
              {t("translations:engagement.new.engagements-table.error.message", {
                engagementName: String(payload?.engagementName),
              })}
            </>
          );
      }
      break;
  }
  return <></>;
};

export const AdditionalFooterCases = (props: {
  notification: UserNotification;
  onClose: (index: number) => void;
}): JSX.Element => {
  const { notification, onClose } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const { loggedInUser } = useCurrentUser();
  const isAppAdmin = loggedInUser?.appRoles?.some((role) => role === AppAdminRole.name);
  const { myEngagementList } = useMyEngagementList(isAppAdmin as boolean);

  const navigateToEngagement = (engagementId: string) => {
    onClose(0);
    const isDeloitteClientOwner = myEngagementList.map((e) => e.id).includes(engagementId)
      ? false
      : true;
    const path = getCompletePath(
      isDeloitteClientOwner
        ? ENGAGEMENT_ROUTES.ENGAGEMENT_MANAGEMENT_DETAIL
        : ENGAGEMENT_ROUTES.ENGAGEMENT_HOME_DETAIL,
      ["id"],
      [engagementId]
    );
    history.push(path);
  };

  const { payload } = notification;
  switch (payload?.methodName) {
    case UserNotificationMethods.DELETE_ENGAGEMENT:
      switch (payload?.completionState?.toLowerCase()) {
        case UserNotificationStatus.SUCCESS:
          return <></>;
      }
      break;
    case UserNotificationMethods.ROLLFORWARD_ENGAGEMENT:
      switch (payload?.completionState?.toLowerCase()) {
        case UserNotificationStatus.PARTIALLYCOMPLETE:
          return (
            <Button
              buttonType={ButtonTypes.SECONDARY}
              onClick={() => navigateToEngagement(payload?.engagementId || "")}
            >
              {t("translations:engagement.new.engagements-table.partially-complete.button")}
            </Button>
          );
        case UserNotificationStatus.SUCCESS:
          return (
            <Button onClick={() => navigateToEngagement(payload?.engagementId || "")}>
              {t("translations:engagement.new.engagements-table.success.button")}
            </Button>
          );
      }
      break;
  }
  return <></>;
};
