import {
  Box,
  BrandStyles,
  Text,
  Link,
  TextVariants,
  ButtonTypes,
  Button,
} from "@cortex-libs/prism";

import { useStyles } from "../@constants/LoginStyle";
import { Documents } from "../@constants/LoginFooter";
import { openOneTrustPopup, isCurrentUrlExcuded } from "../../../utils/onetrust-utils";

export const LoginFooter = (): JSX.Element => {
  const { linkStyle, footerText, footerFlexBox, footerStyles, copyRight, p0, cookieBtnStyles } =
    useStyles();

  const oneTrustDisabled = isCurrentUrlExcuded();

  return (
    <Box className={footerStyles}>
      <Box className={`${footerText} ${footerFlexBox} ${linkStyle}`}>
        <Text color={BrandStyles.Colors.GRAY_7}>Need help? Email us at :</Text>
        <Link href="mailto: DeloitteCortexSupport@deloitte.com" underline="always">
          <Text color={BrandStyles.Colors.GRAY_7}>DeloitteCortexSupport@deloitte.com</Text>
        </Link>
      </Box>
      <Box className={`${footerText} ${footerFlexBox} ${linkStyle} ${copyRight}`}>
        <Text color={BrandStyles.Colors.GRAY_7}>
          {"Copyright © Deloitte Development LLC 2023. All rights reserved. See "}
        </Text>
        <Link href={Documents.TOU} target="_blank" underline="always">
          <Text color={BrandStyles.Colors.GRAY_7}>Terms of Use,</Text>
        </Link>
        <Link href={Documents.PRIVACY_STATEMENT} target="_blank" underline="always">
          <Text color={BrandStyles.Colors.GRAY_7}>Privacy Statement</Text>
        </Link>
        <Text color={BrandStyles.Colors.GRAY_7}>{"and "}</Text>
        <Link href={Documents.NOTICES} target="_blank" underline="always">
          <Text color={BrandStyles.Colors.GRAY_7}>Notices</Text>
        </Link>
        <Text color={BrandStyles.Colors.GRAY_7}>for more information.</Text>
        {!oneTrustDisabled && (
          <Button
            className={`${p0} ot-sdk-show-settings ${cookieBtnStyles}`}
            buttonType={ButtonTypes.TEXT}
            onClick={openOneTrustPopup}
          >
            <Text type={TextVariants.BODY_COPY_SMALL}>Cookies Settings</Text>
          </Button>
        )}
        <Link href={Documents.COOKIE_NOTICE} target="_blank" underline="always">
          <Text color={BrandStyles.Colors.GRAY_7}>Cookies Notice</Text>
        </Link>
      </Box>
      <Box className={footerText}>
        <Text>
          Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited, a UK private company
          limited by guarantee (“DTTL”), its network of member firms, and their related entities.
          DTTL and each of its member firms are legally separate and independent entities. DTTL
          (also referred to as “Deloitte Global”) does not provide services to clients. In the
          United States, Deloitte refers to one or more of the US member firms of DTTL, their
          related entities that operate using the “Deloitte” name in the United States and their
          respective affiliates. Certain services may not be available to attest clients under the
          rules and regulations of public accounting. Please see
          <Link
            size="small"
            href="http://www.deloitte.com/about"
            target="_blank"
            underline="always"
          >
            {" www.deloitte.com/about "}
          </Link>
          to learn more about our global network of member firms.
        </Text>
      </Box>
    </Box>
  );
};
