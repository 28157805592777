import { useState } from "react";
import { CreateEngagementModal, CreateEngagementAsyncModal } from "@cortex-modules/engagement";
import {
  Button,
  ButtonTypes,
  Box,
  Card,
  CardTemplate,
  Grid,
  Icon,
  Text,
  TextVariants,
  Colors,
} from "@cortex-libs/prism";
import { IconNames } from "@cortex-libs/assets";
import {
  Action,
  Permission,
  useConfiguration,
  useCurrentUser,
  useGeoList,
  useUserGlobalPermissions,
} from "@cortex-libs/dmf";
import { useTranslation } from "@cortex-libs/i18n";
import { isUserInDefaultGeo } from "../../../utils/default-geo-utils";

import { EngagementConstants } from "../@constants/constants";

export const WithoutEngagements = (): JSX.Element => {
  const { t } = useTranslation();
  const { loggedInUser, userGlobalAccess } = useCurrentUser();

  const { canExecuteAction: canAddEngagement } = useUserGlobalPermissions(
    [Permission.ENGAGEMENTS],
    Action.Add
  );

  const name = loggedInUser?.firstName;

  const { geoList } = useGeoList();
  const [isCreateEngagementModalOpen, setIsCreateEngagementModalOpen] = useState<boolean>(false);
  const [isCreateEngagementAsyncModalOpen, setIsCreateEngagementAsyncModalOpen] =
    useState<boolean>(false);
  const { baseConfiguration } = useConfiguration();

  const validateAddEngagementButton = () => {
    const checkDefaultGeo = isUserInDefaultGeo(geoList, loggedInUser?.geoCode || "");
    return !userGlobalAccess?.permissions.engagements
      ? checkDefaultGeo
      : canAddEngagement && checkDefaultGeo;
  };

  const handleNewEngagementButtonClick = () => {
    const asyncMode = !!JSON.parse(
      String(
        baseConfiguration?.isEngagementCreationModeAsync ??
          EngagementConstants.DEFAULT_ENGAGEMENT_CREATION_VALUE
      ).toLowerCase()
    );
    if (asyncMode) setIsCreateEngagementAsyncModalOpen(true);
    else setIsCreateEngagementModalOpen(true);
  };

  const handleCompleteNewEngagement = () => {
    setIsCreateEngagementModalOpen(false);
  };

  const handleCompleteNewEngagementAsync = () => setIsCreateEngagementAsyncModalOpen(false);

  const handleEngagementCreationError = () => {
    handleCompleteNewEngagement();
    setIsCreateEngagementModalOpen(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CardFooter = (t: any): JSX.Element => (
    <Box mt={4} display="flex">
      {validateAddEngagementButton() && (
        <Button buttonType={ButtonTypes.PRIMARY} onClick={handleNewEngagementButtonClick}>
          {t("translations:landing.engagement.new.title")}
        </Button>
      )}
    </Box>
  );

  return (
    <>
      <CreateEngagementModal
        isCreateEngagementModalOpen={isCreateEngagementModalOpen}
        onCloseButtonClick={() => setIsCreateEngagementModalOpen(false)}
        completeNewEngagement={handleCompleteNewEngagement}
        engagementCreationError={handleEngagementCreationError}
      />
      <CreateEngagementAsyncModal
        isCreateEngagementModalOpen={isCreateEngagementAsyncModalOpen}
        onCloseButtonClick={() => setIsCreateEngagementAsyncModalOpen(false)}
        completeNewEngagement={handleCompleteNewEngagementAsync}
        engagementCreationError={handleEngagementCreationError}
      />
      <Box>
        <Text color={Colors.GRAY_7}>
          {t("common:greetings")} {name},
        </Text>
      </Box>
      <Box pt={2}>
        <Text type={TextVariants.HEADING_1}>
          {t("translations:landing.start-width-cortex.title")}
        </Text>
      </Box>
      <Box my={5}>
        <Grid container>
          <CardTemplate
            cardProps={{ variant: "elevation", elevation: 0, boxShadow: "0" }}
            description={t("translations:landing.start-width-cortex.description")}
            footerComponent={CardFooter(t)}
            gridSize={6}
            cardSpacing={0}
            ellipsis={false}
            bodyContentHeight={75}
          />
        </Grid>
      </Box>
      <Box>
        <Card
          variant="outlined"
          square
          boxShadow="none"
          borderProps={{ border: `none`, borderRadius: "4px" }}
        >
          <Box p={2}>
            <Box pb={2}>
              <Icon fontSize="large" color={Colors.BLACK} name={IconNames.Cortex} />
            </Box>
            <Grid item xs={6}>
              <Box pb={2}>
                <Text type={TextVariants.SUB_HEADING_1}>
                  {t("translations:landing.engagement.appear.title")}
                </Text>
              </Box>
            </Grid>
          </Box>
        </Card>
      </Box>
    </>
  );
};
