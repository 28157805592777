import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  touModal: {
    height: "85vh",
    overflow: "hidden",
    width: "1128px",
    paddingTop: "24px",
  },
  touModalDeScription: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "14px",
    marginBottom: "40px",
  },
});
