import "./CookiesNotice.css";
import deloittelogo from "./deloittelogo.png";

// function CookiesNotice() {
export const CookiesNotice: React.FC = () => {
  return (
    <div
      className="cookies-notice"
      style={{
        backgroundColor: "#FFFFFF",
        margin: "10px 100px 100px 200px",
      }}
    >
      <body
        style={{
          margin: "50px",
        }}
      >
        &nbsp;&nbsp;
        <img id="bannerLogo" src={deloittelogo} height="80px" width="200px" />
        <hr></hr>
        <p>Last revised: 28 April 2023 </p>
        <h3>
          <a className="optanon-show-settings" href="#">
            Manage cookie preferences
          </a>
        </h3>
        <h4>Cookie Notice</h4>
        <p>
          <b>What is a cookie?</b>
        </p>
        <p>
          A cookie is a small text file placed on your device by the websites that you visit.
          Cookies are then sent back to the website on each subsequent visit, or to another webpage
          that recognizes that cookie. Cookies are used in order to make the website work, or to
          work more efficiently, as well as to provide information to the owners of the website.
          Such information may be used to provide a more personalized and responsive service.
          Cookies may be either first party cookies set directly by us to your device, or
          third-party cookies set by a third-party provider on our behalf. Whenever you use this
          system, information may be collected through the use of cookies and other similar
          technologies (e.g., web beacons, tags, scripts, local storage).
        </p>
        <p>
          This Cookie Notice is a part of our Privacy Notice. For more information about us, and how
          we process personal information, please see our&nbsp;
          <a className="cookies-a" href="https://www2.deloitte.com/uk/en/footerlinks1/privacy.html">
            Privacy Notice.
          </a>
        </p>
        <p>
          <b>Cookies we may use and why</b>
        </p>
        <p>
          This system uses cookies and web beacons that are classified into the following
          categories:
        </p>
        <ul>
          <li>
            “Strictly Necessary” which are necessary for the system to function and cannot be
            switched off in our systems. They are set in response to actions made by you which
            amount to a request for services, such as authenticating into the system or managing
            your session with the requested service.
          </li>
          <li>
            “Analytics and Performance” which are necessary for our non-public and internal systems
            to measure audience and usage statistics, as well as content consumption. These enable
            us to obtain relevant usage information to produce internal analytics so we can monitor
            and improve the performance of our systems. These cannot be switched off in our
            non-public or internal systems.
          </li>
          <li>
            "Preference or Functionality" which allow websites to remember the users’ respective
            site preferencesand choices. These cannot be switched off in our non-public or internal
            systems.
          </li>
        </ul>
        <p>
          To view a list of the cookies that are used by the system, click on “Manage cookie
          preferences” at the top of this Cookie Notice.
        </p>
        <p>
          <b>How long will cookies remain on my device?</b>
        </p>
        <p>
          The length of time cookies will stay on your device will depend on whether it is a
          “persistent” or “session” cookie. A persistent cookie will be stored by a web browser and
          will remain valid until its set expiration date, unless deleted by you before the
          expiration date. A session cookie, on the other hand, will expire at the end of your web
          session, when the web browser is closed.
        </p>
        &nbsp;&nbsp;
      </body>
    </div>
  );
};

export default CookiesNotice;
