import {
  Box,
  BrandStyles,
  Text,
  Link,
  TextVariants,
  ButtonTypes,
  Button,
} from "@cortex-libs/prism";
import { useTranslation } from "@cortex-libs/i18n";
import { useStyles } from "../@constants/Styles";
import { Documents, DocumentsPath, DocumentsNewWindowTitle } from "../@constants/LandingFooter";
import { useConfiguration, useFetchTOUDocument } from "@cortex-libs/dmf";
import { openOneTrustPopup } from "../../../utils/onetrust-utils";

export const LandingFooter = (): JSX.Element => {
  const { baseConfiguration } = useConfiguration();
  const { openDocInNewTab } = useFetchTOUDocument();

  const { t } = useTranslation();
  const { linkStyle, footerText, footerFlexBox, footerStyles, alignMargin, p0, cookieBtnStyles } =
    useStyles();

  return (
    <Box className={footerStyles} bgcolor={BrandStyles.Colors.BACKGROUND_1}>
      <Box className={`${footerText} ${footerFlexBox} ${linkStyle}`}>
        <Text type={TextVariants.BODY_COPY_SMALL}>
          {`${t("translations:landing.footer.need-help.description")}`}
        </Text>
        <Text type={TextVariants.BODY_COPY_SMALL}>
          {`${t("translations:landing.footer.need-help.see")}`}
        </Text>
        <Link
          underline="always"
          onClick={() =>
            openDocInNewTab(
              baseConfiguration?.termsOfUsePath || DocumentsPath.TOU,
              Documents.TOU,
              false,
              DocumentsNewWindowTitle.TOU
            )
          }
          alwaysCursor
        >
          <Text type={TextVariants.BODY_COPY_SMALL}>
            {`${t("translations:landing.footer.need-help.terms-of-use")},`}
          </Text>
        </Link>
        <Link
          href={baseConfiguration?.privacyStatementURL || DocumentsPath.PRIVACY_STATEMENT}
          target="_blank"
          underline="always"
        >
          <Text type={TextVariants.BODY_COPY_SMALL}>
            {`${t("translations:landing.footer.need-help.privacy-statement")}`}
          </Text>
        </Link>
        <Text type={TextVariants.BODY_COPY_SMALL}>
          {`${t("translations:landing.footer.need-help.and")}`}
        </Text>
        <Link
          onClick={() =>
            openDocInNewTab(
              baseConfiguration?.frontendNoticesPath || DocumentsPath.NOTICES,
              Documents.NOTICES,
              false,
              DocumentsNewWindowTitle.NOTICES
            )
          }
          underline="always"
          alwaysCursor
        >
          <Text type={TextVariants.BODY_COPY_SMALL}>
            {`${t("translations:landing.footer.need-help.notices")}`}
          </Text>
        </Link>
        <Text type={TextVariants.BODY_COPY_SMALL}>
          {`${t("translations:landing.footer.need-help.for-more-info")}`}
        </Text>
      </Box>
      <Box className={`${footerText} ${footerFlexBox} ${linkStyle} ${alignMargin}`}>
        <Button
          className={`${p0} ${cookieBtnStyles} ot-sdk-show-settings`}
          buttonType={ButtonTypes.TEXT}
          onClick={openOneTrustPopup}
        >
          <Text type={TextVariants.BODY_COPY_SMALL}>
            {`${t("translations:landing.footer.need-help.cookies-setting")}`}
          </Text>
        </Button>
        <Link
          onClick={() =>
            openDocInNewTab(
              baseConfiguration?.cookieNoticesPath || DocumentsPath.COOKIE_NOTICES,
              Documents.COOKIE_NOTICE
            )
          }
          underline="always"
          alwaysCursor
        >
          <Text type={TextVariants.BODY_COPY_SMALL}>
            {`${t("translations:landing.footer.need-help.cookies-notice")}`}
          </Text>
        </Link>
      </Box>
    </Box>
  );
};
