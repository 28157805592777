import { makeStyles } from "@material-ui/core/styles";
import { BrandStyles } from "@cortex-libs/prism";

const { Typography, Colors } = BrandStyles;

export const useStyles = makeStyles({
  IconStyle: () => ({
    justifyContent: "right",
    "& [class*='MuiBox-root']": {
      paddingRight: "0px",
    },
  }),
  outerCircule: {
    padding: "10px",
    borderRadius: "50%",
    border: `1px solid ${Colors.GRAY_2}`,
    lineHeight: 0,
  },
  linkStyle: {
    "& [class*='MuiTypography-colorPrimary']": {
      color: Colors.GRAY_7,
    },
  },
  footerText: {
    "& [class*=p]": {
      color: Colors.GRAY_7,
      fontSize: Typography.FIELD_LABEL_FONT_SIZE,
      fontWeight: Typography.SMALL_LINK_FONT_WEIGHT,
      lineHeight: Typography.FIELD_LABEL_LINE_HEIGHT,
      "& div": {
        display: "inline",
      },
    },
    "& [class*=a]": {
      color: Colors.GRAY_7,
      fontSize: Typography.FIELD_LABEL_FONT_SIZE,
      marginRight: "2px",
    },
  },
  copyRight: {
    "& [class*=p]": {
      fontSize: Typography.BODY_SMALL_FONT_SIZE,
    },
    "& [class*=a]": {
      fontSize: Typography.BODY_SMALL_FONT_SIZE,
    },
  },
  footerFlexBox: {
    display: "flex",
    flexDirection: "row",
  },
  alignMargin: {
    marginLeft: "auto",
  },
  p0: {
    padding: 0,
  },
  container: {
    minHeight: "100vh",
  },
  footerStyles: {
    position: "sticky",
    bottom: 0,
    left: 0,
    width: "calc(100% - 96px)",
    padding: "32px 48px",
    top: "100%",
    marginTop: "40px",
    background: Colors.BACKGROUND_1,
  },
  cookieBtnStyles: {
    height: "0.9rem",
    textDecoration: "underline",
  },
});
