import { useCurrentUser } from "@cortex-libs/dmf";
import { useTranslation } from "@cortex-libs/i18n";
import { Box, Colors, Text, TextVariants } from "@cortex-libs/prism";
import { Engagements } from "../Landing/components/engagements";

export const LandingFramework = (): JSX.Element => {
  const { t } = useTranslation();
  const { loggedInUser } = useCurrentUser();
  const name = loggedInUser?.firstName;

  return (
    <Box>
      <Box mt={5}>
        <Text type={TextVariants.BODY_COPY_REGULAR} color={Colors.GRAY_7}>
          {t("translations:landing.welcome-to-cortex.title")}, {name}!
        </Text>
      </Box>
      <Box pt={2}>
        <Text type={TextVariants.HEADING_1}>
          {t("translations:landing.engagement.select.title")}
        </Text>
      </Box>
      <Box pt={2}>
        <Text type={TextVariants.BODY_COPY_REGULAR}>
          {t("translations:landing.engagement.select.engagement-to-start")}
        </Text>
      </Box>

      <Box pt={5}>
        <Engagements />
      </Box>
    </Box>
  );
};
