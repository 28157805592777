import React, { useState, useEffect, memo } from "react";
import { intervalToDuration } from "date-fns";
import { useNotificationBanner } from "@cortex-libs/dmf";
import { AlertVariants, Box, Icon, Notification } from "@cortex-libs/prism";
import { IconNames } from "@cortex-libs/assets";
import { BannerProps, MaintenanceBannerProps } from "./@constants/MaintenanceBanner";
import { bannerStyles } from "./@constants/BannerStyles";

export const MaintenanceBanner = memo((props: MaintenanceBannerProps): JSX.Element => {
  const classes = bannerStyles();
  const { isOpen, style } = props;
  const { regularBannerList } = useNotificationBanner();
  const Banners = (props: BannerProps): JSX.Element => {
    const { banner } = props;
    const [countDown, setCountDown] = useState<string>("");
    const closingTime = "00:00:00";
    const interval = intervalToDuration({
      start: new Date(),
      end: new Date(banner?.endDate),
    });
    // Use hh:mm:ss format here
    const { hours, minutes, seconds } = interval;
    const difference = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
    useEffect(() => {
      if (countDown === closingTime) {
        setCountDown(closingTime);
      } else {
        setTimeout(() => {
          setCountDown(difference);
        }, 1000);
      }
    }, [difference, countDown]);

    return (
      <>
        {banner?.text?.length && countDown !== closingTime && (
          <Notification
            isNotificationBanner
            icon={<Icon name={IconNames.StatusError} />}
            severity={AlertVariants.ERROR}
            message={
              <>
                <span style={{ marginRight: "15px" }}>{banner?.text}</span>
                {countDown}
              </>
            }
            isOpen={countDown !== closingTime}
            showClose={false}
          />
        )}
      </>
    );
  };
  return (
    <>
      {isOpen && regularBannerList[0]?.text?.length && (
        <Box className={classes.maintenanceBanner} {...style}>
          {regularBannerList?.map(
            (banner) =>
              new Date(banner?.endDate) > new Date() && (
                <React.Fragment key={banner?.id}>
                  <Banners banner={banner} />
                </React.Fragment>
              )
          )}
        </Box>
      )}
    </>
  );
});
