import { Box, Progress, ProgressTypes } from "@cortex-libs/prism";
import { ServiceInitializerProps } from "./@constants/ServiceInitializerProps";
import { useServiceInitializer } from "../../hooks/service-initializer/use-service-initializer";
import { useCurrentUser } from "@cortex-libs/dmf";

export const ServiceInitializer = (props: ServiceInitializerProps): JSX.Element => {
  const { children } = props;
  const { requiredServicesReady } = useServiceInitializer();
  const { localError } = useCurrentUser();

  return !requiredServicesReady && !localError ? (
    <Box display="flex" mt={10} width="100%" justifyContent="center">
      <Progress status="info" type={ProgressTypes.CIRCULAR} />
    </Box>
  ) : (
    children
  );
};
