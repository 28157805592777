export enum URGENCY_OPTION {
  URGENCY_VERY_LOW = "translations:create-support-ticket-modal.urgencyVeryLow",
  URGENCY_LOW = "translations:create-support-ticket-modal.urgencyLow",
  URGENCY_MEDIUM = "translations:create-support-ticket-modal.urgencyMedium",
}

export const URGENCY_OPTIONS = [
  {
    text: URGENCY_OPTION.URGENCY_VERY_LOW,
    value: "3",
  },
  {
    text: URGENCY_OPTION.URGENCY_LOW,
    value: "2",
  },
  {
    text: URGENCY_OPTION.URGENCY_MEDIUM,
    value: "1",
  },
];

export const URGENCY_OPTIONS_DEFAULT = "2";
export const URGENCY_OPTIONS_MEDIUM = "1";

export enum MODAL_CONSTANTS {
  COPY_FORMAT = "text",
}
