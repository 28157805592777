import { memo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useConfiguration } from "@cortex-libs/dmf";
import {
  Link,
  Toast,
  ToastVariant,
  Button,
  ButtonTypes,
  Grid,
  Box,
  Text,
} from "@cortex-libs/prism";
import { useTranslation } from "@cortex-libs/i18n";
import { get } from "lodash";
import { IconNames } from "@cortex-libs/assets";

import { UserNotification, UserNotificationToasterProps } from "./UserNotificationToasterProps";
import {
  UserNotificationConstants,
  UserNotificationStatus,
  ToasterElements,
} from "./@constants/constant";
import { useStyles } from "./@constants/Styles";
import {
  AdditionalTitleCases,
  AdditionalDescriptionCases,
  AdditionalFooterCases,
} from "./@constants/AdditionalToasterCases";
import configData from "./@constants/signalr-notification-config.json";

export const UserNotificationToaster = memo((props: UserNotificationToasterProps): JSX.Element => {
  const { notifications, onClose, handleActions } = props;
  const { userNotificationToasterStyles, userNotificationToasterWrapper } = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { baseConfiguration } = useConfiguration();

  const [toasterElements, setToasterElements] = useState<ToasterElements>({} as ToasterElements);

  /**
   * @description: To use this function,
   * 1. Add value inside translation.json in format =>  {methodName:{completionState :{ title : 'My title' }}} under user-notification-toaster object.
   * @param notification: SignalR notification object.
   **/
  const getNotificationKey = (notification: UserNotification): string => {
    const componentKey = "user-notification-toaster";
    const { payload } = notification;
    if (payload) {
      return `${componentKey}.${payload?.methodName?.toLowerCase()}.${payload?.completionState?.toLowerCase()}`;
    }
    return "";
  };

  /**
   * @description : Handles Click of Button or Link.
   * If url is local then it will navigate inside application without reload.
   * If url is external it will open in new tab.
   * @param : {{url:string}}
   **/
  const handleOnClick = (url: string) => {
    if (url) {
      let newUrl = url;
      const splitUrl = newUrl.split(".");
      const urlKeyPrefix = splitUrl[0];

      if (newUrl?.includes(".") && baseConfiguration) {
        const urlKey = [...splitUrl[1]][0]?.toLowerCase() + splitUrl[1]?.slice(1);
        if (urlKeyPrefix === UserNotificationConstants.APPSETTING) {
          newUrl = baseConfiguration[urlKey] ?? newUrl;
        }
        if (urlKeyPrefix === UserNotificationConstants.ACTIONS) {
          handleActions(urlKey, notifications[0]?.payload);
        }
      }
      if (urlKeyPrefix !== UserNotificationConstants.ACTIONS) {
        try {
          const origin = new URL(newUrl)?.origin;
          if (origin) {
            window.open(newUrl, "_blank");
          }
        } catch (_) {
          const navigationUrl = [...newUrl][0] === "/" ? newUrl : `/${newUrl}`;
          history.push(navigationUrl);
        }
      }
    }
    onClose(0);
  };

  /**
   * @description: To use this function,
   * 1. In translation.json wrap title of link inside __ (double underscore) for eg.    __My Link__
   * 2. Link href should be added in signalr-notification-config.json file.
   * @param text: text on which style will be applied.
   * @param additionalData : key specific data in signalr-notification-config.json
   **/
  const getLinksInText = (
    notification: UserNotification,
    text: string,
    additionalData: Record<string, string>
  ): string | React.ReactNode => {
    if (text?.length && text.includes(UserNotificationConstants.LINK_DETECTOR)) {
      const splitText = text
        .replace(
          UserNotificationConstants.LINK_DETECTOR_SUFIX,
          UserNotificationConstants.LINK_DETECTOR_PREFIX
        )
        .split(UserNotificationConstants.LINK_DETECTOR_PREFIX);
      const getUrl = (key: string) => additionalData[key.replace(" ", "")];

      return (
        <>
          {splitText.map((text, index) => (
            <>
              {(index + 1) % 2 === 0 ? (
                <>
                  {getUrl(text) ? (
                    <Link
                      size="large"
                      target="_blank"
                      alwaysCursor
                      underline="always"
                      onClick={() => handleOnClick(getUrl(text))}
                    >
                      {t(`translations:${getNotificationKey(notification)}.${text}`) || text}
                    </Link>
                  ) : (
                    <Text display="inline">{`${UserNotificationConstants.LINK_DETECTOR_PREFIX}${
                      t(`translations:${getNotificationKey(notification)}.${text}`) || text
                    }${UserNotificationConstants.LINK_DETECTOR_SUFIX}`}</Text>
                  )}
                </>
              ) : (
                <Text display="inline">{text}</Text>
              )}
            </>
          ))}
        </>
      );
    }
    return text;
  };

  /**
   * @description: This function returns all Dynamic Data of Toaster. To use this function,
   * 1. In signalr-notification-config.json add your values in format => {methodName:{completionState-code :{your data}}}.
   * 2. Add dynamic data like engagmentId inside {{}}.
   * @param notification: Signal_R notification.
   **/
  const getAdditionalData = (notification: UserNotification): Record<string, string> => {
    const jsonKey = getNotificationKey(notification);
    const { payload } = notification;
    try {
      const data = get(JSON.parse(JSON.stringify(configData)), jsonKey);
      if (data && payload) {
        const getKey = (val: string): string =>
          Object.keys(data).find((key) => data[key] === val) || "";
        Object.keys(payload).map((payloadKey) =>
          Object.values(data as unknown as Record<string, string>).map((dataVal) =>
            dataVal.includes(`{{${payloadKey}}}`)
              ? (data[getKey(dataVal)] = data[getKey(dataVal)]?.replace(
                  `{{${payloadKey}}}`,
                  payload[payloadKey]
                ))
              : {}
          )
        );
        return data as unknown as Record<string, string>;
      }
    } catch (_) {
      return {};
    }
    return {} as Record<string, string>;
  };

  /**
   * @description : Returns title of Toaster.
   * @param notification : Signal R payload.
   * @param additionalData : key specific data in signalr-notification-config.json.
   **/
  const getTitle = (
    notification: UserNotification,
    additionalData: Record<string, string>
  ): string => {
    const titleKey = `translations:${getNotificationKey(notification)}.title`;
    const title = t(titleKey, { ...additionalData, ...notification?.payload });
    if (title) {
      return t(titleKey, { ...additionalData });
    }
    return AdditionalTitleCases(t, notification);
  };

  /**
   * @description : Returns description of Toaster.
   * @param notification : signal R notification payload.
   * @param additionalData : key specific data in signalr-notification-config.json.
   **/
  const getDescription = (
    notification: UserNotification,
    additionalData: Record<string, string>
  ) => {
    const descriptionKey = `translations:${getNotificationKey(notification)}.description`;
    const description = t(descriptionKey, { ...additionalData, ...notification?.payload });
    if (description) {
      return getLinksInText(notification, description, additionalData);
    }
    return <AdditionalDescriptionCases notification={notification} />;
  };

  /**
   * @description : Returns Variant of Toaster.
   * @param notification : signal R notification payload.
   **/
  const getVariant = (notification: UserNotification): ToastVariant => {
    const { payload } = notification;
    const completionState = payload?.completionState?.toLowerCase();
    switch (completionState) {
      case UserNotificationStatus.PARTIALLYCOMPLETE:
        return ToastVariant.WARNING;
      case UserNotificationStatus.SUCCESS:
        return ToastVariant.SUCCESS;
      case UserNotificationStatus.FAILED:
        return ToastVariant.ERROR;
      default:
        return ToastVariant.INFO;
    }
  };

  /**
   * @description : Returns IconName of Toaster.
   * @param notification : signal R notification payload.
   **/
  const getIconName = (notification: UserNotification): IconNames => {
    const { payload } = notification;
    const completionState = payload?.completionState?.toLowerCase();
    switch (completionState) {
      case UserNotificationStatus.PARTIALLYCOMPLETE:
        return IconNames.StatusWarning;
      case UserNotificationStatus.SUCCESS:
        return IconNames.StatusTick;
      case UserNotificationStatus.FAILED:
        return IconNames.StatusError;
      default:
        return IconNames.StatusInformation;
    }
  };

  /**
   * @description : Returns Footer of Toaster. This contains all the Buttons inside footer.
   * @param notification : signal R notification payload.
   * @param additionalData : key specific data in signalr-notification-config.json.
   **/
  const getFooter = (
    notification: UserNotification,
    additionalData: Record<string, string>
  ): React.ReactNode => {
    const buttonData = Object.keys(additionalData)
      ?.filter(
        (key) =>
          key.includes(UserNotificationConstants.BUTTON) &&
          !key.includes(UserNotificationConstants.FEATURE_FLAG)
      )
      ?.map((key, index) => {
        const featureFlagKey =
          additionalData[`${key}${UserNotificationConstants.FEATURE_FLAG}`]?.split(".")[1];
        if (baseConfiguration) {
          const featureFlagValue = baseConfiguration[featureFlagKey]?.toLowerCase();
          const displayButton = featureFlagValue?.length ? featureFlagValue === "true" : true;
          return displayButton ? { title: key, link: Object.values(additionalData)[index] } : {};
        }
        return {};
      })
      ?.filter((data) => Boolean(data?.link));
    const canCreateButtons = Boolean(buttonData?.length);

    if (canCreateButtons) {
      return (
        <Grid container>
          {buttonData.map((data) => (
            <Box mb={1} mr={1}>
              <Grid justifyContent="flex-start" alignItems="center" xs="auto">
                <Button
                  onClick={() => handleOnClick(data?.link || "")}
                  buttonType={ButtonTypes.SECONDARY}
                >
                  {t(`translations:${getNotificationKey(notification)}.${data.title}`, {
                    ...additionalData,
                  }) || t("translations:user-notification-toaster.default-button-text")}
                </Button>
              </Grid>
            </Box>
          ))}
        </Grid>
      );
    }
    return <AdditionalFooterCases notification={notification} onClose={onClose} />;
  };

  /**
   * @description : Closes Toaster Notification.
   * @param _ : Unused
   * @param reason : Where clicked ?, away or on Toaster.
   **/
  const handleToastClose = (_: unknown, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    onClose(0);
  };

  /**
   * @description : Returns should disable auto-hide or not
   * @param  : additionalData
   * @returns Boolean
   **/
  const shouldDisableAutoHide = (additionalData: Record<string, string>) => {
    if (additionalData?.disableAutoHide) {
      return JSON.parse(additionalData?.disableAutoHide?.toLowerCase());
    }
    return false;
  };

  // Sets toaster data
  useEffect(() => {
    if (notifications[0]?.payload) {
      const notification = notifications[0];
      const additionalData = getAdditionalData(notification);
      const title = getTitle(notification, additionalData);
      if (title) {
        setToasterElements({
          variant: getVariant(notification),
          iconName: getIconName(notification),
          title,
          description: getDescription(notification, additionalData),
          footer: getFooter(notification, additionalData),
          disableAutoHide: shouldDisableAutoHide(additionalData),
        });
      } else {
        /* Toaster with insufficient data will be removed here. 
      For eg. No title.
      */
        onClose(0);
      }
    } else {
      setToasterElements({} as ToasterElements);
    }
  }, [notifications]);

  return (
    <>
      {toasterElements?.title?.length && (
        <Box className={userNotificationToasterWrapper}>
          <Toast
            open={true}
            className={userNotificationToasterStyles}
            data-testid="usernotification-toast"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            disableAutoHide={toasterElements.disableAutoHide}
            autoHideDuration={Number(UserNotificationConstants.DEFAULT_AUTO_HIDE_DURATION)}
            variant={toasterElements.variant}
            iconName={toasterElements.iconName}
            title={toasterElements.title}
            description={toasterElements.description}
            footer={toasterElements.footer}
            onClose={handleToastClose}
          />
        </Box>
      )}
    </>
  );
});
