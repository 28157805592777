import ReactDOM from "react-dom";
import "./app/root.css";
import React from "react";
import App from "./app/app";
import { PrismProvider } from "@cortex-libs/prism";
import { RecoilRoot } from "@cortex-libs/dmf";
import { init, AuthenticationProvider } from "./utils/authHelper";

init();

ReactDOM.render(
  <React.StrictMode>
    <AuthenticationProvider>
      <PrismProvider>
        <RecoilRoot>
          <App />
        </RecoilRoot>
      </PrismProvider>
    </AuthenticationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
