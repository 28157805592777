export { S as AggDefDefault } from './assets/agg-def-default.svg-6005bcd8.js';
export { S as AggDefMaterialWeak } from './assets/agg-def-material-weak.svg-e10ba913.js';
export { S as AggDefNotEval } from './assets/agg-def-not-eval.svg-f5ce2492.js';
export { S as AggDefSig } from './assets/agg-def-sig.svg-988d0074.js';
export { S as AggDef } from './assets/agg-def.svg-bed8db51.js';
export { S as Aggregate } from './assets/aggregate.svg-1d1fa850.js';
export { S as AlignCenter } from './assets/align-center.svg-2b6823e1.js';
export { S as AlignJustified } from './assets/align-justified.svg-76c53b92.js';
export { S as AlignLeft } from './assets/align-left.svg-e48bf22a.js';
export { S as AlignRight } from './assets/align-right.svg-f446519b.js';
export { S as Analytics } from './assets/analytics.svg-a319986d.js';
export { S as AppLauncherB } from './assets/app-launcher-b.svg-1ad8a747.js';
export { S as AppLauncher } from './assets/app-launcher.svg-b6a6b978.js';
export { S as Argus } from './assets/argus.svg-94db7772.js';
export { S as ArrowDown } from './assets/arrow-down.svg-8a5c8be8.js';
export { S as ArrowFlip } from './assets/arrow-flip.svg-0a6bd983.js';
export { S as ArrowLeftRight } from './assets/arrow-left-right.svg-a061b75c.js';
export { S as ArrowLeft } from './assets/arrow-left.svg-36babc7d.js';
export { S as ArrowRight } from './assets/arrow-right.svg-69db0b8c.js';
export { S as ArrowThickDown } from './assets/arrow-thick-down.svg-2c75bb15.js';
export { S as ArrowThickLeft } from './assets/arrow-thick-left.svg-aa7edff2.js';
export { S as ArrowThickRight } from './assets/arrow-thick-right.svg-64c2377e.js';
export { S as ArrowThickUp } from './assets/arrow-thick-up.svg-4cb3fd1b.js';
export { S as ArrowUp } from './assets/arrow-up.svg-e63295d2.js';
export { S as AvatarGuest } from './assets/avatar-guest.svg-309fef3b.js';
export { S as Bell } from './assets/bell.svg-fc5ff0b2.js';
export { S as Bin } from './assets/bin.svg-087675e3.js';
export { S as BookClose } from './assets/book-close.svg-b887b66b.js';
export { S as BookOpen } from './assets/book-open.svg-a152583c.js';
export { S as BulletedList } from './assets/bulleted-list.svg-817983b4.js';
export { S as Calendar } from './assets/calendar.svg-bf848751.js';
export { S as CaretDown } from './assets/caret-down.svg-f9df9826.js';
export { S as CaretLeft } from './assets/caret-left.svg-7271dd8f.js';
export { S as CaretRight } from './assets/caret-right.svg-e0d23526.js';
export { S as CaretUp } from './assets/caret-up.svg-806fcb86.js';
export { S as ChangeItem } from './assets/change-item.svg-52d0fddd.js';
export { S as ChangeLog } from './assets/change-log.svg-53d99cd1.js';
export { S as ChartPieFilled } from './assets/chart-pie-filled.svg-a7a44d7b.js';
export { S as ChartPie } from './assets/chart-pie.svg-7e213ab6.js';
export { S as ChevronDoubleLeft } from './assets/Cheveron-Double-Left.svg-21b5e5ac.js';
export { S as ChevronDoubleRight } from './assets/Cheveron-Double-Right.svg-3d435683.js';
export { S as ChevronDown } from './assets/chevron-down.svg-a1746143.js';
export { S as ChevronLeft } from './assets/chevron-left.svg-65ae2cf5.js';
export { S as ChevronRight } from './assets/chevron-right.svg-902690c3.js';
export { S as ChevronThickDown } from './assets/chevron-thick-down.svg-c1db35c3.js';
export { S as ChevronThickLeft } from './assets/chevron-thick-left.svg-d0f7d980.js';
export { S as ChevronThickRight } from './assets/chevron-thick-right.svg-aa685527.js';
export { S as ChevronThickUp } from './assets/chevron-thick-up.svg-ddf1ebe9.js';
export { S as ChevronUp } from './assets/chevron-up.svg-80e63257.js';
export { S as Cog } from './assets/cog.svg-3c30981f.js';
export { S as ColorPalette } from './assets/color-palette.svg-4a79e930.js';
export { S as Columns } from './assets/columns.svg-5750672b.js';
export { S as CommentingFilled } from './assets/commenting-filled.svg-c4f73db5.js';
export { S as Commenting } from './assets/commenting.svg-dc993e07.js';
export { S as Compass } from './assets/compass.svg-c4e9f49a.js';
export { S as ComponentFinanciallySig } from './assets/component-financially-sig.svg-fec04fe8.js';
export { S as ComponentNotSigPro } from './assets/component-not-sig-pro.svg-39dd8f8e.js';
export { S as ComponentNotSig } from './assets/component-not-sig.svg-d93972c6.js';
export { S as ComponentSigRisk } from './assets/component-sig-risk.svg-89fadbfe.js';
export { S as Connect } from './assets/connect.svg-213e5b94.js';
export { S as ContactSupport } from './assets/contact-support.svg-8ff9f3f7.js';
export { S as ControlApplication } from './assets/control-application.svg-e9557097.js';
export { S as ControlAutoConnectorOff } from './assets/control-auto-connector-off.svg-3f48d4fe.js';
export { S as ControlAutoConnectorOn } from './assets/control-auto-connector-on.svg-70c78f8e.js';
export { S as ControlDependent } from './assets/control-dependent.svg-3f027221.js';
export { S as ControlGeneric } from './assets/control-generic.svg-3382c1b4.js';
export { S as ControlObjectGroup } from './assets/control-object-group.svg-f426dec1.js';
export { S as Cortex } from './assets/cortex.svg-082f2b7a.js';
export { S as Cross } from './assets/cross.svg-85fa0718.js';
export { S as DataKitchenMix } from './assets/data-kitchen-mix.svg-ad6856a3.js';
export { S as DefControl } from './assets/def-control.svg-00974ce1.js';
export { S as DefMaterialWeak } from './assets/def-material-weak.svg-f4adfc9b.js';
export { S as DefNotEval } from './assets/def-not-eval.svg-f9cf4616.js';
export { S as DefSig } from './assets/def-sig.svg-76586abe.js';
export { S as Def } from './assets/def.svg-863ea93a.js';
export { S as Download } from './assets/download.svg-4a0f24c4.js';
export { S as DragArea } from './assets/drag-area.svg-53ee9c87.js';
export { S as Edit } from './assets/edit.svg-f8a90352.js';
export { S as Export } from './assets/export.svg-5a8a71fa.js';
export { S as ExternalLink } from './assets/external-link.svg-7e2f2ec0.js';
export { S as ExternalTab } from './assets/external-tab.svg-e2f0aede.js';
export { S as FileAccountMapping } from './assets/file-account-mapping.svg-4f931270.js';
export { S as FileBlank } from './assets/file-blank.svg-b762daa2.js';
export { S as FileBusProcess } from './assets/file-bus-process.svg-3862cb90.js';
export { S as FileComponent } from './assets/file-component.svg-0ca69777.js';
export { S as FileControl } from './assets/file-control.svg-fabc31df.js';
export { S as FileCorePolicy } from './assets/file-core-policy.svg-c0a610f6.js';
export { S as FileDeficiency } from './assets/file-deficiency.svg-b130b11b.js';
export { S as FileEpq } from './assets/file-epq.svg-45efe568.js';
export { S as FileExcel } from './assets/file-excel.svg-e9faba03.js';
export { S as FileGra } from './assets/file-gra.svg-331774d6.js';
export { S as FileIosa } from './assets/file-iosa.svg-20407f0b.js';
export { S as FileIpe } from './assets/file-ipe.svg-c0fb0fe2.js';
export { S as FileLeadsheet } from './assets/file-leadsheet.svg-4619fbc8.js';
export { S as FileMateriality } from './assets/file-materiality.svg-cb273bd6.js';
export { S as FileMultiple } from './assets/file-multiple.svg-cdd3d62b.js';
export { S as FilePdf } from './assets/file-pdf.svg-3b3e3271.js';
export { S as FilePowerpoint } from './assets/file-powerpoint.svg-eefd84ae.js';
export { S as FileProcedure } from './assets/file-procedure.svg-42c36fda.js';
export { S as FileReport } from './assets/file-report.svg-cca75221.js';
export { S as FileRiskAccount } from './assets/file-risk-account.svg-9cc6e0a0.js';
export { S as FileTask } from './assets/file-task.svg-defaebaa.js';
export { S as FileTemplate } from './assets/file-template.svg-6e7de203.js';
export { S as FileTrialBalance } from './assets/file-trial-balance.svg-1b801450.js';
export { S as FileVisio } from './assets/file-visio.svg-1f49c4b5.js';
export { S as FileWord } from './assets/file-word.svg-eab151d1.js';
export { S as FileWpGroup } from './assets/file-wp-group.svg-9c7749db.js';
export { S as FileZip } from './assets/file-zip.svg-47b8681e.js';
export { S as File } from './assets/file.svg-fb6ded7f.js';
export { S as FilterClear } from './assets/filter-clear.svg-8969ae21.js';
export { S as FilterFilled } from './assets/filter-filled.svg-0e30eb09.js';
export { S as Filter } from './assets/filter.svg-f6252fdf.js';
export { S as FolderAdd } from './assets/folder-add.svg-0716efb6.js';
export { S as FolderExplorer } from './assets/folder-explorer.svg-8d073ef9.js';
export { S as FolderOpen } from './assets/folder-open.svg-d9b4933c.js';
export { S as FolderOutgoing } from './assets/folder-outgoing.svg-2c7d4781.js';
export { S as Folder } from './assets/folder.svg-d499ffb6.js';
export { S as GroupItems } from './assets/group-items.svg-212d3286.js';
export { S as HistorySaved } from './assets/history-saved.svg-5cb6f8a3.js';
export { S as Home } from './assets/home.svg-317f6d37.js';
export { S as Iconfirm } from './assets/iconfirm.svg-06da1667.js';
export { S as Icount } from './assets/icount.svg-1583bce3.js';
export { S as Image } from './assets/image.svg-d86b3372.js';
export { S as Import } from './assets/import.svg-92690533.js';
export { S as Indent } from './assets/indent.svg-66d829ee.js';
export { S as Iosa } from './assets/iosa.svg-848bd87f.js';
export { S as Link } from './assets/link.svg-8c42d2ad.js';
export { S as LockedOffline } from './assets/locked-offline.svg-74f4256a.js';
export { S as Locked } from './assets/locked.svg-5cae5002.js';
export { S as MailClosed } from './assets/mail-closed.svg-000fb2c3.js';
export { S as Materiality } from './assets/materiality.svg-98d03360.js';
export { S as MenuLeft } from './assets/menu-left.svg-7b19f05b.js';
export { S as MenuRight } from './assets/menu-right.svg-50044714.js';
export { S as Menu } from './assets/menu.svg-de8845be.js';
export { S as MoreMenuBold } from './assets/more-menu-bold.svg-e03572a9.js';
export { S as MoreMenu } from './assets/more-menu.svg-60b22af7.js';
export { S as Move } from './assets/move.svg-a31c4c4c.js';
export { S as Navigation } from './assets/navigation.svg-7aba9002.js';
export { S as NoteClearning } from './assets/note-clearning.svg-7745ad7d.js';
export { S as NoteClosedNeg } from './assets/note-closed-neg.svg-18d60e51.js';
export { S as NoteClosed } from './assets/note-closed.svg-c3f55daa.js';
export { S as NoteGhost } from './assets/note-ghost.svg-e4baaaa4.js';
export { S as NoteOpenNeg } from './assets/note-open-neg.svg-76fe5932.js';
export { S as NoteOpen } from './assets/note-open.svg-98e9ce21.js';
export { S as NotePad } from './assets/note-pad.svg-303267eb.js';
export { S as NotePriorityNeg } from './assets/note-priority-neg.svg-7bcbd0f2.js';
export { S as NotePriority } from './assets/note-priority.svg-f456ba57.js';
export { S as NoteReminder } from './assets/note-reminder.svg-1bce5eb1.js';
export { S as NotificationDot } from './assets/notification-dot.svg-5b5442ee.js';
export { S as NumberedList } from './assets/numbered-list.svg-b477103c.js';
export { S as Optix } from './assets/optix.svg-885eb1ed.js';
export { S as OrgChart } from './assets/org-chart.svg-49b25993.js';
export { S as PaletteApplication } from './assets/palette-application.svg-9d9f9171.js';
export { S as PaletteControl } from './assets/palette-control.svg-0c4c42db.js';
export { S as PaletteDecision } from './assets/palette-decision.svg-41cc0088.js';
export { S as PaletteParticipant } from './assets/palette-participant.svg-5f20c8e8.js';
export { S as PaletteProcessStep } from './assets/palette-process-step.svg-af0b1c49.js';
export { S as PaletteReference } from './assets/palette-reference.svg-a8378f9c.js';
export { S as PaletteRisk } from './assets/palette-risk.svg-a72e199e.js';
export { S as Paperclip } from './assets/paperclip.svg-71fb092a.js';
export { S as Pencil } from './assets/pencil.svg-45125cec.js';
export { S as PhiNeg } from './assets/phi-neg.svg-a04e45dc.js';
export { S as Phi } from './assets/phi.svg-148062ed.js';
export { S as Plus } from './assets/plus.svg-4d079e8e.js';
export { S as ProcedureDefault } from './assets/procedure-default.svg-baddb524.js';
export { S as ProcedureHigher } from './assets/procedure-higher.svg-ba708d48.js';
export { S as ProcedureLow } from './assets/procedure-low.svg-c2524481.js';
export { S as ProcedurePending } from './assets/procedure-pending.svg-dd748113.js';
export { S as ProcedureSignificant } from './assets/procedure-significant.svg-5eb2c71a.js';
export { S as PushPin } from './assets/push-pin.svg-58b9918b.js';
export { S as RedoB } from './assets/redo-b.svg-9c7e8472.js';
export { S as Redo } from './assets/redo.svg-6814ce1d.js';
export { S as Refresh } from './assets/refresh.svg-fe168141.js';
export { S as Reply } from './assets/reply.svg-bdc49f61.js';
export { S as ResizeDecreaseXy } from './assets/resize-decrease-xy.svg-af933d2c.js';
export { S as ResizeDecreaseY } from './assets/resize-decrease-y.svg-9a7e4d52.js';
export { S as ResizeIncreaseXy } from './assets/resize-increase-xy.svg-1d0a9136.js';
export { S as ResizeIncreaseY } from './assets/resize-increase-y.svg-2ca41946.js';
export { S as Reveal } from './assets/reveal.svg-1bca4a46.js';
export { S as ReviewRemoveSignOff } from './assets/review-remove-sign-off.svg-f1f6a524.js';
export { S as ReviewSendReview } from './assets/review-send-review.svg-9cf9865a.js';
export { S as ReviewSignOff } from './assets/review-sign-off.svg-3c742505.js';
export { S as ReviewTickmark } from './assets/review-tickmark.svg-b218f12b.js';
export { S as RiskFraud } from './assets/risk-fraud.svg-f0432707.js';
export { S as RiskHigherAlt } from './assets/risk-higher-alt.svg-45df7019.js';
export { S as RiskHigher } from './assets/risk-higher.svg-983bfbb4.js';
export { S as RiskLowerAlt } from './assets/risk-lower-alt.svg-9889bc42.js';
export { S as RiskLower } from './assets/risk-lower.svg-54c6105e.js';
export { S as RiskNotEval } from './assets/risk-not-eval.svg-006085ec.js';
export { S as RiskRemote } from './assets/risk-remote.svg-be0c06b5.js';
export { S as RiskSevereAlt } from './assets/risk-severe-alt.svg-ca85ce7e.js';
export { S as RiskSevere } from './assets/risk-severe.svg-ac427b71.js';
export { S as ReaderView } from './assets/reader-view.svg-64fddba6.js';
export { S as Rocket } from './assets/rocket.svg-39d1e9f6.js';
export { S as RocketNew } from './assets/rocket-new.svg-21e2642a.js';
export { S as Sandbox } from './assets/sandbox.svg-37faf214.js';
export { S as Save } from './assets/save.svg-95d102ef.js';
export { S as Scribe } from './assets/scribe.svg-27317657.js';
export { S as Search } from './assets/search.svg-4c230f1f.js';
export { S as Settings } from './assets/settings.svg-dff772d7.js';
export { S as Share } from './assets/share.svg-e9a7a692.js';
export { S as Signal } from './assets/signal.svg-0bf89b3d.js';
export { S as SortAlphaAsc } from './assets/sort-alpha-asc.svg-06e0379f.js';
export { S as SortAlphaDesc } from './assets/sort-alpha-desc.svg-04735d45.js';
export { S as SortAmountAsc } from './assets/sort-amount-asc.svg-4237951a.js';
export { S as SortAmountDesc } from './assets/sort-amount-desc.svg-fb302839.js';
export { S as SortAsc } from './assets/sort-asc.svg-7e22afbe.js';
export { S as SortDesc } from './assets/sort-desc.svg-16ab6d92.js';
export { S as SortNumAsc } from './assets/sort-num-asc.svg-566f0d41.js';
export { S as SortNumDesc } from './assets/sort-num-desc.svg-845d8e33.js';
export { S as StarNeg } from './assets/star-neg.svg-b860acd1.js';
export { S as Star } from './assets/star.svg-209fa2e9.js';
export { S as StatusCloud } from './assets/status-cloud.svg-63a3e4eb.js';
export { S as StatusEggTimer } from './assets/status-egg-timer.svg-f88a03e7.js';
export { S as StatusError } from './assets/status-error.svg-cf1845e0.js';
export { S as StatusInformation } from './assets/status-information.svg-4af9530f.js';
export { S as StatusRoundInformation } from './assets/status-round-information.svg-4661dd51.js';
export { S as StatusLightbulbEnergy } from './assets/status-lightbulb-energy.svg-9862d71d.js';
export { S as StatusQuestionMark } from './assets/status-question-mark.svg-01fd9fca.js';
export { S as StatusTick } from './assets/status-tick.svg-1c7f9b0d.js';
export { S as StatusWarning } from './assets/status-warning.svg-cb776128.js';
export { S as StepArchiveReady } from './assets/step-archive-ready.svg-bb82d741.js';
export { S as StepComplete } from './assets/step-complete.svg-b59bb153.js';
export { S as StepDefault } from './assets/step-default.svg-6afa6172.js';
export { S as StepIncomplete } from './assets/step-incomplete.svg-41b264b3.js';
export { S as StepProgress } from './assets/step-progress.svg-b8973b32.js';
export { S as TextBold } from './assets/text-bold.svg-6a1b77b7.js';
export { S as TextColor } from './assets/text-color.svg-1127b885.js';
export { S as TextItalic } from './assets/text-italic.svg-4675d49a.js';
export { S as TextUnderline } from './assets/text-underline.svg-2cbbf3a6.js';
export { S as Time } from './assets/time.svg-91fd78bc.js';
export { S as Timezone } from './assets/timezone.svg-fcb9f5df.js';
export { S as Toolbox } from './assets/toolbox.svg-6141e91e.js';
export { S as UnIndent } from './assets/un-indent.svg-7c032272.js';
export { S as UnbalancedScale } from './assets/unbalanced-scale.svg-13936d4e.js';
export { S as UndoAlt } from './assets/undo-alt.svg-561d534c.js';
export { S as Undo } from './assets/undo.svg-eb6c2015.js';
export { S as Unlock } from './assets/unlock.svg-60cc6e98.js';
export { S as Upload } from './assets/upload.svg-4c8b94c5.js';
export { S as Video } from './assets/video.svg-972c3e5a.js';
export { S as Workflow } from './assets/workflow.svg-da27de98.js';
export { S as GeoPicker } from './assets/geo-picker.svg-90fcbf69.js';
export { S as DeleteAlert } from './assets/delete-alert.svg-1a9a5145.js';
export { S as TestComplete } from './assets/test-complete.svg-fb43484d.js';
export { S as TestError } from './assets/test-error.svg-0b722d36.js';
export { S as TestInputNeeded } from './assets/test-input-needed.svg-19af14a4.js';
export { S as TestProcessing } from './assets/test-processing.svg-27c1c75c.js';
export { S as TestResubmit } from './assets/test-resubmit.svg-0c4a467a.js';
export { S as Warning } from './assets/warning.svg-5f83b17c.js';
export { S as RedAlert } from './assets/red-alert.svg-db6e17eb.js';
export { S as SetDefault } from './assets/set-default.svg-3b3cfa5d.js';
export { S as Incomplete } from './assets/incomplete.svg-01a4152b.js';
export { S as Complete } from './assets/complete.svg-dcbcfff8.js';
export { S as Information } from './assets/information.svg-45bcd6fc.js';
export { S as InformationBlack } from './assets/information-black.svg-434849fd.js';
export { S as Informationwithcircle } from './assets/information-with-circle.svg-9c1bd4f4.js';
export { S as Observation } from './assets/observation.svg-3a46c5e2.js';
export { S as Watch } from './assets/watch.svg-5a878cac.js';
import 'react';

var IconNames;
(function (IconNames) {
    IconNames["AggDefDefault"] = "AggDefDefault";
    IconNames["AggDefMaterialWeak"] = "AggDefMaterialWeak";
    IconNames["AggDefNotEval"] = "AggDefNotEval";
    IconNames["AggDefSig"] = "AggDefSig";
    IconNames["AggDef"] = "AggDef";
    IconNames["Aggregate"] = "Aggregate";
    IconNames["AlignCenter"] = "AlignCenter";
    IconNames["AlignJustified"] = "AlignJustified";
    IconNames["AlignLeft"] = "AlignLeft";
    IconNames["AlignRight"] = "AlignRight";
    IconNames["Analytics"] = "Analytics";
    IconNames["AppLauncherB"] = "AppLauncherB";
    IconNames["AppLauncher"] = "AppLauncher";
    IconNames["Argus"] = "Argus";
    IconNames["ArrowDown"] = "ArrowDown";
    IconNames["ArrowFlip"] = "ArrowFlip";
    IconNames["ArrowLeftRight"] = "ArrowLeftRight";
    IconNames["ArrowLeft"] = "ArrowLeft";
    IconNames["ArrowRight"] = "ArrowRight";
    IconNames["ArrowThickDown"] = "ArrowThickDown";
    IconNames["ArrowThickLeft"] = "ArrowThickLeft";
    IconNames["ArrowThickRight"] = "ArrowThickRight";
    IconNames["ArrowThickUp"] = "ArrowThickUp";
    IconNames["ArrowUp"] = "ArrowUp";
    IconNames["AvatarGuest"] = "AvatarGuest";
    IconNames["Bell"] = "Bell";
    IconNames["Bin"] = "Bin";
    IconNames["BookClose"] = "BookClose";
    IconNames["BookOpen"] = "BookOpen";
    IconNames["BulletedList"] = "BulletedList";
    IconNames["Calendar"] = "Calendar";
    IconNames["CaretDown"] = "CaretDown";
    IconNames["CaretLeft"] = "CaretLeft";
    IconNames["CaretRight"] = "CaretRight";
    IconNames["CaretUp"] = "CaretUp";
    IconNames["ChangeItem"] = "ChangeItem";
    IconNames["ChangeLog"] = "ChangeLog";
    IconNames["ChartPieFilled"] = "ChartPieFilled";
    IconNames["ChartPie"] = "ChartPie";
    IconNames["ChevronDoubleLeft"] = "ChevronDoubleLeft";
    IconNames["ChevronDoubleRight"] = "ChevronDoubleRight";
    IconNames["ChevronDown"] = "ChevronDown";
    IconNames["ChevronLeft"] = "ChevronLeft";
    IconNames["ChevronRight"] = "ChevronRight";
    IconNames["ChevronThickDown"] = "ChevronThickDown";
    IconNames["ChevronThickLeft"] = "ChevronThickLeft";
    IconNames["ChevronThickRight"] = "ChevronThickRight";
    IconNames["ChevronThickUp"] = "ChevronThickUp";
    IconNames["ChevronUp"] = "ChevronUp";
    IconNames["Cog"] = "Cog";
    IconNames["Columns"] = "Columns";
    IconNames["ColorPalette"] = "ColorPalette";
    IconNames["CommentingFilled"] = "CommentingFilled";
    IconNames["Commenting"] = "Commenting";
    IconNames["Compass"] = "Compass";
    IconNames["ComponentFinanciallySig"] = "ComponentFinanciallySig";
    IconNames["ComponentNotSigPro"] = "ComponentNotSigPro";
    IconNames["ComponentNotSig"] = "ComponentNotSig";
    IconNames["ComponentSigRisk"] = "ComponentSigRisk";
    IconNames["Connect"] = "Connect";
    IconNames["ContactSupport"] = "ContactSupport";
    IconNames["ControlApplication"] = "ControlApplication";
    IconNames["ControlAutoConnectorOff"] = "ControlAutoConnectorOff";
    IconNames["ControlAutoConnectorOn"] = "ControlAutoConnectorOn";
    IconNames["ControlDependent"] = "ControlDependent";
    IconNames["ControlGeneric"] = "ControlGeneric";
    IconNames["ControlObjectGroup"] = "ControlObjectGroup";
    IconNames["Cortex"] = "Cortex";
    IconNames["Cross"] = "Cross";
    IconNames["DataKitchenMix"] = "DataKitchenMix";
    IconNames["DefControl"] = "DefControl";
    IconNames["DefMaterialWeak"] = "DefMaterialWeak";
    IconNames["DefNotEval"] = "DefNotEval";
    IconNames["DefSig"] = "DefSig";
    IconNames["Def"] = "Def";
    IconNames["Download"] = "Download";
    IconNames["DragArea"] = "DragArea";
    IconNames["Edit"] = "Edit";
    IconNames["Export"] = "Export";
    IconNames["ExternalLink"] = "ExternalLink";
    IconNames["ExternalTab"] = "ExternalTab";
    IconNames["FileAccountMapping"] = "FileAccountMapping";
    IconNames["FileBlank"] = "FileBlank";
    IconNames["FileBusProcess"] = "FileBusProcess";
    IconNames["FileComponent"] = "FileComponent";
    IconNames["FileControl"] = "FileControl";
    IconNames["FileCorePolicy"] = "FileCorePolicy";
    IconNames["FileDeficiency"] = "FileDeficiency";
    IconNames["FileEpq"] = "FileEpq";
    IconNames["FileExcel"] = "FileExcel";
    IconNames["FileGra"] = "FileGra";
    IconNames["FileIosa"] = "FileIosa";
    IconNames["FileIpe"] = "FileIpe";
    IconNames["FileLeadsheet"] = "FileLeadsheet";
    IconNames["FileMateriality"] = "FileMateriality";
    IconNames["FileMultiple"] = "FileMultiple";
    IconNames["FilePdf"] = "FilePdf";
    IconNames["FilePowerpoint"] = "FilePowerpoint";
    IconNames["FileProcedure"] = "FileProcedure";
    IconNames["FileReport"] = "FileReport";
    IconNames["FileRiskAccount"] = "FileRiskAccount";
    IconNames["FileTask"] = "FileTask";
    IconNames["FileTemplate"] = "FileTemplate";
    IconNames["FileTrialBalance"] = "FileTrialBalance";
    IconNames["FileVisio"] = "FileVisio";
    IconNames["FileWord"] = "FileWord";
    IconNames["FileWpGroup"] = "FileWpGroup";
    IconNames["FileZip"] = "FileZip";
    IconNames["File"] = "File";
    IconNames["FilterClear"] = "FilterClear";
    IconNames["FilterFilled"] = "FilterFilled";
    IconNames["Filter"] = "Filter";
    IconNames["FolderAdd"] = "FolderAdd";
    IconNames["FolderExplorer"] = "FolderExplorer";
    IconNames["FolderOpen"] = "FolderOpen";
    IconNames["FolderOutgoing"] = "FolderOutgoing";
    IconNames["Folder"] = "Folder";
    IconNames["GroupItems"] = "GroupItems";
    IconNames["HistorySaved"] = "HistorySaved";
    IconNames["Home"] = "Home";
    IconNames["Iconfirm"] = "Iconfirm";
    IconNames["Icount"] = "Icount";
    IconNames["Image"] = "Image";
    IconNames["Import"] = "Import";
    IconNames["Indent"] = "Indent";
    IconNames["Iosa"] = "Iosa";
    IconNames["Link"] = "Link";
    IconNames["LockedOffline"] = "LockedOffline";
    IconNames["Locked"] = "Locked";
    IconNames["MailClosed"] = "MailClosed";
    IconNames["Materiality"] = "Materiality";
    IconNames["MenuLeft"] = "MenuLeft";
    IconNames["MenuRight"] = "MenuRight";
    IconNames["Menu"] = "Menu";
    IconNames["MoreMenuBold"] = "MoreMenuBold";
    IconNames["MoreMenu"] = "MoreMenu";
    IconNames["Move"] = "Move";
    IconNames["Navigation"] = "Navigation";
    IconNames["NoteClearning"] = "NoteClearning";
    IconNames["NoteClosedNeg"] = "NoteClosedNeg";
    IconNames["NoteClosed"] = "NoteClosed";
    IconNames["NoteGhost"] = "NoteGhost";
    IconNames["NoteOpenNeg"] = "NoteOpenNeg";
    IconNames["NoteOpen"] = "NoteOpen";
    IconNames["NotePad"] = "NotePad";
    IconNames["NotePriorityNeg"] = "NotePriorityNeg";
    IconNames["NotePriority"] = "NotePriority";
    IconNames["NoteReminder"] = "NoteReminder";
    IconNames["NotificationDot"] = "NotificationDot";
    IconNames["NumberedList"] = "NumberedList";
    IconNames["Optix"] = "Optix";
    IconNames["OrgChart"] = "OrgChart";
    IconNames["Observation"] = "Observation";
    IconNames["PaletteApplication"] = "PaletteApplication";
    IconNames["PaletteControl"] = "PaletteControl";
    IconNames["PaletteDecision"] = "PaletteDecision";
    IconNames["PaletteParticipant"] = "PaletteParticipant";
    IconNames["PaletteProcessStep"] = "PaletteProcessStep";
    IconNames["PaletteReference"] = "PaletteReference";
    IconNames["PaletteRisk"] = "PaletteRisk";
    IconNames["Paperclip"] = "Paperclip";
    IconNames["Pencil"] = "Pencil";
    IconNames["PhiNeg"] = "PhiNeg";
    IconNames["Phi"] = "Phi";
    IconNames["Plus"] = "Plus";
    IconNames["ProcedureDefault"] = "ProcedureDefault";
    IconNames["ProcedureHigher"] = "ProcedureHigher";
    IconNames["ProcedureLow"] = "ProcedureLow";
    IconNames["ProcedurePending"] = "ProcedurePending";
    IconNames["ProcedureSignificant"] = "ProcedureSignificant";
    IconNames["PushPin"] = "PushPin";
    IconNames["ReaderView"] = "ReaderView";
    IconNames["RedoB"] = "RedoB";
    IconNames["Redo"] = "Redo";
    IconNames["Refresh"] = "Refresh";
    IconNames["Reply"] = "Reply";
    IconNames["ResizeDecreaseXy"] = "ResizeDecreaseXy";
    IconNames["ResizeDecreaseY"] = "ResizeDecreaseY";
    IconNames["ResizeIncreaseXy"] = "ResizeIncreaseXy";
    IconNames["ResizeIncreaseY"] = "ResizeIncreaseY";
    IconNames["Reveal"] = "Reveal";
    IconNames["ReviewRemoveSignOff"] = "ReviewRemoveSignOff";
    IconNames["ReviewSendReview"] = "ReviewSendReview";
    IconNames["ReviewSignOff"] = "ReviewSignOff";
    IconNames["ReviewTickmark"] = "ReviewTickmark";
    IconNames["RiskFraud"] = "RiskFraud";
    IconNames["RiskHigherAlt"] = "RiskHigherAlt";
    IconNames["RiskHigher"] = "RiskHigher";
    IconNames["RiskLowerAlt"] = "RiskLowerAlt";
    IconNames["RiskLower"] = "RiskLower";
    IconNames["RiskNotEval"] = "RiskNotEval";
    IconNames["RiskRemote"] = "RiskRemote";
    IconNames["RiskSevereAlt"] = "RiskSevereAlt";
    IconNames["RiskSevere"] = "RiskSevere";
    IconNames["Rocket"] = "Rocket";
    IconNames["RocketNew"] = "RocketNew";
    IconNames["Sandbox"] = "Sandbox";
    IconNames["Save"] = "Save";
    IconNames["Scribe"] = "Scribe";
    IconNames["Search"] = "Search";
    IconNames["Settings"] = "Settings";
    IconNames["Share"] = "Share";
    IconNames["Signal"] = "Signal";
    IconNames["SortAlphaAsc"] = "SortAlphaAsc";
    IconNames["SortAlphaDesc"] = "SortAlphaDesc";
    IconNames["SortAmountAsc"] = "SortAmountAsc";
    IconNames["SortAmountDesc"] = "SortAmountDesc";
    IconNames["SortAsc"] = "SortAsc";
    IconNames["SortDesc"] = "SortDesc";
    IconNames["SortNumAsc"] = "SortNumAsc";
    IconNames["SortNumDesc"] = "SortNumDesc";
    IconNames["StarNeg"] = "StarNeg";
    IconNames["Star"] = "Star";
    IconNames["StatusCloud"] = "StatusCloud";
    IconNames["StatusEggTimer"] = "StatusEggTimer";
    IconNames["StatusError"] = "StatusError";
    IconNames["StatusInformation"] = "StatusInformation";
    IconNames["StatusRoundInformation"] = "StatusRoundInformation";
    IconNames["StatusLightbulbEnergy"] = "StatusLightbulbEnergy";
    IconNames["StatusQuestionMark"] = "StatusQuestionMark";
    IconNames["StatusTick"] = "StatusTick";
    IconNames["StatusWarning"] = "StatusWarning";
    IconNames["StepArchiveReady"] = "StepArchiveReady";
    IconNames["StepComplete"] = "StepComplete";
    IconNames["StepDefault"] = "StepDefault";
    IconNames["StepIncomplete"] = "StepIncomplete";
    IconNames["StepProgress"] = "StepProgress";
    IconNames["TextBold"] = "TextBold";
    IconNames["TextColor"] = "TextColor";
    IconNames["TextItalic"] = "TextItalic";
    IconNames["TextUnderline"] = "TextUnderline";
    IconNames["Time"] = "Time";
    IconNames["Timezone"] = "Timezone";
    IconNames["Toolbox"] = "Toolbox";
    IconNames["UnIndent"] = "UnIndent";
    IconNames["UnbalancedScale"] = "UnbalancedScale";
    IconNames["UndoAlt"] = "UndoAlt";
    IconNames["Undo"] = "Undo";
    IconNames["Unlock"] = "Unlock";
    IconNames["Upload"] = "Upload";
    IconNames["Video"] = "Video";
    IconNames["Workflow"] = "Workflow";
    IconNames["GeoPicker"] = "GeoPicker";
    IconNames["DeleteAlert"] = "DeleteAlert";
    IconNames["TestComplete"] = "TestComplete";
    IconNames["TestError"] = "TestError";
    IconNames["TestInputNeeded"] = "TestInputNeeded";
    IconNames["TestProcessing"] = "TestProcessing";
    IconNames["TestResubmit"] = "TestResubmit";
    IconNames["Warning"] = "Warning";
    IconNames["RedAlert"] = "RedAlert";
    IconNames["SetDefault"] = "SetDefault";
    IconNames["Complete"] = "Complete";
    IconNames["Incomplete"] = "Incomplete";
    IconNames["Information"] = "Information";
    IconNames["InformationBlack"] = "InformationBlack";
    IconNames["Informationwithcircle"] = "Informationwithcircle";
    IconNames["Watch"] = "Watch";
})(IconNames || (IconNames = {}));

export { IconNames };
