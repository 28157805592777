import { useEffect, useState } from "react";
import {
  removeDomainFromEmail,
  isDeloitteEmail,
  User,
  analyticsTrackingObjects,
} from "@cortex-libs/dmf";

type AnalyticsProps = {
  loggedInUser: User | null;
  adobeAnalyticsUrl: string | undefined;
  geoLocation: string | undefined;
  allowed: string | boolean;
};

export function useAdobeInit(props: AnalyticsProps): {
  isAdobeReady: boolean;
  isUserReady: User | null;
  isAdobeDisabled: boolean;
} {
  const [isAdobeReady, setIsAdobeReady] = useState<boolean>(false);
  const { loggedInUser, adobeAnalyticsUrl, geoLocation, allowed } = props;

  useEffect(() => {
    if (allowed && !isAdobeReady && loggedInUser && loggedInUser.email) {
      // Add adobe analytics script in the head tag
      const adobeAnalytics = document.createElement("script");
      const adobeScriptId = "adobeAnalytics";
      adobeAnalytics.src = adobeAnalyticsUrl || "";
      adobeAnalytics.async = true;
      adobeAnalytics.id = adobeScriptId;
      adobeAnalytics.className = "optanon-category-2";

      const head = document.head;
      head.insertBefore(adobeAnalytics, head.firstChild);

      // Add analytics datalayer script in the body tag
      const currentUrl = window.location.pathname;
      const analyticsDataLayerId = analyticsTrackingObjects.DATA_LAYER_NAME;
      const analyticsDataLayer = document.createElement("script");
      analyticsDataLayer.type = "text/javascript";
      analyticsDataLayer.id = analyticsDataLayerId;

      const userId = isDeloitteEmail(loggedInUser.email)
        ? removeDomainFromEmail(loggedInUser.email)
        : "";

      // non-available data, such as the JE fields, are undefined by default.
      analyticsDataLayer.text = `
        var analyticsDataLayer = {
          pageInfo:{
            geoLocation: '${geoLocation}',
            pageName: '${currentUrl}',
            userName: '${userId}',
            engagementId: undefined,
            analyticId: undefined,
            testId: undefined,
            actionName: undefined,
          }
        };`;

      const body = document.body;
      body.appendChild(analyticsDataLayer);

      setIsAdobeReady(true);
    }
  }, [allowed, isAdobeReady, loggedInUser, loggedInUser?.email]);

  return { isAdobeReady, isUserReady: loggedInUser, isAdobeDisabled: !allowed };
}
