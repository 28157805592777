export enum engagementStatus {
  APPROVED = "Approved",
  PENDING_REMOVAL_APPROVAL = "PendingRemovalApproval",
  NONE = "None",
  ENGAGEMENT_DELETE_STATUS = "InProgress",
  IN_PROGRESS = "inprogress",
}
export enum sessionTimeoutEnum {
  // TODO : Make below value configurable, it should added in appsettings.
  SESSION_IDLE_TIMEOUT = 3600000,
  SESSION_IDLE_WARNING = 2700000,
}
export enum contextMenu {
  EDIT_ENGAGEMENT = "translations:engagement.edit.modal.title",
  EDIT_CLIENT_INFO = "translations:client.edit.modal.title",
  VIEW_CLIENT_INFO = "translations:client.view.modal.title",
  ROLL_FORWARD_INFO = "translations:client.rollforward.modal.title",
  DELETE = "common:delete",
}

export enum manageClientEnum {
  CLIENT_NAME = "ClientName",
  COUNTRY = "country",
  FISCAL_YEAR = "fiscalYear",
  MORE_OPTION = "moreOption",
  SET_UP_AUTO_DATA_EXTRACTION = "translations:landing.manage-client.setup_auto_data_extraction",
  MANAGE_CLIENT_TEAM = "translations:landing.manage-client.manage_client_team",
}

export enum manageClientTeamEnum {
  CLIENT_NAME = "ClientName",
  EMAIL = "email",
  ROLE = "role",
  EDIT_INFO = "translations:landing.manage-client.team.table.edit-info",
  REMOVE = "translations:landing.manage-client.team.table.remove",
  EXTERNAL = "External",
  DELOITTE = "Deloitte",
  MORE_OPTION = "more_option",
  LastExcecutedAction = "ADD",
}

export type Toaster = {
  title: string;
  message: string;
};

export enum EngagementDeleteStatus {
  FAILED = "Failed",
}

export enum EngagementConstants {
  DEFAULT_ENGAGEMENT_CREATION_VALUE = "true",
}
