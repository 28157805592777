import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DialogActions } from "@material-ui/core";
import {
  Box,
  Table,
  ColumnProps,
  SearchBox,
  TextVariants,
  Text,
  Button,
  ButtonTypes,
  Icon,
  Colors,
  LegacyPortal,
  Modal,
  UnauthorizedNotification,
  Backdrop,
  Progress,
  ProgressTypes,
} from "@cortex-libs/prism";
import { useTranslation } from "@cortex-libs/i18n";
import { IconNames } from "@cortex-libs/assets";
import { ContextMenuOptions } from "@cortex-libs/prism";
import {
  DayMonth,
  Client,
  useConfiguration,
  useCurrentUser,
  Permission,
  Action,
  useUserGlobalPermissions,
  useClient,
  useLanguage,
  useFormat,
} from "@cortex-libs/dmf";
import { SetupClientModal } from "@cortex-modules/engagement";
import { useStyles } from "../../@constants/Styles";
import { manageClientEnum } from "../../@constants/constants";
import { getClientName, getCountry, getFiscalYear, contextMenuColumn } from "./ManageClientColumn";

export const ManageClient = (): JSX.Element => {
  const history = useHistory();
  const NUMBER_OF_ROWS = 10;
  const { isLanguageChange } = useLanguage();
  const shortDateFormat = useFormat()?.shortDate;
  const dataInstance = "Manage_Client";
  const { t } = useTranslation();

  const { baseConfiguration } = useConfiguration();
  const [lagacyOpen, setLagacyOpen] = useState<boolean>(false);
  const [manageClientData, setManageClientData] = useState<Client[]>([]);
  const [searchKeyWords, setSearchkeyWords] = useState<string>();
  const {
    isLoading,
    totalClientCount,
    newManageClientList,
    updateClientSecureAgent,
    getManageClientByPagination,
  } = useClient("");
  const [currentInProgressPage, setCurrentInProgressPage] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [addManageClient, setAddManageClient] = useState<boolean>(false);
  const { tableManageClient } = useStyles();
  const [isSetupClientModalOpen, setIsSetupClientModalOpen] = useState(false);
  const { getGlobalDCO, loggedInUser, isGlobalDco } = useCurrentUser();
  const [isClientSetupErrorModalOpen, setIsClientSetupErrorModalOpen] = useState(false);

  const { canExecuteAction } = useUserGlobalPermissions(
    [Permission.MANAGE_CLIENTS_PAGE_ACCESS],
    Action.View
  );

  // ContextMenu
  const [selectedManageClient, setSelectedManageClient] = useState<string>("");
  const [selectLegacyClient, setSelectLegacyClient] = useState<string>("");

  const handleContextMenuClickAway = () => {
    setSelectedManageClient("");
  };
  const handlerContextMenu = (clientId: string) => {
    setSelectedManageClient(clientId);
    setSelectLegacyClient(clientId);
  };
  const handleContextItemClick = (
    currentItem: ContextMenuOptions | undefined,
    clientId: string,
    clientName: string,
    usesSecureAgent: boolean
  ) => {
    if (
      currentItem?.item?.toUpperCase() ===
      t(manageClientEnum.SET_UP_AUTO_DATA_EXTRACTION).toUpperCase()
    ) {
      if (!usesSecureAgent) {
        updateClientSecureAgent({ usesSecureAgent: true }, clientName, clientId);
      }
      setLagacyOpen(true);
    }
    if (currentItem?.item?.toUpperCase() === t(manageClientEnum.MANAGE_CLIENT_TEAM).toUpperCase()) {
      history.push(`/client/${clientId}/team`);
    }
    handleContextMenuClickAway();
  };

  useEffect(() => {
    const clientList = Array.from(newManageClientList?.values());

    setManageClientData(
      clientList?.sort((arg1: Client, arg2: Client) =>
        arg1?.name?.toLowerCase() > arg2?.name?.toLowerCase() ? 1 : -1
      )
    );
  }, [newManageClientList]);

  useEffect(() => {}, [isLanguageChange]);

  useEffect(() => {
    getGlobalDCO(loggedInUser?.email || "");
  }, [loggedInUser?.email]);

  const searchData = (searchTerm: string) => {
    const clientList = Array.from(newManageClientList?.values())?.sort(
      (arg1: Client, arg2: Client) =>
        arg1?.name?.toLowerCase() > arg2?.name?.toLowerCase() ? 1 : -1
    );
    if (searchTerm) {
      setSearchkeyWords(searchTerm);
      getManageClientByPagination(0, searchTerm);
      setManageClientData(
        clientList?.filter((searchClient: Client) => {
          return searchClient.name?.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
        })
      );
      setCurrentInProgressPage(0);
    } else {
      setSearchkeyWords("");
      getManageClientByPagination(selectedPage);
      setManageClientData(clientList);
      setCurrentInProgressPage(selectedPage);
    }
  };

  const addNewOrExistingClient = () => {
    setAddManageClient(!addManageClient);
    setIsSetupClientModalOpen(true);
  };

  const handleCompeleteSetupClient = (ClientId: string) => {
    if (loggedInUser?.email) {
      setSelectedManageClient(ClientId);
      setSelectLegacyClient(ClientId);
      setLagacyOpen(true);
      setIsSetupClientModalOpen(false);
    }
  };

  const handleErrorModalCreateClientButtonClick = () => {
    setLagacyOpen(false);
    setIsClientSetupErrorModalOpen(false);
    setIsSetupClientModalOpen(true);
  };

  const handleContactSupportButton = () => {
    setIsClientSetupErrorModalOpen(false);
    window.open(
      baseConfiguration?.omniaIdeaPortalUrl +
        "/sp?id=kb_article&sys_id=6924e9eadb504f00e7acffd31d961985"
    );
  };

  const handleclientCreationError = () => {
    setIsSetupClientModalOpen(false);
    setIsClientSetupErrorModalOpen(true);
  };

  const manageClientTablesColumns: ColumnProps[] = [
    {
      field: manageClientEnum.CLIENT_NAME,
      title: t("translations:landing.manage-client.table.client-name"),
      render: (row: Record<string, unknown>): React.ReactNode => getClientName(String(row?.name)),
    },
    {
      field: manageClientEnum.COUNTRY,
      title: t("translations:landing.manage-client.table.country-name"),
      render: (row: Record<string, unknown>): React.ReactNode => getCountry(String(row?.countries)),
    },
    {
      field: manageClientEnum.FISCAL_YEAR,
      title: t("translations:landing.manage-client.table.fiscal-year"),
      render: (row: Record<string, unknown>): React.ReactNode =>
        getFiscalYear(row?.fiscalYearEnd as DayMonth, shortDateFormat as string),
    },

    {
      field: manageClientEnum.MORE_OPTION,
      title: "",
      render: (row: Record<string, unknown>): React.ReactNode => (
        <div style={{ position: "relative" }}>
          {contextMenuColumn(
            String(row?.id),
            t,
            handleContextMenuClickAway,
            handlerContextMenu,
            handleContextItemClick,
            selectedManageClient,
            String(row?.name),
            Boolean(row?.usesSecureAgent)
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      {canExecuteAction ? (
        <Box>
          <Modal
            open={isClientSetupErrorModalOpen}
            onCloseButtonClick={() => {
              setIsClientSetupErrorModalOpen(false);
              setIsSetupClientModalOpen(false);
            }}
          >
            <Box p={5} width="582px">
              <Box pb={2} display="flex" alignItems="center">
                <Icon
                  color={Colors.RED}
                  fontSize="medium"
                  name={IconNames.DeleteAlert}
                  titleAccess=""
                  data-instance="ManageClient-FailuerMdl-icon"
                />
                <Box ml={2}>
                  <Text
                    type={TextVariants.SUB_HEADING_1}
                    data-instance="ManageClient-FailuerMdl-Title"
                  >
                    {t("translations:landing.manage-client.handle-failure-modal.title")}
                  </Text>
                </Box>
              </Box>
              <Box ml={5}>
                <Text
                  type={TextVariants.BODY_COPY_REGULAR}
                  data-instance="ManageClient-FailuerMdl-Msg"
                >
                  {t("translations:landing.manage-client.handle-failure-modal.message")}
                </Text>
              </Box>
              <Box>
                <DialogActions>
                  <Button
                    buttonType={ButtonTypes.SECONDARY}
                    onClick={handleContactSupportButton}
                    data-instance="ManageClient-FailuerMdl-ContactSupport-Btn"
                  >
                    {t(
                      "translations:landing.manage-client.handle-failure-modal.contact-support-button"
                    )}
                  </Button>

                  <Button
                    buttonType={ButtonTypes.PRIMARY}
                    onClick={handleErrorModalCreateClientButtonClick}
                    data-instance="ManageClient-FailuerMdl-CreateClient-Btn"
                  >
                    {t(
                      "translations:landing.manage-client.handle-failure-modal.create-client-button"
                    )}
                  </Button>
                </DialogActions>
              </Box>
            </Box>
          </Modal>
          <SetupClientModal
            isSetupClientModalOpen={isSetupClientModalOpen}
            onCloseButtonClick={() => setIsSetupClientModalOpen(false)}
            completeSetupClient={handleCompeleteSetupClient}
            clientCreationError={handleclientCreationError}
          />
          <Box className={tableManageClient}>
            <Box width="100%" data-instance={dataInstance}>
              <Box pt={2} className={"Manage_Clients"}>
                <Text type={TextVariants.HEADING_1}>
                  {t("translations:landing.manage-client.title")}
                </Text>
              </Box>
              <Box pt={2}>
                <Text type={TextVariants.BODY_COPY_REGULAR}>
                  {t("translations:landing.manage-client.sub-heading-text")}
                </Text>
              </Box>
              <Box pt={5}>
                <Box display="flex" justifyContent="space-between">
                  <SearchBox
                    placeholder={t("translations:landing.manage-client.search_text")}
                    data-testid="manage-client-search"
                    withOutDropdown={true}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    options={manageClientData as any}
                    onInputChange={searchData}
                    labelKey={t("translations:landing.manage-client.search_text")}
                    width={"320px"}
                  />
                  {isGlobalDco && (
                    <Button
                      buttonType={ButtonTypes.TEXT}
                      startIcon={<Icon name={IconNames.Plus} outline></Icon>}
                      onClick={addNewOrExistingClient}
                      className={"setup_new_or_existing_Client_Button"}
                      data-instance="ManageClient-SetupClient-Button"
                    >
                      <Text
                        type={TextVariants.BODY_COPY_SEMI_BOLD}
                        color={Colors.BLUE_4}
                        data-instance="ManageClient-SetupClient-Button-Txt"
                      >
                        {t("translations:landing.manage-client.add-manage-client")}
                      </Text>
                    </Button>
                  )}
                </Box>
                <Box mt={3}>
                  {isLoading && (
                    <Backdrop open={isLoading} data-instance="ManageClient-Backdrop">
                      <Progress type={ProgressTypes.CIRCULAR} />
                    </Backdrop>
                  )}
                  <Table
                    enablePagination
                    removeSideSpacing
                    rowsPerPage={NUMBER_OF_ROWS}
                    currentPage={currentInProgressPage}
                    onPageChange={(page: number) => {
                      setCurrentInProgressPage(page);
                      setSelectedPage(page);
                      setSelectedManageClient("");
                      setSelectLegacyClient("");
                      getManageClientByPagination(page, searchKeyWords, true);
                    }}
                    serverSide
                    rowCount={totalClientCount}
                    rows={JSON.parse(JSON.stringify(manageClientData))}
                    columns={manageClientTablesColumns}
                    tableClassName={"Manage_Clients_Table"}
                  ></Table>
                </Box>
              </Box>
            </Box>
            <LegacyPortal
              src={`${baseConfiguration?.engagementUiUrl}/clients/${selectLegacyClient}/setup`}
              open={lagacyOpen}
              closeButtonText={t(
                "translations:landing.manage-client.manage_auto_data_extraction"
              )?.toUpperCase()}
              onClose={() => setLagacyOpen(!lagacyOpen)}
            />
          </Box>
        </Box>
      ) : (
        <>
          <UnauthorizedNotification message={t("common:permissions.access-denied-message")} />
        </>
      )}
    </>
  );
};
