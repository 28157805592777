import { useState, useEffect } from "react";
import { DisplayIdleModalProps } from "./@Constants/DisplayIdleModalProps";
import { sessionTimeoutEnum } from "@cortex-apps/main/src/screens/Landing/@constants/constants";
import { Backdrop, Box, Modal, Text, TextVariants, ButtonTypes, Button } from "@cortex-libs/prism";
import { useTranslation } from "@cortex-libs/i18n";
import { LOCAL_STORAGE_KEYS } from "@cortex-libs/dmf";
import IdleIcon from "./IdleIcon";

export const DisplayIdleModal = ({
  logoutAction,
  isOpen,
  stayloggedIn,
}: DisplayIdleModalProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(isOpen);
  const [timer, setTimer] = useState<number>(
    (sessionTimeoutEnum.SESSION_IDLE_TIMEOUT - sessionTimeoutEnum.SESSION_IDLE_WARNING) / 1000
  );
  const { t } = useTranslation();
  let interval: NodeJS.Timeout;
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.DISPLAYIDLEOPEN, "TRUE");
    interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  useEffect(() => {
    if (!open && interval) {
      clearInterval(interval);
    }
  }, [open]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const closeIdleModal = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.DISPLAYIDLEOPEN, "FALSE");
    setOpen(false);
  };

  const handleLogout = () => {
    closeIdleModal();
    logoutAction();
  };

  const handleStayloggedIn = () => {
    closeIdleModal();
    stayloggedIn();
  };

  useEffect(() => {
    if (timer === 0) {
      handleLogout();
    }
  }, [timer]);

  return (
    <Backdrop open={open}>
      <Modal open={open} hideCloseButton closeAfterTransition>
        <Box p={5} width="396px" data-test-id="idle-modal-container">
          <Box pb={2} display="flex" alignItems="center">
            <Box ml={3}>
              {/* Importing the icon seperately because as the timer changes , it flickers  */}
              <IdleIcon />
            </Box>
            <Box ml={-1}>
              <Text type={TextVariants.SUB_HEADING_1}>
                {t("translations:display-idle-modal.heading")}
              </Text>
            </Box>
          </Box>
          <Text type={TextVariants.BODY_COPY_REGULAR}>
            {t(`translations:display-idle-modal.description`)}
          </Text>
          <Text type={TextVariants.BODY_COPY_REGULAR}>
            {t(`translations:display-idle-modal.time-remaining`)} {formatTime(timer)}{" "}
          </Text>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              buttonType={ButtonTypes.SECONDARY}
              onClick={handleLogout}
              data-test-id="logout-Button"
            >
              {" "}
              {t(`translations:display-idle-modal.logOut`)}
            </Button>
            <Box pl={2}>
              <Button
                buttonType={ButtonTypes.PRIMARY}
                onClick={handleStayloggedIn}
                data-test-id="stayLoggedIn-Button"
              >
                {t(`translations:display-idle-modal.stay-logged-in`)}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Backdrop>
  );
};
