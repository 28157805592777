import { useTranslation } from "react-i18next";

import { Box, Container, Text, TextVariants, Link } from "@cortex-libs/prism";

export const AccessDenied = (): JSX.Element => {
  const { t } = useTranslation();
  /***
   * This is Temperory Page Figma is not created
   */
  return (
    <Container fixed>
      <Box p={10} textAlign="left">
        <Text type={TextVariants.SUB_HEADING_2}>
          {t("translations:access-denied.access-denied-header")}
        </Text>
        <Box mb={5}>
          <Text>
            <ul>
              <li>{t("translations:access-denied.access-denied-description1")}</li>
              <li>{t("translations:access-denied.access-denied-description2")}</li>
              <li>{t("translations:access-denied.access-denied-description3")}</li>
            </ul>
            <Text>{t("translations:access-denied.access-denied-description4")}</Text>
            <Text>
              {t("translations:access-denied.access-denied-description5")}
              <Link href={t("translations:access-denied.access-denied-mailTo")}>
                {t("translations:access-denied.access-denied-email")}
              </Link>
            </Text>
          </Text>
        </Box>
      </Box>
    </Container>
  );
};
