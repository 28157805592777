import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  CreateSupportTicketModalStyles: {
    "& [class*='MuiInputBase-input']": {
      textOverflow: "ellipsis",
      fontSize: "12px",
    },
    "& [class*='MuiTooltip-popper']": {
      width: "100px !important",
    },
  },
});
