import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js"; // Import Click Analytics Plugin
import { useConfiguration } from "@cortex-libs/dmf";

type useAppInsightsReturnTypes = {
  appInsights: ApplicationInsights | null;
};

let appInsights: ApplicationInsights | null = null;
export function useAppInsights(): useAppInsightsReturnTypes {
  const { baseConfiguration } = useConfiguration();
  const browserHistory = createBrowserHistory();
  const reactPlugin = new ReactPlugin();
  const clickPluginInstance = new ClickAnalyticsPlugin();
  const clickPluginConfig = {
    autoCapture: true,
  };
  if (baseConfiguration && !baseConfiguration?.appInsightsInstrumentationKey)
    {
      throw new Error("Instrumentation key not provided ");    
  }
  if (!appInsights && baseConfiguration?.appInsightsInstrumentationKey) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: baseConfiguration?.appInsightsInstrumentationKey,
        extensions: [reactPlugin, clickPluginInstance],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
          [clickPluginInstance.identifier]: clickPluginConfig,
        },
      },
    });
    appInsights?.loadAppInsights();
  }

  return {
    appInsights,
  } as const;
}
